import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { SelectChangeEvent } from '@mui/material/Select';
import { convertToBase64 } from '../../../components/src/commanMethods';

interface City {
  id: number;
  name: string;
}

interface CityApiResponse {
  cities: City[];
}

interface FileWithPreview extends File {
}

type SelectedFiles = FileWithPreview[];

type Previews = string[];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  tokenFromOtpScreen: any;
  sellerFormClose: any;
  handleOpenPrivacyModal?:()=>void;
  handleTermsModal?:()=>void;
  handleCancelButton?:()=>void;
  countryCode:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addresssLine1: string;
  addresssLine1Error: string;
  addresssLine2: string;
  addresssLine2Error: string;
  city: string,
  cityData: object,
  comRegistrationNum: string,
  comRegistrationNumError: string,
  selectedFiles: SelectedFiles,
  previews: Previews,
  errorMessage: string,
  termsCondition: boolean,
  otpForSecondForm: string,
  imageUploadBackendError: string,
  openDropdown: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailRegistrationSellerForm2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  cityCallId: string = "";
  secondFormCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addresssLine1: '',
      addresssLine1Error: '',
      addresssLine2: '',
      addresssLine2Error: '',
      city: '',
      cityData: [],
      comRegistrationNum: '',
      comRegistrationNumError: '',
      selectedFiles: [],
      previews: [],
      errorMessage: '',
      termsCondition: false,
      otpForSecondForm: '',
      imageUploadBackendError:  '',
      openDropdown: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.cityCallId){
          this.handleCityApi(responseJson)
        }
        if(apiRequestCallId === this.secondFormCallId){
          this.handleSecondSellerForm(responseJson)
        }
      }
    }
  }

  async componentDidMount() {
    this.handleCity(this.props.countryCode)
  }

  handleSecondSellerForm = (responseJson: any) => {
    if(responseJson && responseJson.meta && responseJson.meta.message){
      this.props.sellerFormClose()
    }
    if (responseJson && responseJson.errors){
      this.setState({imageUploadBackendError: responseJson.errors[0].message})
    }
  }

  handleFormSubmit = async () => {
    const base64Images = []

    for (const file of this.state.selectedFiles) {
      const base64Image = await convertToBase64(file);
      base64Images.push(base64Image);
  }

    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "Token": this.props.tokenFromOtpScreen
    };

    const body = {
      "accounts": {
        "address": this.state.addresssLine1,
        "additional_address": this.state.addresssLine2,
        "commercial_number": this.state.comRegistrationNum,
        "city": this.state.city,
        "commercial_certificates": base64Images
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.secondFormCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleCity = (countryCode:string) => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityApiEndPoint+countryCode
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  validateAndSetFiles = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previews: string[] = [];
    let errorMessage = '';

    files.forEach((file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);

        previews.push(URL.createObjectURL(file));
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...validFiles],
      previews: [...this.state.previews, ...previews],
      errorMessage,
    });
  };

  handleAddresssLine1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({addresssLine1: event.target.value})
  }

  handleAddresssLine2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({addresssLine2: event.target.value})
  }

  handleCityChange = (event: SelectChangeEvent) => {
    this.setState({city: event.target.value})
  }

  handleComRegistrationNum = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({comRegistrationNum: event.target.value})
  }

  handleCityApi = (responseJson: CityApiResponse) => {
    this.setState({cityData: responseJson.cities})
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleTermsCondition = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({termsCondition: event.target.checked})
  }
  
  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    this.handleFormSubmit()
  }

  handleRemoveFile = (index: number) => {
    const { selectedFiles, previews } = this.state;
  
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);
  
    this.setState({
      selectedFiles: newSelectedFiles,
      previews: newPreviews,
    });
  };

  handleIconClick = () => {
    this.setState((prevState) => ({ openDropdown: !prevState.openDropdown })
  )};
  // Customizable Area End
}
