import React from "react";

// Customizable Area Start
import moment from "moment";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div
        data-test-id="termsAndConditionsShopper"
        style={{ background: "rgb(237, 230, 220)" }}
      >
        <ShopperHeader />
        <div
          style={{
            border: "1px solid rgba(83, 80, 63, 1)",
            background: "rgba(255, 255, 255, 1)",
            width: "80vw",
            margin: "5% auto",
            boxSizing: "border-box",
            borderRadius: "4px",
            position: "relative",
            paddingBottom: "80px",
          }}
        >
          <div
            style={{ ...styles.headerTitle, padding: "30px 40px 24px 40px" }}
          >
            {this.state.title}
          </div>
          <hr
            style={{
              width: "100%",
              color: "rgba(83, 80, 63, 1)",
              position: "relative",
            }}
          />
          <div
            style={{
              padding: "40px",
              boxSizing: "border-box",
              ...styles.mainText,
            }}
          >
            <span style={{color:"#53503F",fontWeight:400,fontSize:"18px",lineHeight:"30px"}}>
              {this.state.conditionType[this.state.title]}
            </span>
          </div>
        </div>
        <ShopperFooter />
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  headerTitle:{
    fontWeight:600,
    fontFamily: "'Montserrat', sans-serif",
    color:"rgba(83, 80, 63, 1)",
    fontSize:"24px"
  },
  mainText:{
    fontWeight:400,
    fontFamily: "'Montserrat', sans-serif",
    color:"rgba(83, 80, 63, 1)",
    fontSize:"18px",
    linHeight:"30px",
    letterSpacing:"0.5px"
  }
}
// Customizable Area End
