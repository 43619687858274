Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'


exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.homeText='Home'
exports.shoppingCartHeading ='Shopping Cart '
exports.itemsText='items'

exports.stateEndPoint=(countryCode)=>`/account_block/accounts/country_code_and_flags/county_based_state?country_code=${countryCode}`
exports.moveToFavorite='bx_block_wishlist/wishlists/add_favorite_or_remove'
exports.removeItemCartEndPointe=(id)=>`bx_block_shopping_cart/order_items/${id}`
exports.add_quantityEndPoint='bx_block_shopping_cart/order_items/add_quantity'
exports.remove_quantityEndPoint='bx_block_shopping_cart/order_items/remove_quantity'
exports.addressEndPoint='bx_block_address/addresses'
exports.addressUpdateApiEndPoint=(addressId)=>`/bx_block_address/addresses/${addressId}`
exports.cityListEndPoint=(country_code,stateCode)=>`account_block/accounts/country_code_and_flags/show_cities?country_code=${country_code}&state_code=${stateCode}`
exports.countryListEndPoint=`account_block/accounts/country_code_and_flags.json`
exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.endPointCartEmptyCart='bx_block_catalogue/catalogues/new_arrivals'
exports.endPointgetcategoriesArrayData='bx_block_categories/categories'
exports.filteredCatalogues=(categoryId)=>`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
exports.endPointcategories_hoverData=(categorID)=>`bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
exports.checkoutApiEndPoint='/bx_block_cfskipcashintegration_0/payments/checkout'
exports.confirmApiEndPoint=(transaction_id)=>`bx_block_cfskipcashintegration_0/payments/confirm_payment?transaction_id=${transaction_id}`
exports.model_Number='Modal Number: '
exports.colorText='Color:'
exports.sizeText='Size'
exports.quantityText='Quantity'
exports.move_to_Favorites='Move to Favorites'
exports.listed_Price='Listed Price:'
exports.approved_Offer='Approved Offer:'
exports.address='ADDRESS'
exports.add_address='Add Address'
exports.default='Default'
exports.shippingAddress='Shipping address'
exports.summary='Summary'
exports.subtotal='Subtotal'
exports.delivery='Delivery'
exports.taxes='Taxes'
exports.authentication_Fee='Authentication Fee'
exports.total='Total'
exports.secureCheckoutText='Secure checkout with SSL data encryption'
exports.checkout_Securely='Checkout Securely'
exports.yourCartIsEmpty='Your Cart is empty'
exports.looksLikeYouHaventMadeYourChoiseYet=`Looks like you haven’t made your choice yet..`
exports.startShopping='Start Shopping'
exports.exploreMore='Explore More',
exports.search_for='Search for..'
exports.yourAddress='Your address',
exports.address_Line_1='Address Line 1'
exports.address_Line_2='Address Line 2'
exports.postal_Code='ZIP/Postal Code*'
exports.city='City*'
exports.state='State*'
exports.country='Country*'
exports.Select='Select'
exports.save='Save'
exports.remove='Remove'
exports.cancel='Cancel'
exports.make_this_address_as_default='Make this address as default'
exports.phoneNumber='Phone Number'
exports.phoneErr='Invalid phone number'
exports.enterHere='Enter here'
exports.confirmation='Confirmation'
exports.are_you_sure_you_want_to_remove='Are you sure you want to remove it from cart?'
exports.continue='Continue'
exports.payment_Successful='🎉 Payment Successful!'
exports.payment_Failed='⚠️ Payment Failed!'
exports.patchApiMethod = 'PATCH'
exports.paymentSuccessMessage=`Your transaction was completed successfully. Thank you for your purchase! 
Your order is now being processed and will be on its way shortly.`
exports.payment_FailedMessage=`Oops! Something went wrong with your payment. Please check your card details and try again.
 For further assistance, contact our support team.`
exports.retry_Again=`Retry Again`
exports.low_stock_alert='Low stock alert!'
exports.add_address_before_checkout='Add address before checkout'
// Customizable Area End
