Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.endPointgetcategoriesArrayData='bx_block_categories/categories'

exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.personal_Information='Personal Information';
exports.profile='Profile'
exports.edit_Profile='Edit Profile';
exports.profileEndPointApi='bx_block_profile/profiles'
exports.deleteMethod='DELETE'
exports.countryListEndPoint=`account_block/accounts/country_code_and_flags.json`
exports.updateProfileApiEndPoint= (profileId)=>`/bx_block_profile/profiles/${profileId}`
exports.directUploadApiEndPoint='bx_block_attachment/file_attachments/direct_upload'
exports.directRemoveApiEndPoint=(blob_key)=>`bx_block_attachment/file_attachments/remove_direct_upload?blob_key=${blob_key}`
exports.first_Name='First Name'
exports.last_Name='Last Name'
exports.email='Email'
exports.gender='Gender'
exports.location='Location'
exports.date_of_Birth='Date of Birth'
exports.phone_Number='Phone Number'
exports.phoneErr='Invalid phone number'
exports.first_Name='First Name*'
exports.Your_first_name='Your first name'
exports.Select='Select'
exports.last_Name='Last Name*'
exports.Your_last_name='Your last name'
exports.email='Email*'
exports.your_Email='Your email'
exports.phone_Number='Phone Number'
exports.location='Location*'
exports.date_of_Birth='Date of Birth*'
exports.Gender='Gender'
exports.male='Male'
exports.female='Female'
exports.save='Save'
exports.edit_Profile='Edit Profile'
exports.gender='Gender*'
exports.success='Success!'
exports.your_Profile_details_have_been_updated_successfully=`Your Profile details have been updated successfully`
exports.go_to_Profile='Ok'
exports.emailErr='Invalid email'

exports.view_permit_document='View Permit Document'
exports.commercial_registration_number='Commercial Registration Number'
exports.address='Address'
exports.enter_here='Enter here'
exports.upload_commercial_registration_permit='Upload commercial registration permit'
exports.drag_and_Drop_here='Drag & Drop here'
exports.orText='OR'
exports.browse_files='Browse files'
exports.fileSizeText=`Only supports .jpg, .png and .pdf files` 
exports.fileSizeText2=`file size should be more than 10 kb and not more than 5 MB`
exports.permit_Document_Downloaded='✅ Permit Document Downloaded'
exports.your_Permit_Document_has_been_successfully_downloaded='Your Permit Document has been successfully downloaded.' 
exports.check_your_downloads_folder_to_access_it='Check your downloads folder to access it.'
exports.open_Downloads_Folder='Open Downloads Folder'
// Customizable Area End

