import React from "react";

// Customizable Area Start
import { styled } from "@mui/material/styles";
import {handleReturnElement,ArrowTopBox,MainParentBoxFoundRequest, ImageParentBoxFoundRequest, RightParentBoxFoundRequest, RightFirstTopBoxFoundRequest, RightMiddleBoxFoundRequest, ResponsiveButton} from "./RequstDetailsScreen.web";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import { Box, Modal, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {rightArrow,downLoadIcon,editIconVariants,editIcon} from "./assets"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AddCartConfirmationPopUp from "../../../components/src/popups/addToCartConfirmation.web"
import HideProductModal from "../../../components/src/popups/HideProducts.web"
import moment from "moment";
import {RemoveCartModalBox,CancelRemoveButtonBox,RemoveButton,CancelButton} from "./Analytics.web";
import CloseIcon  from "@mui/icons-material/Close";

// Customizable Area End

import RequstDetailsScreenController, {
  Props,
  configJSON
} from "./RequstDetailsScreenController.web";

export default class ProductsDetailsScreen extends RequstDetailsScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  varaiantsModal = () => {
    return (
      <Box>
        <Modal
          open={this.state.openVariantsModal}
          onClose={() => {
            this.setState({ openVariantsModal: false });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-test-id="isAddressModalOpen"
          sx={{
            maxWidth: "100%",
            backdropFilter: "blur(12px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
          disableAutoFocus
          disableEnforceFocus
        >
          <Box
            sx={{
              boxSizing: "border-box",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: "6px",
              maxWidth: "738px",
              width: "738px",
              outline: "none",
            }}
          >
            <span
              style={{ fontSize: "18px", fontWeight: 600, color: "#53503F" }}
            >
              Product variants
            </span>
            <div
              className="variantsList"
              style={{
                marginTop: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
         {this.state.productDetails?.attributes?.catalogue_variants?.map((ele:any,index:any)=>{
  return (index!==0 && <Box
   sx={{
     height: "70px",
     background: "white",
     border: "1px solid #53503F",
     borderRadius: "4px",
     color: "#53503F",
     display: "flex",
     alignItems: "center",
     gap: "30px",
     boxSizing: "border-box",
     padding: "20px 40px",
   }}
 >
   <span
     style={{
       fontWeight: 600,
       fontSize: "16px",
       fontStyle: "montserrat",
     }}
   >
     {index}.
   </span>
   <img
     src={
       ele?.attributes?.images?.[0]?.url
     }
     style={{ borderRadius: "4px", border: "1px solid" }}
     width={50}
     height={50}
     alt="variant image"
   />
   <span
     style={{
       marginLeft: "-14px",
       fontWeight: 600,
       fontSize: "18px",
       fontStyle: "montserrat",
     }}
   >
     {
     ele?.attributes?.price
     }
   </span>
   <div
     style={{
       height: "22px",
       width: "22px",
       borderRadius: "4px",
       background:ele?.attributes?.catalogue_variant_color?.color_code,
     }}
   ></div>
   <span
     style={{
       marginLeft: "-14px",
       fontWeight: 600,
       fontSize: "16px",
       fontStyle: "montserrat",
     }}
   >
     {
       ele?.attributes?.catalogue_variant_color?.name
     }
   </span>
   <span
     style={{
       fontWeight: 600,
       fontSize: "16px",
       fontStyle: "montserrat",
     }}
   >
     {
       ele?.attributes?.one_size ?"One Size":"NO"
     }
   </span>
   <div className="actionsBox" style={{marginLeft:"149px",color:"yelllow",gap:"25px",display:"flex",boxSizing:"border-box"}}>
   <img style={{color:"#53503F",cursor:"pointer"}} src={editIconVariants} alt="edit"
   data-test-id="variantEdit" onClick={()=>{
    this.props.navigation.navigate("Catalogue",{catalogueId:this.state.navigationData?.id,variantId:ele.id});
   }}/>
   <DeleteOutlineIcon style={{color:"#DC2626",cursor:"pointer"}}/>
   </div>
 </Box>)
         })     
      
              }
            </div>
          </Box>
        </Modal>
      </Box>
    );
}
confirmationModal=()=>{
    return (
        <Box  data-test-id="confirmationModal">
            <Modal
                open={this.state.openConfirmationModal}
                onClose={this.handleCloseConfirmationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-test-id='confirmModal'
            >
                <RemoveCartModalBox >
                    <Box data-test-id='crossIconConfirmModal' className="crossIconBox" onClick={this.handleCloseConfirmationModal}>
                       <CloseIcon/>
                    </Box>
                    <Typography style={webStyle.confirmation}>Confirmation</Typography>
                       <Typography className="areYouSureText">Are you sure you want to show
                       product?</Typography>
                    <CancelRemoveButtonBox>
                    <CancelButton data-test-id='NotNowBtn' onClick={this.handleCloseConfirmationModal} variant="contained" >Not now</CancelButton>
                    <RemoveButton data-test-id='yesBtn' onClick={this.handleConfirmationModal} variant="contained" >Yes</RemoveButton>
                    </CancelRemoveButtonBox>
                </RemoveCartModalBox>
            </Modal>
        </Box>
    )
}
buyingRequestTable=()=>{
    return <div >
    {this.state.buyingRequests?.data?.length>0&&<div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <Box className='productDetailsMainBoxDescBox'>
                <Typography style={{ ...webStyle.titleHeading,fontSize:"24px",fontWeight:600 }}>
                    Product Buying Requests ({this.state.buyingRequests?.offers?.length + this.state.buyingRequests?.orders?.length})
                </Typography>
                                     
            </Box>
    
            <Box sx={{display:"flex",gap:"10px"}}>
                <OrderCount>
                                Total Offers
                                <span style={{fontWeight:600,marginLeft:"7px"}}>{this.state.buyingRequests?.offers?.length}</span>
                </OrderCount>
                <OrderCount>
                    Total Orders
                    <span style={{fontWeight:600,marginLeft:"7px"}}>{this.state.buyingRequests?.orders?.length}</span>
                </OrderCount>
            </Box>
    </div>}
    
    {this.state.buyingRequests?.data?.length>0&&<div className="buyingRequestsTabel" style={{marginTop:"23px"}}>
    <Box sx={{ justifyContent: "center",fontFamily: "'Montserrat', sans-serif", }}>
              <Table size="medium" sx={{ background:"white",borderRadius:"8px",width: "100%",fontFamily: "'Montserrat', sans-serif", }}>
                <TableHead style={{fontFamily: "'Montserrat', sans-serif",}}>
                  <TableRow>
                    {configJSON?.productDetailsPageColumns?.map((ele:any) => (
                      <TableCell
                        key={ele.id}
                        style={{...webStyle.nestedtablecellsText as React.CSSProperties,textAlign:"center",fontWeight:600,fontSize:"14px",lineHeight:"22px",color:"rgba(83, 80, 63, 1)"}}
                      >
                        {ele.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.buyingRequests?.data?.map((request:any, idx:any) => (
                    <TableRow key={request.id} sx={{  background:
                        idx % 2 !== 0
                          ? "rgba(251, 250, 248, 1)"
                          : "rgba(255, 255, 255, 1)",}}>
                      <TableCell style={webStyle.nestedtablecellsText as React.CSSProperties}>
                        {request.attributes.uuid}
                      </TableCell>
                      <TableCell style={webStyle.nestedtablecellsText as React.CSSProperties}>
                        {moment(request.attributes?.created_at).format("MMM D, YYYY")}
                      </TableCell>
                      <TableCell style={webStyle.nestedtablecellsText as React.CSSProperties}>
                        {request.attributes?.customer_full_name}
                      </TableCell>
                      <TableCell style={webStyle.nestedtablecellsText as React.CSSProperties}>
                        {request?.attributes?.location}
                      </TableCell>
                      <TableCell style={{...webStyle.nestedtablecellsText as React.CSSProperties,fontWeight:600}}>
                        {request?.attributes?.listed_price||"-"}
                      </TableCell>
                      <TableCell style={{...webStyle.nestedtablecellsText as React.CSSProperties,fontWeight:600}}>
                        {request?.attributes?.offer_price||"-"}
                      </TableCell>
                      <TableCell style={webStyle.nestedtablecellsText as React.CSSProperties}>
                        <div style={{ display: "flex", gap: "15px",float:"right" }}>
                          <ResponsiveButton
                            style={{
                              ...webStyle.sellwithus,
                              backgroundColor: "rgba(83, 80, 63, 1)",
                              fontWeight: 600,
                              height:"30",
                              width:"100px",
                              color: "rgba(237, 230, 220, 1)",
                              fontSize: "12px",
                            }}
                            data-test-id="acceptBtn"
                            onClick={()=>{this.handleOpenAcceptModal(request.id)
                                this.setState({requestType:request?.attributes?.listed_price?"order":"offer"})
                            }}
    
                          >
                          <CheckOutlinedIcon style={{fontSize:"14px",color:"rgba(239, 238, 233, 1)"}}/>  Accept
                          </ResponsiveButton>
                          <ResponsiveButton
                            style={{
                              ...webStyle.sellwithus,
                              fontSize: "12px",
                              height:"30",
                              width:"100px",
                              backgroundColor: "#EDE6DC",
                              fontWeight: 600,
                              color: "#53503F",
                            }}
                            data-test-id="rejectBtn"
    
                            onClick={()=>{this.handleOpenRejectModal(request.id)
                                this.setState({requestType:request?.attributes?.listed_price?"order":"offer"})
                            }
                        }
                          >
                            <CloseOutlinedIcon style={{fontSize:"14px",color:"rgba(83, 80, 63, 1)"}}/>
                            Reject
                          </ResponsiveButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
    </div>}
    </div>
}
  // Customizable Area End

  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
        <div data-test-id="detailsScreenId" 
        style={{background:"rgb(237, 230, 220)"}}>
        <ShopperHeader/>
        <OuterMainBoxFoundRequest>
        <ArrowTopBox>
                    <Typography data-test-id='home' onClick={()=>{
                        this.props.navigation.navigate("Analytics")
                    }} className="arrowHomeText">{"Home"}</Typography>
                    <img src={rightArrow} alt="rightIcon" />

                    <Typography className="arrowHomeText" data-test-id='OffersTest' onClick={()=>{
                        this.props.navigation.navigate("Analytics",{tab:this.state.navigationData?.tabName,subTab:this.state.navigationData?.subTab})
                    }}> {this.state.navigationData?.tabName} </Typography>
                    <img 
                    src={rightArrow} alt="arrowIcon"
                     />
                    <Typography
                     className="arrowLastText">
                        {"Details"}
                        </Typography>
        </ArrowTopBox>
        <MainParentBoxFoundRequest>
                    <ImageParentBoxFoundRequest>
                        <Box 
                         className='smallImagesColumn'
                         sx={{overflowY:this.state.productDetails?.attributes?.catalogue_variants?.[0]?.attributes?.images?.length >5?'scroll':'unset'}}
                         >

                            {this.state.productDetails?.attributes?.catalogue_variants?.[0]?.attributes?.images?.length > 0&&this.state.productDetails?.attributes?.catalogue_variants?.[0]?.attributes?.images.map((element:any) => (
                                <Box style={{
                                    borderRadius: '4.77px',
                                    border: this.state.isActiveImage == element.id ? '1px solid rgba(83, 80, 63, 1)' : '1px solid transparent',
                                    padding: '2.98px',
                                    cursor: 'pointer',
                                    boxSizing: 'border-box',
                                }} 
                                data-test-id='changeImage'
                                onClick={() => this.handleImageChange(element)}
                                >
                                 <Box  className='smallImageDiv'> <img src={element.url} alt="" /> </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box  className='bigImagesBox'
                        sx={{
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            display: "flex",
                            borderRadius: "4px",
                            justifyContent: "center",
                            alignItems: "center",
                            height:" 479.36px",
                            width: "411.9px",
                        }}
                        ><img style={{
                            maxHeight: "100%",
                            height:"241px",
                            width:"241px",
                            maxWidth: "100%",
                            objectFit: "contain"}} src={this.state.isBigImage?.image_url||this.state.isBigImage?.url} alt="" /></Box>
                    </ImageParentBoxFoundRequest>
                    <RightParentBoxFoundRequest>
                        <RightFirstTopBoxFoundRequest>
                             <Box
                              className='requestIdAndStatusParentBox'
                              ><Typography 
                              
                              className="requestedIdText"
                              >{"Modal Number:"} <span>{`#${this.state.productDetails?.attributes?.modal_no}`}</span></Typography>
                                <Typography 
                                className="dateParentBox"
                                style={{...webStyle.titleHeading}}
                                >
                                    {this.state.productDetails?.attributes?.listed_date}

                                </Typography>
                             </Box>
                             <Box 
                             className='requestIdAndStatusParentBox'
                             > 
                             <Typography 
                                className="nameText"> {this.state.productDetails?.attributes?.product_name}
                                </Typography>
                                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                                    <span style={{
                                        fontWeight:400,
                                        fontSize:"16px",
                                        color:"#53503F",
                                    }}>Hide from listing</span>
                                  <Switch
            checked={this.state.productDetails.attributes?.is_hidden}
            data-test-id="switchTest"
            onChange={()=>{
                if(!this.state.productDetails.attributes?.is_hidden){
                        this.setState({openHideModal:true})
                }else{
                    this.setState({openConfirmationModal:true})
                }
            }}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "rgb(164, 163, 157) !importatnt",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  height:"20px",
                  backgroundColor: "#4A463C",
                width:"40px",
                borderRadius:"10px",
                background:"#A4A39D"
              },
              "& .MuiSwitch-thumb":{
                color:"#FFFFFF",
                height:"16px",
                width:"16px",
                marginTop:"5px"
              },
              "& .MuiSwitch-track":{
                height:"20px",
                width:"40px",
                borderRadius:"10px",
                background:"#A4A39D"
              }
            }}
          />
                                </div>

                             </Box>

                             <Box 
                             className='requestIdAndStatusParentBox'
                             > <Typography 
                             className="dateParentBox"
                             style={{...webStyle.titleHeading,fontSize:"28px"}}
                                > {this.state.productDetails?.attributes?.catalogue_variants[0]?.attributes?.price}</Typography>
                             </Box>
                           
                        </RightFirstTopBoxFoundRequest>
                        <RightMiddleBoxFoundRequest style={{borderBottom:"none"}}>
                            <Typography
                                className="productDetailsHeading"
                            >{"Product Details"}
                            </Typography>
                            <Box
                                className='productDetailsMainBox'
                            >
                                <Box className='productDetailsMainBoxTopBox'>
                                    {handleReturnElement("Wear",this.state.productDetails?.attributes?.wearname)}
                                    {handleReturnElement("Category",this.state.productDetails?.attributes?.category)}
                                    {handleReturnElement("Brand",this.state.productDetails?.attributes?.brand)}
                                    {handleReturnElement("Condition",this.state.productDetails?.attributes?.product_condition)}
                                    {handleReturnElement("Material",this.state.productDetails?.attributes?.product_material)}
                                 </Box>
                                <Box className='productDetailsMainBoxLowerBox'>
                                {handleReturnElement("Use",this.state.productDetails?.attributes?.product_use)}
                                {handleReturnElement("Water resistance",this.state.productDetails?.attributes?.product_water_resistance)}
                                {handleReturnElement("Production year",this.state.productDetails?.attributes?.product_year_production)}
                                {handleReturnElement("Packages",this.state.productDetails?.attributes?.packages)}
                                {handleReturnElement("Dust Bags",this.state.productDetails?.attributes?.dust_bag)}
                                {handleReturnElement("sold",this.state.productDetails?.attributes?.sold)}
                                
                                  </Box>
                                <Box className='productDetailsMainBoxDescBox'>
                                    <Typography style={{ ...webStyle.titleHeading }}>Description
                                                                            </Typography>
                                    <Typography style={{ ...webStyle.valueText }}>{this.state.isExpand ? this.state.productDetails?.attributes?.description : this.state.productDetails?.attributes?.description?.slice(0, 145)} <span data-test-id='readMore' style={{ ...webStyle.titleHeading, cursor: 'pointer' }} onClick={() => this.setState({ isExpand: !this.state.isExpand })}>{this.state.isExpand ? 'read less' : 'read more..'}</span></Typography>
                               
                                </Box>
                                <Box sx={{borderRadius:"4px",
                                    background:"rgba(255, 255, 255, 0.5)",
                                    height:"48px",
                                    width:"230px",
                                    boxSizing:"border-box",
                                    padding:"3px 10px",
                                    color:"rgba(83, 80, 63, 1)",
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"space-between",
                                    fontSize:"16px",
                                    fontWeight:600,
                                    cursor:"pointer"
                                    }}>
                                        Certificates ({this.state.productDetails?.attributes?.certificates.length})
                                        <img src={downLoadIcon} alt="downLoadIcon" />
                                </Box>
                                <Box className='productDetailsMainBoxDescBox'>
                                    <Typography style={{ ...webStyle.titleHeading }}>Product variants ({this.state.productDetails?.attributes?.catalogue_variants.length})
                                                                            </Typography>
                                    <Box sx={{height:"70px",background:"white",
                                        border:"1px solid #53503F",
                                        borderRadius:"4px",
                                        color:"#53503F",
                                        display:"flex",
                                        alignItems:"center",
                                        gap:"30px",
                                        maxWidth:"515px",
                                        boxSizing:"border-box",
                                        padding:"10px 20px"
                                    }}>
                                        <span style={{fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>1.</span>
                                        <img src={this.state.productDetails?.attributes?.catalogue_variants?.[0]?.attributes?.images?.[0]?.url}style={{borderRadius:"4px",border:"1px solid"}} width={50} height={50} alt="variant image" />
                                        <span style={{marginLeft:"-14px",fontWeight:600,fontSize:"18px",fontStyle:"montserrat"}}>{this.state.productDetails?.attributes?.catalogue_variants?.[0]?.attributes?.price}</span>
                                        <div style={{height:"22px",width:"22px",borderRadius:"4px",background:this.state.productDetails?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variant_color?.color_code}}></div>
                                        <span style={{marginLeft:"-14px",fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>{this.state.productDetails?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variant_color?.name}</span>
                                        <span style={{fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>{this.state.productDetails?.attributes?.catalogue_variants[0]?.attributes?.one_size}</span>
                                    
                                    </Box>
                                </Box>
                              {this.state.productDetails?.attributes?.catalogue_variants.length >1 &&  <Box className='productDetailsMainBoxDescBox'>
                                    <Typography style={{ ...webStyle.titleHeading,
                                        fontSize:"18px",
                                        textDecoration:"underline",
                                        lineHeight:"24px",
                                        cursor:"pointer"
                                        }}
                                        data-test-id="variantBtn"
                                        onClick={()=>{
                                            this.setState({openVariantsModal:true})
                                        }}
                                        >
                                    view {this.state.productDetails?.attributes?.catalogue_variants.length-1} more variants
                                    </Typography>
                               
                                </Box>}

                                <div style={{display:"flex",gap:"35px",marginTop:"55px"}}>
                            <EditDeleteButton 
                            data-test-id="varianteditBtn"
                            onClick={()=>{
                            
                                this.setState({deleteOrderModal:true})
                            }}
                            style={{color:"#53503F"}}>
                          <DeleteOutlineIcon/>  Delete Product
                            </EditDeleteButton>

                            <EditDeleteButton style={{
                                background:"#53503F",
                                color:"white"
                            }}
                            data-test-id="variantDeleteBtn"
                            onClick={()=>{
                                this.props.navigation.navigate("Catalogue",{catalogueId:this.state.navigationData?.id})
                            }}
                            >
                           <img src={editIcon} alt="edit" /> Edit Product
                            </EditDeleteButton>
                                </div>
                            </Box>
                        </RightMiddleBoxFoundRequest>
               
                      
                    </RightParentBoxFoundRequest>
                  
                    </MainParentBoxFoundRequest>
{this.buyingRequestTable()}
             
        </OuterMainBoxFoundRequest>
              <ShopperFooter/>   
              {this.state.deleteOrderModal && <AddCartConfirmationPopUp 
              question={"Are you sure you want to delete the product?"} 
              handleClose={this.handleDeleteProductsModal}
              open={this.state.deleteOrderModal} 
              handleYes={this.handleConfirmDeleteProducts} 
              />}

{this.state.openAcceptModal &&
 <AddCartConfirmationPopUp 
 question={"Are you sure you want to accept the Buying Request?"} 
 handleYes={this.handleAcceptYesClick} 
 handleClose={()=>{
     this.setState({openAcceptModal:false})
    }} 
    data-test-id="confirmationModal"
      open={this.state.openAcceptModal} />
      }


{this.state.openRejectModal && 
<AddCartConfirmationPopUp question={"Are you sure you want to reject the Buying Request?"
      } 
      handleYes={this.handleRejectYesClick} 
      data-test-id="confirmationModal"
      handleClose={()=>{
        this.setState({openRejectModal:false})
      }} 
      open={this.state.openRejectModal} />
      }
            {this.varaiantsModal()}

    {this.state.openHideModal &&<HideProductModal
    data-test-id="hideProductModal"
      handleConfirmHidePeriod={this.handleConfirmHidePeriod}
      handlePeriodChange={this.handlePeriodChange}
      hidePeriod={this.state.hidePeriod}
      openHideModal={this.state.openHideModal} 
      handleClose={this.handleHideModal}/>}
{this.confirmationModal()}
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const OrderCount= styled(Box)({
    height:"42px",
    border:"1px solid #53503F",
    borderRadius:"4px",
    fontWeight:400,
    fontSize:"18px",
    background:"#FFFFFF",
    padding:"2px 15px",
    alignItems:"center",
    display:"flex",
    justifyContent:"center",
    boxSizing:"border-box"
})
const EditDeleteButton = styled(Box)({
    display: 'flex',
    alignItems:"center",
    border:"1px solid #53503F",
    gap: '8px',
    cursor:"pointer",
    fontSize:"16px",
    fontWeight:600,
    borderRadius:"8px",
    boxSizing: 'border-box',
    justifyContent: 'center',
    height:"56",
    width:"240px",
    '@media (max-width: 400px) ': { padding: '10px',}
})
const OuterMainBoxFoundRequest = styled(Box)({
    display: 'flex',
    padding: '40px 60px 30px 40px',
    gap: '20px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'center',
    '@media (max-width: 400px) ': { padding: '10px',}
})
const webStyle = {
    nestedtablecellsText: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "center",
        color:"rgba(83, 80, 63, 1)"
      },
      confirmation: {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
    titleHeading: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
    },
    valueText: {
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        fontSize: '16px',
    },
    overflowText:{
        overflow: 'hidden',
        whiteSpace: 'nowrap' as 'nowrap',
        textOverflow: 'ellipsis',
    },
    confirmationText: {
        fontSize: '28px',
        fontWeight: 600,
        color: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
    },
    sellwithus: {
        fontFamily:
        "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
        fontSize: "18px",
        gap: "4px",
        padding: "10px 16px",
        border: "1px solid black",
        height: "56px",
        cursor: "pointer",
        borderRadius: "4px",
        width:"157px",
      },
}
// Customizable Area End
