import * as React from "react";
// Customizable Area Start
import {
  createTheme,
  styled,
} from "@mui/material/styles";
import {
  Stepper,
  Step,
  StepConnector,
  stepConnectorClasses,
  Typography,
  Grid,
  Box
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import {rightArrow, orderStatusDot, stepperStatusIcon,paymentVisa,invoiceIcon,locationIcon,locationTracker,orderIssue,needHelpIcon,deliveryIcon} from "./assets"
import { ArrowTopBox } from "./OrderManagementView.web";
import {useNavigate} from "react-router-dom";
import { getStorageData } from "../../../framework/src/Utilities";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";

const steps = [
  { label: "Order Placed", date: "Wed, 18 Aug 2024" },
  { label: "Order Confirmed", date: "Wed, 19 Aug 2024" },
  { label: "Shipped", date: "Wed, 19 Aug 2024" },
  { label: "Out For Delivery", date: "Wed, 24 Aug 2024" },
  { label: "Delivered", date: "Wed, 24 Aug 2024" },
];
// Customizable Area End

import { configJSON, ViewProps } from "./OrderDetails";

const OrderDetailsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  order,
  deleteOrderItem,
  couponCode,
  setCouponCode,
  applyCouponCode,
  navigateToAddress,
  loading,
  handledeleteOrder,
  handleCancelOrder,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const navigate=useNavigate();
  const fetchOrderDetailsId = React.useRef("");
  const categoryListId=React.useRef("")
  const [orderDetailsData,setOrderDetails]=React.useState<any>({})
  const [categoriesArray ,setCategoryArray]=React.useState([])
const [openLogin,setLogingModal]=React.useState(false)


  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];
const {
  sendNetworkRequest,
  setReceiveCallback,
  subscribe,
} = useRunEngine();
const { extractNetworkResponse } = useBlockHelpers();
  const handleNavigate=(path:string)=>{
    navigate(path)
  }
  const fetchOrderDetails =async () => {
    const params=new URLSearchParams(window.location.search)
    const id=params.get('orderId');
    const headers = {
        "Content-Type": "application/json",
        token: await getStorageData('loginToken'),
    };

    sendNetworkRequest(
      fetchOrderDetailsId,
        "GET",
       `/bx_block_order_management/orders/${id}`,
        headers
    );
}
const openLoginModal=()=>{
  setLogingModal(true)
  }
  const handleCategoryData=(categoryName: any, categoryId: any)=>{
    navigate(`/categories?categoryName=${categoryName}&&categoryId=${categoryId}`)
  }
const getFormattedTimestamp = (orderData: any,event:string) => {
  const timeline = orderData;

  const orderPlaced = timeline?.find((item: any) => item.event === event);
  if (orderPlaced) return moment(orderPlaced.timestamp).format("DD MMM YYYY"); // e.g., "05 Sep 2024"

  const doneStatus = timeline?.find((item: any) => item.status === "done");
  if (doneStatus) return moment(doneStatus.timestamp).format("DD MMM YYYY"); // e.g., "18 Aug 2024"

  return null; // Return null if no match is found
};
React.useEffect(()=>{
  setReceiveCallback(receive);
  subscribedMessages.forEach((message) => subscribe(message));
  fetchOrderDetails()
  fetchCategoryList()
},[])
const fetchCategoryList =async () => {
  const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('loginToken'),
  };

  sendNetworkRequest(
      categoryListId,
      "GET",
     "/bx_block_categories/categories",
      headers
  );
}
const receive = async(from: string, message: Message) => {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
          extractNetworkResponse(message);
      if (apiRequestCallId === fetchOrderDetailsId.current || from === "api") {
       if(responseJson){
        setOrderDetails(responseJson.data)
       }
      }if(apiRequestCallId == categoryListId.current || from === "UNIT_TEST"){
        setCategoryArray(responseJson?.data)
      }
  }
};
  const currentStep=2
  const orderDetails=()=>{
    return<div style={{boxSizing:"border-box",
      border:"1px solid rgba(83, 80, 63, 1)",
      borderRadius:"4px",
      background:"white",}}> 
      <div style={{
      boxSizing:"border-box",
      maxWidth:"70rem",
      width:"70rem",
      padding:"40px"
}}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
      <span
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: 600,
          fontSize: "22px",
          lineHeight: "19.25px",
          color: "rgba(83, 80, 63, 1)",
        }}
      >
        Order ID: {orderDetailsData?.attributes?.order_number} 
      </span>
      <div style={{...webStyle.chipMain, boxSizing: "border-box"}}>
        <span style={webStyle.chip} data-testid="orderStatus">
          <img src={orderStatusDot} alt="status" />
          {
          orderDetailsData?.attributes?.status=="payment_failed" ? "Payment Failed":
          orderDetailsData?.attributes?.status?.charAt(0).toUpperCase() + orderDetailsData?.attributes?.status?.slice(1).toLowerCase()
          }
        </span>
      </div>
      </div>
     <div className="orderActions" style={{display:"flex",alignItems:"center",gap:"24px"}}>
        <div style={{
          borderRadius:"4px",
          border:"1px solid #53503F",
          height:"30px",
          padding:"2px 6px",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          gap:"4px",
          cursor:"pointer",
          boxSizing:"border-box",
          fontWeight:600,
          fontSize:"14px",
          color:"#53503F"
        }}>
          <img src={invoiceIcon} alt="invoice" />
        Invoice
        </div>
        <div style={{
          borderRadius:"4px",
          border:"1px solid #53503F",
          height:"30px",
          padding:"2px 6px",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          gap:"4px",
          boxSizing:"border-box",
          cursor:"pointer",
          fontWeight:600,
          fontSize:"14px",
          color:"#EDE6DC",
          background:"#53503F"
        }}>
          <img src={locationTracker} alt="invoice" />
          Track Order
        </div>
     </div>
    </div>
    <div style={{ display: "flex",alignItems:"center", justifyContent: "space-between",marginTop:"30px"}}>
    <span
        style={{
          color: "rgba(83, 80, 63, 1)",
          fontSize: "16px",
          fontWeight: 400,
        }}
      >
      Order Date: {getFormattedTimestamp(orderDetailsData?.attributes?.order_timeline,"Order Placed")}
      </span>
      <span style={{
        marginTop:"15px",
        fontSize:"16px",
        fontWeight:600,
        fontFamily: "'Montserrat', sans-serif",
        color:"rgba(83, 80, 63, 1)",
}}>
{orderDetailsData?.attributes?.status=="rejected"?"Rejected Date":"Estimated delivery"}: {getFormattedTimestamp(orderDetailsData?.attributes?.order_timeline,"Order Rejected")}
</span>
    </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "rgba(239, 238, 233, 1)",
                    marginTop:"21px",
                    border:"none",
                    width: "100%",
                  }}
                />
            {(orderDetailsData?.attributes?.status!=="rejected"&&orderDetailsData?.attributes?.status!=="delivered"&& orderDetailsData?.attributes?.status!=="payment_failed") &&stepperStatus()}

{(orderDetailsData?.attributes?.status!=="rejected"&&orderDetailsData?.attributes?.status!=="delivered" && orderDetailsData?.attributes?.status!=="payment_failed") &&<hr
                  style={{
                    border:"none",
                    height: "1px",
                    backgroundColor: "rgba(239, 238, 233, 1)",
                    width: "100%",
                    marginTop:"70px",
                  }}
                />}
        {orderDetailsData?.attributes?.order_items?.map((ele: any) => (
       <div key={ele.id}>

       <div
           style={{
             gap: "125px",
             display: "flex",
             height: "120px",
             width: "100%",
             marginTop:"30px",
           }}
         >
           <div
             style={{
               alignItems: "center",
               justifyContent: "center",
               borderRadius: "4px",
               display: "flex",
               background: "rgba(237, 230, 220, 1)",
               minWidth: "120px",
               height: "100%",
             }}
           >
             <img
               alt=""
               width={73}
               height={73}
               src={ele.attributes?.catalogue?.catalogue_variant?.attributes?.image_url}
               />
           </div>
           <div
             style={{
               marginLeft: "-100px",
               display: "flex",
               flexDirection: "column",
               gap: "12px",
               color: "rgba(83, 80, 63, 1)",
             }}
           >
             <span
               style={{
                 fontSize: "22px",
                 fontWeight: 600,
                 whiteSpace: "nowrap",
                 overflow: "hidden", 
                 textOverflow: "ellipsis",
                 maxWidth:"206px",
               }}
             >
             {ele.attributes?.catalogue?.catalogue_name?.trim()||""}

             </span>
             <span  style={{
                 fontSize: "18px" ,
                 fontWeight: 400,
                 whiteSpace: "nowrap",
                 overflow: "hidden", 
                 textOverflow: "ellipsis",
                 maxWidth:"206px",
               }}>{ele.attributes?.catalogue?.identity_name}</span>
             <span style={{
                 fontSize: "18px",
                 fontWeight: 400,
                 whiteSpace: "nowrap",
                 overflow: "hidden", 
                 textOverflow: "ellipsis",
                 maxWidth:"206px",
               }}>Modal Number: {ele?.attributes?.catalogue?.catalogue_model_number}</span>
             <span style={{
                 fontSize: "18px",
                 fontWeight: 400,
                 display:"flex",
                 gap:"12px",
                 alignItems:"center",
                 whiteSpace: "nowrap",
                 overflow: "hidden", 
                 textOverflow: "ellipsis",
                 maxWidth:"206px",
               }}>
                           Color: <div style={{border:"1px solid #53503F",height:"12px",width:"12px",borderRadius:"2px",background:ele?.attributes?.catalogue?.catalogue_variant?.attributes?.catalogue_variant_color?.color_code}}></div>

                 </span>
           </div>

       <div
         style={{ 
           display: "flex",
           flexDirection: "column",
           gap: "12px"
         }}
       >
         <span
           className="montserrat"
           style={{
             fontWeight: 400,
             color: "rgba(83, 80, 63, 1)",
             fontSize: "16px",
           }}
         >
           Size
         </span>
         <span
             className="montserrat"
           style={{
             fontWeight: 600,
             fontSize: "18px",
             color: "rgba(83, 80, 63, 1)",
           }}
         >
                   {ele.attributes?.catalogue?.catalogue_variant?.attributes?.size}

         </span>
       </div>
       <div
         style={{ display: "flex", flexDirection: "column", gap: "12px" }}
       >
         <span
           className="montserrat"
           style={{
             color: "rgba(83, 80, 63, 1)",
             fontWeight: 400,
             fontSize: "16px",
           }}
         >
           Quantity
         </span>
         <span
           style={{
             color: "rgba(83, 80, 63, 1)",
             fontSize: "18px",
             fontWeight: 600,
           }}
           className="montserrat"
         >
          {ele?.attributes?.quantity}
         </span>
       </div>
       <span
         style={{
           fontWeight: 600,
           fontSize: "24px",
           color: "rgba(83, 80, 63, 1)",
         }}
       >
        {ele.attributes?.total_price}
       </span>
     </div>
<hr
                 style={{
                   marginTop:"35px",
                   backgroundColor: "rgba(239, 238, 233, 1)",
                   height: "1px",
                   width: "100%",
                   border:"none"
                 }}
               />
       </div>

       ))}
 


            <div className="paymentAndAddress"
            style={{marginTop:"35px",display:"flex",justifyContent:"space-between"}}
            >
              <Grid container spacing={3} sx={{ marginTop: "35px" }}>
              <Grid item xs={12} md={6}>
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  color:"#53503F"
                }}
              >
                Payment
              </span>
              <span className="montserrat" style={{display:"flex",alignItems:"center",gap:"11px",fontSize: '20px',fontWeight: 400, color: 'rgba(83, 80, 63, 1)', lineHeight: '30px'}}>
              Visa **56  <img src={paymentVisa} alt="ete" />
              </span>
             </Grid>
            <Grid item xs={12} md={6}>
              <Box style={{display: 'flex',margin: '0px 15px'}}>
               <img style={{margin: '0px 15px'}} src={locationIcon} alt="locationIcon" />
               <div>
                <div className="montserrat" style={webStyle.shippingAddressText}>Shipping address</div>
                <div style={webStyle.shippingAddress} className="montserrat">
                        {orderDetailsData?.attributes?.address?.attributes?.address_line_1 || orderDetailsData?.attributes?.address?.attributes?.address_line_2}{orderDetailsData?.attributes?.address?.attributes?.address_line_1 && ',' || orderDetailsData?.attributes?.address?.attributes?.address_line_2 && ','} {orderDetailsData?.attributes?.address?.attributes?.postal_code} {orderDetailsData?.attributes?.address?.attributes?.city!=='nil'&&orderDetailsData?.attributes?.address?.attributes?.city }{","}{orderDetailsData?.attributes?.address?.attributes?.country}
               </div>
              </div>
                  </Box>
                </Grid>
              </Grid>

            </div>

            <hr
                  style={{
                    marginTop:"35px",
                    backgroundColor: "rgba(239, 238, 233, 1)",
                    height: "1px",
                    width: "100%",
                    border:"none"
                  }}
                />
            <div style={{display:"flex",justifyContent:"space-between",marginTop:"35px"}}>
                  <div style={{display:"flex",flexDirection:"column",gap:"12px"}}
                  
                  >
                  <span className="montserrat" style={webStyle.normalText}>Need Help</span>
                  <div style={{display:"flex",gap:"8px",cursor:"pointer"}}
                 data-testid="orderIssueBtn"  onClick={()=>handleNavigate("/Contactus")}
                  >
                  <img src={orderIssue} alt="issue" />
                  <span className="montserrat" style={webStyle.normalText}>Order Issues</span>
                  <img src={needHelpIcon} alt="issue" />
                  </div>
                 <div style={{display:"flex",gap:"8px",cursor:"pointer"}}
                data-testid="deliveryInfoBtn">
                 <img src={deliveryIcon} alt="test" />
                  <span  className="montserrat"style={webStyle.normalText}>Delivery Info</span>
                 <img src={needHelpIcon} alt="issue" />
                 </div>
                 {orderDetailsData?.attributes?.status === 'rejected'||orderDetailsData?.attributes?.status=="payment_failed" ?
                 <div style={{display:"flex",gap:"8px",cursor:"pointer"}}
                data-testid="deliveryInfoBtn">
                 <img src={deliveryIcon} alt="test" />
                  <span  className="montserrat"style={webStyle.normalText}>Refund Info</span>
                 <img src={needHelpIcon} alt="issue" />
                 </div> : null }
                  </div>
                  <div style={{width:"23rem",marginRight:"100px"}}>
                  <span  className="montserrat"style={webStyle.normalText}>Order Summary</span>
                  <div style={{width:"100%",marginTop:"12px",display:"flex",flexDirection:"column",gap:"!2px"}}>
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <span  className="montserrat"style={webStyle.normalText}>Delivery</span>
                  <span>{orderDetailsData?.attributes?.order_summary?.delivery_charges}</span>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <span  className="montserrat"style={webStyle.normalText}>Taxes</span>
                  <span>{orderDetailsData?.attributes?.order_summary?.taxes_charges}</span>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <span  className="montserrat"style={webStyle.normalText}>Authentication Fee</span>
                  <span>{orderDetailsData?.attributes?.order_summary?.authentication_charges}</span>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <span  className="montserrat"style={webStyle.normalText}>Subtotal</span>
                  <span style={{fontWeight:600,color:"#53503F"}}>{orderDetailsData?.attributes?.order_summary?.sub_total}</span>
                  </div>
                  <hr
                  style={{
                    height: "1px",
                    backgroundColor: "rgba(239, 238, 233, 1)",
                    margin:"16px 0px",
                    border:"none",
                    width: "100%",
                  }}
                />
                  <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <span  className="montserrat"style={{...webStyle.normalText,fontWeight:600}}>Total</span>
                  <span style={{fontWeight:600,fontSize:"22px", color:"#53503F",}}>{orderDetailsData?.attributes?.order_summary?.total}</span>
                  </div>
                  </div>
                  </div>
            </div>

 
</div>
          {(orderDetailsData?.attributes?.status=="rejected"||orderDetailsData?.attributes?.status=="payment_failed") && <div style={{width:"100%",height:"58px",background:"#EFEEE9",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            color:"#53503F"
          }}>
        {orderDetailsData?.attributes?.status=="payment_failed"?"Failed payment, money debited? Expect a refund in 3-5 business days.":
         "Refund is initiated in the original payment source"
        } 
          </div>}
          </div>
  }
  const stepperStatus=()=>{
    return (
      <div style={{ marginTop:"70px",width: "100%", textAlign: "center", marginLeft:"0px",boxSizing:"border-box",padding: "20px" }}>
        {/* --- Render Labels Above Stepper --- */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          {orderDetailsData?.attributes?.order_timeline?.map((step:any, index:number) => (
            <div
              key={index}
              style={{ width: "20%",color:"rgba(83, 80, 63, 1)",fontSize: "18px", fontWeight: 400 }}
            >
              {step.event}
            </div>
          ))}
        </div>
  
        {/* --- Stepper with Custom Icons --- */}
        <Stepper alternativeLabel>
        {orderDetailsData?.attributes?.order_timeline.map((step:any, index:number) => (
          <Step key={index} completed={index <= currentStep}>
            {/* --- Apply Custom Connector between steps dynamically --- */}
            {(step.status=="done"&& step.event!=="Order Placed") && <CustomConnector completed={step.status=="done"} />}
            <div>
              {step.status=="done" ? (
                <img src={stepperStatusIcon} alt={"sx"}/>
              ) : (
                <CircleOutlinedIcon style={{ color: "gray", fontSize: "20px" }} />
              )}
            </div>
          </Step>
        ))}
      </Stepper>
  
        {/* --- Render Dates Below Stepper --- */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          {orderDetailsData?.attributes?.order_timeline.map((step:any, index:number) => (
            <div
              key={index}
              style={{ width: "20%",color:"rgba(83, 80, 63, 1)",fontSize: "16px", fontWeight: 400 }}
            >
              { moment(step.timestamp).format("ddd, DD MMM YYYY")}
            </div>
          ))}
        </div>
      </div>
    );
  }
  // Customizable Area End

  return (
    // Customizable Area Start
    <div style={{background:"rgba(237, 230, 220, 1)"}}>
    <AppHeader/>
    <div style={{width:"100%",
          boxSizing:"border-box",
          padding:"40px 40px 100px 40px",
          }}>

<ArrowTopBox>
                    <Typography data-testid='home' onClick={()=>handleNavigate("/")} className="arrowHomeText">{"Home"}</Typography>
                    <img src={rightArrow} alt="test" />
                    <Typography className="arrowHomeText" data-testid="orderList" onClick={()=>handleNavigate("/OrderManagementView")} style={{fontWeight:400}}> {"My Orders"}</Typography>
                    <img src={rightArrow} alt="test" />
                    <Typography className="arrowHomeText" data-testid='OffersTest' style={{fontWeight:600}}> {"Details"}</Typography>
        
        </ArrowTopBox>

        <div style={{display:"flex",
              flexDirection:"column",
              marginTop:"20px",
              alignItems:"center"
              }}>
            {orderDetails()}
 
            </div>
    </div>
    <Footer openLoginModal={openLoginModal}  handleCategoryData={handleCategoryData} categoriesList={categoriesArray}/>

    </div>
    // Customizable Area End
  );
};

// Customizable Area Start
const CustomConnector = styled(StepConnector)<{ completed?: boolean }>(({ completed }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: completed ? "#53503F" : "rgba(239, 238, 233, 1)",
    borderTopWidth: 3,
    minHeight:3
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle ={
  normalText:{
    fontFamily: "'Montserrat', sans-serif",
      color:"#53503F",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "0%",
    },
  chipMain: {
    display: "flex",
    borderRadius: "15px",
    height: "28px",
    padding: "4px 12px",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(83, 80, 63, 1)",
    background:"rgba(239, 238, 233, 1)"
  },
  chip: {
    color: "rgba(83, 80, 63, 1)",
    fontSize: "14px",
    fontWeight: 600,
    display:"flex",
    gap:"6px",
    alignItems:"center"
  },
  shippingAddressText: {
    fontSize: '18px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)',
    lineHeight: '24px'
  },
  shippingAddress: {
    marginTop:"5px",
    fontSize: '20px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)'
  }
};
// Customizable Area End

export default OrderDetailsView;
