//@ts-nocheck

import React from "react";
// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Chip,
  Divider,
  Grid
} from "@mui/material";
import { rightArrowIcon, rightIcon, whiteRightArrow, placeholderImage } from "./assets";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WithdrawOfferPopup from '../../../components/src/popups/withdrawOfferPopup.web';
import {Footer} from '../../../components/src/Footer.web';
import {AppHeader} from '../../../components/src/AppHeader.web';
// Customizable Area End

import OfferProductDescriptionController, {
  Props, configJSON
} from "./OfferProductDescriptionController";


export default class OfferProductDescription extends OfferProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      offer_id, 
      status,
      offer_date, 
      order_price, 
      offer_price, 
      wearname, 
      category_name, 
      brand_name, 
      condition, 
      material, 
      product_use,
      product_water_resistance,
      product_year_production,
      packages,
      dust_bag,
      description
    } = this.state.productDesc;
    
    let variant = this.state.productDesc.variant_info;
    let productImages = this.state.productDesc.variant_info?.attributes.images;
    let bigImage =  this.state.productDesc.variant_info?.attributes.images[0].url;

    const labelArray = [
      {id: 0, firstLabel: 'Wear',secondLabel: wearname},
      {id: 1, firstLabel: 'Category',secondLabel: category_name},
      {id: 2, firstLabel: 'Brand',secondLabel: brand_name},
      {id: 3, firstLabel: 'Condition',secondLabel: condition},
      {id: 4, firstLabel: 'Material',secondLabel: material},
      {id: 5, firstLabel: 'Use',secondLabel: product_use},
      {id: 6, firstLabel: 'Water Resistance',secondLabel: product_water_resistance},
      {id: 7, firstLabel: 'Year of Production',secondLabel: product_year_production},
      {id: 8, firstLabel: 'Packages',secondLabel: packages},
      {id: 7, firstLabel: 'Dust Bag',secondLabel: dust_bag},
    ] 
    
    return (
      // Customizable Area Start
      <>
      <div
        data-test-id="productContainer"
        style={{
          backgroundColor: "#EDE6DC",
          textAlign: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <AppHeader count={this.state.cartCount}/>
      <WithdrawOfferPopup data-testid="deletePopup" open={this.state.deleteOffer} handleClose={this.handleDeletePopupClose} handleLogOut={this.handleDeleteOfferRequest}/>
      <Box style={{backgroundColor:"rgb(237, 230, 220)"}}>
      <Box data-testid="offerProductDescription" style={webStyleNew.mainBox}>
      <Box style={webStyleNew.navigation}>
        <Typography className="montserratFont" style={{cursor: 'pointer',fontSize: '18px', color: 'rgba(83, 80, 63, 1)' }} data-testid="homeNavigateTestId" onClick={this.handleHomeNavigate}>Home</Typography>
        <img src={rightIcon} alt='rightIcon'/>
        <Typography className="montserratFont" style={{cursor: 'pointer',fontSize: '18px', color: 'rgba(83, 80, 63, 1)'}} data-testid="offerNavigateTestId" onClick={this.handleOfferNavigate}>Orders</Typography>
        <img src={rightIcon} alt='rightIcon'/>
        <Typography className="montserratFont" style={{cursor: 'pointer',fontSize: '18px', color: 'rgba(83, 80, 63, 1)',fontWeight: 600}}>Details</Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid container item md={4} lg={4} sm={12} className="productDetails" sx={webStyleNew.productMain}>
          <Grid container item md={1} lg={1} sm={2} xs={12} className="productImages" sx={webStyleNew.productImage} >
            {productImages?.map((item) => 
              <div 
              key={item.id} 
              data-testid='imageTestId' 
              style={{...webStyleNew.imageMainDiv,
                border: this.state.imageId === item.id ? "1px solid rgba(83, 80, 63, 1)" : "none", 
                }} 
              onClick={() => this.handleBigImage(item.url, item.id)}
              >
                <div data-test-id="bigImgId" style={webStyleNew.bigImage}>
                  <img
                    style={{ borderRadius: "4px" }}
                    height={67}
                    width={67}
                    src={item.url || placeholderImage}
                    alt=""
                  />
                </div>
              </div> 
              // console.log("ItemData",item) 
            )}
          </Grid>
          <Grid item md={10} lg={10} sm={12} xs={12} container className="bigImg" sx={webStyleNew.bigImageStyle}>        
            <img
              style={webStyleNew.bigImageData}
              src={this.state.bigImage || placeholderImage}
              alt=""
            />
          </Grid>
        </Grid>
        <Grid md={8} lg={8} sm={12} style={webStyleNew.rightSideDescription}>
            <Box style={webStyleNew.descriptionMain}>
              <Typography className="montserratFont" style={webStyleNew.offer}>Offer ID: 
                <span style={webStyleNew.offerId}># {offer_id}</span>
              </Typography>
              <Box>
                <Typography className="montserratFont" style={webStyleNew.date}> {offer_date} </Typography>
                <Chip label={status} sx={webStyleNew.chipStyle} />
              </Box>
            </Box>
            <Box sx={webStyleNew.productNameMain}>
              <Typography className="montserratFont" style={webStyleNew.productName}> Rolex Watch </Typography>
            </Box>
            <Box style={webStyleNew.listedItem}>
              <Box style={webStyleNew.listedPriceMain}>
                <Typography className="montserratFont" style={webStyleNew.listedPriceLabel}>Listed Price</Typography>
                <Typography className="montserratFont" style={webStyleNew.listedPrice}>{order_price} </Typography>
              </Box>
              <Box style={webStyleNew.arrowIcon}><img src={rightArrowIcon} alt="rightArrowIcon"/></Box>
              <Box style={webStyleNew.listedPriceMain}>
                <Typography className="montserratFont" style={webStyleNew.listedPriceLabel}>Offer Price</Typography>
                <Typography className="montserratFont" style={webStyleNew.listedPrice}>{offer_price} </Typography>
              </Box>
            </Box>
            <Box style={webStyleNew.productDescription}>
              <Typography className="montserratFont" style={webStyleNew.offer}>Product Details </Typography>
            </Box>
            <Divider style={webStyleNew.divider}/>   
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              {labelArray.map((label) => 
              <Box style={{ flexBasis: '17%', margin: '10px 10px' }}>
                <Typography className="montserratFont" sx={webStyleNew.firstLabel}>{label.firstLabel}</Typography>
                <Typography className="montserratFont" sx={webStyleNew.secondLabel}>{label.secondLabel}</Typography>
              </Box>
              )}           
            </Box>
            <Box>
              <Typography className="montserratFont" sx={webStyleNew.firstLabel}>{configJSON.descriptionLabel}</Typography>
              <Typography className="montserratFont" sx={webStyleNew.secondLabel}>{description}</Typography>
            </Box>
            <Box style={{width: '600px',marginBottom: '0px'}}>
              <Typography className="montserratFont" sx={webStyleNew.firstLabel}>{configJSON.productVariant}</Typography>
              <Box sx={webStyleNew.container} data-testid="testId">
                <Box sx={webStyleNew.leftSide}>
                <Typography className='montserratFont' sx={webStyleNew.serialNumber}>1</Typography>
                  <Box sx={webStyleNew.productMainProductList}>
                    <img 
                      src={bigImage || placeholderImage}
                      alt="Product Image" 
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'contain',
                        marginRight: '8px',
                        borderRadius: '2px',
                        border: '1px solid rgba(83, 80, 63, 1)'
                      }}/>
                    <Typography className="montserratFont" sx={webStyleNew.price}>{variant?.attributes.price}</Typography>
                  </Box>
                  <Typography className="montserratFont" sx={webStyleNew.colorText}>
                    <Box 
                    sx={{ ...webStyleNew.colorBox, backgroundColor: variant?.attributes.catalogue_variant_color.color_code }} />
                    <Box>{variant?.attributes.catalogue_variant_color.name}</Box>
                  </Typography>
                  <Typography className="montserratFont" sx={webStyleNew.colorText}>
                    {variant?.attributes?.catalogue_variant_size?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider style={webStyleNew.dottedDivider}/>
            <Box sx={webStyleNew.actionBtnMain}>
              <Button 
                data-testid="deletePopupTestId"
                variant="contained" 
                className="montserratFont"
                startIcon={<DeleteOutlineIcon />}
                onClick={this.handleDeletePopupOpen}
                sx={{
                  ...webStyleNew.btnMain, 
                  fontWeight: 600,
                  marginRight: '39px',
                  backgroundColor: 'rgba(239, 238, 233, 0.5)',
                  '&.Mui-disabled': {
                    backgroundColor: '#53503F', 
                    color: '#EDE6DC', 
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    fontSize: '18px'
                  },
                }}
              >
                {configJSON.withdrawOfferBtn}
              </Button>
              {status === "accepted" ? 
              <Button 
                variant="contained"
                data-testid="buyNowTestId" 
                className="montserratFont"
                endIcon={<img src={whiteRightArrow} alt='rightIcon'/>}
                sx={{
                  ...webStyleNew.btnMain, 
                  backgroundColor: 'rgba(83, 80, 63, 1)',
                  color: '#ffffff',
                  fontWeight: 600,
                  '&.Mui-disabled': {
                    backgroundColor: '#53503F', 
                    color: '#EDE6DC', 
                    cursor: 'not-allowed',
                    opacity: 0.5,
                    fontSize: '18px'
                  },
                }}
                onClick={this.handleBuyNow}
                disabled={this.state.productExist}
              > 
                {configJSON.buyNowBtn}
              </Button> 
            : null}
            </Box>
        </Grid>
      </Grid>
      </Box>
      <Footer/>
      </Box>
      </div>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleNew = {
  productDescription: {
    fontSize: '20px',
    padding: '20px 0px 0px 0px'
  },
  navigation: {
    display: 'flex',
    padding: '25px 0px',
    fontSize: '18px'
  },
  mainBox: {
    backgroundColor: 'rgba(237, 230, 220, 1)',
    padding: '30px',
    height: '100vh',
    marginBottom: '50px'
  },
  productMain: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row", 
    gap:"20px",
    flexWrap: "nowrap",
  },
  productImage: {
    display: "flex",
    gap: "6px",
    flexDirection: "column"
  },
  imageMainDiv: {
    cursor:"pointer",
    padding:"3px",
    borderRadius:"4px"
  },
  bigImage: {
    borderRadius: "4px",
    background: "rgba(255, 255, 255, 1)",
    width: '65.57px',
    height: '69.14px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bigImageStyle: {
    background: "rgba(255, 255, 255, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    height: '479.36px',
    position: "relative", 
    marginLeft: '10px'
  },
  favouriteImage: {
    top: "20px",
    right: "20px",
    color: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    display:"flex",
    gap:"12px",
    alignItems:"center"
  },
  exportIcon: {
    color:"rgba(83, 80, 63, 1)",
    width:"26px",
    height:"26px",
    cursor:"pointer"
  },
  bigImageData: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'contain'

  },
  rightSideDescription: {
    padding: '25px'
  },
  descriptionMain: {
    display: 'flex', 
    justifyContent: 'space-between'
  },
  offer: {
    fontSize: '20px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 400,
    letterSpacing: '-0.12px',
    display: 'flex'
  },
  offerId: {
    fontWeight: 600
  },
  date: {
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(83, 80, 63, 1)'
  },
  chipStyle: {
    margin: '5px 0px',
    width: '100%',
    backgroundColor: 'rgba(239, 238, 233, 1)', 
    color: 'rgba(83, 80, 63, 1)', 
    border: '1px solid rgba(83, 80, 63, 1)'
  },
  productNameMain: {
    textAlign: 'start'
  },
  productName: {
    color: 'rgba(83, 80, 63, 1)',
    fontSize: '24px',
    fontWeight: 600
  },
  listedItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listedPriceMain: {
    border: '1px solid rgba(83, 80, 63, 0.4)',
    backgroundColor: '#ffffff',
    padding: '5px 35px',
    borderRadius: '5px',
    margin: '10px 0px'
  },
  listedPriceLabel: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)',
  },
  listedPrice: {
    fontSize: '18px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  arrowIcon: {
    padding: '0px 10px'
  },
  divider: {
    padding: '10px 0px'
  },
  firstLabel: {
    fontSize: '16px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600,
    textAlign: 'start',
    lineHeight: '40px'
  },
  secondLabel: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'start',
    color: 'rgba(83, 80, 63, 0.8)'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid rgba(83, 80, 63, 0.4)',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  serialNumber: {
    marginRight: '8px', 
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  productMainProductList: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px'
  },
  price: {
    marginRight: '16px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  colorText: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    padding: '0px 20px',
    fontWeight: 500,
    color: 'rgba(83, 80, 63, 1)'
  },
  colorBox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    borderRadius: '50%',
  },
  dottedDivider: {
    padding: '10px 0px',
    marginBottom: '40px'
  },
  btnMain: {
    padding: '15px 40px',
    backgroundColor: 'transparent',
    color: 'rgba(83, 80, 63, 1)',
    boxShadow: 'none',
    border: '1px solid rgba(83, 80, 63, 1)',
    marginTop: '10px',
    borderRadius: '8px',
    width: '21%',
    textTransform: 'none',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#403D2F',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  actionBtnMain: {
    textAlign: 'start'
  }
}
// Customizable Area End
