import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";

interface State {
  [key : string]:boolean|string|unknown
}
export interface FaqInterFace {
  id: number | string,
  type: string,
  attributes: {
    id: number | string,
    question: string,
    answer: string,
    sequence: number | string,
    activated: boolean,
    created_at: string,
    updated_at: string

  },
}
export interface CountryInterFace {
  id:number,
  attributes:{name:string,alpha2: string,country_code:number,id:number}
  value: number,
  label: string,
  countryCode: string,
}
export interface CountryInterArr{
  value?: number|string,
  label?: string,
  countryCode?:string,
  country_id?:number,
  contCode?:number
}
export interface StateList{
  label?: string
  value?: string
}

export interface AddressListInterFace{
  id: number|string,
  type: string,
  attributes: {
    latitude: string,
    longitude: string,
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    country: string,
    postal_code: string,
    is_activated: boolean
}
}
type TypeExtra = CountryInterArr|undefined|null

export interface BankDetailsInterFace {

  id: number | string,
  type: string,
  attributes: {
    bank_name: string,
    account_number:  string,
    account_holder_name: string,
    currency: TypeExtra,
    country: {
      id: string | number,
      name: string,
      alpha2: string,
      country_code: number,
      emoji_flag: null,
      enabled: boolean,
      created_at: string,
      updated_at: string
    },
    iban_number: string,
    activated: boolean

  }
}
export interface CurrencyInterface{
  id: number,
  type: string,
  value: string,
    label: string,
  attributes: {
    id: number,
    key: string,
    created_at: string,
    updated_at: string,
    name: string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openLoginModal: boolean;
  showCategoryPage:boolean;
  cartData:[];
  categoryId: number | string;
  categoryName: string;
  landingPagesData:{id:number,logo:string,name:string}[];
  faqData:FaqInterFace[];
  articlesData:FaqInterFace[];
  singleArticleData:FaqInterFace;
  userRole:string

  isAddressModalOpen:boolean;
  countryListArray:CountryInterArr[];
  cityListArray:CountryInterArr[];
  stateListArray:StateList[];
  address_linr_1: string,
  address_linr_2: string,
  city: TypeExtra,
  state: TypeExtra,
  country_id: TypeExtra,
  postal_code: string,
  postal_codeErr: boolean,
  postal_codeErrText:string,
  addressList:AddressListInterFace[]
  defaultAddress:AddressListInterFace|undefined
  isWeekly:boolean;
  isClicked:boolean
  confirmationModal:boolean;
  onOkFuntionCall:Function|null;
  modalMessage:string;
  autoSelectState:string;
  autoSelectCity:string;
  showEditModalText:boolean;
  successModal:boolean;
  successMessage:string;
  isDeactivate:boolean;
  isChangePasswordModal:boolean
  oldPass:string;
  current_passwordErr:boolean;
  current_passwordErrText:string;
  newPass:string;
  new_passwordErr:boolean;
  new_passwordErrText:string;
  confirmPass:string;
  oldPassVisible: boolean;
  newPassVisible: boolean;
  confirmPassVisible: boolean;
  confirmPassErr:boolean;
  isPass:boolean;
  isDefaultCheck:boolean;
  bankDetails:BankDetailsInterFace[];
  bankName: string;
  accountNumber: string;
  accountHolderName: string;
  ibanNumber: string;
  currency:TypeExtra;
  currencyListArray:CurrencyInterface[];
  isBankDetailsModalOpen:boolean;
  showEditBankDetailsModalText:boolean;
  account_numberErr:boolean;
  account_numberErrText:string;
  iban_numberErr:boolean;
  iban_numberErrText:string;
  cardDetails:{cardNumber:string, cardType:number|string, cardExpiry:string}[];
  shopperBalance:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cartDataApiCallId?: string;
  categoriesArrayDataApi?: string;
  categoryFilterApi?: string;
  getLandingPagesApiCallId?: string;
  getFaqApiCallId?:string;
  articlesApiCallId?:string;
  singleArticleApiCallId?:string;
  getAddressApi?:string;
  getWeeklyStatusApi?:string;
  updateWeeklyStatusApi?:string;
  countryListApi?:string;
  cityListApi?:string;
  stateListApi?:string;
  postAddressApi?:string;
  deleteAddressApi?:string;
  editAddressApi?:string;
  deActivateAccountApi?:string;
  deleteAccountApi?:string;
  changePassWordApi?:string;
  bankDetailsApi?:string;
  editBankDetailApiId?:string;
  addBankDetailApiId?:string;
  cardDetailsApi?:string;
  currencyListApi?:string;
  getShopperBalanceApi?:string;
  removeCardApi?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openLoginModal:false,
      cartData:[],
      showCategoryPage:false,
      categoryId: '',
      categoryName: '',
      landingPagesData:[],
      faqData:[],
      articlesData:[],
      singleArticleData:{
        id: '',
        type: '',
        attributes: {
          id: '',
          question: '',
          answer: '',
          sequence: '',
          activated: false,
          created_at: '',
          updated_at: '',
      
        },
      },
      userRole:'',

      isAddressModalOpen:false,
      countryListArray:[],
      cityListArray:[],
      stateListArray:[],
      address_linr_1: '',
      address_linr_2: '',
      city: null,
      state:null,
      country_id: null,
      postal_code: '',
      postal_codeErr:false,
      postal_codeErrText:'',
      addressList:[],
      defaultAddress:{
        id: '',
        type: '',
        attributes: {
          latitude: '',
          longitude: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          state: '',
          country: '',
          postal_code: '',
          is_activated: false,
      }
      },
      isWeekly:false,
      isClicked:false,
      confirmationModal:false,
      onOkFuntionCall:null,
      modalMessage:'',
      autoSelectState:'',
      autoSelectCity:'',
      showEditModalText:false,
      successModal:false,
      successMessage: '',
      isDeactivate:false,
      isChangePasswordModal:false,
      oldPass: '',
      newPass: '',
      confirmPass: '',
      oldPassVisible: false,
      newPassVisible: false,
      confirmPassVisible: false,
      confirmPassErr:false,
      current_passwordErr: false,
      current_passwordErrText: '',
      new_passwordErr: false,
      new_passwordErrText: '',
      isPass:false,
      isDefaultCheck:false,
      bankDetails:[],
      bankName:'',
      accountNumber:'',
      accountHolderName:'',
      ibanNumber:'',
      currency:null,
      currencyListArray:[],
      isBankDetailsModalOpen:false,
      showEditBankDetailsModalText:false,
      account_numberErr: false,
      account_numberErrText: '',
      iban_numberErr: false,
      iban_numberErrText: '',
      cardDetails:[],
      shopperBalance:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson?.errors) {
      switch (apiRequestCallId) {
        case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items })
          break;
        case this.getLandingPagesApiCallId: this.setState({ landingPagesData: responseJson?.categories })
          break;
        case this.getFaqApiCallId: this.setState({ faqData: responseJson?.data })
          break;
        case this.articlesApiCallId: this.setState({articlesData:responseJson?.data})
          break;
        case this.singleArticleApiCallId: this.setState({singleArticleData:responseJson?.data})
          break;
        case this.getAddressApi: this.handleSelectedAddressId(responseJson?.data)
          break;
        case this.getWeeklyStatusApi: this.setState({isWeekly:responseJson?.is_weekly})
          break;
        case this.updateWeeklyStatusApi: this.setState({ isWeekly: responseJson?.is_weekly,isClicked:false })
          break;
        case this.countryListApi: this.handeCountryArray(responseJson?.data)
          break;
        case this.cityListApi: this.handeCityArray(responseJson?.cities)
          break;
        case this.stateListApi: this.handleStateMap(responseJson?.states)
          break;
        case this.postAddressApi: this.setState({isDefaultCheck:false,autoSelectState:'', autoSelectCity:'',showEditModalText:false,isAddressModalOpen: false, address_linr_1: '', address_linr_2: '', city: null, state: null, country_id: null, postal_code: '',postal_codeErr:false,postal_codeErrText:'' }, () => this.handleGetAddressList())
          break;
        case this.deleteAddressApi:this.handleGetAddressList()
          this.setState({confirmationModal:false,modalMessage:''}) 
          break; 
        case this.editAddressApi: this.handleGetAddressList()
                                  this.handleMakeEmptyAddressModal()
          break; 
        case this.deActivateAccountApi:  this.setState({confirmationModal:false,modalMessage:'',successMessage:responseJson.message,successModal:true,isDeactivate:true})
          break;
        case this.deleteAccountApi: this.setState({ confirmationModal: false, modalMessage: '', isDeactivate: true }, () => this.handleLogout())
          break;
        case this.changePassWordApi:
          this.setState({
            isChangePasswordModal: false,
            successModal: true,
            successMessage: responseJson?.message,
            oldPass: '',
            newPass: '',
            confirmPass: '',
            oldPassVisible: false,
            newPassVisible: false,
            confirmPassVisible: false,
            confirmPassErr: false,
            isPass: true,
            current_passwordErr: false,
            current_passwordErrText: '',
            new_passwordErr: false,
            new_passwordErrText: '',
            cartData: [],
            addressList: [],
            isWeekly: false,
            countryListArray: []
          })
          removeStorageData('loginToken')
          removeStorageData('loginDetails')
          break;
        case this.bankDetailsApi: this.setState({ bankDetails: responseJson?.data })
          break;
        case this.editBankDetailApiId: this.handleGetBankDetails()
        this.handleMakeEmptyBankDetailsModal()
          break;
        case this.addBankDetailApiId: this.handleMakeEmptyBankDetailsModal()
        this.handleGetBankDetails()
          break;
        case this.cardDetailsApi: this.setState({ cardDetails: responseJson?.data })
          break;
        case this.currencyListApi:this.handleCurrencyDataModify(responseJson?.data)
          break; 
        case this.getShopperBalanceApi: this.setState({shopperBalance:responseJson?.balance})
          break;
        case this.removeCardApi: this.handleGetCardDetails()
          break; 
      
      }
    }
    if (responseJson && responseJson?.errors) {
      this.handleErrResponse(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount = async () => {
    const pathName = window?.location?.pathname
    this.handleFetchCartData()
    this.handleCategoryList()
    this.handleGetUser()
    if (pathName == '/Settings') {
      this.handleGetBankDetails()
      this.handleGetBalance()
      this.handleCurrencyList()
      this.handleGetCardDetails()
      this.handleGetAddressList()
      this.handleGetWeeklyUpdate()
      this.handleFetchCountryList()
    } else {
      this.handleGetFaq()
      this.handleGetArticles()
      this.handleGetSingleArticle()
    }
    window?.scrollTo({top:0,behavior:'smooth'})
  };
  handleErrResponse=(apiRequestCallId:string,responseJson:{errors:[{token:string,postal_code:string,account_number:string,iban_number:string}]})=>{
    switch (apiRequestCallId) {
      case this.updateWeeklyStatusApi:
        if (responseJson?.errors[0]?.token) {
          this.setState({ openLoginModal: true, isClicked: false })
        }
        break;
      case this.getAddressApi:
        if (responseJson?.errors[0]?.token) {
          this.setState({ openLoginModal: true })
        }
        break;
      case this.postAddressApi: this.setState({ autoSelectState: '', autoSelectCity: '',isDefaultCheck: false,  postal_codeErr: true, postal_codeErrText: responseJson?.errors[0]?.postal_code })
        this.handlePostAddressErr(responseJson)
        break;
      case this.editAddressApi: this.setState({ isDefaultCheck: false, autoSelectState: '', autoSelectCity: '', postal_codeErr: true, postal_codeErrText: responseJson?.errors[0]?.postal_code })
        this.handlePostAddressErr(responseJson)
        break;
      case this.changePassWordApi:
        if (responseJson?.errors[0]?.token) {
          this.setState({ openLoginModal: true }, () => this.handleMakeEmptyChangePasswordModal())
        }
        const fieldErrors: Partial<State> = {}
        responseJson?.errors?.forEach((errorObj: {}) => {
          Object?.entries(errorObj)?.forEach(([field, message]) => {
            fieldErrors[`${field}Err`] = true
            fieldErrors[`${field}ErrText`] = message
          })
        })
        this.setState((prevState) => ({
          ...prevState,
          ...fieldErrors
        }))
        break;
      case this.editBankDetailApiId: this.handleBankErrResponse(responseJson?.errors)
        break;
      case this.addBankDetailApiId: this.handleBankErrResponse(responseJson?.errors)
        break; 

    }
  }
  handleBankErrResponse=(errors:[{token:string,account_number:string,iban_number:string}])=>{
    if (errors[0]?.token) {
      this.setState({ openLoginModal: true }, () => this.handleMakeEmptyBankDetailsModal())
    }
    const fieldErrors: Partial<State> = {}
    errors?.forEach((errorObj: {}) => {
      Object?.entries(errorObj)?.forEach(([field, message]) => {
        fieldErrors[`${field}Err`] = true
        fieldErrors[`${field}ErrText`] = message
      })
    })
    this.setState((prevState) => ({
      ...prevState,
      ...fieldErrors
    }))
  }
  handleNavigate = (path: string) => {
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  closeLoginModal = async() => {
    const tokenIs = await getStorageData('loginToken')
    if(this.state.isPass&&!tokenIs){
      this.handleNavigate('Home')
    }
    this.setState({ openLoginModal: false })
    this.handleGetUser()
  }
  openLoginModal = () => {
    this.setState({ openLoginModal: true })
  }
  handleFetchCartData = async () => {
    const cartId = await getStorageData('cartId')
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCart(cartId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCategoryData = async (categoryName: string, categoryId: number) => {
    this.props?.navigation?.navigate("CategoriesWeb",{categoryId,categoryName})
  }
  handleCategoryList = async () => {
  
    const requestMessageData2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLandingPagesApiCallId = requestMessageData2.messageId;
    requestMessageData2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageData2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCategoryApi
    );
   
    runEngine.sendMessage(requestMessageData2.id, requestMessageData2);
  }
  handleGetFaq = () =>{
    const requestMessage3 = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getFaqApiCallId=requestMessage3.messageId
    requestMessage3.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessage3.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.endPointFaqApi)
    runEngine.sendMessage(requestMessage3.id,requestMessage3)
  }
  handleGetArticles = () =>{
    const requestMessage4 = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.articlesApiCallId = requestMessage4.messageId
    requestMessage4.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessage4.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.endPointArticlesApi)
    runEngine.sendMessage(requestMessage4.id,requestMessage4)
  }  
  handleGetSingleArticle = () =>{
    const articleId=new URLSearchParams(window?.location?.search).get('Id')
    const requestMessage5 = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.singleArticleApiCallId = requestMessage5.messageId
    requestMessage5.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessage5.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.articleEndPointApi(articleId))
    runEngine.sendMessage(requestMessage5.id,requestMessage5)
  }
  handleGetUser = async () => {
    const userData = await getStorageData('loginDetails',true)
    this.setState({ userRole: userData?.attributes?.role_name })
  }
  handleGetAddressList = async () => {
    const tokenIs= await getStorageData('loginToken')
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
     ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddressList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressApi = requestMessageAddressList.messageId;
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressEndPoint
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))
    runEngine.sendMessage(requestMessageAddressList.id, requestMessageAddressList);
  }
  handleSelectedAddressId=(data:AddressListInterFace[])=>{
    
    const sortedData = this.handleSort(data)
    this.setState({ addressList: sortedData })
    const activeAddress = data?.find((element) => element?.attributes?.is_activated)
    this.setState({ defaultAddress: activeAddress })
  }
  handleSort =(data:AddressListInterFace[])=>{
    const newArr = [...data]
    return newArr.sort((a, b) => Number(b?.attributes?.is_activated) - Number(a?.attributes?.is_activated));
  }
  handleGetWeeklyUpdate = async () =>{
    const tokenIs= await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
     ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddressList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWeeklyStatusApi = requestMessageAddressList.messageId;
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.weeklyStatus
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))
    runEngine.sendMessage(requestMessageAddressList.id, requestMessageAddressList);
  }
  handleUpdateWeeklyStatus = async () => {
    if (!this.state.isClicked) {
      const tokenIs = await getStorageData('loginToken')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        ...(tokenIs && { token: tokenIs })
      };
      const requestMessageAddressList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateWeeklyStatusApi = requestMessageAddressList.messageId;
      requestMessageAddressList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiPutMethod
      );
      requestMessageAddressList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.weeklyUpdate
      );
      requestMessageAddressList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header))
      runEngine.sendMessage(requestMessageAddressList.id, requestMessageAddressList);
      this.setState({isClicked:true})
    }
    
    
  }

  handleChangeInput = (value: string, state: string) => {
    this.setState((prevState) => ({ ...prevState, [state]: value,
       ...(state == 'postal_code' && { postal_codeErr: false, postal_codeErrText: '' }),
       ...(state == 'confirmPass' && { confirmPassErr: false,}),
       ...(state == 'oldPass' && { current_passwordErr: false,current_passwordErrText:''}),
       ...(state == 'newPass' && { new_passwordErr: false, new_passwordErrText:''}),
       ...(state == 'accountNumber' && { account_numberErr: false, account_numberErrText:''}),
       ...(state == 'ibanNumber' && { iban_numberErr: false, iban_numberErrText:''})
       }))
  }
  handleChangeSelect = (event: { value: string, label: string } | undefined | null| CountryInterArr, state: string) => {
    if (state === 'state') {
      if (event?.value !== this.state.state?.value) {
        this.setState({ state: event, city: null }, () => this.handleFetchCityByState(event))
      }
    } else {
      this.setState((prevState) => ({ ...prevState, [state]: event,}), () => this.handleState(state, event))
    }
  }
  handleFetchCityByState = (event: CountryInterArr | StateList | undefined | null) => {
    const requestMessageCity = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityListApi = requestMessageCity.messageId;
    requestMessageCity.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageCity.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityListEndPoint(this.state.country_id?.countryCode, event?.value)
    );
    runEngine.sendMessage(requestMessageCity.id, requestMessageCity);
  }
  handleState = (state: string, event: CountryInterArr | {} | undefined | null) => {

    if (state == 'country_id') {
      this.setState({ cityListArray: [], city: null, stateListArray: [], state: null })
      this.handleFetchState(event)
    }
  }
  handleFetchState = (event: CountryInterArr | { contCode: number, countryCode: string } | undefined | null) => {
    const requestMessageStateList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateListApi = requestMessageStateList.messageId;
    requestMessageStateList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageStateList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stateEndPoint(event?.countryCode)
    );
    runEngine.sendMessage(requestMessageStateList.id, requestMessageStateList);
  }
  handleFetchCountryList = () => {
    const requestMessageCountryList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListApi = requestMessageCountryList.messageId;
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndPoint
    );
    runEngine.sendMessage(requestMessageCountryList.id, requestMessageCountryList);

  }
  handeCountryArray = (data: CountryInterFace[]) => {
    const newData = data.map((element: CountryInterFace) => ({
      value: element.id,
      label: element?.attributes.name,
      countryCode: element?.attributes?.alpha2,
      country_id: element?.attributes?.id,
      contCode: element?.attributes?.country_code
    }));
    this.setState({ countryListArray: newData });
  }
  handeCityArray = (data: []) => {
    const newData = data.map((element: string) => ({
      value: element,
      label: element,

    }));
    this.setState({ cityListArray: newData });
    if(this.state.autoSelectCity!==''){
      let selectedCity = newData?.find((element)=>element?.label===this.state.autoSelectCity)
      this.setState({city:selectedCity}) 
    }
  }
  handleStateMap = (data: StateList) => {
    const newState = Object.entries(data)?.map(([key, value]) => ({
      value: key,
      label: value
    }))
    this.setState({ stateListArray: newState })
    if(this.state.autoSelectState!==''){
      let selectedState = newState?.find((element)=>element?.value===this.state.autoSelectState)
      this.setState({state:selectedState},()=>this.handleFetchCityByState(selectedState)) 
    }
   

  }
  checkForDisableSave = () => {
    let country = this.state.country_id == undefined || this.state.country_id == null
    let state = this.state.state == undefined || this.state.state == null
    let city = this.state.city == undefined || this.state.city == null
    let postal_code = this.state.postal_code == undefined || this.state.postal_code == null || this.state.postal_code.trim() == ''
    return this.handleDisbale(country,state,city,postal_code)
    
  }
  checkForBankDetailsDisableSave = () => {
    
    let country = this.state.country_id == undefined || this.state.country_id == null
    let currency = this.state.currency == undefined || this.state.currency == null
    let bankName = this.state.bankName == undefined || this.state.bankName == null||this.state.bankName?.trim() == ''
    let accountNumber = this.state.accountNumber == undefined || this.state.accountNumber == null||this.state.accountNumber?.trim() == ''
    let accountHolderName = this.state.accountHolderName == undefined || this.state.accountHolderName == null||this.state.accountHolderName?.trim() == ''
    let ibanNumber = this.state.ibanNumber == undefined || this.state.ibanNumber == null || this.state.ibanNumber?.trim() == ''
    if(country||bankName||accountNumber||accountHolderName||ibanNumber||currency){
      return true
    }else{
      return false
    }
  }
  handleDisbale =(country:boolean,state:boolean,city:boolean,postal_code:boolean) =>{
    if (country ){
      return true
     
   } else{
      if(state&&this.state.stateListArray.length>0){
        return true
      }else if(!state&&city){
        return true
      }else if(postal_code){
        return true
      }else{
        return false
      }
   }
  }
  handleDisableForPassword = () =>{
   const  oldPass = this.state.oldPass==''||this.state.oldPass==null||this.state.oldPass==undefined
   const  newPass = this.state.newPass==''||this.state.newPass==null||this.state.newPass==undefined
   const  confirmPass = this.state.confirmPass==''||this.state.confirmPass==null||this.state.confirmPass==undefined
   if(oldPass||newPass||confirmPass){
    return true
   }else return false
  }
  handleSubmitAddress = async () => {      
      const body = {
        ...(this.state.address_linr_1?.trim() !== '' && { "address_line_1": this.state.address_linr_1 }),
        ...(this.state.address_linr_2?.trim() !== '' && { "address_line_2": this.state.address_linr_2 }),
        "city": this.state.city?.value == undefined ? 'nil' : this.state.city?.value,
        "state": this.state.state?.value == undefined ? 'nil' : this.state.state?.value,
        "country_id": this.state.country_id?.country_id,
        "postal_code": this.state.postal_code,
         "is_activated":this.state.isDefaultCheck
      } 
      const tokenIs= await getStorageData('loginToken')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs&&{token: tokenIs})
      };
      const requestMessagePostAddress = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postAddressApi = requestMessagePostAddress.messageId;
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addressEndPoint
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessagePostAddress.id, requestMessagePostAddress);
    
  }
  handleEditAddress = async (addressId:number|string,address:AddressListInterFace) => {     
    const body = {
      "address": {
      ...(this.state.address_linr_1?.trim() !== '' && { "address_line_1": this.state.address_linr_1 }),
      ...(this.state.address_linr_2?.trim() !== '' && { "address_line_2": this.state.address_linr_2 }),
      "city": this.state.city?.value == undefined ? 'nil' : this.state.city?.value,
      "state": this.state.state?.value == undefined ? 'nil' : this.state.state?.value,
      "country_id": this.state.country_id?.country_id,
      "postal_code": this.state.postal_code,
      "is_activated":this.state.isDefaultCheck
    } 
  }
    const tokenIs= await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageEditAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editAddressApi = requestMessageEditAddress.messageId;
    requestMessageEditAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiPatchMethod
    );
    requestMessageEditAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAddressEndPoint(addressId)
    );
    requestMessageEditAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageEditAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageEditAddress.id, requestMessageEditAddress);
  
}
  handlePostAddressErr = (responseJson: { errors: [{ token: string }] }) => {
    if (responseJson.errors[0]?.token) {
      removeStorageData('loginToken')
      this.setState({ isAddressModalOpen: false,showEditModalText:false })
      this.openLoginModal()
    }
  }
  handleMakeEmptyAddressModal= () =>{
    this.setState({ 
      isAddressModalOpen: false,
      showEditModalText:false,
      address_linr_1: '', 
      address_linr_2: '',
      city: null,
      state: null, 
      country_id: null, 
      postal_code: '',
      postal_codeErr:false,
      postal_codeErrText:'',
      autoSelectState:'' ,
      isDefaultCheck:false,
    })
  }
  handleMakeEmptyBankDetailsModal= () =>{
    this.setState({ 
      bankName:'',
      accountNumber:'',
      accountHolderName:'',
      ibanNumber:'',
      currency:null,
      country_id:null,
      isBankDetailsModalOpen:false,
      showEditBankDetailsModalText:false,
      iban_numberErr:false,
      iban_numberErrText:'',
      account_numberErr:false,
      account_numberErrText:'',
    })
  }
  handleMakeEmptyChangePasswordModal = () =>{
    this.setState({ 
      newPass:'',
      isChangePasswordModal: false,
      oldPass:'',
      confirmPass:'',
      newPassVisible:false,
      oldPassVisible:false,
      confirmPassVisible:false,
      confirmPassErr:false,
      isPass:false,
      current_passwordErr: false,
      current_passwordErrText: '',
      new_passwordErr: false,
      new_passwordErrText: '',
    })

  }
  handleRemoveAddress = async (addressId: number | string) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteAddressApi = requestMessage.messageId
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiDeleteMethod)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.deleteAddressEndPoint(addressId))
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleEditAddressModal = (address: AddressListInterFace,functionCall: Function) => {
    
    let countryList =[...this.state.countryListArray]
    let selectedCountry = countryList.find((element)=>element?.label===address?.attributes?.country)
    this.handleChangeSelect(selectedCountry, 'country_id')
    this.setState({
      showEditModalText:true,
      address_linr_1: address?.attributes?.address_line_1,
      address_linr_2: address?.attributes?.address_line_2,
      postal_code: address?.attributes?.postal_code,
      autoSelectState:address?.attributes?.state,
      autoSelectCity:address?.attributes?.city,
      onOkFuntionCall:functionCall,
      isAddressModalOpen:true,
      isDefaultCheck:address?.attributes?.is_activated
    },)
  
  }

  handleCommonModal = (message: string, functionCall: Function) => {
    this.setState({
      confirmationModal: true,
      modalMessage: message,
      onOkFuntionCall: functionCall
    })
  }
  handleEditBankModal= () => {
    let bankDetails = this.state.bankDetails[0]
    let bank = this.state.bankDetails[0]?.attributes
    let countryList =[...this.state.countryListArray]
    let currencyList =[...this.state.currencyListArray]
    let selectedCountry = countryList?.find((element)=>element?.countryCode===bankDetails?.attributes?.country?.alpha2)
    let selectedCurrency = currencyList?.find((element)=>element?.value===bankDetails?.attributes?.currency)
    this.handleChangeSelect(selectedCountry, 'country_id')
    this.handleChangeSelect(selectedCurrency, 'currency')
    this.setState({
      bankName:bank?.bank_name,
      accountNumber:bank?.account_number,
      accountHolderName:bank?.account_holder_name,
      ibanNumber:bank?.iban_number,
      isBankDetailsModalOpen:true,
      showEditBankDetailsModalText:true
    },)
  
  }
  handleDeactivateAccount = async () =>{
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageDeactivateAccount= new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deActivateAccountApi = requestMessageDeactivateAccount.messageId
    requestMessageDeactivateAccount.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiPutMethod)
    requestMessageDeactivateAccount.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.deactivated_account)
    requestMessageDeactivateAccount.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
    runEngine.sendMessage(requestMessageDeactivateAccount.id,requestMessageDeactivateAccount)

  }
  handleDeleteAccount = async () =>{
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageDeleteAccount= new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteAccountApi = requestMessageDeleteAccount.messageId
    requestMessageDeleteAccount.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiDeleteMethod)
    requestMessageDeleteAccount.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.deleteAccount)
    requestMessageDeleteAccount.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
    runEngine.sendMessage(requestMessageDeleteAccount.id,requestMessageDeleteAccount)

  }
  handleChangePasswordSave = async () => {
    if(this.state.newPass?.trim() !== this.state.confirmPass?.trim()){
      this.setState({confirmPassErr:true})
    }else{
      this.setState({confirmPassErr:false})
     const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const body = {
      "data": {
        "current_password": this.state.oldPass,
        "new_password": this.state.newPass
      }
    }
    const requestChangePasswordMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.changePassWordApi = requestChangePasswordMessage.messageId
    requestChangePasswordMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestChangePasswordMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
    requestChangePasswordMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.passChangeEndPoint)
    requestChangePasswordMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiPutMethod)
    runEngine.sendMessage(requestChangePasswordMessage.id, requestChangePasswordMessage) 
    }
    
  }
  handleLogout = () => {
    if (this.state.isPass) {
      this.handleNavigate('Home')
    }
    if (this.state.isDeactivate) {
      window.location.href = "/";
      removeStorageData('loginToken')
      removeStorageData('loginDetails')
    }
  }
  handleReturnHelperText = (state: string) => {
    return (state == 'confirmPass' && this.state.confirmPassErr && 'Password is not matching' ||
      state == 'oldPass' && this.state.current_passwordErr && this.state.current_passwordErrText ||
      state == 'newPass' && this.state.new_passwordErr && this.state.new_passwordErrText)
  }
  handleReturnHelperErr = (state: string) => {
    return (state=='confirmPass'&&this.state.confirmPassErr||
      state=='oldPass'&&this.state.current_passwordErr||
      state=='newPass'&&this.state.new_passwordErr)
  }
  handleGetBankDetails = async () =>{
    const tokenIs= await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
     ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageBankDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bankDetailsApi = requestMessageBankDetails.messageId;
    requestMessageBankDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageBankDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankDetailsApiEndPoint
    );
    requestMessageBankDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))
    runEngine.sendMessage(requestMessageBankDetails.id, requestMessageBankDetails);
  }
  handleEditBankDetails = async () => {
    const bankId = this.state.bankDetails[0]?.id
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const body = {
      "bank_detail": {
        "bank_name": this.state.bankName,
        "account_number": this.state.accountNumber,
        "account_holder_name": this.state.accountHolderName,
        "country_id": this.state.country_id?.country_id,
        "iban_number": this.state.ibanNumber,
        "currency":this.state.currency?.value,
        "activated": true
      }
    }
    const requestMessageEditBank = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.editBankDetailApiId = requestMessageEditBank.messageId
    requestMessageEditBank.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
    requestMessageEditBank.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiPutMethod)
    requestMessageEditBank.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessageEditBank.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.update_bank_detail(bankId))
    runEngine.sendMessage(requestMessageEditBank.id, requestMessageEditBank)
  }
  handleNewBankDetails = async () => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const body = {
      "bank_detail": {

        "bank_name": this.state.bankName,
        "account_number": this.state.accountNumber,
        "account_holder_name": this.state.accountHolderName,
        "currency":this.state.currency?.value,
        "country_id": this.state.country_id?.country_id,
        "iban_number": this.state.ibanNumber,
        "activated": true
      }
    }
    const requestMessageAddBank = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.addBankDetailApiId = requestMessageAddBank.messageId
    requestMessageAddBank.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
    requestMessageAddBank.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod)
    requestMessageAddBank.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessageAddBank.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.add_bank_details)
    runEngine.sendMessage(requestMessageAddBank.id, requestMessageAddBank)
  }
  handleGetCardDetails = async () => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageCardDetails = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.cardDetailsApi = requestMessageCardDetails.messageId
    requestMessageCardDetails.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
    requestMessageCardDetails.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessageCardDetails.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.manage_card)
    runEngine.sendMessage(requestMessageCardDetails.id,requestMessageCardDetails)
  }
  handleCurrencyList= async()=>{
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageCurrency= new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.currencyListApi = requestMessageCurrency.messageId
    requestMessageCurrency.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
    requestMessageCurrency.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessageCurrency.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.fetch_bank_currencies)
    runEngine.sendMessage(requestMessageCurrency.id,requestMessageCurrency)
  }
  handleCurrencyDataModify = (data:CurrencyInterface[]) =>{
    const newData = data?.map((element: CurrencyInterface) => ({
      value: element?.attributes?.name?.toLowerCase(),
      label: element?.attributes?.name,
      id:element?.id,
      type:element?.type,
      attributes: {
        id: element?.attributes?.id,
        key: element?.attributes?.key,
        created_at: element?.attributes?.created_at,
        updated_at: element?.attributes?.updated_at,
        name: element?.attributes?.name
      }
    }));
     this.setState({currencyListArray:newData})
  }
  handleCardType = (cardType:number|string)=>{
   return cardType==1?'Visa':'MasterCard'
  }
  handleGetBalance = async () => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };
    const requestMessageGetShopperBalance = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getShopperBalanceApi = requestMessageGetShopperBalance.messageId
    requestMessageGetShopperBalance.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
    requestMessageGetShopperBalance.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
    requestMessageGetShopperBalance.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.shopper_balance)
    runEngine.sendMessage(requestMessageGetShopperBalance.id,requestMessageGetShopperBalance)
  }
  handleCardRemove = async (cardNumber: string) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs && { token: tokenIs })
    };

    const requestMessageCardRemove = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.removeCardApi = requestMessageCardRemove.messageId
    requestMessageCardRemove.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessageCardRemove.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiDeleteMethod)
    requestMessageCardRemove.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.card_removed(cardNumber))
    runEngine.sendMessage(requestMessageCardRemove.id, requestMessageCardRemove)
  }

  // Customizable Area End
}
