import React from "react";

// Customizable Area Start
import { Box, Button, styled, Typography } from "@mui/material";
import Select from "react-select";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import { rightArrow,checkIcon,image_closeIcon} from "./assets"
import MyWishDetailsController, {
  Props,
  configJSON
} from "./MyWishDetailsController";
// Customizable Area End


export default class WishRequestDetails extends MyWishDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleReturnSelect = () => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {configJSON.update_the_status}
                </LabelText>
                <ReactSelect
                    options={configJSON.statusArray}
                    placeholder={'Select'}
                    value={this.state.statusValue}
                    onChange={(event) => this.handleChangeSelect(event)}
                    data-test-id='selectField'
                    classNamePrefix={'custom-select'}
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            border: '1px solid rgba(83, 80, 63, 1)',
                            boxShadow: 'none',
                            "&:hover": {
                                border: '1px solid rgba(83, 80, 63, 1)'
                            }
                        }),
                        menu: (provided) => ({
                            ...provided,
                            border: '1px solid #53503F',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            zIndex: 10000,
                            borderRadius: '4px',
                            marginTop: '3.93px',
                            overflow: 'hidden',
                            maxWidth: '180px',
                            right: '0',
                            left: 'auto'
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            gap: '8px',
                            boxSizing: 'border-box',
                            backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                            paddingRight: '16px',
                            paddingLeft: '16px',
                            paddingBottom: '12px',
                            paddingTop: '12px',
                            fontWeight: state.isSelected ? 600 : 400,
                            color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: '12px',
                            "&:hover": {
                                backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                            }
                        }),
                    }}
                />
            </Box>
        )
    }
    handleReturnBottumBox = () => {
        const wishData = this.state.wishData?.attributes
        return (
            <RightBottomBox>
                {wishData?.status == 'pending' && <><AcceptButton onClick={() => this.handleChangeStatus(wishData?.id, 'accepted')} data-test-id='acceptButton' variant="outlined" startIcon={<img src={checkIcon} />}>
                    {configJSON.accept}
                </AcceptButton>
                    <Button onClick={() => this.handleChangeStatus(wishData?.id, 'rejected')} data-test-id='rejectRequest' className="reject_Request" variant="outlined" startIcon={<img src={image_closeIcon} />}>
                        {configJSON.reject}
                    </Button></>}
                {wishData?.status == 'accepted' && <>
                    {this.handleReturnSelect()}
                    <AcceptButton disabled={this.handleCheckStatusBeforeNavigation()} onClick={() => this.handleNavigation('Catalogue', wishData?.id)} data-test-id='saveButton' variant="outlined" >
                        {configJSON.save}
                    </AcceptButton>
                </>}
            </RightBottomBox>
        )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
     const wishData =  this.state.wishData?.attributes
    return (
        
        <ContainerBoxWishRequest >
            <ShopperHeader/>
            <OuterMainBoxWishRequest >
                 <ArrowTopBoxWishRequest>
                    <Typography onClick={() => this.handleNavigate('Home')}  data-test-id='home' className="arrowHomeText">{configJSON.homeText}</Typography>
                    <img  src={rightArrow}  alt="rightIconImage" />
                    <Typography onClick={() => this.handleNavigate('Analytics')} className="arrowHomeText" data-test-id='analytics'   > {configJSON.orders} </Typography>
                    <img  src={rightArrow}   alt="rightIconImage" />
                    <Typography className="arrowSecondText" >
                        {configJSON.details}
                    </Typography>
                </ArrowTopBoxWishRequest>
                    <MainParentBoxWishRequest>
                    <ImageParentBoxWishRequest>
                        <Box sx={{overflowY:this.state.wishData?.attributes?.product_image?.length>6?'scroll':'unset'}}  className='smallImagesColumn' >
                            {this.state.wishData?.attributes?.product_image?.length > 0 && this.state.wishData.attributes.product_image.map((element) => (
                                <Box style={{
                                    border: this.state.isActiveImage == element.id ? '1px solid rgba(83, 80, 63, 1)' : '1px solid transparent',
                                    borderRadius: '4.77px',
                                    boxSizing: 'border-box',
                                    padding: '2.98px',
                                    cursor: 'pointer',
                                }}
                                    data-test-id='changeImage' onClick={() => this.handleImageChange(element)}
                                >
                                    <Box   className='smallImageDiv' >
                                        <img src={element.url} alt="" />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box  className='bigImagesBox'>  <img src={this.state.isBigImage?.url} alt="" />  </Box>
                    </ImageParentBoxWishRequest>
                    <RightParentBoxWishRequest>
                        <RightFirstTopBoxWishRequest>
                             <Box className='requestIdAndStatusParentBox'>
                                <Typography className="requestedIdText">{configJSON.request_Id} <span>{wishData?.request_id}</span></Typography>
                             </Box>
                             <Box className='nameDateParentBox'>
                                <Typography className="nameText">
                                {wishData?.name}
                                </Typography>
                                <Box className='dateParentBox'>
                                    <Box className='dateChiledBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.requested_Date}</Typography>
                                        <Typography className="date" style={{ ...styles.valueText }}>{wishData?.request_date}</Typography>
                                    </Box>
                                    {wishData?.status == 'rejected' ? <Box className='dateChiledBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.rejected_Date}</Typography>
                                        <Typography className="date" style={{ ...styles.valueText, textAlign: 'center' }}>{this.handleCheckPresent(wishData?.rejected_date) ? wishData?.rejected_date : '-'}</Typography>
                                    </Box> : <Box className='dateChiledBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.assigned_Date}</Typography>
                                        <Typography className="date" style={{ ...styles.valueText, textAlign: 'center' }}>{this.handleCheckPresent(wishData?.assign_date) ? wishData?.assign_date : '-'}</Typography>
                                    </Box>}
                                </Box>
                             </Box>
                        </RightFirstTopBoxWishRequest>
                        <RightMiddleBoxWishRequest>
                            <Typography className="productDetailsHeadingWishRequest">{configJSON.productDetails}</Typography>
                            <Box className='productDetailsMainBoxWishRequest'>
                                <Box className='productDetailsMainBoxTopBoxWishRequest'>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }} >
                                            {configJSON.category}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText }} >
                                            {wishData?.category}
                                        </Typography>
                                    </Box>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }} >
                                            {configJSON.brand}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText }} >
                                            {wishData?.brand}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='productDetailsMainBoxLowerBox'>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }} >
                                            {configJSON.year_of_Production}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText }} >
                                            {this.handleCheckPresent(wishData?.production_year) ? wishData?.production_year : '-'}
                                        </Typography>
                                    </Box>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }}>
                                            {configJSON.model_Number}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText, ...styles.overflowText }}>
                                            {wishData?.product_model_number}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='productDetailsMainBoxDescBoxWishRequest'>
                                <Typography   style={{ ...styles.titleHeading }}>
                                    {configJSON.description}
                                    </Typography>
                                    <Typography style={{ ...styles.valueText }}>
                                        {this.state.isExpand ? wishData?.description : wishData?.description?.slice(0, 145)} <span data-test-id='readMore' style={{ ...styles.titleHeading, cursor: 'pointer' }} onClick={() => this.setState({ isExpand: !this.state.isExpand })}>{this.state.isExpand ? 'read less' : 'read more..'}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        </RightMiddleBoxWishRequest>
                        <RightMiddleBoxWishRequestCustomerDetails>
                        <Typography className="productDetailsHeadingWishRequest">{configJSON.customer_Details}</Typography>
                        <Box className='productDetailsMainBoxTopBoxWishRequest'>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }} >
                                            {configJSON.customer_Name}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText }} >
                                            {wishData?.customer_name}
                                        </Typography>
                                    </Box>
                                    <Box className='detailElementBoxWishRequest'>
                                        <Typography style={{ ...styles.titleHeading }} >
                                            {configJSON.location}
                                        </Typography>
                                        <Typography style={{ ...styles.valueText }} >
                                            {wishData?.location}
                                        </Typography>
                                    </Box>
                                </Box>
                        </RightMiddleBoxWishRequestCustomerDetails>
                         {this.handleReturnBottumBox()}
                    </RightParentBoxWishRequest>
                    </MainParentBoxWishRequest>
            </OuterMainBoxWishRequest>
            <ShopperFooter/>
        </ContainerBoxWishRequest>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBoxWishRequest = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterMainBoxWishRequest = styled(Box)({
    display: 'flex',
    padding: '40px 40px 180px 40px',
    gap: '20px',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '@media (max-width: 400px) ': {padding: '10px',}
    
})
const ArrowTopBoxWishRequest = styled(Box)({
    display: 'flex',
    gap: '11px',
    paddingLeft: '6px',
    alignItems: 'center',
    "& .arrowSecondText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 600,
    },
    "& .arrowHomeText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 400,
        cursor: 'pointer'
    },

})

const MainParentBoxWishRequest = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '29.81px',
   '@media (max-width: 990px) ': { flexDirection:'column', alignItems:'center' }
})
const ImageParentBoxWishRequest = styled(Box)({
    width: '485.19px', display: 'flex',
    gap: '7.72px',
    "& .smallImagesColumn": {
        height: '479.36px',
        width: "65.57px",
        display: 'flex',
        gap: '7.15px',
        flexDirection: 'column',
    },
    "& .smallImagesColumn>div> .smallImageDiv": {
        width: '100%',
        borderRadius: '4.77px',
        height: '62.59px',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .smallImagesColumn img": {
        width: '40.41px',
        height: '40.41px',
    },
    "& .bigImagesBox": {
        width: "411.9px",
        borderRadius: '4px',
        height: "479.36px",
        backgroundColor: 'rgba(255, 255, 255, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .bigImagesBox img": {
        objectFit: 'contain',
        width: '241.94px',
        height: '241.94px',},
    '@media (max-width: 600px) ': {
        flexDirection: 'column-reverse', alignItems: 'center',
        width: '100%',
        "& .smallImagesColumn": {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflowX: 'scroll',
            height: 'unset'
        },
        "& .smallImagesColumn>div> .smallImageDiv": { width: '100px', },
        "& .bigImagesBox": {
            width: '100%'
        }
    }

})
const RightParentBoxWishRequest = styled(Box)({
    width: '100%',
})  

const RightFirstTopBoxWishRequest = styled(Box)({
    display: 'flex', flexDirection: 'column',gap: '17.93px',
    width: '100%',
    borderBottom: "1px solid rgba(83, 80, 63, 0.5)",
    paddingBottom: '8px',
    boxSizing: 'border-box',
    "& .requestIdAndStatusParentBox": {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    },
    "& .requestedIdText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: 'normal',
        color: 'rgba(83, 80, 63, 1)',
    },
    "& .requestedIdText span": {   fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
        fontSize: '20px',
    },

    "& .statusText": {  display: 'flex',  alignItems: 'center', justifyContent: 'center',
        width: '124px',
        backgroundColor: 'rgba(239, 238, 233, 1)',
        borderRadius: '16.45px',
        color: 'rgba(83, 80, 63, 1)',
        border: '0.5px solid rgba(83, 80, 63, 1)',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 500,
        paddingTop:'4px',
        lineHeight: 'normal',
        paddingBottom:'4px'
    },
    "& .dateParentBox": {
        display: 'flex', gap: '30px',
    },
    "& .dateChiledBox":{
        display: 'flex', flexDirection: 'column', gap: '8px',
    },
    "& .nameText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: 'normal',
        color: 'rgba(83, 80, 63, 1)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as 'nowrap' ,
        maxWidth:'250px',
    },
    "& .nameDateParentBox": {
        display: 'flex',   justifyContent: 'space-between',
    },
    "& .date": {  textAlign: 'right',},
    
    "@media (max-width:450px)": {
        "& .nameText": {

            maxWidth: '150px'

        }
    }
})
const RightMiddleBoxWishRequest = styled(Box)({
    display: 'flex', flexDirection: 'column', gap: '20px',
    width: '100%',
    boxSizing:'border-box',
    paddingTop:'20px',
    paddingBottom:'20px',
    borderBottom:"1px dashed rgba(83, 80, 63, 0.5)",
    "& .productDetailsHeadingWishRequest":{
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        color: 'rgba(83, 80, 63, 1)',
        lineHeight:'normal',
        fontWeight: 400
    },
    "& .productDetailsMainBoxWishRequest":{
        display:'flex',flexDirection:'column',gap:'20px',
    },
    "& .productDetailsMainBoxTopBoxWishRequest, .productDetailsMainBoxLowerBox" :{
        
        display:'flex',
        gap:'5px'
    },
    "& .detailElementBoxWishRequest":{
        display:'flex',flexDirection:'column', gap:'12px',width:'170px'
    },
    "& .productDetailsMainBoxDescBoxWishRequest":{
        display:'flex',gap:'12px',
        flexDirection:'column',
    }
})
const RightMiddleBoxWishRequestCustomerDetails = styled(Box)({
    display: 'flex', flexDirection: 'column', 
    gap: '20px',
    width: '100%',
    paddingTop:'20px',
    boxSizing:'border-box',
    paddingBottom:'20px',
    "& .productDetailsHeadingWishRequest":{
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        lineHeight:'normal',
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400
    },
    "& .productDetailsMainBoxWishRequest":{
        display:'flex',
        flexDirection:'column',gap:'20px',
    },
    "& .productDetailsMainBoxTopBoxWishRequest, .productDetailsMainBoxLowerBox" :{display:'flex',gap:'5px' },
    "& .detailElementBoxWishRequest":{
        display:'flex',
        flexDirection:'column',
        gap:'12px',width:'170px'
    },
    "& .productDetailsMainBoxDescBoxWishRequest":{
        flexDirection:'column',
        display:'flex',gap:'12px',
    }
})
const RightBottomBox = styled(Box)({
    
    width: '100%',
    paddingTop: '104.6px',
    boxSizing: 'border-box',
    display:'flex',
    alignIems: 'center',
    gap: '15px',
    alignItems:'self-end',
    
    "& .reject_Request": {
        width: '157px',
        height: '56px',
        backgroundColor: 'rgba(239, 238, 233, 1)',
        textTransform: 'none',
        borderRadius: '4px',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
        cursor: 'pointer',
        border: '1px solid rgba(83, 80, 63, 1)',
        display: 'flex',
        alignIems: 'center',
        justifyContent: 'center',
    },
    "& .reject_Request : hover": {
        backgroundColor: 'rgba(239, 238, 233, 1)',
        border: '1px solid rgba(83, 80, 63, 1)',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover" :{
        border: '1px solid rgba(83, 80, 63, 1)',
        backgroundColor: 'rgba(239, 238, 233, 1)',
      },
    '@media (max-width: 600px) ': {
        display: 'flex',
        justifyContent: 'center',
    },
    "@media (max-width:435px)":{
        flexDirection:'column',
        alignItems:'center'
    }
    
})
const AcceptButton = styled(Button)({
    backgroundColor: 'rgba(83, 80, 63, 1)',
    width: '157px',
    height: '56px',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '4px',
    fontSize: '18px',
    cursor: 'pointer',
    color: 'rgba(239, 238, 233, 1)',
    justifyContent: 'center',
    alignIems: 'center',
    display: 'flex',
    textDecoration: 'none',
    border: '1px solid rgba(83, 80, 63, 1)',
    "&:hover": {
        border: '1px solid rgba(83, 80, 63, 1) !important',

        backgroundColor: 'rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',

        textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    
    '&.Mui-disabled': {
        backgroundColor: 'rgba(83, 80, 63, 1)',
        color: 'rgba(239, 238, 233, 1)',
        opacity: 0.5,
        cursor: 'not-allowed'
    },

})
const LabelText = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '4px'
})
const ReactSelect = styled(Select)({
    minWidth: '340px',
    outline: 'none',
    height: '56px',
    scrollbarGutter: 'stable',
  
    
    '& .custom-select__menu-list': {
        scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
        direction: 'ltr',
        borderRadius: '8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
        borderRadius: '8px'
    },

    '& .custom-select__menu-list:hover': {
        scrollbarWidth: '5px',
        direction: 'ltr',
        scrollbarColor: '#53503F transparent',
        borderRadius: '8px'
    },
    "& .css-1uccc91-singleValue": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
        display: 'none'
    },
    "& .css-yk16xz-control": {
        border: '1px solid rgba(83, 80, 63, 1)',
        borderColor: 'none',
        height: '100%',
    },
    "& .css-yk16xz-control:hover": {
        border: '1px solid rgba(83, 80, 63, 1)',
        borderColor: 'none'
    },
    "& .css-g1d714-ValueContainer": {
        height: '56px',
        border: 'none',
    },
    "& .css-1pahdxg-control": {
        borderColor: 'transparent !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none !important',
        height: '56px',
        minHeight: '56px'
    },
    "& .custom-select__control": {
        minHeight: '56px',
        height: '56px',
        border: '1px solid rgba(83, 80, 63, 1)',
    },
    "& .custom-select__value-container": {
        height: '56px',
        

    },
    "& .css-1wa3eu0-placeholder": {
        color: '#BABABA',
        fontWeight: 400,
        opacity: 1,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        width: '24px',
        display: 'flex',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
        padding: '0'
    },
    "& .css-6q0nyr-Svg": {
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        color: '#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        zIndex: 1000
    },
    "& .css-9gakcf-option": {
        backgroundColor: '#53503F',
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
    },
    "& span": {
        display: 'none'
    },
    "& svg": {
        cursor: 'pointer',
        position: 'absolute',
        right: '8px',
        top: '20px',
        color: '#53503F'
    },
    "@media (max-width: 400px)": {
        minWidth: '250px'
    }
})

const styles = {
    titleHeading: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
    },
    valueText: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
    },
    overflowText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap'
    },
    confirmation: {
        fontSize: '28px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
}
// Customizable Area End