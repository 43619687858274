//@ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { getStorageData } from '../../framework/src/Utilities';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web';
import { styled } from "@mui/material/styles";
const DownloadImg = require("./downloadIcon.svg").default;
const LogoImg = require("./tresorlogo.svg").default;
const instagramImg = require("./socialmediaicons/instagram.svg").default;
const tiktokImg = require("./socialmediaicons/tiktok.svg").default;
const xImg = require("./socialmediaicons/x.svg").default;
const linkedinImg = require("./socialmediaicons/linkdin.svg").default;

export function Footer({ categoriesList, handleCategoryData, openLoginModal }: any):any {
  const navigate = useNavigate();
  const [auth, setAuth] = useState<any>(null);
  const [footerLinks,setFooterLinks]=useState<any>()
  const [loginData,setLogInDetails] =useState<any>({})
  const [opentermsPopup,setOpentermsPopup]=useState<boolean>(false)
  const [openPrivacyPopup,setOpenPrivacyPopup]=useState<boolean>(false)

  // Scroll to the top of the screen
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getLoginDataFromStorage=async()=>{
    const loginData= await getStorageData("loginDetails")
    setLogInDetails(JSON.parse(loginData))
  }
  const openTermsMOdal=()=>{
    setOpentermsPopup(true)
  }
  const closeTermsModal=()=>{
    setOpentermsPopup(false)

  }
  const openPrivacyMOdal=()=>{
    setOpenPrivacyPopup(true)
  }
  const closePrivacyModal=()=>{
    setOpenPrivacyPopup(false)

  }
  const handleNavigate=()=>{
    if (loginData?.attributes?.role_name === 'personal_shopper') {
      navigate('/userProfileShopper')
    } else {
      navigate('/userProfileBuyer')
    }
  }
  useEffect(() => {
    const fetchAuth = async () => {
      const token = await getStorageData('loginToken');
      const footerLinks = await getStorageData('footerLinks',true);
      setFooterLinks(footerLinks)
      setAuth(token);
    };
    getLoginDataFromStorage()
    fetchAuth();
  }, []);

  return (
    <>
  {/* ts-ignore */}
    <FooterContainer>
      <FooterTop>
        <FooterLinksContainer>
          {/* Logo */}
          <Logo
            src={LogoImg}
            alt="Logo"
            onClick={() => {
              window.location.href = "/";
              scrollToTop();
            }}
          />

          {/* About Us */}
          <FooterSection>
            <FooterTitle>ABOUT US</FooterTitle>
            <FooterLink
              onClick={() => {
                window.location.href = "/";
                scrollToTop();
              }}
            >
              Home
            </FooterLink>
            <FooterLink
              onClick={() => {
                if (!auth) openLoginModal();
                scrollToTop();
              }}
            >
              Login/Register
            </FooterLink>
          </FooterSection>

          {/* Categories */}
          <FooterSection>
            <FooterTitle>CATEGORIES</FooterTitle>
            {categoriesList?.map((ele) => (
              <FooterLink
                key={ele.id || ele.attributes.id}
                onClick={() => {
                  handleCategoryData(
                    ele.name || ele.attributes.name,
                    ele.id || ele.attributes.id
                  );
                  scrollToTop();
                }}
              >
                {ele.name || ele.attributes.name}
              </FooterLink>
            ))}
          </FooterSection>

          {/* Support */}
          <FooterSection>
            <FooterTitle>SUPPORT</FooterTitle>
            <FooterLink
              onClick={() => {
                if (!auth) openLoginModal();
                else handleNavigate();
              }}
            >
              My Account
            </FooterLink>
            <FooterLink
              onClick={() => {
                if (!auth) openLoginModal();
                else window.location.href="/OrderManagementView";
              }}
              style={{ color: "#EDE6DC", textDecoration: "none" }}
            >
              My Orders
            </FooterLink>
            <Link
              to="/Contactus"
              onClick={scrollToTop}
              style={{ color: "#EDE6DC", textDecoration: "none" }}
            >
              Contact Us
            </Link>
            <FooterLink onClick={openTermsMOdal}>Terms & Conditions</FooterLink>
            <FooterLink onClick={openPrivacyMOdal}>Privacy Policy</FooterLink>
            <Link
              to="/HelpFaq"
              onClick={scrollToTop}
              style={{ color: "#EDE6DC", textDecoration: "none" }}
            >
              FAQs
            </Link>
          </FooterSection>

          {/* Social Media */}
          <FooterSection> 
            <FooterTitle>SOCIAL MEDIA</FooterTitle>
            <SocialIconsContainer>
              {footerLinks?.data?.map((link, index) => index < 4&&(
                <a
                  key={index}
                  href={link?.attributes?.value}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    width={20}
                    height={19}
                    src={getSocialIcon(link?.attributes?.name)}
                    alt="icon"
                  />
                </a>
              ))}
            </SocialIconsContainer>
            {footerLinks?.data[4]?.attributes?.name === "appstore_link" && (
              <a
                href={footerLinks?.data[4]?.attributes?.value}
                style={{ textDecoration: "none" }}
              >
                <img height={120} width={120} src={DownloadImg} alt="App Store" />
              </a>
            )}
          </FooterSection>
        </FooterLinksContainer>
      </FooterTop>

      {/* Bottom Footer */}
      <FooterBottom>
        © 2024 - All Rights Reserved by{" "}
        <span
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "14px",
            fontWeight: 800,
            marginLeft: "10px",
            color:"#53503F"
          }}
        >
          TRÉSOR
        </span>
      </FooterBottom>
      {opentermsPopup && <TermsConditions
                  openModal={opentermsPopup}
                  handleClose={closeTermsModal}
                  navigation={""}
                  id=""
                  label="Terms and Conditions"
            />}
            {
              openPrivacyPopup && 
              <TermsConditions
                  openModal={openPrivacyPopup}
                  handleClose={closePrivacyModal}
                  navigation={""}
                  id=""
                  label="Privacy Policy"
            />
            }
    </FooterContainer>
    </>
  );
}

const FooterContainer = styled("div")({
  width: "100%",
});

const FooterTop = styled("div")({
  backgroundColor: "#53503F",
  height: "373px",

  "@media (max-width: 1024px)": {
    height: "auto",
  },
});

const FooterLinksContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "0px 7rem",
  backgroundColor: "#53503F",
  height: "282px",
  position: "relative",
  top: "61px",

  [theme.breakpoints.down("lg")]: {
    padding: "0px 5rem",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "2rem",
    height: "auto",
    top: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "1.5rem",
    top: "30px",
  },
}));

const FooterSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "19px",

  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    gap: "15px",
    marginBottom: "20px",
  },
}));

const FooterTitle = styled(Typography)({
  color: "#EDE6DC",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "30px",
  marginBottom: "20px",
});

const FooterLink = styled("span")({
  color: "#EDE6DC",
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "'Montserrat', sans-serif",
});

const FooterBottom = styled("div")({
  fontFamily: "'Montserrat', sans-serif",
  height: "61px",
  width: "100%",
  backgroundColor: "#EDE6DC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color:"#53503F"
});

const Logo = styled("img")({
  width: "128px",
  height: "106px",
  cursor: "pointer",

  "@media (max-width: 768px)": {
    width: "100px",
    height: "80px",
  },
});

const SocialIconsContainer = styled("div")({
  display: "flex",
  gap: "6px",
});
const getSocialIcon = (name) => {
  switch (name) {
    case "instagram_link":
      return instagramImg;
    case "linkedin_link":
      return linkedinImg;
    case "twitter_link":
      return xImg;
    case "tiktok_link":
      return tiktokImg;
  }
};
