import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';

interface LocationDataResponse {
  data: LocationData[];
}

interface LocationData {
  id: string;
  type: string;
  attributes: LocationAttributes;
}

interface LocationAttributes {
  id: number;
  name: string;
  emoji_flag: string;
  country_code: string;
  alpha2: string;
}

interface Error {
  message: string;
}

interface Attributes {
  name?: string;
  email?: string;
}

interface Meta {
  token: string;
  refresh_token: string;
}
interface FirstFormApiResponse {
  data?: {
    attributes: Attributes;
  };
  meta?: Meta;
  errors?: Error[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  OtpSellerModalOpen: any;
  handleFirstFormToken: any;
  handleCancelBtn: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstUserName: string;
  firstUserNameError: string;
  lastUserName: string;
  lastUserNameError: string;
  workEmail: string;
  workEmailError: string;
  number: string,
  numberError: string;
  password: string;
  passwordError: string;
  eyeIconPassword: boolean;
  confirmPassword: string;
  confirmPasswordError: string;
  eyeIconConfirm: boolean;
  locationData: object; 
  location: any;
  locationError: string;
  formNumber: number;
  errorMessage: string,
  backendError: string,
  userData: object,
  otpErr: string,
  openOtpModal: boolean,
  firstFormToken: string,
  emailBackendError: string,
  phoneBackendError: string,
  openDropdown: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationSellerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  registrationApiCallId: string = "";
  locationApiCallId: string = "";
  cityCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      firstUserName: '',
      firstUserNameError: '',
      lastUserName: '',
      lastUserNameError: '',
      workEmail: '',
      workEmailError: '',
      number: '',
      numberError: '',
      password: '',
      passwordError: '',
      eyeIconPassword: false,
      confirmPassword: '',
      confirmPasswordError: '',
      eyeIconConfirm: false,
      locationData: [],
      location: '',
      locationError: '',
      formNumber: 1,
      errorMessage: '',
      backendError: '',
      userData: [],
      otpErr: '',
      openOtpModal: false,
      firstFormToken: '',
      emailBackendError: '',
      phoneBackendError: '',
      openDropdown: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.locationApiCallId){
          this.handleLocationApi(responseJson)
        }
        if(apiRequestCallId === this.registrationApiCallId){
          this.handleFirstFormData(responseJson)
        }
      }
    }
  }

  handleLocation = () => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.locationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleRegistration = () => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const body = {
      "data": {
          "type": "email_account",
          "terms_and_conditions": true,
          "attributes": {
              "first_name": this.state.firstUserName,
              "last_name": this.state.lastUserName,
              "email": this.state.workEmail,
              "full_phone_number": this.state.number,
              "password": this.state.password,
              "location_1": this.state.location.value,
              "role": "personal_shopper"
          }
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registrationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registrationEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  async componentDidMount() {
    this.handleLocation()
  }

  handleLocationApi = (responseJson: LocationDataResponse) => {
    const data = responseJson.data.map((ele: any) => ({
      value: ele.attributes.id,
      label: ele.attributes.alpha2,
      name:ele.attributes.name
    }));    
    this.setState({locationData: data})
  }

  handleFirstFormData = (responseJson: FirstFormApiResponse) => {
    if(responseJson && responseJson.data && responseJson.meta){
      this.setState({
        userData: responseJson.data.attributes,
        firstFormToken: responseJson.meta.token,
        formNumber: 2,
        openOtpModal: true
      })
      this.props.OtpSellerModalOpen(this.state.location)
      let dataForOtpScreen = {
        'token': responseJson.meta.token,
        'email': this.state.workEmail,
        'countryCode':this.state.location
      }
      this.props.handleFirstFormToken(dataForOtpScreen)
    }
    
    if (responseJson && responseJson.errors){
      let errorBunch = responseJson.errors

      for (const error of errorBunch) {
        if (Object.keys(error).includes('email')) {
          this.setState({ workEmailError: "Email"+Object.values(error)[0] });
        }
      
        if (Object.keys(error).includes('full_phone_number')) {
          this.setState({ numberError: Object.values(error)[0] });
        }
      }
    }
  }

  handleFirstUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({firstUserName: value})
  }

  handleLastUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({lastUserName: value})
  }

  handleWorkEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({workEmail: value})
  }

  handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({number: value})
  }

  handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({password: value})
  }

  handlePasswordEye = () => {this.setState({eyeIconPassword: !this.state.eyeIconPassword})}

  handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({confirmPassword: value})
  }

  handleConfirmPasswordEye = () => {
    this.setState({eyeIconConfirm: !this.state.eyeIconConfirm})
  }

  handleLocationChange = (event: SelectChangeEvent) => {
    const {value} = event.target;
    this.setState({location: value})
  }

  validateField = (fieldValue: string, errorMessage: string, validationFn?: (value: string) => boolean): string => {
    if (!fieldValue) return errorMessage;
    if (validationFn && !validationFn(fieldValue)) return errorMessage;
    return '';
  };

  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };
  
  handleNextBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault() 
    const { 
      firstUserName, 
      lastUserName, 
      workEmail, 
      number,
      password, 
      confirmPassword,
      location, 
    } = this.state;

    const firstUserNameError = this.validateField(firstUserName, configJSON.firstNameError);
    const lastUserNameError = this.validateField(lastUserName, configJSON.lastNameError);
    const workEmailError = this.validateField(workEmail, configJSON.emailRequired, this.validateEmail);
    const numberError = this.validateField(number, configJSON.lastNameError)
    const passwordError = this.validateField(password, configJSON.invalidPassword, this.validatePassword);
    const confirmPasswordError = this.validateField(
      confirmPassword,
      configJSON.confirmPasswordInvalid,
      (confirmPassword) => confirmPassword === password
    );
    const locationError = this.validateField(location.value, configJSON.locationRequired);

    this.setState({
      firstUserNameError,
      lastUserNameError,
      workEmailError,
      numberError,
      passwordError,
      confirmPasswordError,
      locationError,
    });

    if (
      !firstUserNameError &&
      !lastUserNameError &&
      !workEmailError &&
      !numberError &&
      !passwordError &&
      !confirmPasswordError &&
      !locationError
    ) {
      this.handleRegistration();
    }
  }

  handleIconClick = () => {
    this.setState((prevState) => ({ openDropdown: !prevState.openDropdown })
  )};

  // Customizable Area End
}
