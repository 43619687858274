import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData,setStorageData,removeStorageData} from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface ShoppingCartItem {
    id?: number,
    type?: string,
    attributes: {
      id: number,
      catalogue_id?: number,
      catalogue_variant_id?: number,
      quantity: number,
      name?: string,
      description?: string,
      model_number?: string,
      variant_color?: string,
      variant_size?: string,
      variant_qty?: number,
      approved_price?: string,
      price?: string,
      is_offer_available?: boolean,
      image_url?: string
  
  
    };
  }
  export interface Card {
      id: number,
      type: string,
      attributes: {
          id: number,
          catalogue_id: number,
          created_at: string,
          updated_at: string,
          name: string,
          category_id: number,
          sub_category_id: number,
          description: string,
          price: string,
          brand: string,
          currency: string,
          image_url: string,
          is_favorited: boolean
      }
  }
  export interface CategoriesArrayInterface {
      id?: number,
      type?: string,
      attributes?: {
          id?: number,
          name?: string,
          rank?: string | null,
          created_at?: string,
          updated_at?: string,
          brands?: string | null,
          logo?: string
      }
  }
  export interface CategoriesOnHoverListDataInterface {
      Men: {
          sub_category_id: number,
          name: string
      }[],
      Women: {
          sub_category_id: number,
          name: string
      }[],
      NewArrivals: {
          id: number,
          name: string
      }[]
  }
  export interface YouMayLikeCartInter {
      id: number,
      name: string,
      catalogues:
      {
        id: number,
        type: string,
        attributes: {
          id: number,
          catalogue_id: number,
          created_at: string,
          updated_at: string,
          name: string,
          category_id: number,
          sub_category_id: number,
          description: string,
          price: string,
          brand: string,
          currency: string,
          image_url: string,
          is_favorited:boolean
        }
      }[]
    
    }
// Customizable Area End

interface S {
  // Customizable Area Start
  
  token: string;
  cartData: ShoppingCartItem[],
  sortBy:{value:string,lable:string}|undefined|null|{},
  cartDataFav: Card[]
  categoriesArrayData: CategoriesArrayInterface[]
  categoriesOnHoverListData: CategoriesOnHoverListDataInterface[]
  removeFavModal:boolean
  moveToCartModal:boolean
  cartId:number|null
  youMayLikeData: YouMayLikeCartInter[]
  successMessage:string

  openLoginModal:boolean
  allFilter:boolean
  showCategoryPage: boolean,
  filterData:string[]
  categoryId:number|string
  gender:string
  categoryName:string
  categoryData:string[]
  isAlreadyAdded:boolean
  landingPagesData:{id:number,logo:string,name:string}[];

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FavoritesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cartDataApiCallId?: string;
  wishListDataApiCallId?: string;
  categoriesOnHoverListDataApi?: string;
  categoriesArrayDataApi?: string;
  removeFavoriteApi?:string;
  addFavoriteApi?:string;
  moveToCartApi?:string;
  youMayLikeApi?:string;
  categoryFilterApi?:string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      cartData: [],
      cartDataFav: [],
      categoriesArrayData: [],
      categoriesOnHoverListData: [],
      sortBy:{ value: "recent", label: "Recent" },
      removeFavModal: false,
      moveToCartModal: false,
      cartId:null,
      youMayLikeData:[],
      successMessage:'',
      openLoginModal:false,
      allFilter:false,
      showCategoryPage:false,
      filterData:[],
      categoryId:'',
      gender:'',
      categoryName:'',
      categoryData:[],
      isAlreadyAdded:false,
      landingPagesData:[],
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.handleFetchCartData()
    this.handleFetchWishList()
    this.handleFetchDataForYouMayLike()
    this.getcategoriesArrayData()
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        let token = message.getData(getName(MessageEnum.SessionResponseToken));
        this.setState({ token: token });
  
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson?.errors) {
        switch (apiRequestCallId) {
            case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items })
                break;
            case this.wishListDataApiCallId: this.setState({ cartDataFav: responseJson?.data })
                break;
            case this.categoriesOnHoverListDataApi: this.setState({ categoriesOnHoverListData: responseJson })
                break;
            case this.categoriesArrayDataApi: this.setState({ categoriesArrayData: responseJson?.data })
            break;
          case this.removeFavoriteApi:
            this.handleFetchWishList()
            this.handleFetchDataForYouMayLike()
            this.setState({ removeFavModal: false, cartId: null,isAlreadyAdded:false })
            break;
          case this.moveToCartApi:
            this.setState({ cartId: responseJson.item?.attributes?.catalogue_variant_id, successMessage: responseJson?.message }, () => {
              this.handleRemoveFavorite()
              this.handleFetchCartData()
              this.setState({ moveToCartModal: true })
            })
            await setStorageData("cartId", responseJson?.id);
            break;
          case this.youMayLikeApi: this.setState({ youMayLikeData: responseJson?.categories })
            break;
          case this.addFavoriteApi:
            this.handleFetchWishList()
            this.handleFetchDataForYouMayLike()
            break;
          case this.categoryFilterApi:
            this.setState({ categoryData: responseJson?.data })
              break;
  
          }
      }
        if (responseJson && responseJson?.errors) {
          if (responseJson?.errors[0]?.token) {
            removeStorageData('loginToken')
            removeStorageData('loginDetails')
            this.props?.navigation?.navigate('Home')
          }
            if (apiRequestCallId === this.wishListDataApiCallId) {
                this.setState({ cartDataFav: [] })
            }
            if(apiRequestCallId === this.moveToCartApi){
            this.setState({moveToCartModal:true, successMessage: responseJson?.errors[0].message,isAlreadyAdded:true })
            }
           
        }
    
    // Customizable Area End
  };

  // Customizable Area Start
  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };
 
  handleFetchWishList = async () => {
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs&&{token: tokenIs})
     
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishListDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWishlist(this.state.sortBy)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleRemoveFavorite=async()=>{
    
    const bodyFav = {
      "wishlist": {
        "catalogue_variant_id": this.state.cartId
        }
    }

    const tokenIs= await getStorageData('loginToken')
  
    const headerIs = {
      "Content-Type": configJSON.exampleApiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddRemoveToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFavoriteApi = requestMessageAddRemoveToFavorite.messageId;
    requestMessageAddRemoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageAddRemoveToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addRemoveFavApiEndPoint
    );
    requestMessageAddRemoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageAddRemoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyFav)
    );
    runEngine.sendMessage(requestMessageAddRemoveToFavorite.id, requestMessageAddRemoveToFavorite);
  

  }
  handleAddFavorite=async(cartId:number)=>{
    
    const bodyAddFav = {
      "wishlist": {
        "catalogue_variant_id": cartId
        }
    }
    const tokenIs= await getStorageData('loginToken')
    const headerIs = {
      "Content-Type": configJSON.exampleApiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFavoriteApi = requestMessageAddToFavorite.messageId;
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addRemoveFavApiEndPoint
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyAddFav)
    );
    runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);
  

  }
  handleMoveToCart=async(catalogue_variant_id:number,catalogue_id:number)=>{
      this.setState({cartId:catalogue_variant_id})
      const bodyMoveToCart= {
          "data": {
              "catalogue_variant_id": catalogue_variant_id,
              "catalogue_id": catalogue_id,
          }
      }

    const tokenIs= await getStorageData('loginToken')
  
    const headerIs = {
      "Content-Type": configJSON.exampleApiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageMoveToCart = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveToCartApi = requestMessageMoveToCart.messageId;
    requestMessageMoveToCart.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageMoveToCart.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveToCartApiEndPoint
    );
    requestMessageMoveToCart.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageMoveToCart.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyMoveToCart)
    );
    runEngine.sendMessage(requestMessageMoveToCart.id, requestMessageMoveToCart);
  

  }
  handleNavigate=(path:string)=>{
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  getCategoriesOnHoverData = (categorID: string | number) => {
    if (categorID != 0) {
      const requestMessageData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.categoriesOnHoverListDataApi = requestMessageData.messageId;
      requestMessageData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointcategories_hoverData(categorID)
      );
      runEngine.sendMessage(requestMessageData.id, requestMessageData);

    }
  }
  getcategoriesArrayData = () => {
    const requestMessageDataCateg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesArrayDataApi = requestMessageDataCateg.messageId;
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointgetcategoriesArrayData
    );
    runEngine.sendMessage(requestMessageDataCateg.id, requestMessageDataCateg);

  }
  handleFetchDataForYouMayLike = async () => {
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      ...(tokenIs&&{token: tokenIs})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.youMayLikeApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointYouMayLike
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
    handleReturnZero = (itemNumber: number | string) => {
        return Number(itemNumber) > 0 && Number(itemNumber) < 10 ? `(0${itemNumber})` : `(${itemNumber})`
    }
    handleSetSort = (event: {} | undefined | null) => {
        this.setState({ sortBy: event }, () => this.handleFetchWishList())
    }
    handleFetchCartData = async () => {
      const cartId=await getStorageData('cartId')
      const tokenIs= await getStorageData('loginToken')
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        ...(tokenIs&&{token: tokenIs})
       
      }
      const requestMessage1 = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.cartDataApiCallId = requestMessage1.messageId;
  
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      requestMessage1.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointCart(cartId)
      );
      
  
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage1.id, requestMessage1);
    }
    closeLoginModal=()=>{
      this.setState({openLoginModal:false})
    }
    handleCloseAllFilter = () => {
      this.setState({allFilter: false})
    }
    handleCategoryVisibility = () => {
      this.props?.navigation?.navigate("BrandWeb")
    }
    handleApplyButton = (data: []) => {
      this.setState({filterData: data})
    }
    handleCategoryData = async(categoryName: string, categoryId: number) => {
      this.props?.navigation?.navigate("CategoriesWeb",{categoryId,categoryName})
    }
    openLoginModal=()=>{
      this.setState({openLoginModal:true})
    }
    handleCategoryDataSearch = (found: boolean) => {
      this.setState({showCategoryPage:found})
    };
    handleShowCategory=()=>{
      this.setState({showCategoryPage:false})
    }
  // Customizable Area End
}





