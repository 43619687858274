import React from "react";
// Customizable Area Start
import {

  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Drawer,
  Autocomplete,
  Slider,
  Divider,
  Grid,
  Card,
  CardMedia,
  IconButton,
  CardContent
} from "@mui/material";
import {
  createTheme,
  styled,
} from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from "@mui/icons-material/Close";
export const configJSON = require("./config");
import { HeartIconOutlined, rightIcon,noProductIcon,SearchIcon1 ,favHeartIcon} from "./assets";

const MyTextField = styled(TextField)(({ theme }) => ({
  width: "480px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "25px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
const SearchHistoryItem = styled("div")({
  display: 'flex',
  boxSizing:"border-box",
  width: "445px",
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '56px',
  borderBottom:"1px solid rgba(226, 232, 240, 1)",
  backgroundColor: '#fff',
  borderRadius: '4px',
  padding: '0 16px',
  marginBottom: '8px',
  cursor: 'pointer',
  '&:hover': {
  },
});
const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  paddingLeft:"-10px",
  backgroundColor: "rgba(237, 230, 220, 0.5)",
});
const StyledSearchIcon = styled(SearchIcon)({
  width: "17.5px",
  height: "17.5px",
});
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export const BrandSearch: React.FC<any> = ({
  filteredBrands,
  searchTerm,
  handleSearchChange,
  handleSelectBrand,
  
}) => {
  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#333" }}>
        Brand
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          data-testid="brandTestId"
          value={filteredBrands.find((brand:any) => brand.name === searchTerm) || null}
          onInputChange={(event, newInputValue) => handleSearchChange(newInputValue)}
          options={filteredBrands}
          getOptionLabel={(option) => option.name}
          renderInput={(params:any) => (
            <TextField
              {...params}
              placeholder="Search Brands"
              variant="outlined"
              sx={{
                fontFamily: `montserrat, Arial, sans-serif`,
                backgroundColor: "#F9F9F9",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                  border: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon1} alt="searchIcon" />
                  </InputAdornment>
                ),
                endAdornment: null,
              }}
            />
          )}
          onChange={(event, value) => handleSelectBrand(value)}
          noOptionsText="No brands found"
          PaperComponent={({ children }) => (
            <Box
              sx={{
                backgroundColor: "#ffffff",
                border: "1px solid rgba(83, 80, 63, 1)",
                borderRadius: "4px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                marginTop: "8px",
              }}
            >
              {children}
            </Box>
          )}
          renderOption={(props:any, option:any) => {
            const isSelected = filteredBrands.find((brand:any) => brand.name === searchTerm) === option;

            const baseStyle = {
              padding: "8px 16px",
              fontSize: "14px",
            };

            const selectedStyle = isSelected
              ? {
                  backgroundColor: "rgba(83, 80, 63, 1)",
                  color: "#ffffff",
                  fontWeight: 600,
                }
              : {
                  backgroundColor: "#FFF",
                  color: "#000000",
                  fontWeight: 400,
                };

            const finalStyle = { ...baseStyle, ...selectedStyle };

            return (
              <li {...props} key={option.id} style={finalStyle}>
                {option.name}
              </li>
            );
          }}
          fullWidth
        />
      </FormControl>
    </Box>
  );
};


export const AllFilter: React.FC<any> = ({
  open,
  priceRange,
  minPrice,
  maxPrice,
  onClose,
  onClearAll,
  onApply,
  onPriceChange,
  renderValueLabel,
  selectedSubCategories,
  subCategoryData,
  handleSubCategorySelect,
  productionYearData,
  selectedYear,
  onYearChange,
  filteredBrands,
  searchTerm,
  handleSearchChange,
  handleSelectBrand,
  handleDatePosted,
  datePosted,
  color,
  colorChange,
  selectColorData,
  handleSizeChange,
  size,
  sizeData,
  cancelClick
}) => {
  return (
    <Drawer
      anchor="left"
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(239, 238, 233, 1)",
          width: "400px",
          padding: "10px 25px",
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
      data-testid="filterTestId"
    >
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <div>
          {/* Header */}
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              className="montserratFont"
              variant="h6"
              sx={{ color: "rgba(83, 80, 63, 1)", fontWeight: 600, fontSize: "16px" }}
            >
              Filters
            </Typography>
            <Button
              color="error"
              className="montserratFont"
              data-testid="clearBtnTestId"
              onClick={onClearAll}
              sx={{ fontSize: "14px", fontWeight: 600, color: "rgba(255, 61, 61, 1)", textTransform: "capitalize" }}
            >
              Clear All
            </Button>
          </Box>
          <Divider sx={{ margin: "15px 0px !important" }} />

          {/* Price Range Filter */}
          <Box>
            <Typography
              className="montserratFont"
              sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}
            >
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              step={1}
              min={minPrice}
              max={maxPrice}
              onChange={onPriceChange}
              valueLabelDisplay="on"
              valueLabelFormat={renderValueLabel}
              data-testid="priceSliderTestId"
              sx={{
                margin: '30px 20px',
                width: '85%',
                color: "#70624D",
                height: 4,
                fontFamily: `monserrat, Arial, sans-serif`,
                "& .MuiSlider-thumb": {
                  backgroundColor: "#70624D",
                  border: "2px solid #FFFFFF",
                  width: 20, 
                  height: 20,
                  "&:hover": {
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", 
                  },
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#ffffff",
                  opacity: 1,
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#70624D",
                },
                "& .MuiSlider-valueLabel": {
                  fontFamily: `montserrat, Arial, sans-serif`,
                  marginLeft: '10px !important', 
                  borderRadius: '4px',
                  backgroundColor: "#ffffff",
                  border: '1px solid rgba(83, 80, 63, 1)',
                  color: "#70624D",
                  fontSize: "12px",
                  fontWeight: 600,
                  top: -6, 
                  "&:before": {
                    display: "none"
                  },
                },
              }}
            />
          </Box>

        <BrandSearch
          filteredBrands={filteredBrands}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          handleSelectBrand={handleSelectBrand}
        />
          {/* SubCategory Component */}
          <SubCategory
            subCategoryData={subCategoryData}
            selectedSubCategories={selectedSubCategories}
            handleSubCategorySelect={handleSubCategorySelect}
          />

          {/* Year of Production Filter */}
          <FormControl fullWidth margin="normal" sx={{ marginBottom: "15px" }}>
            <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#333" }}>
              Year of production
            </Typography>
            <Select
              id="year-select"
              displayEmpty
              value={selectedYear}
              onChange={(e:any)=>onYearChange(e.target.value)}
              sx={{
                height: "56px",
                backgroundColor: "#F9F9F9",
                borderRadius: "4px",
                "& .MuiSelect-select": { padding: "10px" },
              }}
              data-testid="yearTestId"
            >
              <MenuItem value="">
                <em style={{opacity:0.5}}>Select year</em>
              </MenuItem>
              {productionYearData?.map((year: any) => (
                <MenuItem style={{background:selectedYear===year.product_year_production?"#70624D":"#ffff"}} key={year.product_year_production} value={year.product_year_production}>
                  {year.product_year_production}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {<Box sx={{ marginBottom: '15px' }}>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Date Posted</Typography>
  <Box >
    <Button
      data-testid="recentPostBtn"
      variant="outlined"
      onClick={() => handleDatePosted('recent')}
      className="montserratFont"
      sx={{
        marginRight: '10px',
        flex: 1,
        borderRadius: '4px',
        backgroundColor: datePosted === 'recent' ? '#70624D' : '#FFFFFF',
        color:datePosted === 'recent' ? '#FFFFFF' : '#333',
        borderColor: '#333',
        fontWeight: 500,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor:datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
          borderColor: '#333',
          boxShadow: 'none', 
        },
      }}
    >
      Recent
    </Button>
    <Button
      data-testid="oldPostBtn"
      variant="outlined"
      onClick={() => handleDatePosted('older')}
      className="montserratFont"
      sx={{
        flex: 1,
        borderRadius: '4px',
        backgroundColor: datePosted === 'older' ? '#70624D' : '#FFFFFF',
        color:datePosted === 'older' ? '#FFFFFF' : '#333',
        borderColor: '#333',
        fontWeight: 500,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor:datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
          borderColor: '#333',
          boxShadow: 'none', 
        },
      }}
    >
      Old
    </Button>
  </Box>
</Box>}

<FormControl fullWidth sx={{ marginBottom: '15px' }}>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Select Color</Typography>
  <Select
    id="demo-simple-select"
    labelId="demo-simple-select-label"
    displayEmpty
    data-testid="colorChoiceTestId"
    value={color}
    onChange={(e) =>colorChange(e.target.value)}
    
    renderValue={(selected) => {      
      const selectedColor =selectColorData&& selectColorData?.find((c: any) => c.id === selected);

      if (selectedColor) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
            sx={{
              backgroundColor: '#fff',
              width: '33px',
              height: '33px',
              borderRadius: '4px',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #000',
              margin: '0px 15px 0px 0px'
            }}
          >
            <Box
              sx={{
                backgroundColor: selectedColor.color_code,
                width: '31px',
                height: '31px',
                borderRadius: '4px',
              }}
            />
          </Box>
            {selectedColor.name}
          </Box>
        );
      } else {
        return <em style={webStyle.selectPlaceholder}>Select color</em>;
      }
    }}
    sx={{
      height: '56px',
      borderRadius: '8px',
      backgroundColor: '#F9F9F9',
      '& .MuiSelect-select': {
        alignItems: 'center',
        display: 'flex',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
        '&.Mui-focused fieldset': {
          border: 'none', 
        },
      },
      
    }}
  >
    <MenuItem 
    value="" 
    sx={{
      fontFamily: 'Montserrat, sans-serif',
      '&.Mui-selected:hover': {
        backgroundColor: '#5b5b5b',
      },
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#80806e',  
      },
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
      }}>
        <em style={webStyle.selectPlaceholder}>Select color</em>
      </MenuItem>
    {selectColorData&&selectColorData?.map((color: any) => (
      <MenuItem 
      key={color.id} 
      value={color.id}  
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        color: 'rgba(60, 62, 73, 1)'
      }}>
        <Box
          sx={{
            backgroundColor: color.color_code,
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            marginRight: '8px',
            fontFamily: `montserrat, Arial, sans-serif`,
            '&.Mui-selected': {
              fontFamily: `montserrat, Arial, sans-serif`,
            }
          }}
        />
        {color.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl fullWidth>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Size</Typography>
  <Select
    id="demo-simple-select"
    labelId="demo-simple-select-label"
    displayEmpty
    data-testid="sizeTestId"
    value={size}
    onChange={(e) =>handleSizeChange(e.target.value)}
    sx={{
      borderRadius: '8px',
      backgroundColor: '#F9F9F9',
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none', 
      },
    },
    }}
  >
    <MenuItem 
    value="" 
    sx={{
      fontFamily: `montserrat, Arial, sans-serif`,
      '&.Mui-selected:hover': {
        backgroundColor: '#5b5b5b',
      },
      '&.Mui-selected': {
        backgroundColor: '#80806e',  
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
      }}>
        <em style={webStyle.selectPlaceholder}>Select size</em>
      </MenuItem>
    {sizeData&&sizeData?.map((data: any) => 
      <MenuItem 
      value={data.id} 
      sx={{
        fontFamily: `montserrat, Arial, sans-serif`,
        color: 'rgba(60, 62, 73, 1)'
      }}>
       
        <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          marginRight: '8px',
          fontFamily: `montserrat, Arial, sans-serif`,
          '&.Mui-selected': {
            fontFamily: `montserrat, Arial, sans-serif`,
          }
        }}
      >
       {data.name}
      </Box>
      </MenuItem>)}
  </Select>
</FormControl>

        {/* Footer Buttons */}
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: "16px", marginBottom: "34px" }}>
          <Button
            variant="outlined"
            data-testid="cancelBtnTestId"
            onClick={cancelClick}
            sx={{
              flex: 1,
              height: "48px",
              borderRadius: "8px",
              color: "#333",
              borderColor: "#333",
              fontWeight: 500,
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#70624D", color: "#FFFFFF", border: "none" },
            }}
            className="montserratFont"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="applyTestId"
            onClick={onApply}
            sx={{
              flex: 1,
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#70624D",
              color: "#FFFFFF",
              fontWeight: 500,
              textTransform: "capitalize",
              ":hover":{
              backgroundColor: "#70624D",

              }
            }}
            className="montserratFont"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
                       

export const SubCategory: React.FC<any> = ({ subCategoryData, selectedSubCategories, handleSubCategorySelect }) => {
  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography
        className="montserratFont"
        sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}
      >
        Subcategory
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {subCategoryData?.map((subCat:any) => (
          <Chip
            className="montserratFont"
            data-testid="subcategoryTestId"
            key={subCat.id}
            label={subCat.name}
            onClick={() => handleSubCategorySelect(subCat.id)}
            sx={{
              backgroundColor: selectedSubCategories.includes(subCat.id) ? '#70624D' : '#F5F5F5',
              color: selectedSubCategories.includes(subCat.id) ? '#FFFFFF' : '#333',
              borderRadius: '4px',
              fontWeight: selectedSubCategories.includes(subCat.id) ? 600 : 400,
              padding: '10px 16px',
              height: '40px',
              border: '1px solid rgba(83, 80, 63, 1)',
              fontSize: '12px'
            }}
            clickable
          />
        ))}
      </Box>
    </Box>
  );
};
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
     <>
            
            <div style={{ position: "relative", width: "fit-content", margin: "auto"  }}>
            <MyTextField
              data-test-id="inputFirstNameSearchText"
              placeholder="Search for.."
              value={this.state.searchQuery}
              onChange={(e:any)=>this.handleSearchQuery(e)}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{marginLeft:"-10px"}}>
                    <IconWrapper>
                      <StyledSearchIcon />
                    </IconWrapper>
                  </InputAdornment>
                ),
              }}
            />
     {this.state.isLoggedIn && this.state.showSearchList && this.state.searchHistoryList.length>0 && (
    <Box
    data-test-id="searchHistory"
      onMouseDown={(e:any) => e.preventDefault()}
      style={{
        position: "absolute",
        top: "85%",
        left: "0",
        zIndex: 10,
        boxSizing: "border-box",
        padding: "0px 20px",
        color: "rgba(83, 80, 63, 1)",
        border: "1px solid rgba(83, 80, 63, 1)",
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "8px",
        margin:"0px 25px"
      }}
    >
      {this.state.searchHistoryList.map((history: any, index: any) => (
        <SearchHistoryItem
          style={{
            marginBottom:
              index === this.state.searchHistoryList.length - 1
                ? "0px"
                : "8px",
          }}
          key={index}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
            data-test-id={`searchHistoryOption${history?.id}`}
            onClick={()=>this.handleSearchClick(history?.search_term)}
          >
            <HistoryIcon />
            <span>{history?.search_term}</span>
          </div>
          <CloseIcon
          data-test-id={`deleticon${history?.id}`}
            style={{ cursor: "pointer"}}
            onClick={() => this.deleteSearch(history?.id)}
          />
        </SearchHistoryItem>
      ))}
    </Box>
  )}
      </div>
      {this.state.allFilter &&<AllFilter
     data-test-id={"allfilterTestId1"}
     priceRange={this.state.priceRange}
      minPrice={this.state.minPriceData}
      maxPrice={this.state.maxPriceData}
      onClearAll={this.handleClearAllBtn}
      onApply={this.handleApplyButton}
      onPriceChange={this.handlePriceChange}
      renderValueLabel={this.renderValueLabel}
      selectedSubCategories={this.state.subCategory}
      subCategoryData={this.state.subCategoryData}
      handleSubCategorySelect={this.handleSubCategorySelect}
      productionYearData={this.state.productionYearData}
      selectedYear={this.state.year}
      onYearChange={this.handleyearChange}
      onClose={this.handleCloseAllFilter}
      filteredBrands={this.state.filteredBrands}
      searchTerm={this.state.searchTerm}
      handleSearchChange={this.handleSearchChange}
      handleSelectBrand={this.handleSelectBrand}
      handleDatePosted={this.handleDatePosted}
      datePosted={this.state.datePosted}
      color={this.state.color}
      colorChange={this.handleColorChange}
      selectColorData={this.state.selectColorData}
      handleSizeChange={this.handlesizechange}
      size={this.state.size}
      sizeData={this.state.sizeData}
      cancelClick={this.handleClearAllBtn}
      />}
      {
          ( this.state.isSearching && this.state.categoryData.length>0) && <div className="mainContent" style={{position:"relative",width:"100%",boxSizing:"border-box",padding:"0px 40px"}}>

      <Box sx={webStyle.categoryMain} data-testid="categoryTestId">
        <Box display="flex" justifyContent="space-between" p={2}>
          <Typography className="montserratFont" data-test-id="homeNavigateBtn" style={{width:"fit-content"}} sx={webStyle.homeNavigate}>
           <span data-test-id="homeBtn" onClick={()=>{
                   this.setState({categoryData:[],searchQuery:""},()=>this.props.getCategoryData(false))
           }}>
            Home
           </span>
            <img src={rightIcon} alt='rightIcon'/><span style={{fontWeight: 500}}>
              
              <span style={{...webStyle.homeNavigate,color:"rgba(83, 80, 63, 1)",fontWeight:600}}>
                {"Search"}
                </span>
                </span></Typography>
          <Typography className="montserratFont">{this.state.categoryData?.length} Results</Typography>
        </Box> 
        <Box sx={webStyle.categoryBtn} p={2}>
          <Box sx={webStyle.filterData}>
          <Button 
          className="montserratFont"
          sx={webStyle.darkBtn} 
          onClick={this.handleOpenAllFilter}
          data-testid='allFilterTestId'
          >
            All Filters
          </Button>
          
          <Button 
          className="montserratFont"
          data-testid="womenTestId"
          sx={this.state.sortingByWomen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleWomenFilter}>
            Women
          </Button>

          <Button 
          className="montserratFont"
          data-testid="menTestId"
          sx={this.state.sortingByMen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={this.handleMenFilter}>
            Men
          </Button>
          </Box>
          <Box>
          <FormControl style={webStyle.formControl}>
            {this.state.sortOption ? '' : 
            <InputLabel
              className="montserratFont"
              htmlFor="sortOption"
              style={{
                position: 'absolute',
                top: '50%',
                left: '10%',
                transform: 'translateY(-50%)',
                fontSize: '14px', 
                color: 'rgba(83, 80, 63, 1)', 
                pointerEvents: 'none', 
              }}
            >
              Sort by
            </InputLabel>
            }
            <Select
              className="montserratFont"
              name="sortOption"
              value={this.state.sortOption}
              data-testid="sortOptionTestId"
              onChange={this.handleSortOption}
              renderValue={(selected) => `Sort by: ${configJSON.sortOptions[this.state.sortOption]}`}  
              sx={{
                ...webStyle.select, 
                backgroundColor: 'transparent',
                fontWeight: 400,
                border: '1px solid rgba(83, 80, 63, 1)',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none', 
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: webStyle.menuProps,
                },
              }}
              inputProps={{ className: "montserratFont" }}
            >
              <MenuItem
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="production_year" 
              data-testid="productYearTestId"
              style={webStyle.menuItem} 
              >
                Production Year
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont" value="date_of_listing"  style={webStyle.menuItem}>
                Date of Listing
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="price_high_to_low" 
              data-testid="priceHightToLowTestId"
              style={webStyle.menuItem} 
              >
                Price: High to Low
              </MenuItem>
              <MenuItem 
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                '&.Mui-selected:hover': {
                  color: '#fff',
                  backgroundColor: '#5b5b5b',
                },
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#80806e',  
                },
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#e0e0e0',
                },
              }}
              className="montserratFont"
              value="price_low_to_high" 
              data-testid="priceLowToHighTestId"
              style={webStyle.menuItem} 
              >
                Price: Low to High
              </MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
      </Box>
      </div>}
      <Grid container spacing={5} p={2} sx={{backgroundColor: "#EDE6DC"}}>
      
   { (this.state.categoryData.length > 0 && this.state.isSearching)&& (
      this.state.categoryData.map((showProduct: any, index: any) => (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}>
          <Card
            sx={webStyle.cardMain}
            data-test-id={`productContainer${index}`}
            onClick={() => this.navigateToDetailedProductView(showProduct.attributes.catalogue_id)}
          >
            <Box sx={webStyle.cardMediaMain}>
              <CardMedia
                component="img"
                image={showProduct.attributes.image_url}
                alt="productName"
                sx={webStyle.cardImageMain}
              />
              <IconButton
                sx={webStyle.wishlistIcon}
                aria-label="add to wishlist"
                data-test-id="addToFav"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleAddToFavorite(showProduct?.id)
                }}
              >
                <img
                  src={showProduct?.attributes?.is_favorited ? favHeartIcon : HeartIconOutlined}
                  alt="heart"
                />
              </IconButton>
            </Box>
            <CardContent sx={webStyle.cardTypography}>
              <Typography variant="h6" className="montserratFont" sx={{ margin: 0, fontSize: "22px" }}>
                {showProduct.attributes.name}
              </Typography>
              <Typography className="montserratFont" sx={{ margin: 0, fontSize: "16px", lineHeight: "30px" }} color="text.secondary">
                {showProduct.attributes.identity_name}
              </Typography>
              <Typography variant="body1" sx={{ margin: 0, fontSize: "20px", fontWeight: 400 }}>
                {showProduct.attributes.price}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))
    ) }
{(this.state.categoryData.length === 0 && !this.state.isSearching) && ( // Show "No Results" only when search is finished & no products
    <Box style={{ width: "100%", textAlign: "center" }}>
      <EmptyBagMainBox>
        <Box className="emptyImageText">
          <img className="bagIcon" src={noProductIcon} alt="" />
          <Box className="emptyTextBox">
            <Typography className="emptyText">No Results Found</Typography>
            <Typography className="emptyText2">No results found. Please try again.</Typography>
            <Button
              data-test-id="addProductTestId"
              style={{
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="startShopping"
              onClick={() => {
                this.props.getCategoryData(false);
                this.setState({ isSearching: true,searchQuery:"" },()=>this.props.getCategoryData(false));
              }}
              variant="contained"
            >
              Back to Home
            </Button>
          </Box>
        </Box>
      </EmptyBagMainBox>
    </Box>
  ) }

      </Grid>
</>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const EmptyBagMainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin:"7rem 0px",
  "& .emptyImageText": {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '30px'
  },
  "& .emptyTextBox": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '15px'
  },
  "& .emptyText": {
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '25px',
      fontWeight: 600,
      color: '#53503F',
  },
  "& .emptyText2": {
      fontFamily: "'Montserrat', sans-serif",
      textAlign: 'center',
      fontSize: '20px',
      color: '#53503F',
      fontWeight: 400,
      maxWidth: '338px'
  },
  "& .startShopping": {
      textTransform: 'none',
      borderRadius: '4px',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      backgroundColor: '#53503F',
      color: '#EFEEE9',
      fontWeight: 600,
      cursor: 'pointer',
      width: '283px',
  },
  "& .startShopping:hover": {
      backgroundColor: '#53503F',
  },
  "@media  (max-width:500px)": {
      '& .startShopping': {
          width: 'unset',
          fontWeight: 500,
          fontSize: '14px',
      },
      "& .emptyText": {
          fontSize: '16px',
      },
      "& .emptyText2": {
          fontSize: '14px',
      },
      "& .bagIcon":{
          height:'100px',
          width:'100px'
      }
  }
})
const webStyle = {
  homeNavigate: {
    width: '30%', 
    fontSize: '18px', 
    display: 'flex',
    cursor:"pointer"
  },
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  filterData: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px'
  },
  categoryMain: {
    backgroundColor: "#EDE6DC",
  },
  categoryBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px'
  },
  transparentBtn: {
    border: '1px solid #53503F',
    color: '#53503F',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  darkBtn: {
    border: '1px solid #53503F',
    backgroundColor: '#53503F',
    color: '#ffffff',
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    textTransform: "capitalize",
    borderRadius: "8px"
  },
  cardMain: {
    maxWidth: '100%', 
    boxShadow: 'none',
    border: 'none',
    cursor:"pointer"
  },
  cardMediaMain: {
    position: 'relative'
  },
  cardImageMain: {
    width: 200,
    height: 300,
    objectFit: 'contain',
    margin: '16px auto',
    borderRadius: 4,
  },
  wishlistIcon: {
    position: 'absolute',
    top: 0,
    right: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  cardTypography: {
    backgroundColor: '#EDE6DC', 
    margin: 0, 
    padding: '10px 0px'
  },
  formControl: {
    minWidth: 160,
    borderRadius: 8,
    color: '#8c8c8c',
    fontSize: '14px',
  },
  inputLabel: {
    color: '#8c8c8c',
  },
  select: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
    backgroundColor: '#f2f2e6',
  },
  menuItem: {
    fontSize: '14px',
    color: '#333',
    padding: '10px 20px',
  },
  menuProps: {
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
  },
};
// Customizable Area End
