import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

type OfferRequest = {
  data: OfferData[];
  meta: Meta;
};

type OfferData = {
  id: string;
  type: string;
  attributes: OfferAttributes;
};

type OfferAttributes = {
  id: number;
  offer_id: string;
  status: string;
  product_name: string;
  brand_name: string;
  model_number: string;
  category_name: string;
  wearname: string;
  expired_date: string | null;
  created_at: string;
  updated_at: string;
  offer_date: string;
  order_price: string;
  offer_price: string;
  condition: string;
  material: string | null;
  product_use: string | null;
  product_water_resistance: string | null;
  product_year_production: number;
  packages: string;
  dust_bag: boolean;
  description: string;
  variant_info: VariantInfo;
};

type VariantInfo = {
  id: string;
  type: string;
  attributes: VariantAttributes;
};

type VariantAttributes = {
  id: string;
  earning: string | null;
  catalogue_id: number;
  catalogue_variant_color: VariantColor;
  catalogue_variant_size: VariantSize;
  one_size: boolean;
  created_at: string;
  updated_at: string;
  price: string;
  images: Image[];
};

type VariantColor = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  color_code: string;
};

type VariantSize = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  size: string | null;
  width: string | null;
  weight: string | null;
  height: string | null;
  category_id: string | null;
  scale: string | null;
};

type Image = {
  id: number;
  blob_id: number;
  url: string;
};

type Meta = {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: string | null;
  previous_page: string | null;
  message: string;
  total_items: number;
};

type Delete = {
  message: string;
}

interface ItemAttributes {
  id: number;
  catalogue_id: number;
  catalogue_variant_id: number;
  quantity: number;
  name: string;
  description: string;
  model_number: string;
  variant_color: string;
  variant_size: string;
  variant_qty: number;
  is_available: boolean;
  price: string;
  is_offer_available: boolean;
  image_url: string;
}

interface Item {
  id: string;
  type: string;
  attributes: ItemAttributes;
}

interface ResponseData {
  id: number;
  uuid: string;
  item: Item;
  message: string;
}

type OrderItem = {
  id: string;
  type: "order_item";
  attributes: {
    id: number;
    catalogue_id: number;
    catalogue_variant_id: number;
    quantity: number;
    name: string;
    description: string;
    model_number: string;
    variant_color: string;
    variant_size: string;
    variant_qty: number;
    is_available: boolean;
    price: string;
    is_offer_available: boolean;
    image_url: string;
    is_favorited: boolean;
  };
};

type OrderAttributes = {
  uuid: string;
  status: string;
  customer_id: number;
  total_items: number;
  order_items: OrderItem[];
};

type OrderData = {
  id: string;
  type: "order";
  attributes: OrderAttributes;
};

type OrderSummary = {
  delivery_charge: string;
  tax_percentage: string;
  platform_fees: string;
  subtotal_price: string;
  total_price: string;
};

type MetaData = {
  summary: OrderSummary;
  message: string;
};

type OrderResponse = {
  data: OrderData;
  meta: MetaData;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:any;
  rejectText:any;
  rejectTextError:any;
  selectedId:any;
  viewRequest:any;
  filterKey:any;
  receivedRequests:any
  anchorEl: HTMLImageElement | null; 
  openMenuIndex: number | null; 
  page: number;
  rowsPerPage: number;
  sortOption: string;
  searchTerm: string;
  filteredBrands: any;
  error: string;
  offerListData: any;
  totalPages: number;
  currentPage: number;
  offerFilter: boolean;
  offerFilterData: any;
  totalItemCount: number;
  withdrawId: number;
  withdrawPopupOpen: boolean;
  dataCount: string;
  countDropdown: boolean;
  selectedOption: string;
  backendError: boolean;
  cartCount: number;
  productExist: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  offerDataApiCallId: string = "";
  deleteDataApiCallId: string = "";
  debounceTimeout: any;
  buyNowApiCallId: string = "";
  clearAllApiCallId: string = "";
  cartApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token:"",
      rejectText:"",
      rejectTextError:"",
      selectedId:"",
      viewRequest:"",
      filterKey:"",
      receivedRequests:[],
      anchorEl: null,
      openMenuIndex: null,
      page: 1,
      rowsPerPage: 10,
      sortOption: '',
      searchTerm: '',
      filteredBrands: [
        {id: 1, name: 'Name'},
        {id: 2, name: 'Name'}
      ],
      error: '',
      offerListData: [],
      totalPages: 0,
      currentPage: 1,
      offerFilter: false,
      offerFilterData: [],
      totalItemCount: 0,
      withdrawId: 0,
      withdrawPopupOpen: false,
      dataCount: '5',
      countDropdown: false,
      selectedOption: '',
      backendError: false,
      cartCount: 0,
      productExist: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.debounceTimeout = null;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (!apiRequestCallId || !responseJson) return;
  
    const apiHandlers: Record<string, (data: any) => void> = {
      [this.offerDataApiCallId]: this.handleOfferApiData,
      [this.deleteDataApiCallId]: this.handleDeleteApiData,
      [this.buyNowApiCallId]: this.handleBuyNowApiData,
      [this.cartApiCallId]: this.handleCartApiData
    };
  
    apiHandlers[apiRequestCallId]?.(responseJson);
  }
  

  componentDidMount = async () => {
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.handleOfferData()
    this.handleCart()
    // Customizable Area End
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
  // Customizable Area Start
  
  async componentDidUpdate(prevProps: any, prevState: any) {
    const { offerFilterData } = this.state;
    if (prevState.offerFilterData !== offerFilterData) {
      this.handleOfferData();
    }
}
  
  handleOfferApiData = (responseJson: OfferRequest) => {
    if (Array.isArray(responseJson.data) && responseJson.data.length === 0) {
      this.setState({ backendError: true });
      return;
    }

    if(responseJson && responseJson.data.length > 0 && responseJson.meta){
      this.setState({ 
        offerListData: responseJson.data,
        totalPages: responseJson.meta.total_pages,
        currentPage: responseJson.meta.current_page,
        totalItemCount: responseJson.meta.total_items,
        backendError: false
      })
    }
    
  }

  handleDeleteApiData = (responseJson: Delete) => {
    if(responseJson){
      this.handleOfferData()
      this.setState({withdrawPopupOpen: false})
    }
  }

  handleBuyNowApiData = (responseJson: ResponseData) => {
    if(responseJson.message){
      this.props.navigation.navigate('ShoppingCart')
    }
  }

  handleCartApiData = (responseJson: any) => {

  this.setState({ 
    cartCount: responseJson.data.attributes.order_items.length
  });
};
  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };
  };

  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: any) => {
    this.setState({ viewRequest });
  };

  closeViewModal = () => {
    this.setState({ viewRequest: null });
  };

  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };

  handleMenuClick = (event: React.MouseEvent, index: number) => {
    event.stopPropagation(); 
  
    this.setState(
      (prevState: any) => ({
        openMenuIndex: prevState.openMenuIndex === index ? null : index,
      }),
      () => {
        if (this.state.openMenuIndex !== null) {
          document.addEventListener("click", this.handleGlobalClick, true);
        } else {
          document.removeEventListener("click", this.handleGlobalClick, true);
        }
      }
    );
  };

  handleGlobalClick = (event: any) => {
    if (event.target.closest(".menu-popup")) {
      return; 
    }
    this.setState({ openMenuIndex: null });
    document.removeEventListener("click", this.handleGlobalClick, true);
  };

  handleCloseMenu = (): void => { 
    this.setState({ anchorEl: null, openMenuIndex: null }); 
  }; 

 

  handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  }

  handleSortOption = (event: SelectChangeEvent) => {
    this.setState({ sortOption: event.target.value },() => this.handleOfferData())
  }

  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    if (newInputValue.trim() !== '') {
      this.debounceTimeout = setTimeout(() => {
        this.handleOfferData();
      }, 500);
    }
  };

  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ 
      searchTerm: selectedBrand ? selectedBrand.name : '',
    });
  };

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, token } = data
    const header = {
      'Content-Type': contentType,
      'Token': token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleApplyOfferButton = (data: any) => {   
    this.setState({offerFilterData: data})
  }
  
  handleOfferData = async () => {
    const { sortOption } = this.state;
    const { fromDateFilter, toDateFilter } = this.state.offerFilterData;
    
    const listedPriceRangeData = await getStorageData('listedPriceRange');
    let listed = listedPriceRangeData?.split(',').map(Number);

    const offerPriceRangeData = await getStorageData('offerPriceRange');
    let offer = offerPriceRangeData?.split(',').map(Number);

    const subCategoryFromStore = await getStorageData('subCategory');
    let subCategoryData = subCategoryFromStore?.split(',').map(Number);

    const statusFromStore = await getStorageData('statusData');
    let statusData = statusFromStore ?? undefined;

    const filterConditions = [
      { key: 'sort_by', condition: sortOption === 'Older', value: 'older' },
      { key: 'sort_by', condition: sortOption === 'Price: Low To High', value: 'price_low_to_high' },
      { key: 'sort_by', condition: sortOption === 'Price: High To Low', value: 'price_high_to_low' },
      { key: 'query', condition: this.state.searchTerm, value: this.state.searchTerm },
      { key: 'filter_by_listed_price_range', condition: Array.isArray(listed) && listed.length > 0, value: listed?.join('..') },
      { key: 'offer_date_from', condition: fromDateFilter, value: fromDateFilter },
      { key: 'offer_date_to', condition: toDateFilter, value: toDateFilter },
      { key: 'scope', condition: statusData, value: statusData },
      { key: 'per_page', condition: true, value: Number(this.state.dataCount) },
      { key: 'page_no', condition: this.state.page !== undefined, value: this.state.page },
      { key: 'filter_by_offer_price_range', condition: Array.isArray(offer) && offer.length > 0, value: offer?.join('..') },
      { key: 'filter_by_category_ids', condition: Array.isArray(subCategoryData) && subCategoryData.length > 0, value: subCategoryData?.join(',') },
    ]

    const queryParamsObj: any = {};

    filterConditions.forEach(({ key, condition, value }) => {
      if (condition) {
        queryParamsObj[key] = value;
      }
    });

    const queryParams = new URLSearchParams(queryParamsObj).toString();
    const loginToken = await getStorageData("loginToken")
    

    this.offerDataApiCallId = await this.apiCall({
      method: configJSON.methodType,
      endPoint: `${configJSON.offerListApiEndPoint}?${queryParams}`,
      token: loginToken
    });
  }

  handleWithdrawOffer = async (id: number) => {
    this.setState({withdrawId: id})
  }

  handleDeleteOfferRequest = async () => {
    const loginToken = await getStorageData("loginToken")

    this.deleteDataApiCallId = await this.apiCall({
      method: configJSON.deleteMethodType,
      endPoint: `${configJSON.deleteOfferApiEndPoint}+${this.state.withdrawId}`,
      token: loginToken
    })
  }

  handlePageChange = (event:any, newPage:any) => {
    this.setState({page: newPage},() => this.handleOfferData())
  }

  handleOpenOfferFilter = () => {
    this.setState({offerFilter: true})
  }

  handleClearData = async () => {
    this.setState({offerFilterData: [], page: 1 }, () => this.handleOfferData())
  }
  
  handleCloseOfferFilter = async () => {
    this.setState({ offerFilter: false }, () => this.handleClearData())
  };
  
  handleNavgiateToProductDetails = (id: number) => {
    this.props.navigation.navigate("OfferProductDescription",{productId:id});
  };


  handleNavgiateToProductDescription = (item: any) => {
    let id = item.attributes.variant_info.attributes.catalogue_id;
    this.props.navigation.navigate("ProductDescription",{productId:id,path:{productId:id}})
  };

  handleNotNowOption = () => {
    this.setState({withdrawPopupOpen: false})
  }

  handleWithdrawPopup = () => {
    this.setState({withdrawPopupOpen: true})
  }

  handleWithDrawOffer = (id: number) => {
    this.handleCloseMenu()
    this.handleWithdrawOffer(id)
    this.handleWithdrawPopup()
  }

  handleChange = (event: SelectChangeEvent) => {
    this.setState({ dataCount: event.target.value }, () => this.handleOfferData());
  };

  handleCountDropdownOpen = () => {
    this.setState({countDropdown: true})
  }

  handleCountDropdownClose = () => {
    this.setState({countDropdown: false})
  }

  handleNavigateToHome = () => {
    this.props.navigation.navigate('Home');
  }

  handleBuyNow = async (item: any) => {
    const body = {
      data: {
        "catalogue_variant_id": Number(item.attributes.variant_info.id),
        "catalogue_id": Number(item.attributes.variant_info.attributes.catalogue_id),
        "offer_request_id": item.id
      },
    };
    const loginToken = await getStorageData("loginToken")
    this.buyNowApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.createSendRequestApiMethod,
      endPoint: `${configJSON.buyNowApiEndPoint}`,
      token: loginToken,
      body: body
    });
  }
  
  handleCart = async () => {
      const loginToken = await getStorageData("loginToken")
  
      this.cartApiCallId = await this.apiCall({
        method: configJSON.methodType,
        endPoint: `${configJSON.getCartDataApiEndPoint}`,
        token: loginToken
      })
    }
  // Customizable Area End
}
