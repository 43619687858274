import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "../../../framework/src/Utilities";
import { convertToBase64 } from '../../../components/src/commanMethods';
interface FileWithPreview extends File {
}

interface Errors {
  variantId?: string;
  price?: string;
  youEarn?: string;
  color?: string;
  size?: string;
}

type SelectedFiles = FileWithPreview[];

type Previews = string[];
interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

interface CategoryType {
  attributes: any;
  id: number,
  type: string,
  attribute: {
    id: number,
    name: string,
    rank: number,
    created_at: string,
    updated_at: string,
    brands: string,
    logo: string
  }
}

interface CatalogueResponse {
  message: string;
  data: CatalogueData;
}

interface CatalogueData {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface CatalogueAttributes {
  category_name: string;
  brand_name: string;
  description: string;
  gender: string;
  product_condition: string;
  product_use: string | null;
  product_year_production: number;
  product_material: string;
  product_water_resistance: string;
  status: string;
  created_at: string;
  updated_at: string;
  is_hidden: boolean;
  certificates: Certificate[];
  catalogue_variants: CatalogueVariant[];
}

interface Certificate {
  id: number;
  blob_id: number;
  url: string;
}

interface CatalogueVariant {
  id: string;
  type: string;
  attributes: CatalogueVariantAttributes;
}

interface CatalogueVariantAttributes {
  id: number;
  earning: number;
  catalogue_variant_color: Colour;
  catalogue_variant_size: Size;
  one_size: string | null;
  created_at: string;
  updated_at: string;
  price: string;
  images: Image[];
}

interface Colour {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  color_code: string;
}

interface Size {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  size: string | null;
  width: string | null;
  weight: string | null;
  height: string | null;
  category_id: number;
  scale: string;
}

interface Image {
  id: number;
  blob_id: number;
  url: string;
}

interface CatalogieVariantColor {
  "id": number,
  "name": string,
  "color_code": string
}

interface Image {
  id: number;
  blob_id: number;
  url: string;
}

interface CatalogueVariantSize {
    "id": number,
    "name": string,
    "category_id": number,
}

interface Variant {
  "catalogue_variant_color": CatalogieVariantColor,
  "catalogue_variant_size": CatalogueVariantSize,
  "images": Image[],
  "id": number,
  "catalogue_variant_color_id": number,
  "catalogue_variant_size_id": number,
  "price": number,
  "earning": number,
  "attachment_ids": string | number[]; 
}

interface Delete {
  "message": string;
}
interface Attachment {
  "attachment_id": number,
  "image_url": string,
  "name": string
}
type SingleProductDetails = {
  data: {
    id: string;
    type: string;
    attributes: {
      status: string;
      gender:string;
      category_id:string;
      sub_category_id:string;
      brand_id:number;
      brand_name:string;
      product_condition: string;
      product_material: string;
      product_use: string;
      product_water_resistance: string;
      dust_bag: boolean;
      product_year_production: string;
      packages: string;
      description: string;
      updated_at: string;
      modal_no: string;
      is_hidden: boolean;
      category: string;
      brand: string;
      listed_date: string;
      product_name: string;
      sold: number;
      offers: number;
      orders: number;
      variant_info: {
        listed_price: string;
        image_url: string;
      };
      certificates: any[];
      catalogue_variants: {
        id: string;
        type: string;
        attributes: {
          id: number;
          earning: number;
          stock_qty: number;
          catalogue_id: number;
          catalogue_variant_color: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
            color_code: string;
          };
          catalogue_variant_size: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
            size: string | null;
            width: string | null;
            weight: string | null;
            height: string | null;
            category_id: number;
            scale: string;
          };
          one_size: string | null;
          created_at: string;
          updated_at: string;
          price: string;
          images: {
            id: number;
            blob_id: number;
            url: string;
          }[];
        };
      }[];
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  gender: string;
  modalNumber: string;
  productName: string;
  category: string;
  categoryDropdown: boolean;
  subCategory: string;
  subCategoryDropdown: boolean;
  condition: string;
  conditionDropdown: boolean;
  material: string;
  materialDropdown: boolean;
  use: string;
  useDropdown: boolean;
  waterResistance: string;
  waterResistanceDropdown: boolean;
  filteredBrands: CategoryType[];
  searchTerm: string;
  brand: number;
  yearOfProduction: string;
  yearOfProductionDropdown: boolean;
  packages: string;
  packagesDropdown: boolean;
  dustBag: boolean;
  selectedFiles: SelectedFiles,
  errorMessage: string,
  previews: Previews,
  variantPopup: boolean,
  description: string,
  price: number;
  youEarn: number;
  selectColorData: CategoryType[];
  color: string;
  size: string;
  sizeDropdown: boolean;
  categoryData: CategoryType[];
  subCategoryData: CategoryType[];
  brandData: CategoryType[];
  conditionData: CategoryType[];
  materialData: CategoryType[];
  useData: CategoryType[];
  loginToken: string;
  productionYear: number[];
  packagesData: CategoryType[];
  colorData: CategoryType[];
  sizeData: CategoryType[];
  earnData: number;
  selectedFilesVariant: SelectedFiles;
  errorMessageVariant: string;
  previewsVariant: Previews;
  uploadDocumentId: number[];
  variantId: number[];
  formResponse: CatalogueResponse[];
  variants: any[];
  productImageId: number;
  productImageData: any;
  descriptionError: boolean;
  oneSizeOption: boolean;
  productSuccess: boolean;
  showDeletePopup: boolean;
  editProductPopup: boolean;
  editProductImage: string;
  editVariantId: number;
  editDialog: boolean;
  editData: any;
  deleteVariantId: number;
  editVariantPrice: string;
  editYouEarn: number;
  editColor: string;
  editSize: string;
  editQuantity:number;
  editSizeDropdown: boolean;
  editedData: any;
  selectedFilesImgEditVariant: SelectedFiles; 
  previewsImgEditVariant: Previews;
  errorMessageImgEditVariant: string;
  editImgData: number[];
  editImgVariantId: any;
  editImageIdArray: number[];
  editedImages: any[];
  editImageError: string;
  quantity: number;
  allVariantId: number[];
  catalogueId:string;
  openVariantEditSuccessPopUp:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start  
  categoryApiCallId: string = "";
  subCategoryApiCallId: string = "";
  brandApiCallId: string = "";
  conditionApiCallId: string = "";
  materialApiCallId: string = "";
  useApiCallId: string = "";
  yearOfProductionApiCallId: string = "";
  packagesApiCallId: string = "";
  colorApiCallId: string = "";
  sizeApiCallId: string = "";
  youEarnApiCallId: string = "";
  documentApiCallId: string = "";
  variantApiCallId: string = "";
  submitApiCallId: string = "";
  productImageApiCallId: string = "";
  addVariantApiCallId: string="";
  variantDetailsApiCallId: string="";
  deleteApiCallId: string="";
  editVariantApiCallId: string="";
  imageApiCallId: any="";
  multipleImageEditApiCallId: any="";
  variantImgEditApiCallId: any="";
  productDetailsAPIId:string="";
 
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      gender: '',
      modalNumber: '',
      productName: '',
      category: '',
      categoryDropdown: false,
      subCategory: '',
      subCategoryDropdown: false,
      condition: '',
      conditionDropdown: false,
      material: '',
      materialDropdown: false,
      use: '',
      useDropdown: false,
      waterResistance: '',
      waterResistanceDropdown: false,
      filteredBrands: [],
      searchTerm: '',
      brand: 0,
      yearOfProduction: '',
      yearOfProductionDropdown: false,
      packages: '',
      packagesDropdown: false,
      dustBag: false,
      selectedFiles: [],
      errorMessage: '',
      previews: [],
      variantPopup: false,
      description: '',
      price: 0,
      youEarn: 0,
      selectColorData: [],
      color: '',
      size: '',
      sizeDropdown: false,
      categoryData: [],
      subCategoryData: [],
      brandData: [],
      conditionData: [],
      materialData: [],
      useData: [],
      loginToken: '',
      productionYear: [],
      packagesData: [],
      colorData: [],
      sizeData: [],
      earnData: 0,
      selectedFilesVariant: [],
      errorMessageVariant: '',
      previewsVariant: [],
      uploadDocumentId: [],
      variantId: [],
      formResponse: [],
      variants: [],
      productImageId: 0,
      productImageData: [],
      descriptionError: false,
      oneSizeOption: false,
      productSuccess: false,
      showDeletePopup: false,
      editProductPopup: false,
      editProductImage: '',
      editVariantId: 0,
      editDialog: false,
      editData: [],
      deleteVariantId: 0,
      editVariantPrice: '',
      editYouEarn: 0,
      editColor: "",
      editSize: '',
      editQuantity:0,
      editSizeDropdown: false,
      editedData: [],
      selectedFilesImgEditVariant: [],
      previewsImgEditVariant: [],
      errorMessageImgEditVariant: '',
      editImgData: [],
      editImgVariantId: [],
      editImageIdArray: [],
      editedImages: [],
      editImageError: '',
      quantity: 1,
      allVariantId: [],
      catalogueId:"",
      openVariantEditSuccessPopUp:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.handleCategoryApi()
    this.handleBrandApi()
    this.handleConditionApi()
    this.handleMaterialApi()
    this.handleUseApi()
    let loginTokenFromStorage = await getStorageData('loginToken')
    this.setState({loginToken: loginTokenFromStorage},() => {this.handleProductYearApi()})
    this.handlePackagesApi()
    this.handleColorApi()
    this.handleSizeApi()
    const catalogueId=new URLSearchParams(window.location.search).get('catalogueId')
    if(catalogueId){
      this.setState({catalogueId},()=>{
        this.getProductDetails();
      })
    }
    const variantId=new URLSearchParams(window.location.search).get('variantId')
    if(variantId){
      this.handleEditPopup(Number(variantId))
    }
    // this.handleYouEarnApi()
     // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiHandlers = {
        [this.categoryApiCallId]: this.handleCategoryData,
        [this.subCategoryApiCallId]: this.handleSubCategoryData,
        [this.brandApiCallId]: this.handleBrandData,
        [this.conditionApiCallId]: this.handleConditionData,
        [this.materialApiCallId]: this.handleMaterialData,
        [this.useApiCallId]: this.handleUseData,
        [this.yearOfProductionApiCallId]: this.handleYearOfProductData,
        [this.packagesApiCallId]: this.handlePackagesData,
        [this.colorApiCallId]: this.handleColorData,
        [this.sizeApiCallId]: this.handleSizeData,
        [this.youEarnApiCallId]: this.handleYouEarnData,
        [this.documentApiCallId]: this.handleDocumentUploadData,
        [this.variantApiCallId]: this.handleVariantUploadData,
        [this.submitApiCallId]: this.handleSubmitApiData,
        [this.productImageApiCallId]: this.handleProductImageApiData,
        [this.addVariantApiCallId]: this.handleAddProductVariantApiData,
        [this.variantDetailsApiCallId]: this.handleVariantDetailsApiData,
        [this.deleteApiCallId]: this.handleDeleteApiData,
        [this.editVariantApiCallId]: this.handleEditVariantApiData,
      };
      
      if (apiRequestCallId && responseJson) {
        const handler = apiHandlers[apiRequestCallId];
        if (handler) {
          handler.call(this, responseJson);
        }
      }

      if (apiRequestCallId === this.multipleImageEditApiCallId) {
        this.handleMultipleImgApiData(responseJson)
      }

      if (apiRequestCallId === this.variantImgEditApiCallId) {
        this.handleImgVariantApiData(responseJson)
      }
      if(apiRequestCallId===this.productDetailsAPIId){
        this.handleCatalogueDetails(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCategoryData = async (response: any) => {
    if(response && response.data){
      this.setState({categoryData: await response.data})
    }
  }

  getProductDetails=async()=>{
    this.productDetailsAPIId=this.apiCall({
      method:"GET",
      endPoint:`/bx_block_catalogue/catalogues/edit_catalogue?id=${this.state.catalogueId}`,
      token: this.state.loginToken,
    })
  }
  handleSubCategoryData = async (response: any) => {
    if(response && response.data){
      this.setState({subCategoryData: await response.data})
    }
  }

  handleBrandData = async (response: any) => {
    if(response && response.data){
      this.setState({filteredBrands: await response.data})
    }
  }

  handleConditionData = async (response: any) => {
    this.setState({conditionData: await response.data})
  }

  handleMaterialData = async (response: any) => {
    this.setState({materialData: await response.data})
  }

  handleUseData = async (response: any) => {
    this.setState({useData: await response.data})
  }
  
  handleYearOfProductData = async (response: any) => {
    this.setState({productionYear: await response.years})
  }

  handlePackagesData = async (response: any) => {
    this.setState({packagesData: await response.data})
  }

  handleColorData = async (response: any) => {
    this.setState({selectColorData: await response.data})
  }

  handleSizeData = async (response: any) => {
    this.setState({sizeData: await response.data})
  }

  handleYouEarnData = async (response: any) => {
    this.setState({
      youEarn: await response.earning,
      editYouEarn: await response.earning
    })
  }

  handleDocumentUploadData = async (response: any) => {
    this.setState({uploadDocumentId: [...this.state.uploadDocumentId, response.blob.id]})
  }

  handleVariantUploadData = async (response: any) => {
    this.setState({
      variantId: [...this.state.variantId, response.response[0].attachment_id],
      productImageId: response.response[0].attachment_id
    })
  }

  handleSubmitApiData = async (response: any) => {
    this.setState({
      formResponse: response,
    }, 
    () => {
      const searchParams = new URLSearchParams(window.location.search);
      const variantId = searchParams.get("variantId");
      const catalogueId = searchParams.get("catalogueId");
    
      if (!response.errors) {
        this.setState({
          productSuccess: !variantId, 
          openVariantEditSuccessPopUp: !!catalogueId || !!variantId,
        });
      }
    }    
  )}

  handleShowProductListing = () => {
    this.props.navigation.navigate("Analytics",{tab:"Products",subTab:'All Products'})
    this.setState({productSuccess: false})
  }

  handleProductImageApiData = async (response: any) => {
    this.setState({productImageData: response})
  }

  handleAddProductVariantApiData = (response: any) => {
    let newVariant = response.data.attributes;

    this.state.variants.push(newVariant); 
    this.setState({
      variants: this.state.variants,
      variantPopup: false, 
      variantId: [],
      previewsVariant: [],
      price: 0,
      youEarn: 0,
      color: '',
      size: '',
      allVariantId: [...this.state.allVariantId, newVariant.id],
      quantity:1,
    });  

    this.handleProductImage(this.state.productImageId)
  }

  handleVariantDetailsApiData = async (response: any) => {
    const priceWithoutDollarSign = await response.data.attributes.price.replace('$', '');
    let imagesArray = response.data.attributes.images;
    const idArray = imagesArray.map((item: { id: number; }) => item.id);

    this.multipleImageEditApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.uploadMultipleImageEndPoint}?ids=${idArray.join(",")}`,
    });
    this.setState({
      editImgData: await response.data,
      editData: await response.data,
      editVariantPrice: priceWithoutDollarSign,
      editYouEarn: await response.data.attributes.earning,
      editColor: await response.data.attributes.catalogue_variant_color.id,
      editSize: await response.data.attributes.catalogue_variant_size.id,
      editQuantity:await response.data.attributes.stock_qty
    })
  }
  
  handleDeleteApiData = (response: Delete) => {
    if(response){
      const updatedVariants = this.state.variants.filter(variant => variant.id !== this.state.deleteVariantId);
      const updateId = this.state.allVariantId.filter(variant => variant === this.state.deleteVariantId)
      this.setState({ 
        variants: updatedVariants,
        allVariantId: updateId
      });
    }
  }

updateVariantInArray = (variants: Array<any>, updatedData: any) => {
  return variants.map(variant => 
    variant.id === updatedData.id
      ? { ...variant, ...updatedData }
      : variant
  );
};

handleEditVariantApiData = (response: any) => {
  this.handleEditDialogClose();
  const updatedData = response.data.attributes;
  const updatedVariants = this.updateVariantInArray(this.state.variants, updatedData);
  this.setState({ variants: updatedVariants },()=>{
    this.getProductDetails()
    const variantId=new URLSearchParams(window.location.search).get('variantId')
    if(variantId){
      this.setState({openVariantEditSuccessPopUp:true})//to show success pop up after variant edit
    }
  });
};

handleImgVariantApiData = (response: any) => {
  let newId = response.response[0];
  this.setState({
    editImageError:'',
    editImageIdArray: [...this.state.editImageIdArray, newId],
    editedImages: [...this.state.editedImages, response.response[0]]
  })
}
  handleCatalogueDetails=(response:SingleProductDetails)=>{
    const {attributes}=response.data

    const previews=attributes.certificates.map((ele:any)=>(ele?.url));
    const variants=attributes.catalogue_variants.map((ele:any)=>{
      return {
       id:ele.id,
       images:ele.attributes.images,
       price:ele.attributes.price,
       catalogue_variant_color:ele.attributes.catalogue_variant_color,
       catalogue_variant_size:ele.attributes.catalogue_variant_size,
       earning:ele.attributes.earning
      }
    })
    const certificatesIdList=response.data?.attributes.certificates?.map((certi:any)=>{
      return certi?.id
    })
    this.setState({
      gender:attributes.gender,
      productName:attributes.product_name,
      description:attributes.description,
      category:attributes.category_id,
      subCategory:attributes.sub_category_id,
      modalNumber:attributes.modal_no,
      dustBag:attributes.dust_bag,
      previews:previews,
      selectedFiles:attributes.certificates,
      material:attributes.product_material,
      condition:attributes.product_condition,
      use:attributes.product_use,
      waterResistance:attributes.product_water_resistance,
      yearOfProduction:attributes.product_year_production,
      packages: attributes.packages,         
      searchTerm:attributes.brand_name,
      variants:variants,
      uploadDocumentId:certificatesIdList,
      brand:attributes.brand_id
      },this.handleSubCategoryApi)
  }

  handleMultipleImgApiData = (response: any) => {
    this.setState({ editedImages: response })
  };

  handleGenderChange = (event: React.MouseEvent<HTMLElement>,newValue: string | null) => {
    if (newValue) {
      this.setState({ gender: newValue });
    }
  }

  handleProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({productName: event.target.value})
  }

  handleCategory = (event: SelectChangeEvent) => {
    this.setState({category: event.target.value}, 
      () => {
        this.handleSubCategoryApi()
        this.handleSizeApi()
      })
  }

  handleCategoryDropdown = () => {
    this.setState((prevState) => ({ categoryDropdown: !prevState.categoryDropdown })
  )};

  handleCategoryOpen = () => {
    this.setState({categoryDropdown: true})
  }

  handleCategoryClose = () => {
    this.setState({categoryDropdown: false})
  }

  handleSubCategory = (event: SelectChangeEvent) => {
    this.setState({subCategory: event.target.value})
  }

  handleSubCategoryDropdown = () => {
    this.setState((prevState) => ({ subCategoryDropdown: !prevState.subCategoryDropdown })
  )};

  handleSubcategoryOpen = () => {
    this.setState({subCategoryDropdown: true})
  }

  handleSubcategoryClose = () => {
    this.setState({subCategoryDropdown: false})
  }

  handleCondition = (event: SelectChangeEvent) => {
    this.setState({condition: event.target.value})
  }

  handleConditionDropdown = () => {
    this.setState((prevState) => ({ conditionDropdown: !prevState.conditionDropdown })
  )};

  handleConditionOpen = () => {
    this.setState({conditionDropdown: true})
  }

  handleConditionClose = () => {
    this.setState({conditionDropdown: false})
  }

  handleMaterial = (event: SelectChangeEvent) => {
    this.setState({material: event.target.value})
  }

  handleMaterialDropdown = () => {
    this.setState((prevState) => ({ materialDropdown: !prevState.materialDropdown })
  )};

  handleMaterialOpen = () => {
    this.setState({materialDropdown: true})
  }

  handleMaterialClose = () => {
    this.setState({materialDropdown: false})
  }

  handleUse = (event: SelectChangeEvent) => {
    this.setState({use: event.target.value})
  }

  handleUseDropdown = () => {
    this.setState((prevState) => ({ useDropdown: !prevState.useDropdown })
  )};

  handleUseOpen = () => {
    this.setState({useDropdown: true})
  }

  handleUseClose = () => {
    this.setState({useDropdown: false})
  }

  handleWaterResistance = (event: SelectChangeEvent) => {
    this.setState({waterResistance: event.target.value})
  }

  handleWaterResistanceDropdown = () => {
    this.setState((prevState) => ({ waterResistanceDropdown: !prevState.waterResistanceDropdown })
  )};

  handleWaterResistanceOpen = () => {
    this.setState({waterResistanceDropdown: true})
  }

  handleWaterResistanceClose = () => {
    this.setState({waterResistanceDropdown: false})
  }

  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
  };

  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ brand: selectedBrand ? selectedBrand.id : '' });
  };

  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = event.target.value;
    const wordCount = newDescription.trim().split(/\s+/).filter(Boolean).length;
    if (wordCount > 20) {
      this.setState({descriptionError: true})
    } else {
      this.setState({descriptionError: false})
    }

    this.setState({description: newDescription})
  }

  handleModalNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({modalNumber: event.target.value})
  }
  
  handleYearOfProduction = (event: SelectChangeEvent) => {
    this.setState({yearOfProduction: event.target.value})
  }

  handleYearProductionDropdown = () => {
    this.setState((prevState) => ({ yearOfProductionDropdown: !prevState.yearOfProductionDropdown })
  )};

  handleYearProductionOpen = () => {
    this.setState({yearOfProductionDropdown: true})
  }

  handleYearProductionClose = () => {
    this.setState({yearOfProductionDropdown: false})
  }

  handlePackages = (event: SelectChangeEvent) => {
    this.setState({packages: event.target.value})
  }

  handlePackagesDropdown = () => {
    this.setState((prevState) => ({ packagesDropdown: !prevState.packagesDropdown })
  )};

  handlePackagesOpen = () => {
    this.setState({packagesDropdown: true})
  }

  handlePackagesClose = () => {
    this.setState({packagesDropdown: false})
  }

  handleDustBag = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({dustBag: event.target.checked})
  }

  validateAndSetFiles = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previews: string[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);
        previews.push(URL.createObjectURL(file));
        const base64Image = await convertToBase64(file);
        this.handleDocumentUploadApi(base64Image)
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...validFiles],
      previews: [...this.state.previews, ...previews],
      errorMessage,
    });
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleRemoveFile = (index: number) => {
    const { selectedFiles, previews } = this.state;
  
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);
  
    this.setState({
      selectedFiles: newSelectedFiles,
      previews: newPreviews,
    });
  };

  handleVariantPopupOpen = () => {
    this.setState({variantPopup: true})
  }

  handleVariantPopupClose = () => {
    this.setState({variantPopup: false})
  }


  handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const price = value ? parseFloat(value) : 0; 
    this.setState({ price },() => this.handleYouEarnApi(price));
  }

  handleEarn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const youEarn = value ? parseFloat(value) : 0;
    this.setState({youEarn})
  }

  handleSize = (event: SelectChangeEvent) => {
    this.setState({size: event.target.value})
  }

  handleEditSize = (event: SelectChangeEvent) => {
    this.setState({editSize: event.target.value})
  }

  handleEditSizeDropdown = () => {
    this.setState((prevState) => ({ editSizeDropdown: !prevState.editSizeDropdown })
  )};

  handleEditSizeOpen = () => {
    this.setState({editSizeDropdown: true})
  }

  handleEditSizeClose = () => {
    this.setState({editSizeDropdown: false})
  }
  handleSizeDropdown = () => {
    this.setState((prevState) => ({ sizeDropdown: !prevState.sizeDropdown })
  )};

  handleEdiSizeDropdown = () => {
    this.setState((prevState) => ({ editSizeDropdown: !prevState.editSizeDropdown })
  )};

  handleSizeOpen = () => {
    this.setState({sizeDropdown: true})
  }

  handleSizeClose = () => {
    this.setState({sizeDropdown: false})
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body, token } = data
    const header = {
      'Content-Type': contentType,
      'Token': token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCategoryApi = async () => {
    this.categoryApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.categoryEndPoint}`,
    });
  }

  handleSubCategoryApi = async () => {
    const queryParamsObj: any = { category_id: this.state.category };
    const queryParams = new URLSearchParams(queryParamsObj).toString();

    this.subCategoryApiCallId =  this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.subCategoryEndPoint}?${queryParams}`,
    })
  }

  handleBrandApi = async () => {
    this.brandApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.brandEndPoint}`,
    });
  }
  
  handleConditionApi = async() => {
    this.conditionApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.conditionEndPoint}`,
    }) 
  }

  handleMaterialApi = async() => {
    this.materialApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.materialEndPoint}`
    })
  }

  handleUseApi = async() => {
    this.useApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.useEndPoint}`
    })
  }

  handleProductYearApi = async() => {
    const queryParamsObj: any = { token: this.state.loginToken };
    const queryParams = new URLSearchParams(queryParamsObj).toString();
  
    this.yearOfProductionApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.yearOfProductionEndPoint}?${queryParams}`
    })
  }
  
  handlePackagesApi = async () => {
    this.packagesApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.packagesEndPoint}`
    })
  }

  handleColorApi = async () => {
    this.colorApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.colorEndPoint}`
    })
  }

  handleSizeApi = async () => {
    const queryParamsObj: any = { category_id: this.state.category };
    const queryParams = new URLSearchParams(queryParamsObj).toString();

    this.sizeApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.sizeEndPoint}?${queryParams}`
    })
  }

  handleYouEarnApi = async (userPrice: number) => {
    const queryParamsObj: any = { price: userPrice };
    const queryParams = new URLSearchParams(queryParamsObj).toString();
    
    this.youEarnApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.youEarnEndPoint}?${queryParams}`
    })
  }

  validateAndSetFilesVariant = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previewsVariant: string[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);

        previewsVariant.push(URL.createObjectURL(file));
        const base64Image =await convertToBase64(file);
        this.handleDocumentVariantApi(base64Image)
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFilesVariant: [...this.state.selectedFilesVariant, ...validFiles],
      previewsVariant: [...this.state.previewsVariant, ...previewsVariant],
      errorMessageVariant: errorMessage,
    });
  };

  handleDropVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFilesVariant(Array.from(files));
    }
  };

  handleDragOverVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChangeVariant = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFilesVariant(Array.from(files));
    }
  };

  handleRemoveFileVariant = (index: number) => {
    const { selectedFilesVariant, previewsVariant } = this.state;
  
    const newSelectedFilesVariant = selectedFilesVariant.filter((_, i) => i !== index);
    const newPreviewsVariant = previewsVariant.filter((_, i) => i !== index);
  
    this.setState({
      selectedFilesVariant: newSelectedFilesVariant,
      previewsVariant: newPreviewsVariant,
    });
  };

  handleDocumentUploadApi = async (imageUrl: any) => {
    const body = {
      "platform_type": "desktop",
      "blob": imageUrl,
      "filename": "attachment.png",
      "file_type": "image/png"
    }

    this.documentApiCallId = this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadDocumentEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });
  }

  handleDocumentVariantApi = async (imageUrl: any) => {
    const body = {
      "platform_type": "desktop",
      "blob": [{
        "image": imageUrl,
        "filename": "attachment.jpg",
        "file_type": "image/jpg"
    }]
    }

    this.variantApiCallId = this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadVariantEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });
  }

  isFormValid = () => {
    const { gender, productName, category, subCategory, brand, condition, material, waterResistance, description, modalNumber, yearOfProduction, packages, dustBag, uploadDocumentId } = this.state;
  
    return (
      gender &&
      productName &&
      category &&
      subCategory &&
      brand &&
      condition &&
      material &&
      waterResistance &&
      description &&
      modalNumber &&
      yearOfProduction &&
      packages &&
      uploadDocumentId
    );
  }

  handleAddProduct = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: string) => {
    event.preventDefault()
    
    const body = {
      "catalogue": {
        "status": status,
        "gender": this.state.gender,
        "name": this.state.productName,
        "category_id": Number(this.state.category),
        "sub_category_id": Number(this.state.subCategory),
        "brand_id": Number(this.state.brand),
        "product_condition": this.state.condition,
        "product_material": this.state.material,
        "product_water_resistance": this.state.waterResistance,
        "description": this.state.description,
        "product_model_number": this.state.modalNumber,
        "product_year_production": JSON.stringify(this.state.yearOfProduction),
        "packages": this.state.packages,
        "dust_bag": this.state.dustBag,
        "catalogue_variants_attributes": this.state.allVariantId.map(id => ({ id })),
        "certificates": this.state.uploadDocumentId
      }
    }
  
    this.submitApiCallId =await this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.createNewCatalogueEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });

  }
  handleUpdateProduct=async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: string) => {
    event.preventDefault();
    const { 
      brand, category, productName, subCategory, gender, condition, 
      waterResistance, modalNumber, material, uploadDocumentId, 
      dustBag, packages, variants: stateVariants, yearOfProduction, 
      description
    } = this.state;
    const variants = stateVariants?.map((variant: any) => {
      return {
        id: variant.id,
       };
    });
    const certificates=uploadDocumentId?.map((ele:any)=>(ele?.id||ele))
    const body = {
      catalogue: {
        brand_id: Number(brand),
        category_id: Number(category),
        name: productName,
        sub_category_id: Number(subCategory),
        gender,
        product_condition: condition,
        product_water_resistance: waterResistance,
        product_model_number: modalNumber,
        product_material: material,
        certificates: certificates,
        dust_bag: dustBag,
        packages,
        catalogue_variants_attributes: variants,
        product_year_production: JSON.stringify(yearOfProduction),
        description,
        status,
        product_use:this.state.use,
      }
    };
    
  
    this.submitApiCallId = this.apiCall({
      method: configJSON.apiMethodTypePut,
      endPoint: `${configJSON.createNewCatalogueEndPoint}/${this.state.catalogueId}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });

  }

  isVariantFormValid = () => {
    const { color, size, price, youEarn, variantId } = this.state;
  
    return (
      color &&
      size &&
      price > 0 && 
      youEarn >= 0 && 
      variantId.length > 0
    );
  }

  handleAddVariant = async () => {  
    const { variantId, price, youEarn, color, size, quantity } = this.state;   
    
    const body:any = {
      "catalogue_variants_attributes": {
          "catalogue_variant_color_id": Number(color),
          "catalogue_variant_size_id": Number(size),
          "price": price,
          "earning": youEarn,
          "attachment_ids": variantId,
          "stock_qty": quantity
      }
    }
    if (this.state.catalogueId) {
      body.catalogue_variants_attributes.catalogue_id = this.state.catalogueId;
    }
    this.addVariantApiCallId =  this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.addVariantEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });

  }

  handleProductImage = async (productImageId: number) => {
    this.productImageApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.productImageEndPoint}/${productImageId}`,
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
        getName(MessageEnum.NavigationTargetMessage),
        pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleDeletePopup = (serialNumber: number) => {
    this.setState({
      showDeletePopup: true,
      deleteVariantId: serialNumber
    })
  }

  handleDeletePopupClose = () => {
    this.setState({showDeletePopup: false})
  }

  handleDeleteVariant = async () => {
    this.setState({showDeletePopup: false})

    this.deleteApiCallId = this.apiCall({
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.deleteApiEndPoint+this.state.deleteVariantId}`,
      token: this.state.loginToken
    });
  }

  handleEditPopup = (editVariant: number) => {
    this.setState({
      editVariantId: editVariant,
      editProductPopup: true,
    })
  }

  handleEditPopUpClose = () => {
    this.setState({editProductPopup: false})
  }

  handleEditDialogClose = () => {
    this.setState({editDialog: false})
  }

  handleEditPopUpFunctionality = async () => {
    this.setState({
      editProductPopup: false,
      editDialog: true
    })
    this.variantDetailsApiCallId = this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.variantDetailsEndPoint+this.state.editVariantId}`,
      token: this.state.loginToken
    });
  }

  handleEditPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ editVariantPrice: value },() => this.handleYouEarnApi(Number(value)));
  }

  handleSaveText = async () => {
    const attachmentIds = this.state.editedImages.map(item => item.attachment_id);

    this.setState({
        editImageError: attachmentIds.length === 0 ? "At least one variant image is required." : ""
    });

    attachmentIds.length > 0 && (this.editVariantApiCallId = this.apiCall({
        method: configJSON.apiMethodTypePut,
        endPoint: `${configJSON.variantEditEndPoint + this.state.editVariantId}`,
        body: {
            "catalogue_variants_attributes": {
                "catalogue_variant_color_id": this.state.editColor,
                "catalogue_variant_size_id": this.state.editSize,
                "price": this.state.editVariantPrice,
                "earning": this.state.editYouEarn,
                "attachment_ids": attachmentIds,
                "stock_qty":this.state.editQuantity
            }
        },
        token: this.state.loginToken,
        contentType: 'application/json'
    }));
  };


  handleVariantEditImgApi = async (imageUrl: any) => {
    const body = {
      "platform_type": "desktop",
      "blob": [{
        "image": imageUrl,
        "filename": "attachment.jpg",
        "file_type": "image/jpg"
    }]
    }

    this.variantImgEditApiCallId = this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadVariantEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });
  }

  validateAndSetImgEditVariant = (files: File[]) => {
    const validFilesData: File[] = [];
    const invalidFilesData: string[] = [];
    const previewsVariant: string[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFilesData.push(file);

        previewsVariant.push(URL.createObjectURL(file));
        const base64Image = await convertToBase64(file);
        this.handleVariantEditImgApi(base64Image)
      } else {
        invalidFilesData.push(file.name);
      }
    });

    if (invalidFilesData.length > 0) {
      errorMessage = `Invalid files: ${invalidFilesData.join(', ')}`;
    }

    this.setState({
      selectedFilesImgEditVariant: [...this.state.selectedFilesImgEditVariant, ...validFilesData],
      previewsImgEditVariant: [...this.state.previewsImgEditVariant, ...previewsVariant],
      errorMessageImgEditVariant: errorMessage,
    });
  };

  handleDropImgEditVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetImgEditVariant(Array.from(files));
    }
  };

  handleDragOverImgEditVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChangeImgEditVariant = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetImgEditVariant(Array.from(files));
    }
  };

  handleRemoveFileImgEditVariant = (id: number) => {
    const updatedImages = this.state.editedImages.filter(item => item.attachment_id !== id);
    this.setState({ editedImages: updatedImages });
  };

  handleFormDiscardBtn = () => {
    this.navigationToAnyPage("Analytics")
  }

  handleDiscardBtn = () => {
    this.setState({
      color: '',
      size: '',
      price: 0,
      youEarn: 0,
      previewsVariant: [],
      selectedFilesVariant: []
    })
    this.handleVariantPopupClose()
  }

  handleIncreaseQty = () => {
    this.setState((prevState) => ({ quantity: prevState.quantity + 1 }));
  };

  handleDecreaseQty = () => {
    if (this.state.quantity > 1) {
      this.setState((prevState) => ({ quantity: prevState.quantity - 1 }));
    }
  }
  // Customizable Area End
}
