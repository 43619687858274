import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation:any,
  id:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openLoginModal:any;
  cartLength:any;
  allFilter:any;
  gender:any;
  categoryId:any;
  categoriesOnHoverList:any;
  categoriesArray:any;
  productCount:any;
  showCategoryPage:boolean;
  filterData:any[];
  categoryName:string;
  categoryData:any[];
  product:any[];
  sortOption:any;
  sortingByWomen:boolean;
  sortingByMen:boolean;
  genderForFilter:any;
  filteredBrands:any;
  searchTerm:any;
  brand: number;
  subCategoryData: any;
  subCategory: any;
  minPriceData: number,
  maxPriceData: number,
  priceRange: any;
  year:string;
  productionYearData: any;
  datePosted:string
  color:any;
  selectColorData:any;
  size:any;
  sizeData:any;
  brandData:any;
  categoriesFilterOptions:any;
  selectedCategoryList:any[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getHoverCategoriesList:string="";
  categorydataApiId:string="";
  getCategoriesListApiCallId:string="";
  cartDataApiCallId:string="";
  productApiCallId:string="";
  addToFavoriteApi:string="";
  brandProductsApi:string="";
  filterDynamicDataApiCallId:string="";
  brandId:string="";
  categoryId:string="";
  getFilterOptionsAPi:string="";
  filterApplyApiId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      openLoginModal:false,
      cartLength:false,
      allFilter:false,
      gender:false,
      categoryId:false,
      categoriesOnHoverList:[],
      categoriesArray:[],
      productCount:0,
      showCategoryPage:false,
      filterData:[],
      categoryName:"",
      categoryData:[],
      product:[],
      sortOption: 'recent',
      sortingByWomen:false,
      sortingByMen:false,
      genderForFilter:"",
      filteredBrands: [],
      searchTerm:"",
      brand: 0,
      subCategoryData:[],
      subCategory: [],
      minPriceData: 0,
      maxPriceData: 0,
      priceRange: [50,450],
      year: '',
      productionYearData: [],
      datePosted:"",
      color:"",
      selectColorData:[],
      size:"",
      sizeData:[],
      brandData:[],
      categoriesFilterOptions:[],
      selectedCategoryList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
    
    switch (apiRequestCallId) {   
      case this.getCategoriesListApiCallId:
        this.setState({ categoriesArray: responseJson.data });
        break;
      case this.getHoverCategoriesList:
        this.setState({ categoriesOnHoverList: responseJson });
        break;
        case this.cartDataApiCallId:
           this.setState({ cartLength: responseJson?.data?.attributes?.order_items.length})
        break;
        case this.categorydataApiId:
          this.setState({categoryData:responseJson.data})
          break;
          case this.productApiCallId:
            this.handleProductApiCallId(responseJson)
            break;
          case this.addToFavoriteApi:
            if(responseJson?.message){
              this.handleApiCall()
            }
            else if (responseJson?.errors[0]?.token){
              removeStorageData('loginToken')
              this.openLoginModal()
            }
          break;
          case this.brandProductsApi:
            this.setState({product:responseJson?.data})
          break;
          case this.getFilterOptionsAPi:
            let minPriceFloor1= Math.floor(responseJson.min_price)
            let maxPriceFloor1 = Math.floor(responseJson.max_price)
            this.setState({
              priceRange: [minPriceFloor1, maxPriceFloor1],
              brandData: responseJson.brands,
              subCategoryData: responseJson.sub_categories,
              productionYearData: responseJson.production_year,
              selectColorData: responseJson.color_codes,
              sizeData: responseJson.size_names,
              minPriceData: minPriceFloor1,
              maxPriceData: maxPriceFloor1,
              filteredBrands: responseJson.brands,
              categoriesFilterOptions:responseJson.categories
            })
          break;
          case this.filterApplyApiId:
            if(responseJson.errors){
              this.setState({product:[],allFilter:false})
            }else{
              this.setState({allFilter:false,product:responseJson.data})
            }
          break;
      default:
        this.parseApiErrorResponse(errorReponse);
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getCategoriesList();
    const cartId=await getStorageData('cartId')
    this.fetchCartData(cartId);
    this.handleApiCall();
    const brandId=new URLSearchParams(window.location.search).get('brandId')
    if(brandId){
      this.brandId=brandId
    }
  }

  closeLoginModal=()=>{
    this.setState({openLoginModal:false})
  }
  handleApiCall=()=>{
    const brandId=new URLSearchParams(window.location.search).get('brandId')
    if(brandId){
      this.getBrandProducts(brandId);
    }else{
      this.handleNewArrivalProduct()
    }
  }
  handleFilterData = async () => {
    const brandId=new URLSearchParams(window.location.search).get('brandId')
    let endpoint="";
    if(brandId){
      endpoint=`/bx_block_categories/categories/filter_view?brand_id=${brandId}`
    }else{
      endpoint="/bx_block_catalogue/catalogues/load_new_arrivals_filter"
    }
    const tokenIs= await getStorageData('loginToken')
    this.getFilterOptionsAPi= this.apiCall(tokenIs,{
      method:"GET",
      endPoint:endpoint,
  })
  }
 
  fetchCartData=async(cartsId?:string|number)=>{
    const cartId=await getStorageData('cartId')
    const tokenIs= await getStorageData('loginToken')
    this.cartDataApiCallId= this.apiCall(tokenIs,{
      method:"GET",
      endPoint:`bx_block_shopping_cart/orders/${cartsId||cartId}`,
  })
  }
  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
  };
  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ 
      searchTerm: selectedBrand ? selectedBrand.name : '',
      brand: selectedBrand ? selectedBrand.id : '' 
    });
  };
  handleProductApiCallId = (responseJson: any) => {
    this.setState({product: responseJson.data})
  }
  navigateToDetailedProductView=(productId:string)=>{
    this.props.navigation.navigate("ProductDescription",{productId:productId,path:{productId:productId}})
  }
  handleSortOption = (event: SelectChangeEvent) => {  
      this.setState({ sortOption:event.target.value }, this.handleApplyButton);
  };
  
  getCategoriesList=()=>{
    this.getCategoriesListApiCallId= this.apiCall("",{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  }
  handleCategoryVisibility = () => {
    this.props.navigation.navigate("Home")
  }
  handleNewArrivalNavigation=()=>{
    this.props.navigation.navigate("BrandWeb")
    this.brandId=""
    this.handleApiCall()
  }
  handleNewArrivalProduct = async () => {
    const token=await getStorageData("loginToken")
    this.productApiCallId = this.apiCall(token,{
      method: configJSON.httpGetType,
      endPoint: 'bx_block_catalogue/catalogues/filtered_catalogues?new_arrivals=true',
    });
  }
  getBrandProducts = async (brandId:any) => {
    this.brandProductsApi = this.apiCall("",{
      method: configJSON.httpGetType,
      endPoint: `/bx_block_catalogue/catalogues/filtered_catalogues?brand_id=${brandId}`,
    });
  };
  handleSubCategorySelect = (subCat: any) => {
    const { subCategory } = this.state;
    this.setState({
      subCategory: subCategory.includes(subCat)
        ? subCategory.filter((name:string) => name !== subCat)
        : [...subCategory, subCat],
    });
  };
  handleCategorSelect=(cat:any)=>{
    const { selectedCategoryList } = this.state;
    this.setState({
      selectedCategoryList: selectedCategoryList.includes(cat)
        ? selectedCategoryList.filter((name:any) => name !== cat)
        : [...selectedCategoryList, cat],
    });
  }
  handleAddToFavorite=async(catalogue_variant_id:number,category_id:number)=>{
    this.setState({categoryId:category_id})
    const bodyFav = {
      "wishlist": {
        "catalogue_variant_id": catalogue_variant_id
      }
    }
    const tokenIs= await getStorageData('loginToken')
    const headerIs = {
      "Content-Type": configJSON.categoryApiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToFavoriteApi = requestMessageAddToFavorite.messageId;
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveToFavorite
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyFav)
    );
    runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);
  

  }
  handleCloseAllFilter = () => {
    this.setState({allFilter: false})
  }
  handleOpenAllFilter=()=>{
    this.setState({allFilter: true},this.handleFilterData);
  }
  handleClearAllBtn = () => {
this.setState({allFilter:false,selectedCategoryList:[],year:"",color:"",
  size:"",
  datePosted:"",
  subCategory:[],
  filteredBrands:[],
  searchTerm:"",
  brand:0,
  sortingByMen:false,
  sortingByWomen:false
},this.handleApiCall)

    this.handleCloseAllFilter()

  }
  handleApplyButton =async () => {
    const endPointWithFilters=this.getApplyFilterParams();
    const token=await getStorageData("loginToken")
    this.filterApplyApiId=this.apiCall(token,{
      method:"GET",
      endPoint:endPointWithFilters
    })
  }
  getApplyFilterParams = () => {
    const filterParams=this.getFilterParams();
    const endpoint=this.getEndpoint()
  
    return `${endpoint}&&${filterParams}`;
  };
  getFilterParams = () => {
  
    const { selectedCategoryList, sortOption, sortingByWomen, sortingByMen, brand, subCategory, year, color, size } = this.state;
    const params:any = {};
  
    if (sortOption) params.sort_by = sortOption;
    if (sortingByWomen) params.sorted_by_women = sortingByWomen;
    if (sortingByMen) params.sorted_by_men = sortingByMen;
    if (brand) params.filter_by_brand_ids = brand;
    if (subCategory) params.filter_by_subcategory_ids = subCategory;
    if (year) params.filter_by_product_year_production = year;
    if (color) params.filter_by_color_ids = color;
    if (size) params.filter_by_size_ids = size;
    if (this.state.selectedCategoryList) params.filter_by_category_ids=selectedCategoryList?.length ? selectedCategoryList.join(",") : null
    return this.buildParams(params);
  };
 
   getEndpoint = () => {
    if(this.brandId){
      return `bx_block_catalogue/catalogues/filtered_catalogues?brand_id=${this.brandId}`;
    }else{
      return `/bx_block_catalogue/catalogues/filtered_catalogues?new_arrivals=true`
    }
  };
  buildParams = (paramsObj:any) => {
    const params = new URLSearchParams();
    Object.entries(paramsObj).forEach(([key, value]:any) => {
      if (value) {
        params.append(key, value);
      }
    });
    return decodeURIComponent(params.toString());
  };
  getCategoriesOnHover=(categorID:string|number)=>{
    if(categorID!=0){
      this.getHoverCategoriesList=this.apiCall("",{
        method:"GET",
        endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
      })
    }
  }
  handleCategoryData = async(categoryName: any, categoryId: any) => {
    this.props.navigation.navigate("CategoriesWeb",{categoryId,categoryName})
  }
  openLoginModal=()=>{
    this.setState({openLoginModal:true})
  }
  handleShowCategory=()=>{
    this.setState({showCategoryPage:false},()=>{
    this.handleApiCall()
    })
  }
  handleCategoryDataSearch = (found: any) => {
    this.setState({showCategoryPage:found})
  };
  apiCall = (token: any,payload:any) => {
    let {method,endPoint,body}=payload
    const header = {
      'Content-Type': 'application/json',
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body&&requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
  handlePriceChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue) && newValue.length === 2) {
      this.setState({ priceRange: newValue });
    }
  };
  renderValueLabel = (value: number) => {
    return `$${value}`;
  };
  // Customizable Area End
}