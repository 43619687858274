Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.exampleAPiPutMethod = "PUT";
exports.exampleAPiDeleteMethod = "DELETE";
exports.exampleAPiPatchMethod = "PATCH";



exports.articleEndPointApi=(articleId)=>`bx_block_settings/help_centers/${articleId}`
exports.endPointArticlesApi='bx_block_settings/help_centers/articles_load'
exports.endPointFaqApi='bx_block_settings/help_centers/faqs_load'
exports.endPointCategoryApi='/bx_block_content_management/landing_pages'
exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.filteredCatalogues=(categoryId)=>`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
exports.help_FAQ='Help & FAQ'
exports.home='Home'
exports.frequently_asked_questions='Frequently asked questions ?'
exports.important_links='Important links'
exports.article='Article'

exports.settings='Settings'
exports.account='ACCOUNT'
exports.cardDetails='Card details'
exports.edit='Edit'
exports.remove='Remove'
exports.newCard='New Card'
exports.change='Change'

exports.address='ADDRESS'
exports.default='Default'
exports.shippingAddress='Shipping address'
exports.newAddress='New Address'
exports.notifications='NOTIFICATIONS'
exports.weeklyUpdates='Weekly updates'
exports.actions='ACTIONS'
exports.deactivate_account ='Deactivate account '
exports.delete_account='Delete account'

exports.add_address='Add Address'
exports.yourAddress='Your address'
exports.editAddress='Edit address'
exports.address_Line_1='Address Line 1'
exports.address_Line_2='Address Line 2'
exports.postal_Code='ZIP/Postal Code*'
exports.city='City*'
exports.state='State*'
exports.country='Country*'
exports.Select='Select'
exports.enterHere='Enter here'
exports.submit='Submit'
exports.not_now='Not now'
exports.yes='Yes'
exports.confirmation='Confirmation'
exports.addressRemoveConfirmationMessage=`Are you sure you want to remove this address?`
exports.save='Save'
exports.success='Success!'
exports.okay='Okay'
exports.loginNow='Login Now'
exports.deactivateAccountConfirmationMessage='Are you sure you want to deactivate your account from TRÉSOR ?'
exports.deleteAccountConfirmationMessage='Are you sure you want to delete your account from TRÉSOR ?'
exports.make_this_address_as_default='Make this address as default'
exports.accountBalance='Account Balance'
exports.bankDetails='Bank Details'
exports.editBankDetails='Edit Bank Details'
exports.newBankDetails='New Bank Details'
exports.bankName='Bank Name*'
exports.bankNamePlaceHolder='Bank name'
exports.accountNumber='Account Number*'
exports.accountNumberPlaceHolder='Account number'
exports.accountHolderName='Account Holder’s Name*'
exports.accountHolderNamePlaceHolder='Account Holder’s name'
exports.currency='Currency*'
exports.ibanNumber='IBAN Number*'
exports.ibanNumberPlaceHolder='IBAN number'

exports.addressEndPoint='bx_block_address/addresses'
exports.weeklyStatus='bx_block_settings/settings/weekly_status'
exports.weeklyUpdate='bx_block_settings/settings/weekly_update'
exports.countryListEndPoint=`account_block/accounts/country_code_and_flags.json`
exports.cityListEndPoint=(country_code,stateCode)=>`account_block/accounts/country_code_and_flags/show_cities?country_code=${country_code}&state_code=${stateCode}`
exports.stateEndPoint=(countryCode)=>`/account_block/accounts/country_code_and_flags/county_based_state?country_code=${countryCode}`
exports.addressEndPoint='bx_block_address/addresses'
exports.deleteAddressEndPoint=(addressId)=>`bx_block_address/addresses/${addressId}`
exports.deactivated_account='bx_block_settings/settings/deactivated_account'
exports.deleteAccount='bx_block_settings/settings/delete_account'
exports.passChangeEndPoint='bx_block_profile/passwords/:id'
exports.bankDetailsApiEndPoint='bx_block_settings/settings/fetch_bank_details'
exports.update_bank_detail=(bankId)=>`bx_block_settings/settings/update_bank_detail?id=${bankId}`
exports.add_bank_details='bx_block_settings/settings/add_bank_details'
exports.manage_card='bx_block_cfskipcashintegration_0/payments/manage_card'
exports.fetch_bank_currencies='bx_block_settings/settings/fetch_bank_currencies'
exports.shopper_balance='bx_block_cfskipcashintegration_0/payments/shopper_balance'
exports.card_removed=(cardNumber)=>`/bx_block_cfskipcashintegration_0/payments/card_removed?card_number=${cardNumber}`
// Customizable Area End