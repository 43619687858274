import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConditions {
  id: string;
  attributes: {
    created_at: string;
    description: string;
    accepted_by: [
      {
        account_id: string;
        accepted_on: string;
        email: string;
      }
    ];
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  termsCondsId: string;
  termsConds: ITermsConditions | null;
  isLoading: boolean;
  title:string;
  conditionType:any;
  modalContentTermsconditions:string;
  modalContentPrivacyPolicy:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  // Customizable Area Ends

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      termsCondsId: "",
      termsConds: null,
      isLoading: false,
      title:"",
      conditionType:{
      },
      modalContentTermsconditions:"",
      modalContentPrivacyPolicy:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const params = new URLSearchParams(window.location.search);
    const label = params.get("label");
    if(label){
      this.setState({title:label})
    }
    this.getTermsCondsDetails()
    // Customizable Area End
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const params = new URLSearchParams(window.location.search);
    const currentId = params.get("label");
  
    if (currentId && currentId !== prevState.title) {
      this.setState({ title: currentId });
    }
  }
  
  
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsUsers = (termsConds: ITermsConditions) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsUsers"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsUsers: termsConds.attributes?.accepted_by,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsConds: this.state.termsConds?.attributes?.description,
      termsCondsId: this.state.termsConds?.id,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getTermsCondsDetails =async () => {
    const token=await getStorageData("loginToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
     token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermsCondsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getTermsCondsCallId &&
      responseJson?.data
    ) {
      this.getTermsCondsCallId = "";
     responseJson.data.map((ele:any)=>{
      if(ele.condition_type=="terms_and_condition"){
        this.setState({
          conditionType:{...this.state.conditionType,"Terms and Conditions":ele?.description},
          isLoading:false
        })
      }else if(ele.condition_type==="privacy_policy"){
        this.setState({
          conditionType:{...this.state.conditionType,"Privacy Policy":ele?.description},
          isLoading:false
        })
      }
     })
    }
    // Customizable Area End
  }
}
