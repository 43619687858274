import React from "react";

// Customizable Area Start
import { Box, Button, Modal, styled, Typography } from "@mui/material";

import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import { rightArrow,deleteIcon,crossIcon} from "./assets"
import MyWishDetailsController, {
  Props,
  configJSON
} from "./MyWishDetailsController";
// Customizable Area End


export default class MyWishDetails extends MyWishDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

    handleCartRemoveModal = (deleteId:number|string) => {
        return (
            <Box >
                <Modal
                    open={this.state.isRequestDeleteModal}
                    onClose={() => this.setState({ isRequestDeleteModal: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='requestDeleteModal'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                    
                >
                    <DeleteRequestModalBox >
                        <Box data-test-id='crossIconBox' className="crossIconBox" onClick={() => this.setState({ isRequestDeleteModal: false })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography style={styles.confirmation}>{configJSON.confirmation}</Typography>
                        <Typography className="areYouSureText">{configJSON.are_you_sure_you_want_to_delete_the_wish_request}</Typography>
                        <CancelYesButtonBox>
                            <CancelButton data-test-id='cancelButton' onClick={() => this.setState({ isRequestDeleteModal: false })} variant="contained" >{configJSON.not_now}</CancelButton>
                            <RemoveButton data-test-id='removeButton' variant="contained" onClick={()=>this.handleDeleteRequest(deleteId)} >{configJSON.yes}</RemoveButton>
                        </CancelYesButtonBox>
                    </DeleteRequestModalBox>
                </Modal>
            </Box>
        )
    }

  // Customizable Area End

  render() {
    // Customizable Area Start
     const wishData =  this.state.wishData?.attributes
    return (
        
        <ContainerBox >
            <AppHeader
                closeLoginModal={this.closeLoginModal}
                openLoginModal={this.state.openLoginModal}
                count={this.state.cartData?.length}
                data-test-id='appHeader'
            />

            <OuterMainBox >
                 <ArrowTopBox>
                    <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.homeText}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography data-test-id='WishRequests'  className="arrowHomeText" onClick={() => this.handleNavigate('WishRequests')}> {configJSON.my_Wishes} </Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography className="arrowSecondText"> {configJSON.details} </Typography>
                </ArrowTopBox>
                    <MainParentBox>
                    <ImageParentBox>
                        <Box className='smallImagesColumn' sx={{overflowY:this.state.wishData?.attributes?.product_image?.length>6?'scroll':'unset'}}>
                            {this.state.wishData?.attributes?.product_image?.length > 0 && this.state.wishData.attributes.product_image.map((element) => (
                                <Box style={{
                                    border: this.state.isActiveImage == element.id ? '1px solid rgba(83, 80, 63, 1)' : '1px solid transparent',
                                    borderRadius: '4.77px',
                                    padding: '2.98px',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer'
                                }} 
                                onClick={() => this.handleImageChange(element)}
                                data-test-id='changeImage'
                                >
                                    <Box className='smallImageDiv'>
                                        <img src={element.url} alt="" />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box className='bigImagesBox'>
                            <img src={this.state.isBigImage?.url} alt="" />
                        </Box>
                    </ImageParentBox>
                    <RightParentBox>
                        <RightFirstTopBox>
                             <Box className='requestIdAndStatusParentBox'>
                                <Typography className="requestedIdText">{configJSON.request_Id} <span>{wishData?.request_id}</span></Typography>
                                <Typography className="statusText">{wishData?.status}</Typography>
                             </Box>
                             <Box className='nameDateParentBox'>
                                <Typography className="nameText">
                                {wishData?.name}
                                </Typography>
                                <Box className='dateParentBox'>
                                    <Typography style={{...styles.titleHeading}}>{configJSON.requested_Date}</Typography>
                                    <Typography className="date" style={{...styles.valueText}}>{wishData?.request_date}</Typography>
                                </Box>
                             </Box>
                        </RightFirstTopBox>
                        <RightMiddleBox>
                            <Typography className="productDetailsHeading">{configJSON.productDetails}</Typography>
                            <Box className='productDetailsMainBox'>
                                <Box className='productDetailsMainBoxTopBox'>
                                    <Box className='detailElementBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.category}</Typography>
                                        <Typography style={{ ...styles.valueText }}>{wishData?.category}</Typography>
                                    </Box>
                                    <Box className='detailElementBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.brand}</Typography>
                                        <Typography style={{ ...styles.valueText }}>{wishData?.brand}</Typography>
                                    </Box>
                                </Box>
                                <Box className='productDetailsMainBoxLowerBox'>
                                    <Box className='detailElementBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.year_of_Production}</Typography>
                                        <Typography style={{ ...styles.valueText }}>{this.handleCheckPresent(wishData?.production_year)?wishData?.production_year:'-'}</Typography>
                                    </Box>
                                    <Box className='detailElementBox'>
                                        <Typography style={{ ...styles.titleHeading }}>{configJSON.model_Number}</Typography>
                                        <Typography style={{ ...styles.valueText,...styles.overflowText }}>{wishData?.product_model_number}</Typography>
                                    </Box>
                                </Box>
                                <Box className='productDetailsMainBoxDescBox'>
                                <Typography style={{ ...styles.titleHeading }}>{configJSON.description}</Typography>
                                <Typography style={{ ...styles.valueText }}>{this.state.isExpand?wishData?.description:wishData?.description?.slice(0,145)} <span data-test-id='readMore' style={{...styles.titleHeading,cursor:'pointer'}} onClick={()=>this.setState({isExpand:!this.state.isExpand})}>{this.state.isExpand?'read less':'read more..'}</span></Typography>
                                </Box>
                            </Box>
                        </RightMiddleBox>
                        <RightBottomBox>
                            <Button data-test-id='deleteRequest' className="delete_Request" onClick={()=>this.setState({isRequestDeleteModal:true,deleteId:this.state.wishData?.id})} variant="outlined" startIcon={<img src={deleteIcon} />}>
                                {configJSON.delete_Request}
                            </Button>
                            {
                                wishData?.status == 'found' && <BackButton data-test-id='backToFoundRequest' onClick={() => this.props?.navigation?.navigate('FoundWishDetails', { Id: wishData?.id })} variant="outlined" >
                                    {configJSON.back}
                                </BackButton>
                            }
                        </RightBottomBox>
                    </RightParentBox>
                    </MainParentBox>
            </OuterMainBox>
             {this.handleCartRemoveModal(this.state.deleteId)}
            <Footer data-test-id="footerTest" openLoginModal={this.openLoginModal} handleCategoryData={this.handleCategoryData} categoriesList={this.state.categoriesArrayData} />
        </ContainerBox>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterMainBox = styled(Box)({
    padding: '40px 40px 180px 40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '@media (max-width: 400px) ': {
        padding: '10px',
    }

})
const ArrowTopBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
    paddingLeft: '6px',
    "& .arrowHomeText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .arrowSecondText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 600,
        color: '#53503F'
    }

})

const MainParentBox = styled(Box)({
    
    width: '100%',
    display: 'flex',
    gap: '29.81px',
    
    '@media (max-width: 990px) ': {
        flexDirection:'column',
        alignItems:'center'
    }

})
const ImageParentBox = styled(Box)({
    
    width: '485.19px',
    display: 'flex',
    gap: '7.72px',
    "& .smallImagesColumn": {
        width: "65.57px",
        height:'479.36px',
        display: 'flex',
        flexDirection: 'column',
        gap: '7.15px'
    },
    "& .smallImagesColumn>div> .smallImageDiv": {
        width: '100%',
        height: '62.59px',
        borderRadius: '4.77px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "& .smallImagesColumn img": {
        width: '40.41px',
        height: '40.41px',
    },
    "& .bigImagesBox": {
        width: "411.9px",
        height: "479.36px",
        borderRadius: '4px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "& .bigImagesBox img": {width: '241.94px',
        height: '241.94px',
        objectFit: 'contain',
    },
    
    '@media (max-width: 600px) ': {
        flexDirection:'column-reverse',
        alignItems:'center',
        width:'100%',
        "& .smallImagesColumn": {
            width: '100%',
            flexDirection: 'row',
            overflowX: 'scroll',
            justifyContent: 'flex-start',
            height:'unset'
        },
        "& .smallImagesColumn>div> .smallImageDiv": {
            width: '100px',

        },
        "& .bigImagesBox": {
            width:'100%'
        }
    }

})
const RightParentBox = styled(Box)({
    
    width: '100%',
})
const RightFirstTopBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '17.93px',
    borderBottom: "1px solid rgba(83, 80, 63, 0.5)",
    paddingBottom: '8px',
    boxSizing: 'border-box',
    "& .requestIdAndStatusParentBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    "& .requestedIdText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
        lineHeight: 'normal'
    },
    "& .requestedIdText span": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        fontSize: '20px',
        color: 'rgba(83, 80, 63, 1)'
    },

    "& .statusText": {
        width: '124px',
        backgroundColor: 'rgba(239, 238, 233, 1)',
        borderRadius: '16.45px',
        border: '0.5px solid rgba(83, 80, 63, 1)',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 500,
        color: 'rgba(83, 80, 63, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 'normal',
        paddingTop:'4px',
        paddingBottom:'4px'
    },
    "& .dateParentBox": {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    "& .nameText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
        lineHeight: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap' ,
        maxWidth:'250px'
    },
    "& .nameDateParentBox": {
        display: 'flex',
        justifyContent: 'space-between',
    },
    "& .date": {
        textAlign: 'right'
    },
    "@media (max-width:450px)": {
        "& .nameText": {
            maxWidth: '150px'
        }
    }
})
const RightMiddleBox = styled(Box)({
    
    width: '100%',
    paddingTop:'20px',
    boxSizing:'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    borderBottom:"1px dashed rgba(83, 80, 63, 0.5)",
    paddingBottom:'20px',
    "& .productDetailsHeading":{
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
        lineHeight:'normal'
    },
    "& .productDetailsMainBox":{
        display:'flex',
        flexDirection:'column',
        gap:'20px',
    },
    "& .productDetailsMainBoxTopBox, .productDetailsMainBoxLowerBox" :{
        
        display:'flex',
        gap:'5px'
    },
    "& .detailElementBox":{
        
        display:'flex',
        flexDirection:'column',
        gap:'12px',
        width:'170px'
    },
    "& .productDetailsMainBoxDescBox":{
        display:'flex',
        flexDirection:'column',
        gap:'12px',
    }
})
const RightBottomBox = styled(Box)({
    
    width: '100%',
    paddingTop: '45px',
    boxSizing: 'border-box',
    display:'flex',
    alignIems: 'center',
    gap: '10px',
    
    "& .delete_Request": {
        width: '288px',
        height: '56px',
        backgroundColor: 'rgba(239, 238, 233, 1)',
        textTransform: 'none',
        borderRadius: '8px',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
        cursor: 'pointer',
        border: '1px solid rgba(83, 80, 63, 1)',
        display: 'flex',
        alignIems: 'center',
        justifyContent: 'center',
        gap: '8px',
    },
    "& .delete_Request : hover": {
        backgroundColor: 'rgba(239, 238, 233, 1)',
        border: '1px solid rgba(83, 80, 63, 1)',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover" :{
        border: '1px solid rgba(83, 80, 63, 1)',
        backgroundColor: 'rgba(239, 238, 233, 1)',
      },
    '@media (max-width: 600px) ': {
        display: 'flex',
        justifyContent: 'center',
    },
    "@media (max-width:399px)":{
        flexDirection:'column',
        alignItems:'center'
    }
    
})
const BackButton = styled(Button)({
    backgroundColor: 'rgba(83, 80, 63, 1)',
    width: '155px',
    height: '56px',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '8px',
    fontSize: '18px',
    cursor: 'pointer',
    color: 'rgba(237, 230, 220, 1)',
    justifyContent: 'center',
    alignIems: 'center',
    display: 'flex',
    textDecoration: 'none',
    border: '1px solid rgba(83, 80, 63, 1)',
    "&:hover": {
        border: '1px solid rgba(83, 80, 63, 1) !important',

        backgroundColor: 'rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',

        textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },

})
const DeleteRequestModalBox=styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '4px',
    padding: '68px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '340px',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',
   
    "& .areYouSureText": {
        fontSize: '24px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop:'8px',
        maxWidth:'386px',
        textAlign:"center"
    },
    "& .paymentSuccessMessage": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        color: '#53503F',
        fontWeight: 400,
        marginTop: '16px',
        textAlign: "center",
        maxWidth: '502px',
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor:'pointer'
    },
    "@media  (max-width:550px)": {
        width: 'unset',
    },
    '@media (max-width:400px )': {
        padding: '68px 25px',

    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})

const CancelYesButtonBox=styled(Box)({
    display:'flex',
    alignItems:'center',
    gap:'50px',
    marginTop:'40px',
    '@media (max-width:420px )': {
        gap:'28.45px',
    },
    '@media (max-width:359px )': {
        flexDirection:'column'
    },
})

const CancelButton=styled(Button)({
    width: '155px',
    border:'1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'transparent',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#53503F',
    "&:hover": {
        backgroundColor: 'transparent',
    },
})

const RemoveButton=styled(Button)({
    width: '155px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#53503F',
    },
})
const styles = {
    titleHeading: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
    },
    valueText: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
    },
    overflowText:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap'
    },
    confirmation: {
        fontSize: '28px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
}
// Customizable Area End