import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  openModal?:boolean;
  openForgotModal?:boolean;
  handleClose?:any;
  handleOpenForgotModal?:any
  handleOpenLogin?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  newPassword:string,
  newConfPassword:string,
  showNewPass:boolean,
  showConfPass:boolean,
  newPassErr:string,
  newConfErr:string,
  forgotEmailForOtp:string,
  forgotEmailErr:string,
  openOtpModal:boolean,
  otpErr:string,
  resetOtpModal:boolean,
  ResetPassSuccessModal:boolean;
  prevPassErr:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  sendOTPApiID:string="";
  otpConfirmApiId:string="";
  resetPassApiId:string=""


  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      newPassword:"",
      newConfPassword:"",
      showNewPass:false,
      showConfPass:false,
      newPassErr:"",
      newConfErr:"",
      forgotEmailForOtp:"",
      forgotEmailErr:"",
      openOtpModal:false,
      otpErr:"",
      resetOtpModal:false,
      ResetPassSuccessModal:false,
      prevPassErr:""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ token: otpAuthTkn });
        if (this.isChangePassword) {
          this.setState({ accountStatus: "ChangePassword" });
        }
        this.otpToken = this.state.token;
        // runEngine.debugLog("otpAuthTkn", this.state.token);
      } else {
        const accountType = message.getData(
          getName(MessageEnum.NavigationForgotPasswordPageInfo)
        );
        if (accountType) {
          this.startForgotPassword(accountType);
        }
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson === undefined) {
        return;
      }

      if (responseJson && responseJson.data[0]) {
        const passRegex = RegExp(
          responseJson.data[0].password_validation_regexp
        );
        const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
        const passwordRulesFromValidation =
          responseJson.data[0].password_validation_rules;

        this.setState({
          //email schema
          emailSchema: {
            email: Yup.string()
              .email(configJSON.pleaseEnterAValidEmail)
              .required(configJSON.emailIsRequired)
              .matches(emailRegex, configJSON.invalidEmailAddress),
          },
          //password schema
          passwordSchema: {
            password: Yup.string()
              .required(configJSON.pleaseEnterAPassword)
              .matches(passRegex, configJSON.invalidPassword),

            confirmPassword: Yup.string()
              .required(configJSON.pleaseConfirmYourPassword)
              .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  configJSON.passwordsMustMatch
                ),
              }),
          },
          passwordRules: passwordRulesFromValidation,
        });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered email!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;

        this.setState({ token: this.otpToken });

        //navigate to OTP page
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );

        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenEmailMessage),
          this.state.emailValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered phone!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken });

        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );
        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenPhoneNumberMessage),
          this.state.phoneValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered 3");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation",
        });
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
  // Customizable Area Start
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.sendOTPApiID !== null &&
      this.sendOTPApiID === 
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && !responseJson.errors){ 
        localStorage.setItem("otpToken",responseJson.meta.token)
        this.setState({openOtpModal:true})
      }else{
        this.setState({forgotEmailErr:responseJson.errors[0].message},()=>{
          this.props.handleOpenForgotModal()
        })
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resetPassApiId !== null &&
      this.resetPassApiId === 
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && !responseJson.errors){
        this.setState({resetOtpModal:false,ResetPassSuccessModal:true,prevPassErr:""})
      }else{
        this.setState({prevPassErr:responseJson.errors[0].message})
      }
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpConfirmApiId !== null &&
      this.otpConfirmApiId === 
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson && !responseJson.errors){ 
      //otp success code
      this.setState({resetOtpModal:true,openOtpModal:false,otpErr:""},()=>{
      }) 
      }else{
        //invalid otp code
        this.setState({otpErr:responseJson?.errors[0].otp})
      }
    }
  // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  handleNewPasswordShowHide=()=>{
    this.setState({showNewPass:!this.state.showNewPass})
  }
  handleNewConfPasswordShowHide=()=>{
    this.setState({showConfPass:!this.state.showConfPass})
  }
  handleSubmit=(e: React.MouseEvent<HTMLButtonElement>)=>{
    e.preventDefault();
    const {newPassword,newConfPassword}=this.state;
    if(!newPassword && !newConfPassword){
      this.setState({newConfErr:"Password is required",
        newPassErr:"Password is required"
      }) 
      return
    }
    const isValidPass=this.validatePassword()
    if(isValidPass){
      const otpToken=localStorage.getItem("otpToken")
      const payloadBody={
        "data": {
            "token": otpToken,
            "new_password": newPassword,
            "confirm_password": newConfPassword
        }
    }
      this.resetPassApiId=this.apiCall("",{
        method:"POST",
        endPoint:`bx_block_forgot_password/passwords`,
        body:JSON.stringify(payloadBody)
      })
    }
  }

  handleforgotEmailChange=(e:any)=>{
    this.setState({forgotEmailForOtp:e.target.value})
  }
  handleForgotEmailSubmit = () => {
  this.setState({ forgotEmailErr: "" }, () => {
    const isValidEmail = (email: string) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    };
    if (isValidEmail(this.state.forgotEmailForOtp)) {
      const payloadBody={
        "data": {
            "type": "email_account",
            "attributes": {
                "email": this.state.forgotEmailForOtp
            }
        }
    }
      this.sendOTPApiID=this.apiCall("",{
        method:"POST",
        endPoint:`bx_block_forgot_password/otps`,
        body:JSON.stringify(payloadBody)
      })
      // this.setState({openOtpModal:true}) 
    } else {
      this.setState({forgotEmailErr:"Please enter valid email"})
    }
    })
  };

  apiCall = (token: any,payload:any) => {
    let {method,endPoint,body}=payload
    const header = {
      'Content-Type': 'application/json',
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body&&requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
  submitOtp=(otp:string)=>{
    this.setState({otpErr:""},()=>this.handleSubmitOtp(otp))
  }
  handleSubmitOtp=(otp:string)=>{
    const token=localStorage.getItem("otpToken")
    const payloadBody={
      "data": {
          "token": token,
          "otp_code": otp
      }
  }
    this.otpConfirmApiId=this.apiCall(token,{
      method:"POST",
      endPoint:`bx_block_forgot_password/otp_confirmations`,
      body:JSON.stringify(payloadBody)
    })
  }
  handleCloseOtpModal=()=>{
    this.setState({openOtpModal:false})
  }
  handRsetModal=()=>{
    this.setState({resetOtpModal:!this.state.resetOtpModal})
  }
  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>, key: keyof S) => {
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  };
  handleResetSuccesModal=()=>{
    this.setState({ResetPassSuccessModal:!this.state.ResetPassSuccessModal},()=>{this.props.handleOpenLogin();
      this.props.handleClose()
    })
  }
  validatePassword = () => {
    let passErr = "";
    let confPassErr = "";
  
    // Regular expressions for validation
    const capitalLetterRegex = /[A-Z]/;
    const lowercaseLetterRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const newPass=this.state.newPassword;
  const newConfPass=this.state.newConfPassword
    // Validate newPass
    if (newPass.length < 8) {
      passErr = "Password must be more than 8 characters.";
    } else if (!capitalLetterRegex.test(newPass)) {
      passErr = "Password must contain at least one capital letter.";
    } else if (!lowercaseLetterRegex.test(newPass)) {
      passErr = "Password must contain at least one lowercase letter.";
    } else if (!numberRegex.test(newPass)) {
      passErr = "Password must contain at least one number.";
    } else if (!specialCharRegex.test(newPass)) {
      passErr = "Password must contain at least one special character.";
    }
    // Validate newConfPass (ensure it matches newPass)
    if (newConfPass !== newPass) {
      confPassErr = "Passwords do not match.";
    }
  
    // Update the error states
    this.setState({
      newPassErr:passErr,
      newConfErr:confPassErr,
      prevPassErr:""
    })  
    // Return a boolean indicating if the password is valid
    return passErr === "" && confPassErr === "";
  };
  
  // Customizable Area End
}
