
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";

exports.descriptionLabel = "Description";
exports.description = "Lorem ipsum dolor sit amet consectetur. Id vel dictum amet malesuada id vestibulum vitae consequat. Fermentum convallis sit nam interdum et netus read more.."
exports.productVariant = "Product variants (01)";
exports.withdrawOfferBtn = "Withdraw Offer";
exports.buyNowBtn = "Buy Now ";
exports.productDescriptionApiEndPoint = "bx_block_request_management/offer_requests/";
exports.wishlist = "bx_block_wishlist/wishlists/add_favorite_or_remove";
exports.deleteApiEndPoint = "bx_block_request_management/offer_requests/";
exports.deleteApiMethod = "DELETE";
exports.deleteOfferApiEndPoint = "bx_block_request_management/offer_requests/";
exports.addToCartApiEndPoint = "bx_block_shopping_cart/order_items";
exports.getCartDataApiEndPoint = 'bx_block_shopping_cart/orders/null';
// Customizable Area End
