import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SelectChangeEvent } from '@mui/material/Select';
import moment from "moment";
interface LocationDataResponse {
  data: LocationData[];
}

interface LocationData {
  id: string;
  type: string;
  attributes: LocationAttributes;
}

interface LocationAttributes {
  id: number;
  name: string;
  emoji_flag: string;
  country_code: string;
  alpha2: string;
}

interface Error {
  message: string;
}

interface Attributes {
  role_name: any;
  name?: string;
  email?: string;
}

interface Meta {
  token: string;
  refresh_token: string;
}
interface ResponseType {
  data?: {
    attributes: Attributes;
  };
  meta?: Meta;
  errors?: Error[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpenTermsModal:any;
  handleOpenPrivacyModal:any
  handleOtpModalBuyerOpen: any;
  handleFirstFormToken: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  eyeIconPassword: boolean;
  eyeIconConfirm: boolean;
  firstUserName: string;
  lastUserName: string;
  emailAddress: string;
  userPassword: string;
  confirmUserPassword: string;
  locationData: object;
  location: string;
  dateOfBirth: any;
  showMonthList:boolean;
  gender: string;
  termsCondition: boolean;
  firstUserNameError: string,
  lastUserNameError: string,
  emailAddressError: string,
  userPasswordError: string,
  confirmUserPasswordError: string,
  locationError: string,
  dateError: string,
  genderError: string,
  termsConditionError: string,
  emailBackendError: string,
  dateOfBirthBackendError: string,
  openDropdown: boolean,
  calendarOpen: boolean,
  isCalendarOpen:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  registrationApiCallId: string = "";
  locationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      eyeIconPassword: false,
      eyeIconConfirm: false,
      firstUserName: "",
      lastUserName: "",
      emailAddress: "",
      userPassword: "",
      confirmUserPassword: "",
      locationData: [],
      location: "",
      dateOfBirth: "",
      showMonthList:false,
      gender: '',
      termsCondition: false,
      firstUserNameError: "",
      lastUserNameError: "",
      emailAddressError: "",
      userPasswordError: "",
      confirmUserPasswordError: "",
      locationError: "",
      dateError: "",
      genderError: '',
      termsConditionError: "",
      emailBackendError: "",
      dateOfBirthBackendError: "",
      openDropdown: false,
      calendarOpen: false,
      isCalendarOpen:false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {

        if(apiRequestCallId === this.registrationApiCallId) {
          this.handleApiResponse(responseJson)
        }
        if(apiRequestCallId === this.locationApiCallId){
          this.handleLocationApi(responseJson)
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleLocation()
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleApiResponse = (responseJson: ResponseType) => {
    if(responseJson && responseJson.meta && responseJson.meta.token && responseJson.data ){
      let roleName = responseJson.data.attributes
      this.props.handleOtpModalBuyerOpen()
      let dataForOtpScreen = {
        'token': responseJson.meta.token,
        'email': this.state.emailAddress,
        'roleName': roleName.role_name
      }
      this.props.handleFirstFormToken(dataForOtpScreen)
    }
    if (responseJson && responseJson.errors){
      let errorBunch = responseJson.errors

      for (const error of errorBunch) {
        if (Object.keys(error).includes('email')) {
          this.setState({ emailBackendError: Object.values(error)[0] });
        }
      
        if (Object.keys(error).includes('date_of_birth')) {
          this.setState({ dateOfBirthBackendError: Object.values(error)[0] });
        }
      }
    }
  }

  handleLocationApi = (responseJson: LocationDataResponse) => {
    this.setState({locationData: responseJson.data})
  }

  handlePasswordEye = () => {
    this.setState({eyeIconPassword: !this.state.eyeIconPassword})
  }

  handleConfirmEye = () => {
    this.setState({eyeIconConfirm: !this.state.eyeIconConfirm})
  }

  handleFirstUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({firstUserName: value})
  }

  handleLastUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({lastUserName: value})
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({emailAddress: value})
  }

  handleLocationChange = (event: SelectChangeEvent) => {
    const {value} = event.target;
    this.setState({location: value})
  }

  handleUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({userPassword: value})
  }

  handleConfirmUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    this.setState({confirmUserPassword: value})
  }

  handleDate = (newValue: Date | null) => {
      this.setState({ dateOfBirth: moment(newValue).format("YYYY-MM-DD") ,showMonthList:false});
  };

  handleGenderChange = (event: React.MouseEvent<HTMLElement>,newValue: string | null) => {
    if (newValue) {
      this.setState({ gender: newValue });
    }
  }

  handleTermsCondition = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({termsCondition: event.target.checked})
  }

  handleRegistration = () => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const body = {
      "data": {
          "type": "email_account",
          "terms_and_conditions": this.state.termsCondition,
          "attributes": {
              "email": this.state.emailAddress,
              "first_name": this.state.firstUserName,
              "last_name": this.state.lastUserName,
              "date_of_birth": this.state.dateOfBirth,
              "password": this.state.userPassword,
              "location_1": this.state.location,
              "gender": this.state.gender
          }
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registrationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registrationEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleLocation = () => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.locationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };

  validateField = (fieldValue: string, errorMessage: string, validationFn?: (value: string) => boolean): string => {
    if (!fieldValue) return errorMessage;
    if (validationFn && !validationFn(fieldValue)) return errorMessage;
    return '';
  };
  
  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const { 
      firstUserName, 
      lastUserName, 
      emailAddress, 
      location, 
      userPassword, 
      confirmUserPassword, 
      dateOfBirth
    } = this.state;

    const firstUserNameError = this.validateField(firstUserName, configJSON.firstNameError);
    const lastUserNameError = this.validateField(lastUserName, configJSON.lastNameError);
    const emailAddressError = this.validateField(emailAddress, configJSON.emailRequired, this.validateEmail);
    const locationError = this.validateField(location, configJSON.locationRequired);
    const userPasswordError = this.validateField(userPassword, configJSON.invalidPassword, this.validatePassword);
    const confirmUserPasswordError = this.validateField(
      confirmUserPassword,
      configJSON.confirmPasswordInvalid,
      (value) => value === userPassword
    );
    const dateError = this.validateField(dateOfBirth, configJSON.dateRequired);

    this.setState({
      firstUserNameError,
      lastUserNameError,
      emailAddressError,
      locationError,
      userPasswordError,
      confirmUserPasswordError,
      dateError,
    });

    if (
      !firstUserNameError &&
      !lastUserNameError &&
      !emailAddressError &&
      !locationError &&
      !userPasswordError &&
      !confirmUserPasswordError &&
      !dateError
    ) {
      this.handleRegistration();
    }
  }

  handleIconClick = () => {
    this.setState((prevState) => ({ openDropdown: !prevState.openDropdown })
  )};

  handleCalendarIconClick = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen })
  )};
  
  // Customizable Area End
}
