import React,{useEffect, useState} from 'react';
const LogoImg=require("./tresorlogo.svg").default;
const instagramImg=require("./socialmediaicons/instagram.svg").default
const tiktokImg=require("./socialmediaicons/tiktok.svg").default
const xImg=require("./socialmediaicons/x.svg").default
const linkedinImg=require("./socialmediaicons/linkdin.svg").default
const DownloadImg=require("./downloadIcon.svg").default
import { Link, useNavigate } from "react-router-dom";
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web';
import { getStorageData } from '../../framework/src/Utilities';

interface ShopperFooterProps {

}

const ShopperFooter: React.FC<ShopperFooterProps> = ({ }) => {
  const [opentermsPopup,setOpentermsPopup]=useState<boolean>(false)
  const [openPrivacyPopup,setOpenPrivacyPopup]=useState<boolean>(false)
  const [footerLinks,setFooterLinks]=useState<any>()
  const navigate=useNavigate()
  useEffect(()=>{
    getFooterLinks()
  },[])
 const getFooterLinks=async()=>{
    const footerLinks = await getStorageData('footerLinks',true);
    setFooterLinks(footerLinks)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const openTermsMOdal=()=>{
    setOpentermsPopup(true)
  }
  const closeTermsModal=()=>{
    setOpentermsPopup(false)

  }
  const openPrivacyMOdal=()=>{
    setOpenPrivacyPopup(true)
  }
  const closePrivacyModal=()=>{
    setOpenPrivacyPopup(false)

  }
  return (
    <footer style={styles.footer}>
    <div className='links' style={styles.footerMain}>
        <div style={{display:"flex",alignItems:"center",gap:"64px"}}>

        <img style={{width: "127.87px",
              height: "105.67px",
              padding: "0.65px 14.21px 14.68px 14.21px",}} width={127} height={85} src={LogoImg} alt="logo" />
              <div className="linksVisit" style={{display:"flex",alignItems:"center",gap:"27.98px"}}>
                <span style={styles.pageLinks} onClick={()=>{
                  navigate("/shopperTermsAndConditions?label=Terms and Conditions")
                }}>Terms & Conditions</span>
                <span style={styles.pageLinks} onClick={()=>{
                  navigate("/shopperTermsAndConditions?label=Privacy Policy")
                }}>Privacy Policy</span>
                
                <Link style={styles.pageLinks} to="/Contactus" onClick={scrollToTop}>
                  Contact Us
                </Link>
                <Link to="/HelpFaq" style={styles.pageLinks}>Help & FAQs</Link>
              </div>
              </div>
            <div className="rightLinks" style={{display:"flex",alignItems:"center",gap:"27px",paddingRight:"40px"}}>
                <span style={{...styles.pageLinks,fontSize:"20px",fontWeight:600,lineHeight:"30px"}}>SOCIAL MEDIA</span>
                <div style={{display:"flex",gap:"12px",alignItems:"center"}}>
                {
                    footerLinks?.data[0]?.attributes?.name==="instagram_link" &&
                    <a href={footerLinks?.data[0]?.attributes?.value} style={{textDecoration:"none"}}>
                      <img width={20} height={19} src={instagramImg} alt="instagram" />
                    </a>
                  }
                {
                    footerLinks?.data[1]?.attributes?.name==="linkedin_link" &&
                    <a href={footerLinks?.data[1]?.attributes?.value} style={{textDecoration:"none"}}>
                      <img width={20} height={19} src={linkedinImg} alt={"icon"} />
                    </a>
                  }
                 {
                    footerLinks?.data[2]?.attributes?.name==="twitter_link" &&
                    <a href={footerLinks?.data[2]?.attributes?.value} style={{textDecoration:"none"}}>
                      <img width={20} height={19} src={xImg} alt={"icon"} />
                    </a>
                  }
                 {
                    footerLinks?.data[3]?.attributes?.name==="tiktok_link" &&
                    <a href={footerLinks?.data[3]?.attributes?.value} style={{textDecoration:"none"}}>
                      <img width={20} height={19} src={tiktokImg} alt={"icon"} />
                    </a>
                  }
               </div>
               {
                    footerLinks?.data[4]?.attributes?.name==="appstore_link" &&
                    <a href={footerLinks?.data[4]?.attributes?.value} style={{textDecoration:"none"}}>
                      <Link
                  style={{ color: "#EDE6DC", textDecoration: "none" }}
                  to={footerLinks?.data[4]?.attributes?.value} 
                  onClick={scrollToTop}
                >
                  <img
                    style={{ marginTop: "-15px" }}
                    height={120}
                    width={120}
                    src={DownloadImg}
                    alt="cdc"
                  />
                </Link>
                    </a>
                  }
            </div>
    </div>
    <div
          style={{
            fontFamily: "'Montserrat', sans-serif",
            height: "61px",
            width: "100%",
            backgroundColor: "#EDE6DC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color:"#53503F",
          }}
        >
          © 2024 - All Rights Reserved by {"  "}
          <span
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "14px",
              fontWeight: 800,
              lineHeight: "21.87px",
              textAlign: "left",
              marginLeft:"10px",
              color:"#53503F"
            }}
          >
            TRÉSOR
          </span>
        </div>
        {opentermsPopup && <TermsConditions
                  openModal={opentermsPopup}
                  handleClose={closeTermsModal}
                  navigation={""}
                  id=""
                  label="Terms and Conditions"
            />}
              {
              openPrivacyPopup && 
              <TermsConditions
                  openModal={openPrivacyPopup}
                  handleClose={closePrivacyModal}
                  navigation={""}
                  id=""
                  label="Privacy Policy"
            />
            }
    </footer>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  footer: {
    width: "100%",
    paddingRight:"40px",
    fontFamily: "'Montserrat', sans-serif",
  },
  footerMain: {
    width: "100%",
    height: "165px",
    background: "rgba(83, 80, 63, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pageLinks: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17.07px",
    textAlign: "left",
    color: "rgba(237, 230, 220, 1)",
    cursor:"pointer",
    textDecoration: "none"
  },
};

export default ShopperFooter;
