import React from "react";

// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";

import {Box, Grid, InputLabel, TextField, Button, Checkbox,ToggleButton, ToggleButtonGroup, InputAdornment, IconButton} from '@mui/material';
import { eyeOpenIcon, eyeCloseIcon,checkBox,checkedBox,downArrow,calenderIcon } from "./assets";
import EmailAccountRegistrationController, {
    Props,
    configJSON
  } from "./EmailAccountRegistrationController";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    placeholder: string, 
    label: string, 
    value: string | undefined, 
    name: string, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    error: string,
    dataTestId: string,
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #53503F',
              },
            },
            "& fieldset": { border: error ? '1px solid #DC2626':'none' },
            '& .MuiInputBase-input::placeholder': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
            '& .MuiInputBase-input': {
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              color: '#53503F'
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          data-testid={dataTestId}
          style={styles.inputField}
        />
        {error ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            {error}
          </p>
          ) : null
        }
        {name === 'email' && this.state.emailBackendError ? (
          <p style={styles.error} id='emailError' className='montserratLightError'>
            Email {this.state.emailBackendError}
          </p>
          ) : null
        }
      </Grid>
    );
  }
  
  renderPasswordField(
    name: string,
    placeholder: string, 
    label: string, 
    value: string, 
    dataTestId: string, 
    eyeState: boolean, 
    handleEyeClick: () => void, 
    eyeIconTestId: string,
    error: string,
    handleChange: any,
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">{label}</InputLabel>
        <Box sx={styles.passwordField}>
          <TextField
            sx={{
              "& fieldset": { border: error ? '1px solid #DC2626':'none' },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid #53503F',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F'
              },
              '& .MuiInputBase-input': {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                color: '#53503F'
              },
              }}
            name={name}
            fullWidth
            value={!eyeState ? "*".repeat(value.length) : value}
            onChange={handleChange}
            placeholder={placeholder}
            type={eyeState ? 'text' : 'password'}
            style={styles.inputField}
            data-testid={dataTestId}
          />
          <Box
            data-testid={eyeIconTestId}
            sx={styles.eyeIconMainWithOutError}
            onClick={handleEyeClick}
          >
            <img src={eyeState ? eyeOpenIcon : eyeCloseIcon} alt="eyeIcon" />
          </Box>
        </Box>
        {error ? (
            <p style={styles.error} id='emailError' className='montserratLightError'>
              {error}
            </p>
            ) : null
          }
      </Grid>
    );
  }
  dateCss=()=>{
    return (
      <style>
        {`
    .react-datepicker-wrapper {
         width: 100% !important;
    }
      .custom-input {
        width: 100%;
        height:56px;
        padding: 12px;
        box-sizing:"border-box",
        border: none !important; /* Removes border */
        padding: 0 !important; /* Removes extra padding */
        background: transparent !important; /* Prevents background from affecting UI */
        outline: none !important; /* Removes focus outline */
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        z-index:99999999999999 !important
      }
        .react-datepicker__month-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  color:"#53503F"
}
.react-datepicker__month-text--selected {
  background-color: #53503F !important;
  color: #EDE6DC !important;
  border-color: #53503F !important;
}
.react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #53503F !important;
  color:#EDE6DC;
  }
.react-datepicker__month-text {
  text-align: center;
  padding: 8px 25px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #EFEEE9;
}

.react-datepicker__month-text:hover {
  background-color: #53503F !important;
   color:#EDE6DC;
}

.react-datepicker__month-text--selected {
   background-color: #53503F !important;
   color:#EDE6DC;
  font-weight: 600;
  
}
        .custom-input::placeholder {
        color: #53503F;
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
      }
        .react-datepicker{
    position:absolute !important;
    right: -290px !important;
}
      
      /* 📅 Entire Datepicker */
  .react-datepicker-popper {
  z-index: 99999 !important; /* ✅ Ensures calendar is on top */
}


.react-datepicker {
  position: relative !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius:4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index:99999999999999999 !important
  text-align:center
}


/* 📌 Header (Month & Year) */
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding: 10px;
  text-align: center;
}

/* 📅 Month & Year Title */
.react-datepicker__current-month {
  font-size: 13px;
  font-weight: 700;
  color: #53503F;
}

/* 📅 Weekday Names (Mon, Tue, etc.) */
.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  font-weight: 400;
  color: #53503F;
  font-size: 13px;
}

/* 🗓️ Days (Normal State) */
.react-datepicker__day {
  width: 36px;
  line-height: 36px;
  text-align: center;
  font-size:12px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  color: #53503F;
}
.react-datepicker__month-container .react-datepicker__week .react-datepicker__day:hover {
  background-color: #53503F !important;
  color: white !important;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* 🏆 Selected Day */
.react-datepicker__day--selected {
  background-color: #53503F !important;
  color: white !important;
  font-weight: bold;
}

/* 🔄 Hover Effect on Days */
.react-datepicker__day:hover {
  background-color: #ddd !important;
  color: black !important;
  height:36px;
  width:36px;
  border-radius:50%;
}

/* 🚫 Disabled Days */
.react-datepicker__day--disabled {
  color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

/* 📆 Today Highlight */
.react-datepicker__day--today {
  border: 1px solid #53503F;
  font-weight: bold;
  color: #53503F;
  background:white
}

/* ⏭️ Next & Previous Month Arrows */
.react-datepicker__navigation {
  top: 10px;
  color:#53503F
}

.react-datepicker__navigation--previous {
  left: 10px;
  color:#53503F
}

.react-datepicker__navigation--next {
  right: 10px;
  color:#53503F

}

/* 🔲 Calendar Popper (Dropdown) */
.react-datepicker-popper {
  z-index: 1000;
}

/* 📦 Calendar Container */
.react-datepicker__month-container {
  padding: 10px;
}

/* 📅 Month Dropdown (if used) */
.react-datepicker__month-select,
.react-datepicker__year-select {
  font-size: 14px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* 🕛 Time Picker (if used) */
.react-datepicker__time-container {
  border-left: 1px solid #ccc;
}
    `}
      </style>
    );
  }
  CustomDatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    decreaseYear: () => void;
    increaseYear: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => {
    const isMonthListOpen = this.state.showMonthList;
  
    return (
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", alignItems: "center" }}>
        {/* Left Arrow: Decrease month if day calendar is open, decrease year if month list is open */}
        <KeyboardArrowLeftIcon
          onClick={isMonthListOpen ? decreaseYear : decreaseMonth}
          style={{
            color: prevMonthButtonDisabled ? "gray" : "#53503F",
            cursor: prevMonthButtonDisabled ? "not-allowed" : "pointer",
          }}
        />
  
        {/* Month & Year Picker */}
        <div style={{ display: "flex", gap: "10px" }}>
          <span
            data-testid="monthTest"
            style={{ fontWeight: 600, cursor: "pointer", fontSize: "12px", fontFamily: "Montserrat" }}
            onClick={() => this.setState({ showMonthList: true })}
          >
            {new Intl.DateTimeFormat("en-US", { month: "long" }).format(date)}
          </span>
          <span
            data-testid="yearTest"
            style={{ fontWeight: 600, cursor: "pointer" }}
            onClick={() => this.setState({ showMonthList: true })}
          >
            {date.getFullYear()}
          </span>
        </div>
  
        {/* Right Arrow: Increase month if day calendar is open, increase year if month list is open */}
        <ChevronRightIcon
          onClick={isMonthListOpen ? increaseYear : increaseMonth}
          style={{
            color: nextMonthButtonDisabled ? "gray" : "#53503F",
            cursor: nextMonthButtonDisabled ? "not-allowed" : "pointer",
          }}
        />
      </div>
    );
  };
  
  
  ReturnBirthDateField = (placement: string) => {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="montserratMediumGoogle">Date of Birth*</InputLabel>
        <div style={{ position: "relative", width: "100%" }}>
          <DatePicker
            name="dateOfBirth"
            useShortMonthInDropdown={false}
            data-testid="dateOfBirthTestId"
            selected={
              this.state.dateOfBirth
                ? moment(this.state.dateOfBirth, "DD-MM-YYYY").toDate()
                : null
            }
            onCalendarOpen={() => this.setState({ isCalendarOpen: true, showMonthList: false })}
            onCalendarClose={() => this.setState({ isCalendarOpen: false })}
            onChange={(newValue: any, event: any) => {
              this.setState({ 
                dateOfBirth: moment(newValue).format("DD-MM-YYYY"), 
                isCalendarOpen: !!event.target?.classList.contains("react-datepicker__month-select"), 
                showMonthList: false 
              });
            }}           
  
            dateFormat="dd-MM-yyyy"
            placeholderText="DD/MM/YYYY"
            className="custom-input"
            showPopperArrow={false}
            popperPlacement={placement as any}
            formatWeekDay={(day: any) => day.substr(0, 3)}
            portalId="root"
            maxDate={new Date()}
            popperModifiers={DatePickerCss}
            dropdownMode="select"
            showMonthYearPicker={this.state.showMonthList} 
            renderCustomHeader={(params) =>{
              return (
              this.CustomDatePickerHeader({ ...params })
            )}}
  
            customInput={
              <Box sx={{ width: "100%", ...styles.passwordField }}>
                <TextField
                  sx={{
                    background: "#FFFFFF",
                    "& .MuiInputBase-input::placeholder": { fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`, color: "#53503F" },
                    "& .MuiInputBase-input": { fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`, color: "#53503F" },
                  }}
                  name="dateOfBirth"
                  fullWidth
                  autoComplete="no"
                  value={this.state.dateOfBirth}
                  placeholder="DD/MM/YYYY"
                  data-testid="dataTestId"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <img src={calenderIcon} alt="calendarIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            }
            open={this.state.isCalendarOpen} // Ensures calendar remains open
          />
        </div>
        {this.dateCss()}
        {this.state.dateOfBirthBackendError && (
          <p style={styles.error} id="emailError" className="montserratLightError">
            Date of birth {this.state.dateOfBirthBackendError}
          </p>
        )}
      </Grid>
    );
  };
  
  
  // Customizable Area End

  render() {
     // Customizable Area Start
   const placement = "top-end";
      // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <Box sx={styles.root}>
        <Box style={styles.formMain}>
          <form>
            <Grid container spacing={2}>
              {this.renderInputField(
                configJSON.firstNamePlaceholder,
                configJSON.labelFirstName,
                this.state.firstUserName,
                "firstName",
                this.handleFirstUserName,
                this.state.firstUserNameError,
                "firstNameTestId"
              )}
              {this.renderInputField(
                configJSON.lastNamePlaceholder,
                configJSON.lastName,
                this.state.lastUserName,
                "lastName",
                this.handleLastUserName,
                this.state.lastUserNameError,
                "lastNameTestId"
              )}
              {this.renderInputField(
                configJSON.emailPlaceholder,
                configJSON.placeHolderEmail,
                this.state.emailAddress,
                "email",
                this.handleEmailChange,
                this.state.emailAddressError,
                "emailTestId"
              )}
              <Grid item lg={6} md={4} sm={4} xs={12}>
                <InputLabel className="montserratMediumGoogle">
                  {configJSON.locationText}
                </InputLabel>
                <FormControl fullWidth sx={styles.selectField}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.location}
                    onChange={this.handleLocationChange}
                    open={this.state.openDropdown}
                    onClose={() => this.setState({ openDropdown: false })}
                    onOpen={() => this.setState({ openDropdown: true })}
                    data-testid="locationTestId"
                    displayEmpty
                    sx={{
                      ...styles.locationDropdown,
                      "& .MuiSelect-select": {
                        color: "#53503F",
                        fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                      },
                    }}
                    name="location"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          border: "1px solid #53503F",
                          minWidth: "50%",
                          maxWidth: "50vw",
                          height: "180px",
                          maxHeight: "180px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#53503F",
                            borderRadius: "3px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                          },
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    }}
                    IconComponent={() => (
                      <img
                        src={downArrow}
                        alt="arrow"
                        style={{ padding: "10px" }}
                        data-testid="dropDownClickTest"
                        onClick={this.handleIconClick}
                      />
                    )}
                  >
                    <MenuItem
                      value=""
                      id="dataaaa"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        "&.Mui-selected:hover": {
                          backgroundColor: "#5b5b5b",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#80806e",
                          color: "#fff",
                        },
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                      }}
                    >
                      <em style={styles.selectPlaceholder}>Select</em>
                    </MenuItem>
                    {Array.isArray(this.state.locationData) &&
                      this.state.locationData.map((locate) => (
                        <MenuItem
                          value={locate.attributes.id}
                          sx={{
                            "&.Mui-selected": {
                              fontWeight: "400 !important",
                              backgroundColor: "#53503F",
                              color: "#ffffff",
                              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                            },
                            "&:hover": {
                              backgroundColor: "#53503F",
                              color: "#ffffff",
                            },
                          }}
                        >
                          {locate.attributes.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {this.state.locationError ? (
                  <p
                    style={styles.error}
                    id="emailError"
                    className="montserratLightError"
                  >
                    {this.state.locationError}
                  </p>
                ) : null}
              </Grid>
              {this.renderPasswordField(
                "password",
                configJSON.placeholderPass,
                configJSON.labelPassword,
                this.state.userPassword,
                "passwordTestId",
                this.state.eyeIconPassword,
                this.handlePasswordEye,
                "eyeIconPasswordTestId",
                this.state.userPasswordError,
                this.handleUserPassword
              )}
              {this.renderPasswordField(
                "confirmPassword",
                configJSON.placeholderConfirmPass,
                configJSON.confirmPassword,
                this.state.confirmUserPassword,
                "confirmpasswordTestId",
                this.state.eyeIconConfirm,
                this.handleConfirmEye,
                "eyeIconConfirmPassTestId",
                this.state.confirmUserPasswordError,
                this.handleConfirmUserPassword
              )}
             {this.ReturnBirthDateField(placement)}
              <Grid item lg={6} md={4} sm={4} xs={12}>
                <InputLabel className="montserratMediumGoogle">
                  Gender*
                </InputLabel>
                <ToggleButtonGroup
                  value={this.state.gender}
                  onChange={this.handleGenderChange}
                  fullWidth
                  aria-label="gender selection"
                  sx={styles.toggleMain}
                  data-testid="genderTestId"
                  exclusive
                >
                  <ToggleButton
                    value="Male"
                    aria-label="male"
                    sx={{
                      ...styles.toggleBtn,
                      textTransform: "none",
                      fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                    }}
                  >
                    Male
                  </ToggleButton>
                  <ToggleButton
                    value="Female"
                    aria-label="female"
                    sx={{
                      ...styles.toggleBtn,
                      textTransform: "none",
                      fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                    }}
                  >
                    Female
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item lg={12} md={12} sm={4} xs={12}>
                <Box sx={styles.termCondition}>
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: "#53503F",
                      },
                    }}
                    checkedIcon={<img src={checkedBox} alt="checkedBox" />}
                    icon={<img src={checkBox} alt="checkbox" />}
                    value={this.state.termsCondition}
                    onChange={this.handleTermsCondition}
                    name="termsCondition"
                    data-testid="termsConditionTestId"
                  />
                  <p className="montserratFont">
                    I agree with{" "}
                    <span
                      onClick={this.props.handleOpenTermsModal}
                      style={{
                        ...styles.termBold,
                        fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                      }}
                    >
                      Terms and Conditions
                    </span>{" "}
                    &{" "}
                    <span
                      onClick={this.props.handleOpenPrivacyModal}
                      style={{
                        ...styles.termBold,
                        fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                      }}
                    >
                      Privacy Policy
                    </span>
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Box style={styles.createAccount}>
              <Button
                disabled={
                  this.state.firstUserName === "" ||
                  this.state.lastUserName === "" ||
                  this.state.emailAddress === "" ||
                  this.state.termsCondition === false ||
                  this.state.location === "" ||
                  this.state.gender === "" ||
                  this.state.dateOfBirth === "" ||
                  this.state.userPassword === "" ||
                  this.state.confirmUserPassword === ""
                }
                data-testid="submitTestId"
                onClick={this.handleSubmit}
                variant="contained"
                className="montserratLightGoogle"
                sx={{
                  ...styles.btn,
                  "&.Mui-disabled": {
                    backgroundColor: "#53503F",
                    color: "#EDE6DC",
                    cursor: "not-allowed",
                    opacity: 0.5,
                    fontSize: "18px",
                  },
                }}
              >
                Create Account
              </Button>
            </Box>
            <span data-test-id="spanTest" className="spanTest"></span>
          </form>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DatePickerCss=[
  {
    name: "preventOverflow",
    fn: (state:any) => state,
    options: {
      boundary: "window",
      altAxis: true,
    },
  },
  {
    name: "flip",
    fn: (state:any) => state,
    options: {
      fallbackPlacements: ["top", "bottom", "right", "left"], // Allow dynamic positioning
    },
  },
  {
    name: "offset",
    fn: (state:any) => state,
    options: {
      offset: [10, 10], // Adjust spacing
    },
  },
  {
    name: "applyPlacement", // Custom placement modifier
    fn: (state:any) => {
      state.placement = "top-end"; // Manually set placement
      return state;
    },
  },
]
const styles = {
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  formMain: {
    width: '100%'
  },
  inputField: {
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#fff',
    marginTop: '5px',
    marginBottom: '10px',
  },
  passwordField: {
    position: 'relative'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9', 
    padding: '16px',
    borderRadius: 8,
  },
  logo: {
    padding: '10px'
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: '10px',
    lineHeight: '24px',
    fontWeight: 800,
    color: '#53503F',
    textAlign: "left",
  }, 
  remember: {
    display: 'flex',
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    right: '15px',
    bottom: '40%',
    backgroundColor: '#ffffff'
  },
  eyeIconWithError: {
    position: 'absolute',
    bottom: '56%',
    right: '15px',
    backgroundColor: '#ffffff'
  },
  rememberMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createAccount: {
    margin: 'auto',
    width: '40%'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#fff',
    paddingBottom: '15px',
    marginTop: '10px',
    paddingTop: '15px',
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  welcomeText: {
    fontFamily: "Avenir",
    fontWeight: 400,
    fontSize: '20px',
  },
  error: {
    margin: '0px',
    color: '#DC2626',
    fontSize: "12px",
    paddingBottom: '15px'
  },
  selectField: {
    marginTop: '5px',
    backgroundColor: '#f0f0f0', 
    marginBottom: '10px',
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  radioField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  toggleMain: {
    gap: '15px',
    height:"56px"
  },
  toggleBtn: {
    borderRadius: '3px !important',
    textDecoration: 'none',
    border: '2px solid #53503F !important', 
    color: '#53503F', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#fff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  locationDropdown: {
      backgroundColor: 'white',  
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
  },
  termCondition: {
    display: 'flex',
    color: '#53503F'
  },
  termBold: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor:"pointer",
  }
};
// Customizable Area End