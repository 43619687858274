export const makAWishImg=require("../assets/makeWishImg.png").default
export const menuIcon = require("../assets/group_dots.png").default;
export const downArrow = require("../assets/downArrow.png").default;
export const rightArrow = require("../assets/rightIcon.png").default;
export const filterIcon = require("../assets/filterIcon.png").default;
export const searchIcon = require("../assets/searchIcon.png").default;
export const rightGreenIcon=require("../assets/greenTick.png").default
export const calenderIcon=require("../assets/button_calendar.png").default
export const notFoundIcon=require("../assets/group_.png").default
export const deleteIcon=require("../assets/deleteIcon.png").default
export const crossIcon=require('../assets/crossIcon.svg').default
export const buyNowIcon=require('../assets/buyNow.png').default
export const checkIcon=require('../assets/checkIcon.png').default
export const image_closeIcon=require('../assets/image_close.png').default;
export const eyeIconImage = require('../assets/image_.png').default;





