import React from "react";

// Customizable Area Start
import { Box, styled,Typography} from "@mui/material";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import {rightArrow} from "./assets";
import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
// Customizable Area End


export default class Article extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleReturnHeader = () => {
        return (
            this.state.userRole == 'personal_shopper' ? <ShopperHeader /> :
                <AppHeader closeLoginModal={this.closeLoginModal} data-test-id='appHeader' openLoginModal={this.state.openLoginModal} count={this.state.cartData?.length} />
        )
    }
    handleReturnFooter = () => {
        return (
            this.state.userRole == 'personal_shopper' ? <ShopperFooter /> :
                <Footer data-test-id="footerTest" openLoginModal={this.openLoginModal} handleCategoryData={this.handleCategoryData} categoriesList={this.state.landingPagesData} />
        )
    }
  // Customizable Area End

  render() {
      // Customizable Area Start
    const article =  this.state.singleArticleData?.attributes
    return (
        <ContainerBoxArticleRequest >
           {this.handleReturnHeader()}
           <OuterMainBoxArticleRequest >
                <ArrowTopNavigationBox>
                    <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.home}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography data-test-id='HelpFaq' onClick={() => this.handleNavigate('HelpFaq')} className="arrowHomeText">{configJSON.help_FAQ}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography className="arrowLastText"> 
                       {configJSON.article}
                    </Typography>
                </ArrowTopNavigationBox>
                    <MainOuterWhiteBox>
                        <ArticleHeadingBox>
                            {configJSON.article}
                        </ArticleHeadingBox>
                        <DescBox>
                         <Typography className="questionText" 
                         dangerouslySetInnerHTML={{
                            __html: article?.question?.replace(/<p>&nbsp;<\/p>|<pre>\s*<\/pre>|\r\n/g, '')
                                .trim()
                        }}
                        data-test-id='questionText'
                         />
                        <Typography className="descText" dangerouslySetInnerHTML={{
                            __html: article?.answer
                        }}/>
                        </DescBox>
                    </MainOuterWhiteBox>
           </OuterMainBoxArticleRequest>
           {this.handleReturnFooter()}
        </ContainerBoxArticleRequest>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBoxArticleRequest = styled(Box)({ backgroundColor: '#EDE6DC', })
const OuterMainBoxArticleRequest = styled(Box)({
    padding: '40px 40px 94.6px 40px',
    gap: '20px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 400px) ': {padding: '10px'}
})
const ArrowTopNavigationBox = styled(Box)({
    gap: '11px',
    display: 'flex',
    paddingLeft: '6px',
    alignItems: 'center',
    "& .arrowHomeText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        cursor: 'pointer',
        fontWeight: 400,
    },
    "& .arrowLastText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
        fontSize: '18px',
    }
})
const MainOuterWhiteBox = styled(Box)({
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '4px',
    width: '1091px',
    minHeight: 'max-content',
    alignSelf: 'center',
    '@media (max-width: 1110px) ': {

        width: '100%',

    }
})
const ArticleHeadingBox = styled(Box)({
    width: '100%',
    borderBottom: '1px solid rgba(83, 80, 63, 0.5)',
    height: '80px',
    fontSize: '22px',
    fontFamily: "'Montserrat', sans-serif",
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    padding: '24.5px 40px 24.5px 40px',
    boxSizing: 'border-box'

})
const DescBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    boxSizing: 'border-box',
    gap: '20px',
    "& .questionText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        lineHeight: 'normal',
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 600,
        "& p": {
            margin: '0',
            padding: '0'
        },
        "& pre": {
            display: 'none'
        }
    },
    "& .descText": {
        fontSize: '16px',
        lineHeight: '30px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
    }
})
// Customizable Area End