export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const productBanner=require("../assets/productDetailsBanner.jpeg").default;
export const charityHandImg=require("../assets/charityImg.svg").default;
export const cartIcon=require("../assets/cartIcon.svg").default;
export const exportIcon=require("../assets/export.svg").default;
export const certificateIcon=require("../assets/certificateIcon.svg").default;
export const heartIcon=require('../assets/heart.png').default
export const favHeartIcon=require('../assets/favHeartIcon.png').default
export const rightArrowIcon=require('../assets/view_line.png').default;
export const watch=require('../assets/watch.png').default;
export const rightIcon = require('../assets/rightIcon.png').default;
export const whiteRightArrow = require('../assets/whiteRightArrow.png').default;
export const placeholderImage = require('../assets/PlaceholderImage.png').default;