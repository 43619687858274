import React from "react";

// Customizable Area Start
import {TextField, Box, styled,Typography,Button, Switch,Modal, InputAdornment} from "@mui/material";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import {checkBoxIcon,checkedIcon,rightArrow,cardIcon, addIcon, lockIcon, locationIcon, deleteIconRed, deActiveAccountIcon,crossIcon, visibilityIcon, visibilityOffIcon} from "./assets";
import Settings2Controller, {
  Props,
  configJSON,
  CountryInterArr,
} from "./Settings2Controller";
import Select from "react-select";
// Customizable Area End


export default class SettingsBuyer extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleAddressModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.isAddressModalOpen}
                    onClose={() => this.handleMakeEmptyAddressModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='isAddressModalOpen'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <AddressModalBox >
                        <img className="crossIcon" data-test-id='crossIcon' onClick={() => this.handleMakeEmptyAddressModal()} src={crossIcon} alt="" />
                        <Typography style={styles.add_address}>{this.state.showEditModalText?configJSON.editAddress:configJSON.add_address}</Typography>
                        <Box className='gridInputBox'>
                            <Box className='gridInputBoxChiled'>
                                {this.handleReturnInput(configJSON.address_Line_1, configJSON.yourAddress, this.state.address_linr_1, `address_linr_1`,null,'',{errorText:'',error:false})}
                                {this.handleReturnInput(configJSON.address_Line_2, configJSON.yourAddress, this.state.address_linr_2, `address_linr_2`,null,'',{errorText:'',error:false})}
                                {this.handleReturnSelect(this.state.countryListArray, configJSON.country, configJSON.Select, this.state.country_id, 'country_id')}
                                {this.handleReturnSelect(this.state.stateListArray, configJSON.state, configJSON.Select, this.state.state, 'state')}
                                {this.handleReturnSelect(this.state.cityListArray, configJSON.city, configJSON.Select, this.state.city, 'city')}
                                {this.handleReturnInput(configJSON.postal_Code, configJSON.enterHere, this.state.postal_code, `postal_code`,null,'',{errorText:this.state.postal_codeErrText,error:this.state.postal_codeErr})}
                                <Box className='makeAddressDefaultCheckBoxParent'>
                                <img data-test-id='checkBoxImage' onClick={()=>this.setState({isDefaultCheck:!this.state.isDefaultCheck})} src={this.state.isDefaultCheck ? checkedIcon : checkBoxIcon} alt="" /> <span className="makeAddressDefaultCheckText">{configJSON.make_this_address_as_default}</span>
                                </Box>
                            </Box>
                        </Box>
                        {this.state.showEditModalText?<SubmitButton disabled={this.checkForDisableSave()} onClick={() => this.state.onOkFuntionCall&&this.state.onOkFuntionCall()}   data-test-id='saveButton' variant="contained" >{configJSON.save}</SubmitButton>
                       
                        :<SubmitButton onClick={() => this.handleSubmitAddress()} disabled={this.checkForDisableSave()} data-test-id='submitButton' variant="contained" >{configJSON.submit}</SubmitButton>
                        }
                        </AddressModalBox>
                </Modal>
            </Box>
        )
    }
    handleBankDetailsModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.isBankDetailsModalOpen}
                    onClose={() => this.handleMakeEmptyBankDetailsModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='isBankDetailsModalOpen'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <AddressModalBox >
                        <img className="crossIcon" data-test-id='crossIcon4' onClick={() => this.handleMakeEmptyBankDetailsModal()} src={crossIcon} alt="" />
                        <Typography style={styles.add_address}>{this.state.showEditBankDetailsModalText?configJSON.editBankDetails:configJSON.bankDetails}</Typography>
                        <Box className='gridInputBox'>
                            <Box className='gridInputBoxChiled'>
                                {this.handleReturnInput(configJSON.bankName, configJSON.bankNamePlaceHolder, this.state.bankName, `bankName`,null,'',{errorText:'',error:false})}
                                {this.handleReturnInput(configJSON.accountNumber, configJSON.accountNumberPlaceHolder, this.state.accountNumber, `accountNumber`,null,'',{errorText:this.state.account_numberErrText,error:this.state.account_numberErr})}
                                {this.handleReturnInput(configJSON.accountHolderName, configJSON.accountHolderNamePlaceHolder, this.state.accountHolderName, `accountHolderName`,null,'',{errorText:'',error:false})}
                                {this.handleReturnSelect(this.state.currencyListArray, configJSON.currency, configJSON.Select, this.state.currency, 'currency')}
                                {this.handleReturnSelect(this.state.countryListArray, configJSON.country, configJSON.Select, this.state.country_id, 'country_id')}
                                {this.handleReturnInput(configJSON.ibanNumber, configJSON.ibanNumberPlaceHolder, this.state.ibanNumber, `ibanNumber`,null,'',{errorText:this.state.iban_numberErrText,error:this.state.iban_numberErr})}
                            </Box>
                        </Box>
                        {this.state.showEditBankDetailsModalText?<SubmitButton onClick={() => this.handleEditBankDetails()}  disabled={this.checkForBankDetailsDisableSave()}  data-test-id='saveEditBankDetails' variant="contained" >{configJSON.save}</SubmitButton>
                        :<SubmitButton onClick={() => this.handleNewBankDetails()} disabled={this.checkForBankDetailsDisableSave()} data-test-id='submitButtonBankDetails' variant="contained" >{configJSON.submit}</SubmitButton>
                        }
                        </AddressModalBox>
                </Modal>
            </Box>
        )
    }
    handleReturnInput = (label: string, placeholder: string, stateValue: string, state: string,isShowPassword:boolean|undefined|null,showState:string,errState:{errorText:string,error:boolean}) => {
        const isPassword = state=='oldPass'||state=='newPass'||state=='confirmPass'
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                {isPassword?<InputField
                    fullWidth
                    type={isPassword&&!isShowPassword&&stateValue?.trimStart()?'password':'text'}
                    error={this.handleReturnHelperErr(state)}
                    helperText={this.handleReturnHelperText(state)}
                    placeholder={placeholder}
                    variant="outlined"
                    value={stateValue}
                    data-test-id={state}
                    onChange={(event) => { this.handleChangeInput(event.target.value, state) }}
                    InputProps={{
                        sx: {
                            paddingRight: "0px",
                        },
                        endAdornment: isPassword && (
                            <InputAdornment position="end" sx={{ marginLeft: '0px',cursor:'pointer'}}>
                                <img data-test-id='eyeIcon' onClick={()=>this.setState((prevState) => ({ ...prevState, [showState]: !isShowPassword,}))} src={isShowPassword?visibilityIcon:visibilityOffIcon} alt="" 
                                style={{ position: 'absolute', right: '9.18px',zIndex:1000000 }} />
                            </InputAdornment>
                        ),
                    }}
                   sx={{
                    "& input":{
                        paddingRight:'40px !important'
                    }
                   }}
                />:
                <InputField
                    fullWidth
                    error={errState?.error}
                    helperText={errState?.errorText}
                    placeholder={placeholder}
                    variant="outlined"
                    value={stateValue}
                    data-test-id={state}
                    onChange={(event) => { this.handleChangeInput(event.target.value, state) }}
                />}
            </Box>
        )
    }
    handleReturnSelect = (optionArrList: CountryInterArr[], label: string, placeholder: string, stateValue: CountryInterArr | {} | undefined | null, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                <ReactSelect
                    options={optionArrList}
                    placeholder={placeholder}
                    value={stateValue}
                    data-test-id={state}
                    onChange={(event) => this.handleChangeSelect(event as { value: string, label: string }, state)}
                    isDisabled={optionArrList.length == 0}
                    classNamePrefix={'custom-select'}
                    styles={{

                        menu: (provided) => ({
                            ...provided,
                            border: '1px solid #53503F',
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            zIndex: 10000,
                            borderRadius: '4px',
                            marginTop: '3.93px',
                            overflow: 'hidden',

                        }),
                        option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            gap: '8px',
                            boxSizing: 'border-box',
                            backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                            paddingRight: '16px',
                            paddingLeft: '16px',
                            paddingBottom: '12px',
                            paddingTop: '12px',
                            fontWeight: state.isSelected ? 600 : 400,
                            color: state.isSelected ? '#FFFFFF' : '#3C3E49',
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: '12px',
                            "&:hover": {
                                backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                            }

                        }),

                    }}
                />
            </Box>
        )
    }
    getFormatedAddress = (element: {
        id: string | number,
        type: string,
        attributes: {
            latitude: string,
            longitude: string,
            address_line_1: string,
            address_line_2: string,
            city: string,
            state: string,
            country: string,
            postal_code: string | number,
            is_activated: boolean
        }
    }) => {

        return <Typography className="cotentText" style={{ ...styles.cotentText }}>{element?.attributes?.address_line_1 || element?.attributes?.address_line_2}{element?.attributes?.address_line_1 && ',' || element?.attributes?.address_line_2 && ','} {element?.attributes?.postal_code} {element?.attributes?.city !== 'nil' && element?.attributes?.city}</Typography>
    }
    handleConfirmationModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.confirmationModal}
                    onClose={()=>this.setState({ confirmationModal: false, })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='confirmationModal'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <ModalBox >
                        <Box data-test-id='confirmationCrossIconBox' className="crossIconBox" onClick={()=>this.setState({ confirmationModal: false, })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography style={styles.confirmation}>{configJSON.confirmation}</Typography>
                        <Typography className="areYouSureText">{this.state.modalMessage}</Typography>
                        <NotNowYesButtonBox>
                            <NoteNowButton data-test-id='noteNowButton' onClick={()=>this.setState({ confirmationModal: false})} variant="contained" >{configJSON.not_now}</NoteNowButton>
                            <RemoveModalButton data-test-id='removeModalButton' variant="contained" onClick={()=>this.state.onOkFuntionCall&&this.state.onOkFuntionCall()} >{configJSON.yes}</RemoveModalButton>
                        </NotNowYesButtonBox>
                    </ModalBox>
                </Modal>
            </Box>
        )
    }
    successModal=()=>{
        return (
            <Box >
                <Modal
                    open={this.state.successModal}
                    onClose={() => this.setState({ successModal: false },()=>this.handleLogout())}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='successModal'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableEnforceFocus
                    disableAutoFocus
                >
                    <ModalBox >
                        <Box data-test-id='crossIconBox3' className="crossIconBox" onClick={() => this.setState({ successModal: false },()=>this.handleLogout())}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography style={styles.confirmation}>{configJSON.success}</Typography>
                        <Typography className="paymentSuccessMessage">{this.state.successMessage}</Typography>
                        <OkButtonBox>
                        {this.state.isPass?<ContinueButton data-test-id='loginNow' variant="contained" onClick={() => this.setState({ successModal: false,openLoginModal: true })}>{configJSON.loginNow}</ContinueButton>
                            :
                            <ContinueButton data-test-id='continueButton' variant="contained" onClick={() => this.setState({ successModal: false },()=>this.handleLogout())}>{configJSON.okay}</ContinueButton>
                        }
                        </OkButtonBox>
                    </ModalBox>
                </Modal>
            </Box>
        )
    }
    handleChangePasswordModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.isChangePasswordModal}
                    onClose={() => this.handleMakeEmptyChangePasswordModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='changePasswordModalOpen'
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <AddressModalBox >
                        <img className="crossIcon" data-test-id='crossIconPassword' onClick={() => this.handleMakeEmptyChangePasswordModal()} src={crossIcon} alt="" />
                        <Typography style={styles.add_address}>Change Password</Typography>
                        <Box className='gridInputBox'>
                            <Box className='gridInputBoxChiled'>
                                {this.handleReturnInput('Old Password', 'Your password', this.state.oldPass, `oldPass`,this.state.oldPassVisible,'oldPassVisible',{errorText:'',error:false})}
                                {this.handleReturnInput('New Password', 'Your password', this.state.newPass, `newPass`,this.state.newPassVisible,'newPassVisible',{errorText:'',error:false})}
                                {this.handleReturnInput('Confirm Password', 'Your password', this.state.confirmPass, `confirmPass`,this.state.confirmPassVisible,'confirmPassVisible',{errorText:'',error:false})}
                            </Box>
                        </Box>
                      <SubmitButton onClick={() => this.handleChangePasswordSave()} disabled={this.handleDisableForPassword()} data-test-id='submitPasswordButton' variant="contained" >Change Password</SubmitButton>
                        
                        </AddressModalBox>
                </Modal>
            </Box>
        )
    }
    handleReturnHeader = () => {
        return (
            this.state.userRole == 'personal_shopper' ? <ShopperHeader /> :
                <AppHeader closeLoginModal={this.closeLoginModal} data-test-id='appHeader' openLoginModal={this.state.openLoginModal} count={this.state.cartData?.length} />
        )
    }
    handleReturnFooter = () => {
        return (
            this.state.userRole == 'personal_shopper' ? <ShopperFooter /> :
                <Footer data-test-id="footerTest" openLoginModal={this.openLoginModal} handleCategoryData={this.handleCategoryData} categoriesList={this.state.landingPagesData} />
        )
    }

  // Customizable Area End

  render() {
      // Customizable Area Start
    
    return (
        <ContainerBoxSettings >
            {this.handleReturnHeader()}
           <OuterMainBoxSettings >
                <ArrowTopNavigation>
                    <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.home}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography className="arrowLastText"> 
                       {configJSON.settings}
                    </Typography>
                </ArrowTopNavigation>
                <MainOuterWhiteBox>
                    <InnerBox>
                        <Box className='firstBox'>
                            <Box className='titleBox titleBox2'>
                                <Typography style={{ ...styles.titleText }}>{configJSON.account}</Typography>
                                { this.state.userRole === 'personal_shopper'&&this.state.bankDetails?.length==0&&<BrownButtonAddAddress onClick={()=>this.setState({isBankDetailsModalOpen:true})} data-test-id='bankDetails' id='newBankDetailsButton' className="newBankDetailsButton" variant="outlined" startIcon={<img src={addIcon} />}>
                                    {configJSON.bankDetails}
                                </BrownButtonAddAddress>}
                            </Box>
                            {this.state.userRole === 'personal_shopper'?
                           <>{this.state.bankDetails?.length>0&& <Box className='mainBox3'>
                                <Box className='chiledBox'>
                                    <Box className='imgBox'> <img src={cardIcon} alt="" className="icon" /></Box>
                                    <Box className='descBox'>
                                        <Box className='descTextBox'>
                                            < Typography style={{ ...styles.titleText }}>{configJSON.accountBalance}</Typography>
                                            <Typography style={{ ...styles.ammountText }}>{this.state.shopperBalance}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='buttonBox2'>
                                    <BrownButtonNewBankDetails onClick={()=>this.setState({isBankDetailsModalOpen:true})}  data-test-id='newBankDetails' id='newBankDetailsButton' className="newBankDetailsButton" variant="outlined" startIcon={<img src={addIcon} />}>
                                        {configJSON.newBankDetails}
                                    </BrownButtonNewBankDetails>
                                    <ChangeButton data-test-id='editBankDetailButton' onClick={()=>this.handleEditBankModal()}>{configJSON.edit}</ChangeButton>
                                </Box>
                            </Box>}
                            </>:<>
                            {this.state.cardDetails?.length>0&&this.state.cardDetails?.map((element)=>(
                            <Box className='mainBox' data-test-id='cardDetailBox'>
                                <Box className='chiledBox'>
                                    <Box className='imgBox'> <img src={cardIcon} alt="" className="icon" /></Box>
                                    <Box className='descBox'>
                                        <Box className='descTextBox'>
                                            < Typography style={{ ...styles.titleText }}>{configJSON.cardDetails}</Typography>
                                            <Typography style={{ ...styles.cotentText }}>{this.handleCardType(element?.cardType)} ending on {element?.cardNumber}</Typography>
                                        </Box>
                                         <Box className='buttonBox'>
                                            < RemoveButton data-test-id='removeCardButton2' onClick={()=>this.handleCardRemove(element.cardNumber)}>{configJSON.remove}</RemoveButton>
                                        </Box> 
                                    </Box>
                                </Box>
                            </Box> ))}</>
                            }
                            <Box className='PasswordMainBox'>
                                <Box className='chiledBox'>
                                    <Box className='imgBox'> <img src={lockIcon} alt="" className="icon" /></Box>
                                    <Box className='descBox'>
                                        <Box className='descTextBox'>
                                            < Typography style={{ ...styles.titleText }}>Password</Typography>
                                            <Typography style={{ ...styles.cotentText }}>********</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                < ChangeButton data-test-id='changePasswordButton' onClick={()=>this.setState({isChangePasswordModal:true})}>{configJSON.change}</ChangeButton>
                            </Box>
                        </Box>

                        <Box className='firstBox'>
                            <Box className='titleBox titleBox2'>
                                <Typography style={{ ...styles.titleText }}>{configJSON.address}</Typography>
                                {this.state.addressList?.length==0&&<BrownButtonAddAddress data-test-id='newAddress1' className="addAddress" onClick={()=>this.setState({isAddressModalOpen:true})} variant="outlined" startIcon={<img src={addIcon} />}>
                                    {configJSON.newAddress}
                                </BrownButtonAddAddress>}
                            </Box>
                            {this.state.addressList.length > 0 && this.state.addressList?.map((element, index) => (
                                <Box className='mainBox' data-test-id='addressBox' key={index}>
                                    <Box className='chiledBox'>
                                        <Box className='imgBox' style={{height:'fit-content'}}> <img src={locationIcon} alt="" className="locationIcon" /></Box>
                                        <Box className='descBox'>
                                            <Box className='descTextBox'>
                                               {element?.attributes?.is_activated&& < Typography style={{ ...styles.defaultText }}>{configJSON.default}</Typography>}
                                                < Typography style={{ ...styles.titleText }}>{configJSON.shippingAddress}</Typography>
                                                {this.getFormatedAddress(element)}
                                            </Box>
                                            <Box className='buttonBox'>
                                                <EditButton data-test-id='editButton' onClick={()=>this.handleEditAddressModal(element,()=>this.handleEditAddress(element?.id,element))}>{configJSON.edit}</EditButton>
                                                <RemoveButton data-test-id='removeAddressButton' disabled={this.state.addressList.length==1} onClick={()=>this.handleCommonModal(configJSON.addressRemoveConfirmationMessage,()=>this.handleRemoveAddress(element?.id))}>{configJSON.remove}</RemoveButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {(element?.attributes?.is_activated||index==0)&&<BrownButton data-test-id='newAddress' onClick={()=>this.setState({isAddressModalOpen:true})} className="newAddress" variant="outlined" startIcon={<img src={addIcon} />}>
                                        {configJSON.newAddress}
                                    </BrownButton>}
                                </Box>
                            ))}
                        </Box>
                        <Box className='firstBox'>
                            <Box className='titleBox'>
                                <Typography style={{ ...styles.titleText }}>{configJSON.notifications}</Typography>
                            </Box>
                            <Box className='mainBox2'>
                                < Typography style={{ ...styles.titleText }}>{configJSON.weeklyUpdates}</Typography>
                                <CustomSwitchButton checked={this.state.isWeekly} data-test-id='weeklyUpdate' onChange={()=>this.handleUpdateWeeklyStatus()}/>
                            </Box>
                        </Box>
                        <Box className='lastBox'>
                        < Typography style={{ ...styles.titleText }}>{configJSON.actions}</Typography>
                          <Box className='deleteAndDeActiveParentBox'>
                            <Box data-test-id='deactivateAccount' onClick={()=>this.handleCommonModal(configJSON.deactivateAccountConfirmationMessage,()=>this.handleDeactivateAccount())}>
                               <img src={deActiveAccountIcon} alt="" /> 
                               <Typography style={{...styles.deactiveText}}>{configJSON.deactivate_account}</Typography>
                            </Box>
                            <Box data-test-id='deleteAccount' onClick={()=>this.handleCommonModal(configJSON.deleteAccountConfirmationMessage,()=>this.handleDeleteAccount())}>
                            <img src={deleteIconRed} alt="" />
                            <Typography style={{...styles.deleteText}}>{configJSON.delete_account}</Typography>
                            </Box>

                          </Box>
                        </Box>
                    </InnerBox>
                </MainOuterWhiteBox>
           </OuterMainBoxSettings>
           {this.handleAddressModal()}
           {this.handleBankDetailsModal()}
           {this.handleChangePasswordModal()}
           {this.handleConfirmationModal()}
           {this.successModal()}
           {this.handleReturnFooter()}
        </ContainerBoxSettings>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBoxSettings = styled(Box)({ 
    backgroundColor: '#EDE6DC',
 })
const OuterMainBoxSettings = styled(Box)({
    gap: '20px',
    padding: '40px 40px 139.76px 40px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    '@media (max-width: 400px) ': {
        padding: '10px'
    }
})
const ArrowTopNavigation = styled(Box)({
    gap: '11px',
    paddingLeft: '6px',
    display: 'flex',
    alignItems: 'center',
    "& .arrowHomeText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        cursor: 'pointer',
        color: '#53503F',
        fontWeight: 400,
    },
    "& .arrowLastText": {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        color: '#53503F',
    }
})
const MainOuterWhiteBox = styled(Box)({
    border: '1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '4px',
    width: '859px',
    minHeight: 'max-content',
    alignSelf: 'center',
    padding:'45.09px 59.3px',
    "@media (max-width:997px)":{
        width:'80%'
    },
    "@media (max-width:350px)":{
        width:'95%'
    }
    
})
const InnerBox = styled(Box)({
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    gap:'25px',

    "& .firstBox": {
        display: 'flex',
        flexDirection: 'column',
    },
    "& .titleBox":{
        paddingBottom:'20px',
        borderBottom:'1px dashed rgba(83, 80, 63, 0.5)',
        boxSizing:"border-box",
        display:'flex',
        justifyContent:'space-between'
    },
    "& .titleBox2":{
        display:'flex',
        justifyContent:'space-between'
    },
    "& .mainBox": {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop:'35px',
        paddingBottom:'35px',
        boxSizing:"border-box",
        borderBottom:'1px solid rgba(83, 80, 63, 0.5)'
    },
    "& .mainBox2": {
        display: 'flex',
        boxSizing:"border-box",
        justifyContent: 'space-between',
        paddingTop:'35px',
        paddingBottom:'35px',
        borderBottom:'1px solid rgba(83, 80, 63, 0.5)',
        alignItems:'center'
    },
    "& .mainBox3": {
        display: 'flex',
        paddingTop:'35px',
        justifyContent: 'space-between',
        boxSizing:"border-box",
        borderBottom:'1px solid rgba(83, 80, 63, 0.5)',
        paddingBottom:'35px',
    },
    "& .PasswordMainBox":{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom:'35px',
        paddingTop:'35px',
        boxSizing:"border-box",
        borderBottom:'1px solid rgba(83, 80, 63, 0.5)'

    },
    
    "& .chiledBox": {
        display: 'flex',
        gap: '10px',
       
    },
    "& .descBox":{
        display: 'flex',
        gap:'12px',
        flexDirection:'column',
       
    },
    "& .descTextBox":{
        display: 'flex',
        gap:'4px',
        flexDirection:'column',
    },
    "& .buttonBox":{
        display: 'flex',
        gap:'12px',
    },
    "& .buttonBox2":{
        gap:'21.03px',
        height:'fit-content',
        display: 'flex',
    },
   "& .lastBox":{
    display: 'flex',
    justifyContent: 'space-between',
    },
    "& .deleteAndDeActiveParentBox": {
        display: 'flex',
        gap: '15.54px',
        flexDirection: 'column',
        alignItems: 'end',
        "& div": {
            display: 'flex',
            gap: '13.15px',
            alignItems: 'center',
            cursor:'pointer',
            "& img": {
                width: '20px',
                height: '20px'
            }
        }
    },
    "& .cotentText": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '350px',
    },
    "@media (max-width:455px)": {
        "& .lastBox": {
            flexDirection: 'column',
            gap: '20px'
        },
        "& .deleteAndDeActiveParentBox": {

            alignItems: 'unset',
        },
    },
   
    "@media (max-width:785px)": {
        "& .cotentText": {
            maxWidth: '250px',
        },
    },
    "@media (max-width:625px)": {
        "& .mainBox": {
            flexDirection: 'column',
            gap:'20px',
        },
     
    },
    "@media (max-width:490px)": {
        "& .buttonBox2":{
            flexDirection: 'column',
            gap:'20px',
            alignItems:'baseline'
    },
    },
    "@media (max-width:750px)": {
        "& .mainBox3": {
            flexDirection: 'column',
            gap:'20px',
        },
    },

})
const EditButton =styled(Button)({
    
    height: '26px',
    width: '45px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '12px',
    borderRadius: '4px',
    fontWeight: 400,
    color: 'rgba(83, 80, 63, 1)',
    border: '1px solid rgba(83, 80, 63, 1)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignIems: 'center',
    "&:hover": { backgroundColor: 'transparent ',  border: '1px solid rgba(83, 80, 63, 1)', },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover": {
        textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1) !important',
        backgroundColor: 'transparent !important',
    },
})


const RemoveButton =styled(Button)({
    height: '26px',
    width: '70px',
    textTransform: 'none',
    fontSize: '12px',
    backgroundColor: 'transparent',
    fontFamily: "'Montserrat', sans-serif",
    color: 'rgba(83, 80, 63, 1)',
    borderRadius: '4px',
    border: '1px solid rgba(83, 80, 63, 1)',
    fontWeight: 400,
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignIems: 'center',
    "&:hover": { backgroundColor: 'transparent ', 
         border: '1px solid rgba(83, 80, 63, 1)', },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover": {
        border: '1px solid rgba(83, 80, 63, 1) !important',
        textDecoration: 'none',
        backgroundColor: 'transparent !important',
    },
    '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: '#rgba(83, 80, 63, 1)',
        opacity: 0.5,
        cursor: 'not-allowed'
    },
})
const BrownButton = styled(Button)({
    width: '148.55px',
    backgroundColor: 'rgba(83, 80, 63, 1)',
    height: '30px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(237, 230, 220, 1)',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignIems: 'center',
    border: '1px solid rgba(83, 80, 63, 1)',
    textDecoration: 'none',
    "&:hover": {
        border: '1px solid rgba(83, 80, 63, 1) !important',
        backgroundColor: 'rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',
        textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    "@media (max-width:625px)": {
       marginLeft:'36px',
      
    }
})
const BrownButtonNewBankDetails = styled(Button)({
    width:"fit-content",
    height: '30px',
    backgroundColor: 'rgba(83, 80, 63, 1)',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '14px',
    color: 'rgba(237, 230, 220, 1)',
    fontWeight: 600,
    justifyContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    border: '1px solid rgba(83, 80, 63, 1)',
    alignIems: 'center',
    textDecoration: 'none',
    "&:hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        textDecoration: 'none',
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    "@media (max-width:750px)": { marginLeft:'36px', }
      
      
   
})
const BrownButtonAddAddress = styled(Button)({
    backgroundColor: 'rgba(83, 80, 63, 1)',
    width: '148.55px',
    height: '30px',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: '14px',
    color: 'rgba(237, 230, 220, 1)',
    justifyContent: 'center',
    cursor: 'pointer',
    alignIems: 'center',
    display: 'flex',
    border: '1px solid rgba(83, 80, 63, 1)',
    textDecoration: 'none',
    "&:hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        textDecoration: 'none',
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
})
const ChangeButton =styled(Button)({
    height: '30px',
    textTransform: 'none',
    width: '86.87px',
    fontSize: '14px',
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: 'transparent',
    color: 'rgba(83, 80, 63, 1)',
    borderRadius: '4px',
    border: '1px solid rgba(83, 80, 63, 1)',
    fontWeight: 600,
    display: 'flex',
    cursor: 'pointer',
    alignIems: 'center',
    justifyContent: 'center',
    "&:hover": { backgroundColor: 'transparent ', 
         border: '1px solid rgba(83, 80, 63, 1)', },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover": {
        border: '1px solid rgba(83, 80, 63, 1) !important',
        textDecoration: 'none',
        backgroundColor: 'transparent !important',
    },
})
const CustomSwitchButton = styled(Switch)(({ theme }) => ({
    width: 52,
    padding: 0,
    height: 28,
    "& .MuiSwitch-switchBase": {
        padding: 2,
        transition: "transform 0.3s ease, background-color 0.3s ease",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-checked": {
            color: "rgba(237, 230, 220, 1)",
            transform: "translateX(24px)",
            "& + .MuiSwitch-track": {
                backgroundColor: "rgba(83, 80, 63, 1)",
                opacity: 1,
                border: '1px solid transparent'
            },
        },
    },
    "& .MuiSwitch-thumb": {
        height: 24,
        width: 24,
        padding: 2,
        boxSizing: 'border-box',
        backgroundColor: "rgba(237, 230, 220, 1)",
        transition: "none",
        "&:hover": {
            backgroundColor: "rgba(237, 230, 220, 1)",
        },
    },
    "& .MuiSwitch-track": {
        padding: 2,

        backgroundColor: "transparent",
        borderRadius: 40,
        opacity: 1,
        border: '2px solid rgba(237, 230, 220, 1)',
        boxSizing: 'border-box',
        transition: "border 0.3s ease, background-color 0.3s ease",
    },

}));

const AddressModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.833)',
    width: '460px',
    borderRadius: '8px',
    padding: '50px',
    boxSizing: 'border-box',
    backgroundColor: '#EFEEE9',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '855px',
    justifyContent: 'center',
    alignItems: 'center',
   
    '& .gridInputBox': {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '49px 0px'
    },
    '& .gridInputBoxChiled': {
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
    },
     
    "& .crossIcon": {
        position: 'absolute',
        right: '29px',
        top: '29px',
        cursor: 'pointer'
    },
    "& .makeAddressDefaultCheckBoxParent": {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        color: '#53503F',
        fontWeight: 400,
        marginTop:'1.5px',
        boxSizing:'border-box',
        "& img": {
            cursor: 'pointer',
            height: '24px',
            width: '24px'
        }
    },
    "@media (min-width:500px) and (max-width:780px)": {
        '& .gridInputBox': {
            width: '80%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media  (max-width:500px)": {
        '& .gridInputBox': {
            width: '100%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media (max-width:780px)": {
        padding: '20px 20px',
        maxHeight: 'unset',
        height: "unset",
        transform: 'translate(-50%, -50%) scale(0.77)',
        width: '95%',
        "& .TwoInputBox1": {
            alignItems: 'start'
        },
        '& .gridInputBox': {
            gap: '20px',
            margin: '20px 0px'
        },
        "& .singleInputParentBox": {
            width: '100%'

        }
    },
    "@media (max-width:370px)": {
        width: '125%',
        "& .singleInputParentBox": {
        }
    }
})
const InputField = styled(TextField)({
    minWidth: "360px",
    height: '56px',

    '& input::placeholder': {
        color: '#BABABA',
        opacity: 1,
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },

    "& .MuiInputBase-root": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        height: '100%'
    },

    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",

    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
        },
        "&.Mui-focused fieldset": {
            borderWidth: "0px",

        },
    },

    "& .MuiOutlinedInput-input": {
        padding: "17px 8px",
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: '100%'

    },

    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
        fontWeight: 600,
        lineHeight:'normal',
        fontSize: '12px',
        fontFamily: "'Montserrat', sans-serif",
        color: "#DC2626",
    },
    '& .css-1wc848c-MuiFormHelperText-root.Mui-error':{
        color: "#DC2626",
        margin:'0px',
        marginTop:'0.5px'
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border:'1px solid #DC2626'
    },
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",

    },
});
const LabelText = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '4px'
})
const ReactSelect = styled(Select)({
    minWidth: '360px',
    outline: 'none',
    height: '56px',
    scrollbarGutter: 'stable',

    '& .custom-select__menu-list': {
        scrollbarWidth: '5px', scrollbarColor: '#53503F transparent',
        direction: 'ltr',
        borderRadius: '8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
        borderRadius: '8px'
    },

    '& .custom-select__menu-list:hover': {
        scrollbarWidth: '5px',
        direction: 'ltr',
        scrollbarColor: '#53503F transparent',
        borderRadius: '8px'
    },
    "& .css-1uccc91-singleValue": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
        display: 'none'
    },
    "& .css-yk16xz-control": {
        border: 'none',
        borderColor: 'none',
        height: '100%',
    },
    "& .css-yk16xz-control:hover": {
        border: 'none',
        borderColor: 'none'
    },
    "& .css-g1d714-ValueContainer": {
        height: '56px',
        border: 'none'
    },
    "& .css-1pahdxg-control": {
        borderColor: 'transparent !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none !important',
        height: '56px',
        minHeight: '56px'
    },
    "& .custom-select__control": {
        minHeight: '56px',
        height: '56px',
    },
    "& .custom-select__value-container": {
        height: '56px',
    },
    "& .css-1wa3eu0-placeholder": {
        color: '#BABABA',
        fontWeight: 400,
        opacity: 1,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        width: '24px',
        display: 'flex',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
        padding: '0'
    },
    "& .css-6q0nyr-Svg": {
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        color: '#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        zIndex: 1000
    },
    "& .css-9gakcf-option": {
        backgroundColor: '#53503F',
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
    },
    "& span": {
        display: 'none'
    },
    "& svg": {
        cursor: 'pointer',
        position: 'absolute',
        right: '8px',
        top: '20px',
        color: '#53503F'
    },
    "@media (max-width: 400px)": {
        minWidth: 'unset'
    }
})
const SubmitButton = styled(Button)({
    width: '360px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    '&.Mui-disabled': {
        backgroundColor: '#53503F',
        color: '#EDE6DC',
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    "&:hover": {
        backgroundColor: '#53503F',
    },
    "@media (max-width:780px)": {
        width: '80%'
    },
    "@media  (max-width:500px)": {
        width: '100%'
    }

})
const ModalBox=styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '4px',
    padding: '68px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '340px',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',
   
    "& .areYouSureText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop:'8px',
        maxWidth:'386px',
        textAlign:"center"
    },
    "& .paymentSuccessMessage": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        color: '#53503F',
        fontWeight: 400,
        marginTop: '16px',
        textAlign: "center",
        maxWidth: '502px',
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor:'pointer'
    },
    "@media  (max-width:550px)": {
        width: 'unset',
    },
    '@media (max-width:400px )': {
        padding: '68px 25px',

    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})

const NotNowYesButtonBox=styled(Box)({
    display:'flex',
    alignItems:'center',
    gap:'50px',
    marginTop:'40px',
    '@media (max-width:420px )': {
        gap:'28.45px',
    },
    '@media (max-width:359px )': {
        flexDirection:'column'
    },
})

const NoteNowButton=styled(Button)({
    width: '155px',
    border:'1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'transparent',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#53503F',
    "&:hover": {
        backgroundColor: 'transparent',
    },
})

const RemoveModalButton=styled(Button)({
    width: '155px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#53503F',
    },
})

const OkButtonBox=styled(Box)({
    display:'flex',
    gap:'50px',
    alignItems:'center',
    marginTop:'48px',
    '@media (max-width:420px )': {   gap:'28.45px', },
   
    '@media (max-width:359px )': {  flexDirection:'column'},  
   
})
const ContinueButton=styled(Button)({
    width: '360px',
    height: '56px',
    textTransform: 'none',
    backgroundColor: '#53503F',
    borderRadius: '4px',
    fontSize: '18px',
    fontFamily: "'Montserrat', sans-serif",
    color: '#EDE6DC',
    fontWeight: 600,
    cursor: 'pointer',
    "&:hover": {backgroundColor: '#53503F',},
})
const styles={
    titleText:{
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        color: '#53503F',
        width:'fit-content'
    },
    cotentText:{
        fontWeight: 400,
        fontSize: '18px',
        lineHeight:'normal',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        width:'fit-content'
    },
    ammountText:{
        fontWeight: 600,
        fontSize: '32px',
        lineHeight:'normal',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        width:'fit-content'
    },
    defaultText: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 'normal',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        width:'fit-content'
    },
    deactiveText:{
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        lineHeight:'normal',
        color: '#53503F',
    },
    deleteText:{
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        color: '#DC2626',
        lineHeight:'normal'
    },
    add_address: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 600,
        color: '#53503F',
    },
    confirmation: {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
}
// Customizable Area End