import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wishData:any;
  isActiveImage:number|string;
  isBigImage:any;
  isExpand:any;
  catalogueList:any;
  navigationData:any;
  openAcceptModal:boolean;
  openRejectModal:boolean;
  deleteOrderModal:boolean;
  openVariantsModal:boolean;
  hideProductToggle:boolean;
  openHideModal:boolean;
  hidePeriod:any;
  productDetails:any;
  buyingRequests:any;
  openConfirmationModal:boolean;
  buyingRequestId:any;
  requestType:any;
  imagesData:any[];
  catalogueInfo:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequstDetailsScreenController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  requestDetailsAPIId:string="";
  acceptRejectApiId:string="";
  productDetailsAPIId:string="";
  orderDetailsAPIId:string="";
  getBuyingRequestsOFProductId:string="";
  hideProductApiId:string="";
  showProductAPI:string="";
  deleteProductsApi:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
     ,getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      wishData:{},
      isActiveImage:0,
      isExpand:false,
      catalogueList:[],
      navigationData:{},
      openAcceptModal:false,
      openRejectModal:false,
      deleteOrderModal:false,
      isBigImage:{},
      openVariantsModal:false,
      hideProductToggle:false,
      openHideModal:false,
      hidePeriod:"",
      productDetails:{},
      buyingRequests:{},
      openConfirmationModal:false,
      buyingRequestId:"",
      requestType:"",
      imagesData:[],
      catalogueInfo:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.handleApiResponse(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const params=new URLSearchParams(window.location.search)
   const id=params.get('id');
   const tabName=params.get("tab");
   const subTab=params.get("subtab")
   this.setState({navigationData:{
    id,
    tabName,
    subTab
   }},()=>{
    if(this.state.navigationData.tabName=="Offers"){
      this.getRequestDetails();
    }else if(this.state.navigationData.tabName=="Products"||this.state.navigationData.tabName=="Buying Requests"){
       this.getProductDetails();
       this.getBuyingRequestsOFProduct()
    }
    else if(this.state.navigationData.tabName=="Orders"){
      this.getOrderDetails()
   }
  })
   firebase.app("")
  }
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.requestDetailsAPIId:
     
        if (responseJson) {
          this.setState({isBigImage:responseJson.data?.attributes?.variant_info?.attributes?.images?.[0] ,
            imagesData:responseJson.data?.attributes?.variant_info?.attributes?.images
          });
        this.handleDetailsApiResponse(responseJson)

        }
        break;
        case this.orderDetailsAPIId:
          if (responseJson) {
            this.setState({ 
              imagesData:responseJson.data?.attributes?.catalogue_variant_details?.attributes?.images
              ,isBigImage:responseJson.data?.attributes?.catalogue_variant_details?.attributes?.images?.[0] });
             this.handleDetailsApiResponse(responseJson);
          }
          break;
        case this.productDetailsAPIId:
          this.setState({productDetails:responseJson.data,isBigImage:responseJson.data.attributes.variant_info})
          break;
        case this.hideProductApiId:
          this.setState({openHideModal:false},this.getProductDetails)
        break;
        case this.showProductAPI:
          if(responseJson.message){

            this.setState({openConfirmationModal:false},this.getProductDetails)
          }
        break;
        case this.deleteProductsApi:
          if(responseJson.message){
            this.setState({deleteOrderModal:false})
          }
        break;
        case this.getBuyingRequestsOFProductId:
        this.buyingRequestHandleResponse(responseJson)
          break;
        case this.acceptRejectApiId:
          this.handleDetailsApiResponse(responseJson)

            if(this.state.navigationData.tabName=="Offers"){
              this.getRequestDetails();
            }else if(this.state.navigationData.tabName=="Products"){
               this.getProductDetails();
               this.getBuyingRequestsOFProduct()
            }
        break;
  
    }
  }
  handleDetailsApiResponse=(responseJson:any)=>{
    this.setState({wishData:{
      id:responseJson?.data?.attributes?.id,
      price:responseJson?.data?.attributes?.listed_price,
      listedPrice:responseJson?.data?.attributes?.order_price,
      offerPrice:responseJson?.data?.attributes?.offer_price,
      offerOrderNumber:responseJson.data.attributes?.offer_id||responseJson?.data?.attributes?.order_number,
      offerOrderDate:responseJson?.data?.attributes?.offer_date||moment(responseJson?.data?.attributes?.created_at).format("DD MMM YYYY"),
      productName:responseJson?.data?.attributes?.brand_name||responseJson?.data?.attributes?.product_names,
      variantInfo:{
        img:responseJson?.data?.attributes?.variant_info?.attributes?.images?.[0]?.url||responseJson?.data?.attributes?.catalogue_variant_details?.attributes?.images?.[0]?.url,
        price:responseJson?.data?.attributes?.variant_info?.attributes?.price||responseJson?.data?.attributes?.catalogue_variant_details?.attributes?.price,
        colorCode:responseJson?.data?.attributes?.variant_info?.attributes?.catalogue_variant_color?.color_code||responseJson?.data?.attributes?.catalogue_variant_details?.attributes?.catalogue_variant_color?.color_code,
        colorName:responseJson?.data?.attributes?.variant_info?.attributes?.catalogue_variant_color?.name||responseJson?.data?.attributes?.catalogue_variant_details?.attributes?.catalogue_variant_color?.name,
        size:responseJson?.data?.attributes?.variant_info?.attributes?.one_size||responseJson?.data?.attributes?.catalogue_variant_details?.attributes?.one_size,

      },
      customerName:responseJson?.data?.attributes?.customer_name||responseJson?.data?.attributes?.account_full_name,
      customerLocation:responseJson?.data?.attributes?.location||responseJson?.data?.attributes?.country_name
    }},()=>{
      this.handleProductDetailsResponse(responseJson)
    })
  }
  handleProductDetailsResponse=(responseJson:any)=>{
    this.setState({
      catalogueInfo:{
        wearName:responseJson?.data?.attributes?.wearname||responseJson?.data?.attributes?.product_details?.catalogue_wear,
      categoryName:responseJson?.data?.attributes?.category_name ||responseJson?.data?.attributes?.product_details?.catalogue_product_category,
      brandName:responseJson?.data?.attributes?.brand_name||responseJson?.data?.attributes?.product_details?.catalogue_product_brand,
      condition:responseJson?.data?.attributes?.condition ||responseJson?.data?.attributes?.product_details?.catalogue_product_condition,
      material:responseJson?.data?.attributes?.material||responseJson?.data?.attributes?.product_details?.catalogue_product_material,
      use:responseJson?.data?.attributes?.product_use||responseJson?.data?.attributes?.product_details?.catalogue_product_use,
      waterResistance:responseJson?.data?.attributes?.product_water_resistance||responseJson?.data?.attributes?.product_details?.catalogue_product_water_resistance,
      productionYear:responseJson?.data?.attributes?.product_year_production||responseJson?.data?.attributes?.product_details?.catalogue_product_year_production,
      packages:responseJson?.data?.attributes?.packages||responseJson?.data?.attributes?.product_details?.catalogue_packages,
      dustBags:responseJson?.data?.attributes?.dust_bag||responseJson?.data?.attributes?.product_details?.catalogue_dust_bag,
      description:responseJson?.data?.attributes?.description||responseJson?.data?.attributes?.product_details?.catalogue_product_description,
      }
    })
  }
  buyingRequestHandleResponse=(responseJson:any)=>{
    const array1 = responseJson.data[0].offers;
    const array2 = responseJson.data[1].orders;
    
    const mergedArray = [];
    const maxLength = Math.max(array1.length, array2.length);
    
    for (let i = 0; i < maxLength; i++) {
      if (i < array1.length) mergedArray.push(array1[i]); // Add from array1
      if (i < array2.length) mergedArray.push(array2[i]); // Add from array2
    }
              this.setState({buyingRequests:{
                offers:responseJson.data[0].offers,
                orders:responseJson.data[1].orders,
                data:mergedArray
              }}
            )
    
  }
  handleImageChange = (imageData: { id: number | string, blob_id: number | string, url: string }) => {
    this.setState({ isActiveImage: imageData.id, isBigImage: imageData })
  }
  getRequestDetails=async()=>{
    const token=await getStorageData("loginToken")
    this.requestDetailsAPIId=this.requestApiCall(token,{
      method:"GET",
      endPoint:`/bx_block_request_management/offer_requests/${this.state.navigationData?.id}`
    })
  }
  getProductDetails=async()=>{
    const token=await getStorageData("loginToken")
    this.productDetailsAPIId=this.requestApiCall(token,{
      method:"GET",
      endPoint:`/bx_block_catalogue/catalogues/${this.state.navigationData?.id}`
    })
  }
  getOrderDetails=async()=>{
    const token=await getStorageData("loginToken")
    this.orderDetailsAPIId=this.requestApiCall(token,{
      method:"GET",
      endPoint:`/bx_block_order_management/shopper_orders/${this.state.navigationData?.id}`
    })
  }
  handleConfirmationModal=async()=>{
    const token =await getStorageData("loginToken");
    const body={
      "catalogue_ids":`${this.state.navigationData?.id}`
  }
  this.showProductAPI=this.requestApiCall(token,{
    method:"PUT",
    endPoint:`/bx_block_catalogue/catalogues/unhide_catalogues`,
    body:JSON.stringify(body)
  })
  
  }
  handleCloseConfirmationModal=()=>{
    this.setState({openConfirmationModal:!this.state.openConfirmationModal})
  }
  getBuyingRequestsOFProduct=async()=>{
    const token=await getStorageData("loginToken")
    this.getBuyingRequestsOFProductId=this.requestApiCall(token,{
      method:"GET",
      endPoint:`/bx_block_request_management/buying_requests?catalogue_id=${this.state.navigationData?.id}`
    })
  }
  handleConfirmHidePeriod=async()=>{
    const token =await getStorageData("loginToken");
    const body={
      "catalogue_ids":this.state.navigationData?.id,
      "hidden_days": this.state.hidePeriod
  }
  this.hideProductApiId=this.requestApiCall(token,{
    method:"PUT",
    endPoint:`/bx_block_catalogue/catalogues/hide_catalogues`,
    body:JSON.stringify(body)
  })
  this.setState({openHideModal:false})
  }
  handleHideModal=()=>{
    this.setState({openHideModal:!this.state.openHideModal})
  }
  handlePeriodChange=(event:any)=>{
    this.setState({hidePeriod:event?.target.value})
  }
  handleOpenAcceptModal=(id:string)=>{
    this.setState({openAcceptModal:true,buyingRequestId:id})
  }
  handleAcceptYesClick=()=>{
    this.setState({openAcceptModal:false,navigationData:{...this.state.navigationData,subTab:"Accepted Offers"}},()=>this.handleAcceptRejectApi("accepted"))
  }
  handleAcceptRejectApi=async(action:string)=>{
    const token=await getStorageData("loginToken")
    const endPoint=this.state.requestType=="offer"?`/bx_block_request_management/offer_requests/update_offer_status`:`/bx_block_order_management/shopper_orders/${this.state.buyingRequestId}`;
    let body={};
    if(this.state.requestType=="offer"){
        body=JSON.stringify( {
          "id": this.state.buyingRequestId,
          "new_status": action,
          "expired_date": moment().add(10, "days").format("YYYY-MM-DD"),   
      })
    }else if(this.state.requestType=="order"){
        body=JSON.stringify( {
          "shopper_order": {
              "shopper_action":action
          }
        })
    }
    this.acceptRejectApiId=this.requestApiCall(token,{
      method:"PUT",
      endPoint,
      body
    })
  }
  handleDeleteProductsModal=()=>{
    this.setState({deleteOrderModal:!this.state.deleteOrderModal})
  }
  handleConfirmDeleteProducts=async()=>{
    const token =await getStorageData("loginToken");
    const body={
      "catalogue_ids":this.state.navigationData?.id
  }
  this.deleteProductsApi=this.requestApiCall(token,{
    method:"DELETE",
    endPoint:`/bx_block_catalogue/catalogues/delete_all`,
    body:JSON.stringify(body)
  })
  }
  handleOpenRejectModal=(id:string)=>{
    this.setState({openRejectModal:true,buyingRequestId:id})
  }
  handleRejectYesClick=()=>{
    this.setState({openRejectModal:false,navigationData:{...this.state.navigationData,subTab:"Rejected Offers"}},()=>this.handleAcceptRejectApi("rejected"))
  }
  requestApiCall = (token: any,payload:any) => {
    let {method,endPoint,body}=payload

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': 'application/json',
      token: token||"",
    };
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    body&&requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;
  };
  // Customizable Area End
}
