import React from "react";

// Customizable Area Start
import { InputAdornment,TextField, Box,IconButton, styled,Button, Typography,LinearProgress,Slide, Backdrop,Slider,Chip, Modal } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CloseIcon  from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Select from 'react-select'
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import DashboradTable from "../../../components/src/DashboardTable.web";
import HideProductModal from "../../../components/src/popups/HideProducts.web"
import AddCartConfirmationPopUp from "../../../components/src/popups/addToCartConfirmation.web"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
const ReactSelect = styled(Select)({
  minWidth: '155px',
  width:"auto",
  borderRadius: "8px",
  outline: 'none',
  height:'44px',
  scrollbarGutter:'stable',
 
  '& .custom-select__menu-list': {
    scrollbarWidth: '5px',
    scrollbarColor: '#53503F transparent',
    direction: 'ltr',
    borderRadius: '8px',
    overflow: 'visible',
  },
  '& .custom-select__menu-list::-webkit-scrollbar': {
    scrollbarWidth: '5px',
    scrollbarColor: '#53503F transparent',
    direction: 'ltr',
  },
  '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
    scrollbarColor: '#53503F transparent',
    scrollbarWidth: '5px',
    direction: 'ltr',
    borderRadius: '8px',
  },
  '& .custom-select__menu-list:hover': {
    scrollbarWidth: '5px',
    direction: 'ltr',
    scrollbarColor: '#53503F transparent',
    borderRadius:'8px'
  },

  "& .custom-select__menu-list span": {
    display: 'inline-block',
    paddingLeft: '8px',
  },

  "& .css-1uccc91-singleValue": {
    color: "#53503F",
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
  },
  "& .css-1okebmr-indicatorSeparator": {
    display: 'none'
  },
  "& .css-yk16xz-control": {
    border: 'none',
    borderColor: 'none',
    height:'100%',
  },
  "& .css-yk16xz-control:hover": {
    border: 'none',
    borderColor: 'none'
  },
  "& .css-g1d714-ValueContainer": {
    height: '44px',
    border: 'none'
  },
  "& .css-1pahdxg-control": {
    borderColor: 'transparent !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    border: 'none !important',
    height:'44px',
    minHeight:'44px'
  },
  "& .custom-select__control": {
    minHeight: '44px',
    height: '44px',
  },
  "& .custom-select__value-container": {
    height: '44px',
  },
  "& .css-1wa3eu0-placeholder": {
    color: '#BABABA',
    fontWeight: 400,
    opacity: 1,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '16px',
  },
  "& .css-1hb7zxy-IndicatorsContainer": {
    width: '24px',
    display: 'flex',
    justifyContent: "center"
  },
  "& .css-tlfecz-indicatorContainer": {
    padding: '0 10px'
  },
  "& .css-6q0nyr-Svg": {
    color: '#53503F',
    cursor:'pointer'
  },
  "& .css-26l3qy-menu": {
    fontWeight: 400,
    color: '#3C3E49',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '12px',
    zIndex: 1000
  },
  "& .css-9gakcf-option": {
    backgroundColor: '#53503F',
    fontWeight: 600,
    color: '#FFFFFF',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '12px',
  },
  "& span": {
    display: 'inline-block',
  },

  "@media (max-width: 400px)": {
    minWidth: 'unset'
  }
});
const {ProductsIcon,upArrowIcon,noProductIcon,purcahedOrderIcon,OffersIcon,requestsIcon,downArrowIcon,calenderIcon}=require("./assets")
const EmptyBagMainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin:"7rem 0px",
  "& .emptyImageText": {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '30px'
  },
  "& .emptyTextBox": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '15px'
  },
  "& .emptyText": {
      textAlign: 'center',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '25px',
      fontWeight: 600,
      color: '#53503F',
  },
  "& .emptyText2": {
      fontFamily: "'Montserrat', sans-serif",
      textAlign: 'center',
      fontSize: '20px',
      color: '#53503F',
      fontWeight: 400,
      maxWidth: '338px'
  },
  "& .startShopping": {
      textTransform: 'none',
      borderRadius: '4px',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      backgroundColor: '#53503F',
      color: '#EFEEE9',
      fontWeight: 600,
      cursor: 'pointer',
      width: '283px',
  },
  "& .startShopping:hover": {
      backgroundColor: '#53503F',
  },
  "@media  (max-width:500px)": {
      '& .startShopping': {
          width: 'unset',
          fontWeight: 500,
          fontSize: '14px',
      },
      "& .emptyText": {
          fontSize: '16px',
      },
      "& .emptyText2": {
          fontSize: '14px',
      },
      "& .bagIcon":{
          height:'100px',
          width:'100px'
      }
  }
})
const placement = "top-end";
 const overviewImages: {
  "Listed Products": string;
  "Purchased Orders": string;
  "Offers": string;
  "Wish Requests": string;
} = {
  "Listed Products": ProductsIcon,
  "Purchased Orders": purcahedOrderIcon,
  "Offers": OffersIcon,
  "Wish Requests": requestsIcon,
};
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  priceRange(label:string){
    return this.state.activeTab!=="Wish Requests" &&
    (<>  
    <span style={{fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333'}}>{label}</span>
    <div style={{width:"88%",margin:"auto",marginLeft:"20px"}}>
     <Slider
          onChange={this.handlePriceChange}
          value={this.state.priceRange}
          min={this.state.minPriceData}
          max={this.state.maxPriceData}
          step={1}
          valueLabelFormat={this.renderValueLabel}
          data-test-id="pricerangeTest"
          valueLabelDisplay="on"
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            boxSizing:"border-box",
            margin: '30px auto',
            height: 4,
            zIndex:9779999,
            width:"88%",
            color: "#70624D",
            marginLeft:"20px",
            "& .MuiSlider-thumb": {
              border: "2px solid #FFFFFF",
              backgroundColor: "#70624D",
              width: 20, 
              height: 20,
              "&:hover": {
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
              },
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#ffffff",
              opacity: 1,
            },
            "& .MuiSlider-track": {
              backgroundColor: "#70624D",
            },
            "& .MuiSlider-valueLabel": {
              border: '1px solid rgba(83, 80, 63, 1)',
              marginLeft: '10px !important', 
              borderRadius: '4px',
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "12px",
              backgroundColor: "#ffffff",
              top: -6, 
              color: "#70624D",
              fontWeight: 600,
              "&:before": {
                display: "none"
              },
            },
          }}
        />
    </div>

</>)
  }

  offerRange(){
    return this.state.activeTab!=="Wish Requests" &&(<>
    <span style={{fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333'}}>{"Offer Range"}</span>
    <div style={{width:"88%",margin:"auto",marginLeft:"20px"}}>
   <Slider
          step={1}
          min={this.state.minOfferPrice}
          value={this.state.offerPriceRange}
          max={this.state.maxOfferPrice}
          onChange={this.handleOfferPriceChange}
          valueLabelDisplay="on"
          valueLabelFormat={this.renderValueLabel}
          data-test-id="pricerangeTest"
          sx={{
            margin: '30px auto',
            fontFamily: "'Montserrat', sans-serif",
            boxSizing:"border-box",
            color: "#70624D",
            height: 4,
            width:"88%",
            zIndex:9999999,
            marginLeft:"20px",
            "& .MuiSlider-thumb": {
              backgroundColor: "#70624D",
              height: 20,
              width: 20, 
              border: "2px solid #FFFFFF",
              "&:hover": {
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
              },
            },
            "& .MuiSlider-rail": {
              opacity: 1,
              backgroundColor: "#ffffff",
            },
            "& .MuiSlider-track": {
              backgroundColor: "#70624D",
            },
            "& .MuiSlider-valueLabel": {
              marginLeft: '10px !important', 
              top: -6, 
              borderRadius: '4px',
              fontFamily: "'Montserrat', sans-serif",
              border: '1px solid rgba(83, 80, 63, 1)',
              fontWeight: 600,
              backgroundColor: "#ffffff",
              color: "#70624D",
              fontSize: "12px",
              "&:before": {
                display: "none"
              },
            },
          }}
        />
    </div>

</>)
  }

  categoryDiv(){
  return  <Box sx={{ marginBottom: '15px' }}>
    <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Category</Typography>
    <Box display="flex" flexWrap="wrap" gap={1}>
      {this.state.categoryList?.map((subCat: any,index:any) => (
        <Chip
          className="montserratFont"
          data-testid="subcategoryTestId"
          key={subCat.id}
          label={subCat.name}
          data-test-id={`categoryChip${index}`}
          onClick={() => this.handleCategorySelect(subCat.id)}
          sx={{
            backgroundColor: this.state.selectedCategoryList.includes(subCat.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
            color: this.state.selectedCategoryList.includes(subCat.id) ? '#FFFFFF' : '#333',
            borderRadius: '4px',
            fontWeight: this.state.selectedCategoryList.includes(subCat.id) ? 600 : 400,
            padding: '10px 16px',
            height: '40px',
            border: '1px solid rgba(83, 80, 63, 1)',
            fontSize: '12px'
          }}
          clickable
        />
      ))}
    </Box>
  </Box>
  }
  brandDiv(){
    return (this.state.activeTab=="Buying Requests"||this.state.activeTab=="Wish Requests"|| this.state.activeTab=="Products")&&<Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Brand</Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>

          {this.state.brandsList?.map((subCat: any,index:any) => (
            <Chip
            data-test-id={`brandChip${index}`}
            onClick={() => this.handleBrandSelect(subCat.id)}
            data-testid="subcategoryTestId"
            clickable
            label={subCat.name}
            key={subCat.id}
            className="montserratFont"
              sx={{
                backgroundColor: this.state.selectedBrandList.includes(subCat.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
                color: this.state.selectedBrandList.includes(subCat.id) ? '#FFFFFF' : '#333',
                borderRadius: '4px',
                fontWeight: this.state.selectedBrandList.includes(subCat.id) ? 600 : 400,
                padding: '10px 16px',
                height: '40px',
                border: '1px solid rgba(83, 80, 63, 1)',
                fontSize: '12px'
              }}
            />
          ))}
        </Box>
      </Box>
  }
  UseDiv(){
    return this.state.activeTab=="Buying Requests" &&  <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>use</Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>

          {[{name:"New",id:"1"},{name:"Second Hand",id:"2"}].map((subCat: any,index:any) => (
            <Chip
            data-test-id={`useChip${index}`}
            onClick={() => this.handleUseFilterSelect(subCat.id)}
            data-testid="subcategoryTestId"
            clickable
            label={subCat.name}
            key={subCat.id}
            className="montserratFont"
              sx={{
                backgroundColor: this.state.selectedUseFilterList.includes(subCat.id) ? '#70624D' : 'rgba(255, 255, 255, 1)',
                color: this.state.selectedUseFilterList.includes(subCat.id) ? '#FFFFFF' : '#333',
                borderRadius: '4px',
                fontWeight: this.state.selectedUseFilterList.includes(subCat.id) ? 600 : 400,
                padding: '10px 16px',
                height: '40px',
                border: '1px solid rgba(83, 80, 63, 1)',
                fontSize: '12px'
              }}
            />
          ))}
        </Box>
      </Box>
  }
  StatusDiv(){
    const getStatus = (label: string | undefined): string => {
      if (label === "in_draft") {
        return "Draft";
      }
      if (label) {
        return label.charAt(0).toUpperCase() + label.slice(1);
      }
      return "";
    };
    return this.state.activeTab=="Products" &&  <Box sx={{ marginBottom: '15px' }}>
    <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Status</Typography>
    <Box display="flex" flexWrap="wrap" gap={1}>

      {this.state.statusList?.map((status: any,index:any) => (
        <Chip
        data-test-id={`statusChip${index}`}
        onClick={() => this.handleStatusFilterSelect(status.name||status.status)}
        data-testid="subcategoryTestId"
        clickable
        label={getStatus(status.name)||getStatus(status.status)}
        key={status.id}
        className="montserratFont"
          sx={{
            backgroundColor: this.state.selectedStatusFilterList.includes(status.name||status.status) ? '#70624D' : 'rgba(255, 255, 255, 1)',
            color: this.state.selectedStatusFilterList.includes(status.name||status.status) ? '#FFFFFF' : '#333',
            borderRadius: '4px',
            fontWeight: this.state.selectedStatusFilterList.includes(status.name||status.status) ? 600 : 400,
            padding: '10px 16px',
            height: '40px',
            border: '1px solid rgba(83, 80, 63, 1)',
            fontSize: '12px'
          }}
        />
      ))}
    </Box>
  </Box>
  }
  dateCss=()=>{
    return   <style>
    {`
      .custom-input {
        width: 100%;
        height:56px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        z-index:99999999999999 !important
      }
        .custom-input::placeholder {
        color: #53503F;
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
      }
        .react-datepicker{
    position:absolute !important;
    right: -290px !important;
}
      
      /* 📅 Entire Datepicker */

      .react-datepicker-popper {
  z-index: 99999 !important; /* ✅ Ensures calendar is on top */
}


.react-datepicker {
  position: relative !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius:4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index:99999999999999999 !important
  text-align:center
}


/* 📌 Header (Month & Year) */
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding: 10px;
  text-align: center;
}

/* 📅 Month & Year Title */
.react-datepicker__current-month {
  font-size: 13px;
  font-weight: 700;
  color: #53503F;
}

/* 📅 Weekday Names (Mon, Tue, etc.) */
.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  font-weight: 400;
  color: #53503F;
  font-size: 13px;
}

/* 🗓️ Days (Normal State) */
.react-datepicker__day {
  width: 36px;
  line-height: 36px;
  text-align: center;
  font-size:12px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  color: #53503F;
}
.react-datepicker__month-container .react-datepicker__week .react-datepicker__day:hover {
  background-color: #53503F !important;
  color: white !important;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* 🏆 Selected Day */
.react-datepicker__day--selected {
  background-color: #53503F !important;
  color: white !important;
  font-weight: bold;
}

/* 🔄 Hover Effect on Days */
.react-datepicker__day:hover {
  background-color: #ddd !important;
  color: black !important;
  height:36px;
  width:36px;
  border-radius:50%;
}

/* 🚫 Disabled Days */
.react-datepicker__day--disabled {
  color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

/* 📆 Today Highlight */
.react-datepicker__day--today {
  border: 1px solid #53503F;
  font-weight: bold;
  color: #53503F;
  background:white
}

/* ⏭️ Next & Previous Month Arrows */
.react-datepicker__navigation {
  top: 10px;
  color:#53503F
}

.react-datepicker__navigation--previous {
  left: 10px;
  color:#53503F
}

.react-datepicker__navigation--next {
  right: 10px;
  color:#53503F

}

/* 🔲 Calendar Popper (Dropdown) */
.react-datepicker-popper {
  z-index: 1000;
}

/* 📦 Calendar Container */
.react-datepicker__month-container {
  padding: 10px;
}

/* 📅 Month Dropdown (if used) */
.react-datepicker__month-select,
.react-datepicker__year-select {
  font-size: 14px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* 🕛 Time Picker (if used) */
.react-datepicker__time-container {
  border-left: 1px solid #ccc;
}
    `}
  </style>
  }
  DateDiv(label:string){
    return (this.state.activeTab=="Buying Requests"|| this.state.activeTab=="Wish Requests"|| this.state.activeTab=="Orders"|| this.state.activeTab=="Offers")&&    <div className="date" style={{marginTop:"15px"}}>
    <span>{label}</span>
  <div  style={{display:"flex",gap:"10px",marginTop:"7px"}}>  
    <div>
<span style={{color:"rgba(83, 80, 63, 1)"}} className="montserratMediumGoogle">From</span>
      <div style={{position: "relative", display: "inline-block", }}>
      <DatePicker
      data-test-id="fromDate" 
      data-testid="dateOfBirthTestId"
  placeholderText="MM/DD/YYYY"
  dateFormat="dd-MM-yyyy"
  className="custom-input"
  popperPlacement={placement as any}
  onChange={(newValue: any) => this.handleFromDate(newValue ? new Date(newValue) : null)}
  formatWeekDay={(day: any) => day.substr(0, 3)}
  showPopperArrow={false}
  selected={this.state.fromDateFilter}
  portalId="root"
  popperModifiers={DatePickerCss}
/>

                <img data-test-id="todateIcon"
                src={calenderIcon} alt='data'  style={webStyle.calenderImageCss as  React.CSSProperties}/> 
      </div>

 {this.dateCss()}
    </div>

    <div>
      <span style={{color:"rgba(83, 80, 63, 1)"}} className="montserratMediumGoogle">To</span>
      <div style={{position: "relative", display: "inline-block", }}>
        <DatePicker
         data-test-id="toDate"
         popperModifiers={DatePickerCss}

          showPopperArrow={false}
          onChange={(newValue:any) => this.handleToDate(newValue ? new Date(newValue) : null)}
          dateFormat="dd-MM-yyyy"
          selected={this.state.toDateFilter} 
          portalId="root"
          formatWeekDay={(day:any) => day.substr(0, 3)}
          placeholderText="MM/DD/YYYY"
          className="custom-input"
          popperPlacement={placement as any}
        />

                <img data-test-id="todateIcon"
                src={calenderIcon} alt='data'  style={webStyle.calenderImageCss as  React.CSSProperties}/> 
      </div>

 {this.dateCss()}
      </div></div>
  </div>
  }
  WishRequestAssignedDateDiv(){
    return (this.state.activeTab=="Wish Requests")&&
    <div className="date" style={{marginTop:"15px"}}>
    <span>{"Assign Date"}</span>
    <div  style={{display:"flex",gap:"10px",marginTop:"7px"}}>  
    <div>
<span style={{color:"rgba(83, 80, 63, 1)"}} className="montserratMediumGoogle">From</span>
        <div style={{position: "relative", display: "inline-block", }}>
        <DatePicker
          popperModifiers={DatePickerCss}
          data-test-id="fromAssignDate"
          selected={this.state.fromAssignDateFilter}
          onChange={(newValue:any) => this.handleFromAssignDate(newValue ? new Date(newValue) : null)}
          showPopperArrow={false}
          dateFormat="dd-MM-yyyy"
          placeholderText="MM/DD/YYYY"
          popperPlacement={placement as any}
          className="custom-input"
          formatWeekDay={(day:any) => day.substr(0, 3)}
          portalId="root"      
        />

                <img data-test-id="todateIcon"
                src={calenderIcon} alt='data'  style={webStyle.calenderImageCss as  React.CSSProperties}/> 
      </div>

 {this.dateCss()}
    </div>

    <div>
      <span 
      className="montserratMediumGoogle"
      style={{color:"rgba(83, 80, 63, 1)"}} 
      >To</span>
      <div style={{position: "relative", display: "inline-block", }}>
        <DatePicker
          data-test-id="toAssignDate"
          selected={this.state.toAssignDateFilter}
          onChange={(newValue:any) => this.handleToAssignDate(newValue ? new Date(newValue) : null)}
          dateFormat="dd-MM-yyyy"
          placeholderText="MM/DD/YYYY"
          className="custom-input"
          showPopperArrow={false}
          popperPlacement={placement as any}
          formatWeekDay={(day:any) => day.substr(0, 3)}
          portalId="root"
          popperModifiers={DatePickerCss}
        />

               <img data-test-id="todateIcon"
                src={calenderIcon} alt='data'  style={webStyle.calenderImageCss as  React.CSSProperties}/> 
      </div>

 {this.dateCss()}
      </div></div>
  </div>
  }
  confirmationModal=()=>{
    return (
        <Box  data-test-id="confirmationModal">
            <Modal
                open={this.state.openConfirmationModal}
                onClose={this.handleCloseConfirmationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-test-id='confirmModal'
            >
                <RemoveCartModalBox >
                    <Box data-test-id='crossIconConfirmModal' className="crossIconBox" onClick={this.handleCloseConfirmationModal}>
                       <CloseIcon/>
                    </Box>
                    <Typography style={webStyle.confirmation}>Confirmation</Typography>
                       <Typography className="areYouSureText">Are you sure you want to show
                       products?</Typography>
                    <CancelRemoveButtonBox>
                    <CancelButton data-test-id='NotNowBtn' onClick={this.handleCloseConfirmationModal} variant="contained" >Not now</CancelButton>
                    <RemoveButton data-test-id='yesBtn' onClick={this.handleConfirmationModal} variant="contained" >Yes</RemoveButton>
                    </CancelRemoveButtonBox>
                </RemoveCartModalBox>
            </Modal>
        </Box>
    )
}
tabsList=()=>{
  return   <div className="tabs" style={{marginTop:"35px",display:"flex",gap:"25px",alignItems:"center"}}>
  {this.state.tabsList?.map((ele: string, index: any) => (
<span
key={index}
className="tabList"
data-test-id={`tabList${index}`}
onClick={() => this.handleTabChange(ele)}
style={{
paddingBottom: "16px",
cursor: "pointer",
position: "relative",
color:"#53503F",
fontWeight:ele===this.state.activeTab?600:400
}}
>
{ele}
{ele === this.state.activeTab && (
<span
style={{
content: "''",
position: "absolute",
bottom: "0",
left: "0",
width: "100%",
height: "5px",
backgroundColor: "rgba(83, 80, 63, 1)",
borderRadius: "6px",
}}
/>
)}
</span>
))}

  </div>
}
subtabList=()=>{
  return       <div className="tabs" style={{borderBottom:"1px solid rgba(83, 80, 63, 0.5)",width:"fit-content",marginTop:"35px",display:"flex",gap:"25px",alignItems:"center"}}>
  {this.state.subTabsList[this.state.activeTab]?.map((ele: any, index: any) => {
let status = ""; // Define status with a default empty value

if (typeof ele === "string") {
[status] = ele?.split(" "); // Extract status if ele is a string
}
const count = this.state.productCountData[status] || 0; // Use status safely
return (
<span
data-test-id={`subTab${index}`}
onClick={() => this.handleSubTabChange(ele)}
style={{
paddingBottom: "16px",
cursor: "pointer",
display: "flex",
justifyContent: "center",
alignItems: "center",
position: "relative",
color:"#53503F",
fontWeight:ele===this.state.subTab?600:400
}}
>
{ele} {`(${count})`}
{ele === this.state.subTab && (
<span
style={{
content: "''",
position: "absolute",
bottom: "0",
left: "0",
width: "100%",
height: "5px",
backgroundColor: "rgba(83, 80, 63, 1)",
borderRadius: "6px",
}}
/>
)}
</span>
);
})}

</div>
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ContainerBox style={{backgroundColor: "#EDE6DC",width:"100%",boxSizing:"border-box"}}>
        <ShopperHeader/>
        <div className="mainContent" style={{padding:"0px 40px",boxSizing:"border-box",marginTop:"40px"}}>
            <div style={{display:"flex",width:"100%",justifyContent:"space-between",marginBottom:"15.43px"}}>
                <span style={{...webStyle.overviewText} as  React.CSSProperties}>Overview</span>
                <ReactSelect 
                  data-test-id="daySelectID"
                   value={this.state.selectedDayFilter}
                   onChange={(e:any)=>this.handleDaySelect(e)}
                   styles={{
                  
                    singleValue: (provided: any) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }),
                    control: (provided: any, state: any) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      border: "1px solid #53503F",
                      ":hover": {
                        border: "1px solid #53503F",
                      },
                      borderColor: "#53503F",
                      boxShadow: "0 0 0 0.5px #53503F",
                    }),
                    placeholder: (provided: any) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }),
                  }}
                  
                    classNamePrefix="react-select" 
                    components={{ IndicatorSeparator:null }} 
                    options={[{value:"today",label:"Today"},{value:"weekly",label:"Weekly"},{value:"monthly",label:"Monthly"}]} 
                  />
            </div>
            <div className="overviewSection" style={{gap:"22px",display:"flex",boxSizing:"border-box",maxHeight:"22.43rem",height:"100%"}}>
            <div
  className="smallBoxes"
  style={{
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    width: "41%",
    justifyContent: "space-between",
  }}
>
  {this.state.overviewData?.overview?.map((ele: any) => {
    return (
      <div
        style={{
          border:"1px solid #53503F",
          padding: "20px 20px 0px 20px",
          width: "calc(50% - 11px)",
          boxSizing: "border-box",
          height: "10.5rem",
          borderRadius: "4px",
          background: "rgba(255, 255, 255, 1)",
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <span style={webStyle.bigText as React.CSSProperties}>
              {ele.total_count}
            </span>
            <span style={webStyle.desc as React.CSSProperties}>{ele.name}</span>
          </div>
          
          <div
            style={{
              alignItems: "center",
              width: "52px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
              background: "rgba(239, 238, 233, 1)",
            }}
          >
            <img src={overviewImages[ele.name  as keyof typeof overviewImages]||""} alt="" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            marginTop: "15px",
          }}
        >
          {ele.growth_status !== "nothing" && (
            <img
              height={20}
              width={20}
              src={
                ele.growth_status === "increased"
                  ? upArrowIcon
                  : downArrowIcon
              }
              alt={"arrowIcon"}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            <span
              style={{
                ...webStyle.bigText,
                fontSize: "12px",
                lineHeight: "14.63px",
              } as React.CSSProperties}
            >
              {ele.growth_percentage}
            </span>

            <span style={webStyle.monthText as React.CSSProperties}>
              {ele.message}
            </span>
          </div>
        </div>
      </div>
    );
  })}
</div>

    <div className="bigOverviewBox" style={{color:"rgba(83, 80, 63, 1)",flexDirection:"column",paddingLeft:"31px",boxSizing:"border-box",background:"rgba(255, 255, 255, 1)",borderRadius:"4px",display:"flex",flexGrow:1,height:"100%",border:"1px solid rgba(83, 80, 63, 1)"}}>
                  <span style={{...webStyle.bigText,fontSize:"18px",lineHeight:"22px",marginTop:"25px"}as React.CSSProperties}>{this.state.overviewData?.activity_board?.heading_message}</span>
                <div>
                  <ul style={{marginLeft:"-21px",marginTop:"24px",display:"flex",flexDirection:"column",gap:"50px"}}>
                    <li> <div style={{display:"flex",gap:"35px",alignItems:"center"}}>
                      <span style={{marginLeft:"40px",width:"180px",fontWeight:400,lineHeight:"14px",fontSize:"18px"}}>
                      Orders
                      </span>
                      <Box sx={{ width: "18.75rem" }}>
                      <LinearProgress
                          variant="determinate"
                          value={this.state.overviewData?.activity_board?.orders_count}
                          sx={{
                            height: "8px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "rgba(83, 80, 63, 1)",
                              borderRadius: "4px",
                            },
                          }}
                      />
                      </Box>
                      <span style={{fontWeight:600,fontSize:"25px",lineHeight:"18px"}}>{this.state.overviewData?.activity_board?.orders_count}</span>
                      </div></li>
                      <li> <div style={{display:"flex",gap:"35px",alignItems:"center"}}>
                      <span style={{marginLeft:"40px",width:"180px"}}>
                      Return Orders
                      </span>
                      <Box sx={{ width: "18.75rem" }}>
                      <LinearProgress
                          variant="determinate"
                          value={this.state.overviewData?.activity_board?.return_orders_count}
                          sx={{
                            height: "8px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "rgba(83, 80, 63, 1)",
                              borderRadius: "4px",
                            },
                          }}
                      />
                      </Box>
                      <span style={{fontWeight:600,fontSize:"25px",lineHeight:"18px"}}>{this.state.overviewData?.activity_board?.return_orders_count}</span>
                      </div></li>
                       <li> <div style={{display:"flex",gap:"35px",alignItems:"center"}}>
                      <span style={{marginLeft:"40px",width:"180px"}}>
                      Cancel Orders
                      </span>
                      <Box sx={{ width: "18.75rem" }}>
                      <LinearProgress
                          variant="determinate"
                          value={this.state.overviewData?.activity_board?.cancel_orders_count}
                          sx={{
                            height: "8px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "rgba(83, 80, 63, 1)",
                              borderRadius: "4px",
                            },
                          }}
                      />
                      </Box>
                      <span style={{fontWeight:600,fontSize:"25px",lineHeight:"18px"}}>{this.state.overviewData?.activity_board?.cancel_orders_count}</span>
                      </div></li>
                       <li> <div style={{display:"flex",gap:"35px",alignItems:"center"}}>
                      <span style={{marginLeft:"40px",width:"180px"}}>
                      Delivered Orders
                      </span>
                      <Box sx={{ width: "18.75rem" }}>
                      <LinearProgress
                          variant="determinate"
                          value={this.state.overviewData?.activity_board?.delivered_orders_count}
                          sx={{
                            height: "8px",
                            backgroundColor: "#E0E0E0",
                            borderRadius: "4px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "rgba(83, 80, 63, 1)",
                              borderRadius: "4px",
                            },
                          }}
                      />
                      </Box>
                      <span style={{fontWeight:600,fontSize:"25px",lineHeight:"18px"}}>{this.state.overviewData?.activity_board?.delivered_orders_count}</span>
                      </div></li>
                  </ul>
                </div>
              </div>
            </div>
            
          {this.tabsList()}
          <hr style={{marginTop:"0px",width:"100%",backgroundColor:"rgba(83, 80, 63, 0.5)",border:"none",height:"1px"}}/>
           
           {this.subtabList()}
           
            <div className="filterAndSortOptions" style={{marginTop:"30px",display:"flex",height:"44px",justifyContent:"space-between"}}>
                  <div className="search" style={{display:"flex",gap:"20px"}}>
                    <div data-test-id="filterBtn" onClick={this.handleFilter} style={{cursor:"pointer",display:"flex",borderRadius:"4px",justifyContent:"center",width:"80px",alignItems:"center",height:"100%",background:"rgba(83, 80, 63, 1)",color:"rgba(255, 255, 255, 1)"}}>
                   <FilterListIcon style={{fontSize:"20px",marginRight:"8px"}}/>
                    Filter
                    </div>
                  <MyTextField
                  style={{margin:"0px"}}
              data-test-id="inputFirstNameSearchText"
              placeholder="Search.."
              value={this.state.searchQuery}
              onChange={(e:any)=>this.handlesearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{marginLeft:"-10px"}}>
                    <IconWrapper>
                      <StyledSearchIcon />
                    </IconWrapper>
                  </InputAdornment>
                ),
              }}
            />
                  </div>
                  <div className="sort" style={{display:"flex",gap:"24px"}}>
                  {(this.state.activeTab=="Products" && this.state.selectedIds.length>0)&&  
                  <div className="deleteAndHide" style={{display:"flex",gap:"24px"}}>
                    <div data-test-id="deleteButton"  onClick={this.handleDeleteProductsModal} style={{fontWeight:600,gap:"3px",padding:"0px 10px",cursor:"pointer",display:"flex",borderRadius:"4px",justifyContent:"center",alignItems:"center",height:"100%",background:"rgba(239, 238, 233, 1)",color:"rgba(83, 80, 63, 1)"}}>
                   <DeleteOutlineOutlinedIcon/> Delete
                    </div>
                  {this.state.subTab!=="Hidden Products"? <div onClick={this.handleHideModal} data-test-id="hideButtn" style={{fontWeight:600,gap:"3px",padding:"0px 10px",cursor:"pointer",display:"flex",borderRadius:"4px",justifyContent:"center",alignItems:"center",height:"100%",background:"rgba(239, 238, 233, 1)",color:"rgba(83, 80, 63, 1)"}}>
                   <VisibilityOffOutlinedIcon/> Hide
                    </div>:<div onClick={this.handleCloseConfirmationModal} data-test-id="hideButtn" style={{fontWeight:600,gap:"3px",padding:"0px 10px",cursor:"pointer",display:"flex",borderRadius:"4px",justifyContent:"center",alignItems:"center",height:"100%",background:"rgba(239, 238, 233, 1)",color:"rgba(83, 80, 63, 1)"}}>
                   <VisibilityOutlinedIcon/> Show
                    </div>}
                    </div>}
                      <ReactSelect
                        data-test-id="SortSelect"
                        value={this.state.selectedSortFilter}
                        onChange={(data:any) =>this.handleSortOptionChange(data)}
                        styles={{
                          singleValue: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:"center",
                            height: '100%',
                          }),
                          control: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            height: '44px',
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            boxShadow: 'none',
                            width:"230px",
                            ':hover': {
                              borderColor: '#aaa',
                            },
                          }),
                          placeholder: (provided: any) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }),
                          dropdownIndicator: (provided: any) => ({
                            ...provided,
                            padding: '0 8px',
                            color:"rgba(83, 80, 63, 1)"
                          }),
                        }}
                        classNamePrefix="react-select"
                        components={{ IndicatorSeparator: null }}
                        options={configJSON.sortingOptions[this.state.activeTab]}
                      />
                  </div>
            </div>
            {this.state.openFilter &&(
        <Backdrop
          open={true}
          onClick={this.handleFilter}
          sx={{
            zIndex: 1299,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        />
      )}
       <Slide direction="right" in={this.state.openFilter} mountOnEnter unmountOnExit>
        <Box
        data-test-id="sliderDiv"
          sx={{
            overflowY:"hidden",
            position: "fixed",
            top: 0,
            left: 0,
            width: "400px",
            height: "100%",
            boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
            zIndex: 1300,
            display: "flex",
            flexDirection: "column",
            alignItems:"center",
            justifyContent:"space-between",
            borderTopRightRadius: "25px", 
            borderBottomRightRadius: "25px",
            padding:"14px",
            boxSizing:"border-box",
            background:"rgba(239, 238, 233, 1)"
          }}
        >
          <Box sx={{overflowX:"auto",
              marginRight:"-12px",
              paddingRight:"12px",
              height:"100%",
              width:"100%",
              position:"relative",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0", // Track color
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color
                borderRadius: "4px",
                height:"10px"
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(83, 80, 63, 1)", // Thumb color on hover
              },
          }}>

    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"9px 0px",width:"100%"}}>
         <Typography 
         className="montserratFont" 
         variant="h6" 
         sx={{ color: 'rgba(83, 80, 63, 1)',
         fontWeight: 600, fontSize: '16px'}}>
            Filters
          </Typography>
          <Button
            color="error"
            className="montserratFont"
            data-testid="clearBtnTestId"
            onClick={this.handleCancelFilter}
            sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 61, 61, 1)', textTransform: 'capitalize',":hover":{background:"transparent"} }}
          >
            Clear All
          </Button>
         </div>
         <hr style={{marginTop:"0px",width:"100%",backgroundColor:"rgba(83, 80, 63, 1)",border:"none",height:"1px"}} />
         
      {this.priceRange(configJSON.priceRangesLabels[this.state.activeTab])}
      {this.state.activeTab ==="Offers"&&this.offerRange()}
      {this.categoryDiv()}
      {this.brandDiv()}
      {this.UseDiv()}
      {this.StatusDiv()}
      {this.DateDiv(configJSON.filterDateLabels[this.state.activeTab])}
     {this.WishRequestAssignedDateDiv()}
   
            </Box>
            <div style={{
              marginTop:"30px",
              width:"100%",
              padding:"0px 23px",
              boxSizing:"border-box",
              display:"flex",
              position:"relative",
              bottom:"25px",
              justifyContent:"space-between",
              }}>
            <FooterButton data-test-id="cancelBtnTest"  style={{fontSize: '18px',fontWeight:600,backgroundColor:"#EFEEE9",color:"#53503F",border:"1px solid #53503F"}} onClick={this.handleCancelFilter}>Cancel</FooterButton>
            <FooterButton data-test-id="applyBtnTest" style={{fontSize: '18px',backgroundColor:"#53503F"}}  onClick={this.handleApplyBtn}>Apply</FooterButton>
            </div>
        </Box>
      </Slide>
      {this.confirmationModal()}
      {this.state.openHideModal &&<HideProductModal
      handleConfirmHidePeriod={this.handleConfirmHidePeriod}
      handlePeriodChange={this.handlePeriodChange}
      hidePeriod={this.state.hidePeriod}
      openHideModal={this.state.openHideModal} 
      handleClose={this.handleHideModal}/>}
      {this.state.deleteOrderModal && <AddCartConfirmationPopUp handleClose={this.handleDeleteProductsModal} question={"Are you sure you want to delete the product?"} handleYes={this.handleConfirmDeleteProducts} open={this.state.deleteOrderModal} />}
 
      {this.state.allCountZero && (
  <EmptyBagMainBox>
    <Box className="emptyImageText">
      <img className="bagIcon" src={noProductIcon} alt="" />
      <Box className="emptyTextBox">
        <Typography className="emptyText">You haven’t added any products yet</Typography>
        <Typography className="emptyText2">
          Please start selling an item to see the activity on your dashboard
        </Typography>
        <Button
          data-test-id="addProductTestId"
          className="startShopping"
          onClick={() => this.navigationToAnyPage("Catalogue")}
          variant="contained"
        >
          Add Product
        </Button>
      </Box>
    </Box>
  </EmptyBagMainBox>
)}

{!this.state.allCountZero && (
  <div style={{ marginBottom: "60px" }}>
    <DashboradTable
      handleDetailsNavigation={this.handleDetailsNavigation}
      handleNormalAcceptRejectClick={this.handleNormalAcceptRejectClick}
      data-test-id="tabelTest"
      handleAcceptRejectClick={this.handleAcceptRejectClick}
      buyingRequestsList={this.state.buyingRequestsList}
      handleAccordionClick={this.handleAccordionClick}
      handleChangePage={this.handleChangePage}
      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
      totalPages={this.state.metadata?.total_pages}
      rowsPerPage={this.state.perPage}
      page={this.state.page - 1}
      data={this.getFilteredData()}
      tabName={this.state.activeTab}
      subTabName={this.state.subTab}
      selectedIds={this.state.selectedIds}
      onCheckboxChange={this.handleCheckboxChange}
      getSingleProductId={this.getSingleProductId}
    />
  </div>
)}

        </div> 
        <ShopperFooter/>
      </ContainerBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DatePickerCss=[
  {
    name: "preventOverflow",
    fn: (state:any) => state,
    options: {
      boundary: "window",
      altAxis: true,
    },
  },
  {
    name: "flip",
    fn: (state:any) => state,
    options: {
      fallbackPlacements: ["top", "bottom", "right", "left"], // Allow dynamic positioning
    },
  },
  {
    name: "offset",
    fn: (state:any) => state,
    options: {
      offset: [10, 10], // Adjust spacing
    },
  },
  {
    name: "applyPlacement", // Custom placement modifier
    fn: (state:any) => {
      state.placement = "top-end"; // Manually set placement
      return state;
    },
  },
]
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      // margin: "20px 0px",
    },
  },
});
export const RemoveCartModalBox=styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.89)',
  width: '600px',
  borderRadius: '4px',
  padding: '68px 49px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#EFEEE9',
  height: '340px',
  justifyContent: 'center',
  maxHeight: '340px',
  alignItems: 'center',
  
  "& .areYouSureText": {
      fontSize: '20px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      color: '#53503F',
      marginTop:'8px',
      maxWidth:'340px',
      textAlign:"center"
  },
  "& .crossIconBox": {
      position: 'absolute',
      right: '28px',
      top: '28px',
      cursor:'pointer'
  },
  "@media  (max-width:550px)": {
      width: 'unset',
  },
  '@media (max-width:400px )': {
      padding: '68px 25px',

  },
  '@media (max-width:362px )': {
      maxHeight: 'unset',
      height: 'unset',
  },
  '@media (max-width:359px )': {
      width: '100%',
  },
});
const ContainerBox = styled(Box)({
  backgroundColor: '#EDE6DC',
  boxSizing:"border-box",
  width:"100%",
  // minHeight:"100vh"
});
export const CancelRemoveButtonBox=styled(Box)({
  display:'flex',
  alignItems:'center',
  gap:'50px',
  marginTop:'40px',
  '@media (max-width:420px )': {
      gap:'28.45px',
  },
  '@media (max-width:359px )': {
      flexDirection:'column'
  },
});
const FooterButton = styled("button")({
  width: "155px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "18px",
  cursor: "pointer",
});
const MyTextField = styled(TextField)({
  width: "406px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "4px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
});
const StyledSearchIcon = styled(SearchIcon)({
  width: "17.5px",
  height: "17.5px",
});;
const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  paddingLeft:"-10px",
  backgroundColor: "rgba(237, 230, 220, 0.5)",
});
export const CancelButton=styled(Button)({
  width: '155px',
  border:'1px solid rgba(83, 80, 63, 1)',
  backgroundColor: 'transparent',
  height: '56px',
  borderRadius: '4px',
  textTransform: 'none',
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 600,
  fontSize: '18px',
  cursor: 'pointer',
  color: '#53503F',
  "&:hover": {
      backgroundColor: 'transparent',
  },
});
export const RemoveButton=styled(Button)({
  width: '155px',
  height: '56px',
  backgroundColor: '#53503F',
  textTransform: 'none',
  borderRadius: '4px',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  fontWeight: 600,
  color: '#EDE6DC',
  cursor: 'pointer',
  "&:hover": {
      backgroundColor: '#53503F',
  },
})
const webStyle = {
    confirmation: {
      fontSize: '22px',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      color: '#53503F',
  },
  calenderImageCss:{
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
  overviewText:{
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: "30.48px",
    textAlign: "left",
    color:"rgba(83, 80, 63, 1)"
  },
  bigText:{
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "36.57px",
    textAlign: "left",
    color:"rgba(83, 80, 63, 1)"
  },
  desc:{
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21.94px",
    textAlign: "left",
    color:"rgba(83, 80, 63, 1)"
  },
  monthText:{
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "10px",
    fontWeight: 300,
    marginTop:"3px",
    lineHeight: "12.19px",
    textAlign: "left",
    color:"rgba(83, 80, 63, 1)",
  }
};
// Customizable Area End
