import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { convertToBase64 } from '../../../components/src/commanMethods';
import { getStorageData } from "../../../framework/src/Utilities";
interface SelectType {
  value:string|number;
  label:string|number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal:boolean;
  handleCloseModal:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    showMakeAWish:boolean;
    selectedFiles:any;
    previews:any;
    errorMessage:any;
    openConfirmationModal:boolean;
    successModal:boolean;
    categoryList:SelectType[];
    brandsList:SelectType[];
    YOPList:SelectType[];
    selectedCategory:SelectType|null;
    selectedBrand:SelectType|null;
    selectedYop:SelectType|null;
    productName:string;
    productDesc:string;
    modalNumber:string;
    productAndNameErr:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MakeAWishWebcontroller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCtegoryListApi:string="";
  getBrandListApi:string="";
  getYOPListApi:string="";
  confirmRequestApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showMakeAWish:false,
      selectedFiles:[],
      previews:[],
      errorMessage:"",
      openConfirmationModal:false,
      successModal:false,
      categoryList:[],
      brandsList:[],
      YOPList:[],
      selectedCategory:null,
      selectedBrand:null,
      selectedYop:null,
      productName:"",
      productDesc:"",
      modalNumber:"",
      productAndNameErr:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if(apiRequestCallId==this.getCtegoryListApi){
      this.handleCategoryResponse(responseJson?.data)
    }else if(apiRequestCallId==this.getBrandListApi){
      this.handleBrandsResponse(responseJson?.data)
    }else if(apiRequestCallId==this.getYOPListApi){
      this.handleYOPResponse(responseJson)
    }else if(apiRequestCallId===this.confirmRequestApi){
      if(responseJson.message){
      this.setState({successModal:true,openConfirmationModal:false})
      }
    }
    // Customizable Area End

  }

  componentDidMount = async () => {
    this.getToken();
    // Customizable Area Start
    this.getCategoryLists();
    this.getBrandsLists();
    this.getProductionYearList()
    // Customizable Area End
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
  // Customizable Area Start
  handleChangeSelect=(event:any,state:any)=>{
    this.setState((prevState) => ({ ...prevState, [state]: event}))
  }
  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };
  validateAndSetFiles = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previews: any[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);

        previews.push(URL.createObjectURL(file));
        const base64Image = await convertToBase64(file);
        previews.push(base64Image)
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...validFiles],
      previews: [...this.state.previews, ...previews],
      errorMessage,
    });
  };

  
  handleDragOver = (event:any) => {
    event.preventDefault();
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };
    handleRemoveFile = (index: number) => {
    const { selectedFiles, previews } = this.state;
  
    const newSelectedFiles = selectedFiles.filter((_:any, i:any) => i !== index);
    const newPreviews = previews.filter((_:any, i:any) => i !== index);
  
    this.setState({
      selectedFiles: newSelectedFiles,
      previews: newPreviews,
    });
  };
  closeConfirmationModal=()=>{
    this.setState({openConfirmationModal:false,successModal:true})
  }
  closeSuccessmodal=()=>{
    this.setState({successModal:false,
      productName:"",
      productDesc:"",
      modalNumber:"",
      productAndNameErr:"",
      selectedFiles:[],
      previews:[]
    },()=>{
      this.handleNavigate("WishRequests")
    })
  }
  handleNavigate=(path:string)=>{
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  getCategoryLists=async()=>{
    const token=await getStorageData("loginToken")
    this.getCtegoryListApi=this.apiCall(token,{
      method:"GET",
      endPoint:`/bx_block_categories/categories`,
    })
  }
  handleCategoryResponse=(data:any)=>{
    let updatedCategoryList:any[]=[];
    if (!data || !Array.isArray(data)) {
      throw new Error("Invalid input data");
  }

  updatedCategoryList= data?.map(
    (item:any) => ({
      value: item.attributes.id,
      label: item.attributes.name
  }));

  this.setState({categoryList:updatedCategoryList})
  }
  getBrandsLists=async()=>{
    const token=await getStorageData("loginToken")
    this.getBrandListApi=this.apiCall(token,{
      method:"GET",
      endPoint:`/bx_block_catalogue/brands`,
    })
  }
  handleBrandsResponse=(data:any)=>{
    let updatedBrandsList:any[]=[];
    if (!data || !Array.isArray(data)) {
      throw new Error("Invalid input data");
  }

  updatedBrandsList= data?.map(
    (item:any) => ({
      value: item.attributes.id,
      label: item.attributes.name
  }));

  this.setState({brandsList:updatedBrandsList})
  }
  handleMakeAWishClick=() => {
    const {productName,productDesc}=this.state
    if(!productName && !productDesc){
      this.setState({openConfirmationModal:false,productAndNameErr:"Product Name and Product Description required"})
    }else{
      this.setState(
        { openConfirmationModal: true },
        this.props.handleCloseModal
      );
    }
  }
  getProductionYearList=async()=>{
    const token=await getStorageData("loginToken")
    this.getYOPListApi=this.apiCall(token,{
      method:"GET",
      endPoint:`/bx_block_request_management/wish_requests/production_year_list`,
    })
  }
  handleYOPResponse=(data:any)=>{
    let updatedYOPList:any[]=[];
    if (!data || !Array.isArray(data?.years)) {
      throw new Error("Invalid input data");
  }
  updatedYOPList= data.years?.map(
    (item:any) => ({
      value: item,
      label: item
  }));

  this.setState({YOPList:updatedYOPList})
  }
  handleConfirmRequest=async()=>{
    const tokenIs= await getStorageData('loginToken');
    const base64Images = []

    for (const file of this.state.selectedFiles) {
      const base64Image = await convertToBase64(file);
      base64Images.push(base64Image);
  }
    const body={
      "wish_request": {
        "category_id": `${this.state.selectedCategory?.value}`,
        "brand_id": `${this.state.selectedBrand?.value}`,
        "production_year": `${this.state.selectedYop?.value}`,
        "name": this.state.productName,
        "description": `this.state.productDesc`,
        "product_model_number": this.state.modalNumber,
        "product_images": base64Images,
      }
    }
    this.confirmRequestApi =this.apiCall(tokenIs,{
      method: "POST",
      endPoint: '/bx_block_request_management/wish_requests/create_wish_request',
      body:body,
    })
  }
  apiCallPOST = async (token:any,data:any) => {
    const { contentType, method, endPoint, body } = data;
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": contentType,
      token: token||"",
    };
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessages.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;

  };

  apiCall = (token: any,payload:any) => {
    let {method,endPoint,body,contentType,type}=payload
    const header = {
      'Content-Type':contentType|| 'application/json',
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleProductNameChange=(event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({productName: event.target.value})
  }
  handleProductDescChange=(event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const wordCount = this.countWords(value);

    if (wordCount <= 20) {
      this.setState({productDesc: event.target.value})
    }
  }
  countWords = (text: string) => {
    return text.trim().split(/\s+/).filter((word) => word.length > 0).length;
  };
  handleProductModalChange=(event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({modalNumber: event.target.value})
  }
  // Customizable Area End
}
