export const downArrow = require("../assets/downArrow.png").default;
export const searchIcon = require("../assets/searchIcon.png").default;
export const checkBox = require("../assets/checkbox_.png").default;
export const checkedBox = require("../assets/checked.png").default;
export const addIcon = require("../assets/addIcon.png").default;
export const editIcon = require("../assets/editIcon.png").default;
export const deleteIcon = require("../assets/deleteIcon.png").default;
export const uploadIcon = require("../assets/upload.png").default;
export const crossIcon = require("../assets/cross.png").default;
export const closeIcon = require("../assets/image_close.png").default;
export const closeIcon2 = require("../assets/button_icon(1).png").default;
export const plusIcon = require("../assets/plusIcon.png").default;
export const subtractIcon = require("../assets/subtract.png").default;