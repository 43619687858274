import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";
import { isValidPhoneNumber } from "react-phone-number-input";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
export interface CategoriesArrayInterface {

  id?: number,
  type?: string,
  attributes?: {
    id?: number,
    name?: string,
    rank?: string | null,
    created_at?: string,
    updated_at?: string,
    brands?: string | null,
    logo?: string
  }

}

export interface ProFileInterface {
  id: number|null,
  type: string,
  attributes: {
      avatar_url: string
      country_code: string
      country_name: string
      date_of_birth: string
      deactivated_at: string
      email: string
      first_name: string
      full_phone_number: string
      gender: string
      is_deactivated: boolean
      last_name: string
      phone_number: string
      role_name: string
      unique_auth_id: string
      commercial_number?:string
      address?:string
      postal_code?:string
      city?:string
      commercial_documents_list?:[{url:string,blob_key:string,id:number}]
  }
}
export interface QueryInterface {
  value?: number | string,
  label?: string,
}
// Customizable Area End
interface S {
  // Customizable Area Start
  name: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;

  profileData:ProFileInterface
  first_Name: string
  last_Name: string
  email: string
  emailErr:boolean
  phone_Number: string
  new_phone_numberErr:boolean
  new_phone_numberErrText:string
  phoneNumber:string
 
  successPopupOpen:boolean;
  successMessage:string;


  cartData:[]
  shopperProfileUpdatedModal:boolean;

  message:string
  messageErr:boolean
  query:{value:string,lable:string}|null
  openLoginModal:boolean
  showCategoryPage:boolean
  categoryId:number|string
  categoryName:string
  categoriesArrayData: CategoriesArrayInterface[]
  userRole:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  profileApi?:string
  postContactUsApiCallId?:string
  cartDataApiCallId?:string
  categoryFilterApi?:string
  categoriesArrayDataApi?:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,

      profileData: {
        id: null,
        type: '',
        attributes: {
          avatar_url: '',
          country_code: '',
          country_name: '',
          date_of_birth: '',
          deactivated_at: '',
          email: '',
          first_name: '',
          full_phone_number: '',
          gender: '',
          is_deactivated: false,
          last_name: '',
          phone_number: '',
          role_name: '',
          unique_auth_id: '',
        }
      },
      first_Name: '',
      last_Name: '',
      email: '',
      emailErr: false,
      phone_Number: '',
      phoneNumber: '',
      new_phone_numberErr: false,
      new_phone_numberErrText: '',
      successPopupOpen: false,
      successMessage: '',
      cartData: [],
      shopperProfileUpdatedModal: false,
      message: '',
      messageErr: false,
      query: null,
      openLoginModal:false,
      showCategoryPage:false,
      categoryId:'',
      categoryName:'',
      categoriesArrayData:[],
      userRole:''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.handleFetchProfile()
    this.handleFetchCartData()
    this.getcategoriesArrayData()
    this.handleGetUser()
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // Customizable Area End
  }
 // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
// Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        switch (apiRequestCallId) {
          case this.postContactUsApiCallId: this.setState({ successPopupOpen: true, successMessage: responseJson?.meta?.message })
          break;
          case this.profileApi:
            this.setState({
              first_Name: responseJson?.data?.attributes?.first_name,
              last_Name: responseJson?.data?.attributes?.last_name,
              phoneNumber: this.handlePhoneNumberAutoFill(responseJson?.data?.attributes?.full_phone_number),
              email: responseJson?.data?.attributes?.email,
              profileData: responseJson?.data,
            })
            break;
          case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items })
            break;
          case this.categoriesArrayDataApi: this.setState({ categoriesArrayData: responseJson?.data })
            break;
        }
       
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };



  handleFetchProfile = async ()=> {
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      ...(tokenIs&&{token: tokenIs})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileEndPointApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangePhoneNumber = (event: string | undefined) => {
    if (event) {
      this.setState({ phoneNumber: event,new_phone_numberErrText:'', new_phone_numberErr: !isValidPhoneNumber(event) })
    }
  }
  handleChangeSelect = (event: QueryInterface | {} | undefined | null, state: string) => {
    this.setState((prevState) => ({ ...prevState, [state]: event}))
  }
  handleChangeInput = (value: string, state: string) => {
   
    if(state=='email'){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
     if(emailRegex.test(value)){
      this.setState((prevState) => ({ ...prevState, [state]: value,emailErr:false}))
    }else{
       this.setState((prevState) => ({ ...prevState, [state]: value,emailErr:true }))
     }
    }else{
      this.setState((prevState) => ({ ...prevState, [state]: value }))
    }
  }
  handleChangeMultiLineInput = (value: string, state: string) => {
    let wordsIs = value?.replace(/\s+/g, " ")
    if (wordsIs.length <= 200) {
      this.setState((prevState) => ({ ...prevState, [state]: value }))
    }
  }

  handleDisable = () => {
    const first_Name = this.state.first_Name?.trim() == ''
    const last_name = this.state.last_Name?.trim() == ''
    const email = this.state.email?.trim() == ''||this.state.emailErr
    const phoneNumber = this.state.phoneNumber == ''||this.state.new_phone_numberErr
    const message = this.state.message?.trim() == ''

    if (first_Name || last_name  || email || phoneNumber || message) {
      return true
    } else {
      return false
    }
  }
  handlePhoneNumberAutoFill=(phoneNumber:string|null)=>{
    if(phoneNumber ==''||phoneNumber ==null){
        return ''
    }else{
      return `+${phoneNumber}`
    }
     
  }

  handleNavigate=(path:string)=>{
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  handleSendMessage=async()=>{
    const tokenIs= await getStorageData('loginToken')
    const body={
      "data": {
        "first_name":this.state.first_Name ,
        "last_name": this.state.last_Name,
        "email":this.state.email ,
        ...(this.state.phoneNumber!==''&&{"phone_number": this.state.phoneNumber}),
        "message": this.state.message,
        ...(this.state.query?.value!==null&&{"query_type":this.state.query?.value })
      }
    }
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      ...(tokenIs&&{token: tokenIs})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postContactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFetchCartData = async () => {
    const cartId=await getStorageData('cartId')
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      ...(tokenIs&&{token: tokenIs})
     
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartDataApiCallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCart(cartId)
    );
    
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  openLoginModal=()=>{
    this.setState({openLoginModal:true})
  }
  handleCategoryData = (categoryName: string, categoryId: number) => {
    this.props?.navigation?.navigate("CategoriesWeb",{categoryId,categoryName})
  }
  getcategoriesArrayData = () => {
    const requestMessageDataCateg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesArrayDataApi = requestMessageDataCateg.messageId;
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointgetcategoriesArrayData
    );
    runEngine.sendMessage(requestMessageDataCateg.id, requestMessageDataCateg);

  }
  handleGetUser = async () => {
    const loginDetails = await getStorageData("loginDetails",true)
    const parseLoginDetails = loginDetails
    this.setState({ userRole: parseLoginDetails?.attributes?.role_name })
  }
  closeLoginModal = () => {
    this.setState({ openLoginModal: false })
    this.handleGetUser()
  }
  // Customizable Area End
}
