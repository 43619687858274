
import * as React from "react";
// Customizable Area Start
import {
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {rightArrow,rightArrowIcon,deliveryIcon,orderStatusDot} from "./assets"
import { Footer } from "../../../components/src/Footer.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
// Customizable Area End

import { ViewProps, configJSON } from "./OrderManagement";
import { AppHeader } from "../../../components/src/AppHeader.web";

const OrderManagementView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  orders,
  loading,
  openCreateModal,
  isVisibleCreateModal,
  catalogueId,
  setCatalogueId,
  catalogueVariantId,
  setCatalogueVariantId,
  quantity,
  setQuantity,
  onSubmitCreateOrder,
  navigateToOrderDetail,
  openAddItemModalHandler,
  resetCreateOrderModal,
  selectedOrderId,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const {
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
} = useRunEngine();
const { extractNetworkResponse } = useBlockHelpers();
  const navigate=useNavigate();
  const fetchOrderListId = React.useRef("");
  const categoryListId=React.useRef("")
  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];
const [openLogin,setLogingModal]=React.useState(false)
const [ordersList,setOrderList]=React.useState<any[]>([])
const [categoriesArray ,setCategoryArray]=React.useState([])
const [activeTab ,setActiveTab]=React.useState("ONGOING")

  const handleNavigate=()=>{
    navigate("/")
  }
 const openLoginModal=()=>{
  setLogingModal(true)
  }
 const getFormattedTimestamp = (orderData: any,event:string) => {
    const timeline = orderData;
  
    const orderPlaced = timeline?.find((item: any) => item.event === event);
    if (orderPlaced) return moment(orderPlaced.timestamp).format("DD MMM YYYY"); // e.g., "05 Sep 2024"
  
    const doneStatus = timeline?.find((item: any) => item.status === "done");
    if (doneStatus) return moment(doneStatus.timestamp).format("DD MMM YYYY"); // e.g., "18 Aug 2024"
  
    return null; // Return null if no match is found
  };
  const handleCategoryData=(categoryName: any, categoryId: any)=>{
    navigate(`/categories?categoryName=${categoryName}&&categoryId=${categoryId}`)
  }
  const orderBox=(orderData:any)=>{
    return (
      <div
        style={{cursor:"pointer",
          width: "1095px",
          boxSizing: "border-box",
          borderRadius: "4px",
          background: "white",
          height: "312px",
          border: "1px solid rgba(83, 80, 63, 1)",
          padding: "30px",
        }}
        data-testid="orderId"
        onClick={()=>{
          navigate(`/orderdetails?orderId=${orderData.id}`)
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              fontFamily: "montserrat",
              fontWeight: 600,
              fontSize: "22px",
              lineHeight: "19.25px",
              color: "rgba(83, 80, 63, 1)",
            }}
            data-testid="orderNumberTest"
          >
            Order ID: {orderData.attributes.order_number}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "18px",
              fontFamily: "monteserrat",
              color: "rgba(83, 80, 63, 1)",
            }}
          >
            {orderData.attributes?.order_items?.length} Item
          </span>
        </div>

        <div
          style={{ display: "flex", marginTop: "15px", alignItems: "center" }}
        >
          <div
            style={{
              display: "flex",
              boxSizing: "border-box",
              borderRadius: "15px",
              height: "28px",
              padding: "4px 12px",
              width: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgba(83, 80, 63, 1)",
              background:"rgba(239, 238, 233, 1)"
            }}
          >
            <span
              style={{
                color: "rgba(83, 80, 63, 1)",
                fontSize: "14px",
                fontWeight: 600,
                display:"flex",
                gap:"6px",
                alignItems:"center"
              }}
            data-testid="orderStatus"
            >
 <img src={orderStatusDot} alt="status" />
              Order {
               orderData?.attributes?.status=="payment_failed" ? "Payment Failed":
               orderData?.attributes?.status?.charAt(0).toUpperCase() + orderData?.attributes?.status?.slice(1).toLowerCase()
               
              }
            </span>
          </div>
          <span
            style={{
              color: "rgba(83, 80, 63, 1)",
              fontSize: "16px",
              fontWeight: 400,
              marginLeft: "10px",
            }}
          >
            | Order Date: {getFormattedTimestamp(orderData?.attributes?.order_timeline,"Order Placed")}
          </span>
        </div>
        {orderData.attributes?.order_items?.map((ele: any) => (
  <div key={ele.id}>
    <div
      className="infoBox"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
      }}
    >
      <div
        style={{
          gap: "55px",
          height: "120px",
          display: "flex",
          width: "85%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            borderRadius: "4px",
            background: "rgba(237, 230, 220, 1)",
            minWidth: "120px",
            justifyContent: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <img
            alt=""
            height={73}
            src={ele.attributes?.catalogue?.catalogue_variant?.attributes?.image_url}
            width={73}
          />
        </div>
        <div
          style={{
            marginLeft: "-25px",
            gap: "12px",
            flexDirection: "column",
            display: "flex",
            color: "rgba(83, 80, 63, 1)",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: "22px",
              whiteSpace: "nowrap",
              overflow: "hidden", 
              textOverflow: "ellipsis",
              maxWidth:"206px",
            }}
            data-testid="brandName"
          >
            {ele.attributes?.catalogue?.catalogue_name?.trim()||""}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "18px",
              whiteSpace: "nowrap",
              overflow: "hidden", 
              textOverflow: "ellipsis",
              maxWidth:"206px",
            }}
          >
           {ele.attributes?.catalogue?.identity_name}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "18px",
              whiteSpace: "nowrap",
              overflow: "hidden", 
              textOverflow: "ellipsis",
              maxWidth:"206px",
            }}
          >
            Model Number: {ele?.attributes?.catalogue?.catalogue_model_number}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "18px",
              display:"flex",
              gap:"12px",
              alignItems:"center"
            }}
          >
            Color: <div style={{border:"1px solid #53503F",height:"12px",width:"12px",borderRadius:"2px",background:ele?.attributes?.catalogue?.catalogue_variant?.attributes?.catalogue_variant_color?.color_code}}></div>
          </span>
        </div>

        <div
          style={{
            flexDirection: "column",
            gap: "12px",
            display: "flex",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontSize: "16px",
              color: "rgba(83, 80, 63, 1)",
            }}
            className="montserrat"
          >
            Size
          </span>
          <span
            className="montserrat"
            style={{
              fontSize: "18px",
              fontWeight: 600,
              color: "rgba(83, 80, 63, 1)",
            }}
          >
         {ele.attributes?.catalogue?.catalogue_variant?.attributes?.size}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <span
            className="montserrat"
            style={{
              fontWeight: 400,
              color: "rgba(83, 80, 63, 1)",
              fontSize: "16px",
            }}
          >
            Quantity
          </span>
          <span
            style={{
              fontWeight: 600,
              fontSize: "18px",
              color: "rgba(83, 80, 63, 1)",
            }}
            className="montserrat"
            data-testid="quantityTest"
          >
            {ele.attributes.quantity}
          </span>
        </div>
        <span
          style={{
            color: "rgba(83, 80, 63, 1)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
         {ele.attributes?.total_price}
        </span>
      </div>
      <span
        style={{
          color: "rgba(83, 80, 63, 1)",
          fontWeight: 800,
        }}
      >
        <img width={23} height={23} src={rightArrowIcon} alt="rightArrow" />
      </span>
    </div>
    <hr
      style={{
        height: "1px",
        backgroundColor: "rgba(239, 238, 233, 1)",
        marginTop: "15px",
        border: "none",
        width: "100%",
      }}
    />
  </div>
))}

        <div 
        data-testid="deliveryDate"
        style={{
          marginTop:"15px",
          fontSize:"16px",
          fontWeight:600,
          fontFamily:"montserrat",
          color:"rgba(83, 80, 63, 1)",
          display:"flex",
          alignItems:"center",
          gap:"10px"
}}>
  <img src={deliveryIcon} alt="test" />
Estimated delivery: {getFormattedTimestamp(orderData?.attributes?.order_timeline,"Delivered")}
</div>
      </div>
    );
  }
  const fetchOrderList =async () => {
    const headers = {
        "Content-Type": "application/json",
        token: await getStorageData('loginToken'),
    };

    sendNetworkRequest(
        fetchOrderListId,
        "GET",
       activeTab=="ONGOING"?"/bx_block_order_management/orders?scope=ongoing":"/bx_block_order_management/orders",
        headers
    );
}
const fetchCategoryList =async () => {
  const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('loginToken'),
  };

  sendNetworkRequest(
      categoryListId,
      "GET",
     "/bx_block_categories/categories",
      headers
  );
}

React.useEffect(() => {
  setReceiveCallback(receive);
  subscribedMessages.forEach((message) => subscribe(message));
  fetchOrderList();
  fetchCategoryList()
  return () => {
    subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
  };
}, [activeTab])

const receive = async(from: string, message: Message) => {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
          extractNetworkResponse(message);
      if (apiRequestCallId === fetchOrderListId.current || from === "UNIT_TEST") {
       if(responseJson){
        setOrderList(responseJson.data)
       }
      }
      if(apiRequestCallId == categoryListId.current || from === "UNIT_TEST"){
        setCategoryArray(responseJson?.data)
      }
  }
};
  // Customizable Area End

  return (
    // Customizable Area Start
    <div style={{background:"rgba(237, 230, 220, 1)"}}>
    <AppHeader/>
    <div style={{width:"100%",
          boxSizing:"border-box",
          padding:"40px 40px 100px 40px",
          }}>
              <ArrowTopBox>
                    <Typography data-testid='home' onClick={handleNavigate} className="arrowHomeText">{"Home"}</Typography>
                    <img src={rightArrow} alt="test" />
                    <Typography className="arrowHomeText" data-testid='OffersTest' style={{fontWeight:600}}> {"My Orders"}</Typography>
        </ArrowTopBox>
        <ArrowTopBox>
        <div className="tabs" style={{width:"90%",borderBottom:"1px solid #53503F",marginTop:"35px",display:"flex",gap:"25px",alignItems:"center"}}>
  {["ONGOING","HISTORY"]?.map((ele: string, index: any) => (
<span
key={index}
className="tabList"
data-testid={`tabList${index}`}
onClick={() => setActiveTab(ele)}
style={{
paddingBottom: "16px",
cursor: "pointer",
position: "relative",
color:"#53503F",
fontWeight:ele===activeTab?600:400
}}
>
{ele}
{ele === activeTab && (
<span
style={{
content: "''",
position: "absolute",
bottom: "0",
left: "0",
width: "100%",
height: "5px",
backgroundColor: "rgba(83, 80, 63, 1)",
borderRadius: "6px",
}}
/>
)}
</span>
))}

  </div>
        </ArrowTopBox>
        
            <div style={{display:"flex",
              flexDirection:"column",
              gap:"30px",
              marginTop:"20px",
              alignItems:"center"
              }}>
                {
                  ordersList?.map((ele:any)=>{
                    return orderBox(ele)
                  })
                }
            </div>
        </div>
    <Footer openLoginModal={openLoginModal}  handleCategoryData={handleCategoryData} categoriesList={categoriesArray}/>
   </div>
    // Customizable Area End
  );
};

// Customizable Area Start
export const ArrowTopBox = styled(Box)({
  gap: '11px',
  display: 'flex',
  paddingLeft: '6px',
  alignItems: 'center',
  // marginTop:"30px",
  "& .arrowHomeText": {
      fontSize: '18px',
      fontFamily: "'Montserrat', sans-serif",
      color: '#53503F',
      cursor: 'pointer',
      fontWeight: 400,
  },

  "& .arrowLastText": {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      color: '#53503F',
      fontSize: '18px',
  }

})
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainWrapper: {
    paddingBottom: 32,
    background: "#fff",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    alignItems: "center",
  },
  orderWrapper: {
    border: "1px solid #ccc",
    borderRadius: 5,
    padding: 20,
    marginTop: 20,
  },
  orderIdWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  orderItemPrice: {
    fontWeight: 700,
  },
  orderItemInnerBox: {
    flex: 1,
  },
  orderStatus: {
    fontWeight: 700,
  },
  addNewOrderItemStyle: {
    marginTop: 10,
    alignSelf: "flex-end",
    marginLeft: 10,
    textTransform: "capitalize" as "capitalize",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 5,
  },
  orderNumber: {
    cursor: "pointer",
    fontWeight: 700,
  },
  loadingText: {
    textAlign: "center" as "center",
    marginTop: 20,
  },
  modalTitle: {
    marginBottom: 10,
  },
  textFieldStyle: {
    marginBottom: 15,
  },
};
// Customizable Area End

export default OrderManagementView;
