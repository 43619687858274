import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import moment from "moment";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab:string;
  txtInputValue:string;
  subTab:string;
  selectedDayFilter:any;
  subTabsList:any;
  tabsList:any;
  searchQuery:string;
  selectedSortFilter:any;
  openFilter:boolean;
  openFromCalendar:boolean;
  fromDateFilter:any;
  openToCalendar:boolean;
  toDateFilter:any;
  openAssignFromCalendar:boolean;
  fromAssignDateFilter:any;
  openToAssignCalendar:boolean;
  toAssignDateFilter:any;
  selectedCategoryList:any[];
  selectedBrandList:any[];
  selectedUseFilterList:any[];
  priceRange: any[],
  offerPriceRange:any[],
  minOfferPrice:any,
  maxOfferPrice:any,
  minPriceData: any,
  maxPriceData: any,
  selectedStatusFilterList:any[]
  overviewData:any,
  tabData:any[],
  selectedIds:any[],
  openHideModal:boolean,
  hidePeriod:number|string;
  deleteOrderModal:boolean;
  singleProductId:any;
  categoryList:any[],
  brandsList:any[],
  statusList:any[],
  metadata:any,
  page:any;
  perPage:any;
  openConfirmationModal:boolean;
  buyingRequestsList:any;
  productCountData:any;
  allCountZero:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  overviewApiId:string="";
  cataloguesApi:string="";
  hideProductApiId:string='';
  deleteProductsApi:string="";
  searchDebounceTimer:any=null;
  searchAPIId:string="";
  sortApiId:string="";
  productsFilterListApi:string="";
  filterApplyApiId:string="";
  showProductAPI:string="";
  buyingRequestsAPI:string="";
  buyingRequestsListApi:string="";
  acceptRejectApi:string="";
  ordersListApi:string="";
  offersListApi:string="";
  wishRequetsListApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
     , getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeTab:"Buying Requests",
      txtInputValue:"",
      subTab:configJSON?.subtabsMapping["Buying Requests"]?.[0] || "",
      selectedDayFilter:{value:"today",label:"Today"},
      subTabsList:configJSON.subtabsMapping,
      tabsList:configJSON.tabsNames,
      searchQuery:"",
      selectedSortFilter:{ value: "Sort by : Recent", label: "Sort by : Recent" },
      openFilter:false,
      openFromCalendar:false,
      fromDateFilter:"",
      openToCalendar:false,
      toDateFilter:"",
      selectedCategoryList:["all"],
      selectedBrandList:["all"],
      selectedUseFilterList:["all"],
      priceRange: [-200, 450],
      offerPriceRange:[45,450],
      minOfferPrice:50,
      maxOfferPrice:500,
      minPriceData: 50,
      maxPriceData: 500,
      selectedStatusFilterList:["All"],
      overviewData:{},
      tabData:[],
      selectedIds:[],
      openHideModal:false,
      hidePeriod:"",
      deleteOrderModal:false,
      singleProductId:null,
      categoryList:[],
      brandsList:[],
      statusList:[],
      metadata:{},
      page:1,
      perPage:5,
      openConfirmationModal:false,
      buyingRequestsList:[],
      productCountData:{},
      openAssignFromCalendar:false,
      fromAssignDateFilter:"",
      openToAssignCalendar:false,
      toAssignDateFilter:"",
      allCountZero:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    this.handleApiResponse(apiRequestCallId, responseJson);
    if (responseJson?.errors[0]?.token) {
      removeStorageData('loginToken')
      removeStorageData('loginDetails')
      this.props?.navigation?.navigate('Home')
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.overviewApiId:
        if (responseJson) {
          this.setState({ overviewData: responseJson });
        }
        break;
  
      case this.cataloguesApi:
        this.formdataResponseHandler(responseJson);
        break;
  
      case this.hideProductApiId:
        if (responseJson) {
          this.setState(
            { openHideModal: false, selectedIds: [], hidePeriod: "", subTab: "Hidden Products" },
            () => {
              this.getTabData();
            }
          );
        }
        break;
  
      case this.deleteProductsApi:
        this.setState(
          { deleteOrderModal: false, selectedIds: [] },
          () => {
            this.getTabData();
          }
        );
        break;
  
      case this.searchAPIId:
      case this.buyingRequestsAPI:
      case this.ordersListApi:
      case this.offersListApi:
      case this.wishRequetsListApi:
      case this.sortApiId:
        if (responseJson.data) {
          this.formatData(responseJson.data,responseJson.meta);
          this.setState({ metadata: responseJson.meta,page:responseJson.meta?.current_page });
        } else {
          this.setState({ tabData: [],metadata: responseJson.meta,page:responseJson.meta?.current_page});
        }
        break;
  
      case this.productsFilterListApi:
        this.handleFiltersListResponse(responseJson);
        break;
  
      case this.filterApplyApiId:
        this.formdataResponseHandler(responseJson);
        break;
  
      case this.showProductAPI:
        this.setState(
          { openConfirmationModal: false, selectedIds: [], subTab: "All Products" },
          () => {
            this.getTabData();
          }
        );
        break;
  
      case this.buyingRequestsListApi:
        const finalArray = this.handleButingRequestsListResponse(responseJson);
        this.setState({ buyingRequestsList: finalArray });
        break;
  
      case this.acceptRejectApi:
        this.getTabData()
        break;
    }
  }
  
  handleTabChange = (tabName: string) => {
    const defaultSubTab = configJSON.subtabsMapping[tabName]?.[0] || ''
    this.props.navigation.navigate("Analytics",{})
    this.setState({ activeTab: tabName,
       subTab: defaultSubTab,
       searchQuery:"",
       page:1,perPage:5,metadata:{},
       selectedBrandList:[0],
       selectedCategoryList:[0],
       selectedStatusFilterList:["All"],
       priceRange:[45,450],
       offerPriceRange:[45,450],
       fromDateFilter:"",
       fromAssignDateFilter:"",
       toDateFilter:"",
       toAssignDateFilter:"",
       selectedSortFilter:{value:"recent",label:"Recent"}
      },()=>{
      this.getTabData();
      this.getFilterList()
    });
}
handleButingRequestsListResponse=(responseJson:any)=>{
 return responseJson?.data.flatMap((item: any) => {
    if (item?.offers) {
      return item.offers?.map((ele: any) => ({
        id: ele.id,
        date: moment(ele?.attributes?.created_at).format("MMM D, YYYY"),
        customer: ele?.attributes?.customer_full_name,
        location: ele?.attributes?.location,
        listed_price: "-",
        offer_price: ele?.attributes?.offer_price || "-",
        listType: "offers",
      }));
    } else if (item.orders) {
      return item.orders?.map((ele: any) => ({
        id: ele.id,
        date: moment(ele?.attributes?.created_at).format("MMM D, YYYY"),
        customer: ele?.attributes?.customer_full_name,
        location: ele?.attributes?.location,
        listed_price: ele?.attributes?.listed_price || "-",
        offer_price: "-",
        listType: "orders",
      }));
    }
  });
}
getFilterList=async()=>{
const{activeTab} =this.state
switch (activeTab){
  case "Products":
        this.getProductsFilterList(`/bx_block_catalogue/catalogues/load_shopper_filter`)
  break;
  case "Orders":
    this.getProductsFilterList("/bx_block_order_management/shopper_orders/load_filter")
    break;
  case "Offers":
    this.getProductsFilterList("/bx_block_request_management/offer_requests/load_filter")
    break;
  case "Buying Requests":
    this.getProductsFilterList("/bx_block_catalogue/catalogues/load_shopper_filter")
    break;
  case "Wish Requests":
    this.getProductsFilterList("/bx_block_request_management/wish_requests/filter_category_brand")//need to change correct url
    break;

}
}
getBuyingRequestList=async()=>{
  const token=await getStorageData("loginToken");
  this.buyingRequestsAPI=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_catalogue/catalogues?show_buying_requests=true&&page=${this.state.page}&&per_page=${this.state.perPage}&&query=${this.state.searchQuery}`
  })
}
getOrdersList=async()=>{
  const {subTab,searchQuery}=this.state;
  let endPoint=`/bx_block_order_management/shopper_orders?page=${this.state.page}&&per_page=${this.state.perPage}`
  if(subTab=="Accepted Orders"){
    endPoint+="&&scope=accepted"

  }else if(subTab=="Rejected Orders"){
    endPoint+="&&scope=rejected"
  }
  if (searchQuery) {
    endPoint += `&&query=${encodeURIComponent(searchQuery)}`;
  }
  const token=await getStorageData("loginToken");
  this.ordersListApi=this.apiCall(token,{
    method:"GET",
    endPoint
  })
}
getWishRequestsList=async()=>{
  const {subTab,searchQuery}=this.state;
  let endPoint=`/bx_block_request_management/wish_requests/fetch_wish_lists?page=${this.state.page}&&per_page=${this.state.perPage}`
  if(subTab=="Rejected Requests"){
    endPoint+="&&scope=rejected"
  }else if(subTab=="Accepted Requests"){
    endPoint+="&&scope=accepted"
  }
  if (searchQuery) {
    endPoint += `&&query=${encodeURIComponent(searchQuery)}`;
  }
  const token=await getStorageData("loginToken");
  this.wishRequetsListApi=this.apiCall(token,{
    method:"GET",
    endPoint
  })
}
getOffersList=async()=>{
  const {subTab,searchQuery}=this.state;
  let endPoint=`/bx_block_request_management/offer_requests/fetch_shopper_offer?page=${this.state.page}&&per_page=${this.state.perPage}`
  if(subTab=="Rejected Offers"){
    endPoint+="&&scope=rejected"
  }else if(subTab=="Accepted Offers"){
    endPoint+="&&scope=accepted"
  }else{
    endPoint+="&&scope=pending"
  }
  if (searchQuery) {
    endPoint += `&&query=${encodeURIComponent(searchQuery)}`;
  }
  const token=await getStorageData("loginToken");
  this.offersListApi=this.apiCall(token,{
    method:"GET",
    endPoint
  })
}
handleCloseConfirmationModal=()=>{
  this.setState({openConfirmationModal:!this.state.openConfirmationModal})
}
handleConfirmationModal=async()=>{
  const {selectedIds,singleProductId}=this.state
  const token =await getStorageData("loginToken");
  const body={
    "catalogue_ids":singleProductId|| selectedIds
}
this.showProductAPI=this.apiCall(token,{
  method:"PUT",
  endPoint:`/bx_block_catalogue/catalogues/unhide_catalogues`,
  body:JSON.stringify(body)
})

}
handleFiltersListResponse = async (responseJson: any) => {
  const { activeTab } = this.state;
  let minPriceFloor = 0;
  let maxPriceFloor = 0;
  const trimmedTab = activeTab.trim();
  const { brands = [], categories = [], max_listed_price = 0, min_listed_price = 0, status = [] } =
  responseJson?.filter || {};
  minPriceFloor = Math.floor(min_listed_price)||45;
  maxPriceFloor = Math.floor(max_listed_price)||450;
  switch (trimmedTab) {
    case "Products":
      this.setState({
        categoryList: categories,
        brandsList: brands,
        statusList: status,
        priceRange: [minPriceFloor, maxPriceFloor],
        maxPriceData: maxPriceFloor,
        minPriceData: minPriceFloor,
      });
      break;

    case "Orders":
      minPriceFloor = Math.floor(responseJson.min_listed_price)||45;
      maxPriceFloor = Math.floor(responseJson.max_listed_price)||450;
      this.setState(
        {
          categoryList: responseJson?.categories || [],
          maxPriceData: maxPriceFloor||500,
          minPriceData: minPriceFloor||20,
          priceRange:[minPriceFloor, maxPriceFloor],
        });
      break;

    case "Offers":
      minPriceFloor = Math.floor(responseJson.min_listed_price)||45;
      maxPriceFloor = Math.floor(responseJson.max_listed_price)||450;
      this.setState(
        {
          categoryList: responseJson?.categories,
          maxPriceData: maxPriceFloor||500,
          minPriceData: minPriceFloor||20,
          priceRange:[minPriceFloor, maxPriceFloor],
          minOfferPrice:responseJson.min_offer_price,
          maxOfferPrice:responseJson.max_offer_price,
          offerPriceRange:[responseJson.min_offer_price,responseJson.max_offer_price]
        });
      break;
      case "Wish Requests":
      this.setState(
        {
          categoryList:[{id:0,name:"All"},... responseJson?.categories],
          brandsList:[{id:0,name:"All"},...responseJson?.brands]
        });
      break;
      case "Buying Requests":
      this.setState(
        {
          categoryList:[... responseJson?.filter?.categories],
          brandsList:[...responseJson?.filter?.brands],
          maxPriceData: maxPriceFloor||500,
          minPriceData: minPriceFloor||20,
          priceRange:[minPriceFloor, maxPriceFloor],
        });
      break;
  }
};

getProductsFilterList=async(endPoint:string)=>{
  const token=await getStorageData("loginToken");
  this.productsFilterListApi=this.apiCall(token,{
    method:"GET",
    endPoint:endPoint
  })
}
formdataResponseHandler=(responseJson:any)=>{
  if(!responseJson.errors){
    this.setState({metadata:responseJson.meta,openFilter:false},()=>{
      this.formatData(responseJson.data,responseJson.meta);
    })
  }else{
    this.setState({tabData:[],metadata:responseJson.meta,})
  }
}
handleChangeRowsPerPage = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  this.setState({
    perPage:parseInt(event.target.value),
    page:1,
  },()=>this.getTabData())
};

handleChangePage = (_event: unknown, newPage: number) => {
  this.setState({page:newPage+1},()=>this.handleApplyBtn())
};
getTabData=async()=>{
  const {activeTab,subTab,searchQuery}=this.state
  const token=await getStorageData("loginToken")
  const baseEndpoint = `/bx_block_catalogue/catalogues?per_page=${this.state.perPage}&&page=${this.state.page > 0 ? this.state.page : 1}`;
let endPoint = subTab === "Hidden Products" 
  ? `${baseEndpoint}&&scope=hidden_products`
  : baseEndpoint;
  if (searchQuery) {
    endPoint += `&&query=${encodeURIComponent(searchQuery)}`;
  }
  if(activeTab=="Products"){
    this.cataloguesApi=this.apiCall(token,{
      method:"GET",
      endPoint:endPoint
    })
  } else if(activeTab==="Buying Requests"){
    this.getBuyingRequestList()
  }else if(activeTab==="Orders"){
    this.getOrdersList();
  }else if(activeTab==="Offers"){
    this.getOffersList();
  }else if(activeTab==="Wish Requests"){
    this.getWishRequestsList();
  }
}
async componentDidMount() {
  super.componentDidMount();
  window.scrollTo(0, 0);

  const url = new URL(window.location.href);
  url.searchParams.delete('data');
  window.history.replaceState({}, document.title, url.toString());

  this.getOverviewdata();
  this.getBuyingRequestList();
  this.getProductsFilterList("/bx_block_catalogue/catalogues/load_shopper_filter")
  this.setState({selectedBrandList:[0],
    selectedCategoryList:[0],
    selectedStatusFilterList:["All"]
  })
  const tab=new URLSearchParams(window.location.search).get('tab')
  const subTab=new URLSearchParams(window.location.search).get('subTab')
if(tab){
this.handleTabChange(tab)
}
if(subTab){
  this.handleSubTabChange(subTab)
}
}
componentDidUpdate(prevProps:any, prevState:any) {
  if (prevState.productCountData !== this.state.productCountData) {
    this.setState({
      allCountZero: this.state.subTabsList[this.state.activeTab]?.every((ele: any) => {
        let status = "";
        if (typeof ele === "string") {
          [status] = ele?.split(" ");
        }
        return (this.state.productCountData[status] || 0) === 0;
      }),
    });
  }
}

handleDetailsNavigation=(path:string,data:any)=>{
this.props.navigation.navigate(path,data)
}
handleSubTabChange = (subTabname: string) => {
    this.setState({ subTab: subTabname,selectedIds:[],searchQuery:"",page:1,perPage:5,
      selectedSortFilter:{value:"recent",label:"Recent"},
      selectedStatusFilterList:["All"]
     },()=>{
        this.getTabData();
      }
    );
}
handleSearchDebounce = () =>{
  this.searchDebounceTimer = setTimeout(async() => {
    this.searchApiCall()
   }, 500);
  }
  searchApiCall=async()=>{
    const token=await getStorageData("loginToken")
    const endPoint=this.returnSearchEndpoint()
      this.searchAPIId=this.apiCall(token,{
        method:"GET",
        endPoint
      })
  }
  returnSearchEndpoint = () => {
    const { activeTab, subTab, page, perPage, searchQuery } = this.state;
    const currentPage = page > 0 ? page : 1;
    let baseEndpoint = "";
    let scope = "";
  
    switch (activeTab) {
      case "Orders":
        baseEndpoint = "/bx_block_order_management/shopper_orders";
        if (subTab === "Accepted Orders") {
          scope = "accepted";
        } else if (subTab === "Rejected Orders") {
          scope = "rejected";
        }
        break;
        case "Wish Requests":
          baseEndpoint = "/bx_block_request_management/wish_requests/fetch_wish_lists";
          if (subTab === "Accepted Requests") {
            scope = "accepted";
          } else if (subTab === "Rejected Requests") {
            scope = "rejected";
          }
          break;
        case "Offers":
          baseEndpoint = "/bx_block_request_management/offer_requests/fetch_shopper_offer";
          if (subTab === "Accepted Offers") {
            scope = "accepted";
          } else if (subTab === "Rejected Offers") {
            scope = "rejected";
          }
          break;
  
      case "Products":
        baseEndpoint = "/bx_block_catalogue/catalogues";
        if (subTab === "Hidden Products") {
          scope = "hidden_products";
        }
        break;
  
      case "Buying Requests":
        baseEndpoint = "/bx_block_catalogue/catalogues?show_buying_requests=true";
        break;
    }
  
    const queryParams = [
      scope && `scope=${scope}`,
      `page=${currentPage}`,
      `per_page=${perPage}`,
      `query=${searchQuery}`,
    ]
      .filter(Boolean)
      .join("&&");
  
    return `${baseEndpoint}?${queryParams}`;
  };
  
formatData=(response:any,meta:any)=>{
  const {activeTab}=this.state;
  let data:any[]=[]
  if(activeTab=="Products"){
    data=response.map((ele:any)=>{
      return {
        modelNumber:ele.attributes?.modal_no,
        image:ele?.attributes?.variant_info?.image_url,
        listedDate:ele.attributes?.listed_date,
        category:ele.attributes?.category,
        brand:ele.attributes?.brand,
        productName:ele.attributes?.product_name,
        listedPrice:ele.attributes?.variant_info?.listed_price,
        sold: ele.attributes?.sold||"-",
        offers: ele.attributes?.offers||"-",
        orders: ele.attributes?.orders||"-",
        status:ele.attributes?.status,
        selected:false,
        id:ele.id
      }
    })
    this.setState({
      productCountData:{
        "All":meta.all_product_count,
        "Hidden":meta.hidden_product_count
      }
    })
    
  }else if(activeTab==="Buying Requests"){
    data=response.map((ele:any)=>{
      return {
        modelNumber:ele?.attributes?.modal_no,
        image:ele?.attributes?.variant_info?.image_url,
        listedDate:ele.attributes?.listed_date,
        category:ele.attributes?.category,
        brand:ele.attributes?.brand,
        productName:ele.attributes?.product_name,
        listedPrice:ele.attributes?.variant_info?.listed_price,
        use:ele.attributes?.use||"-",
        requests:ele.attributes?.requests||0,
        id:ele.id
      }
    })
  }else if(activeTab==="Orders"){
    data=response.map((ele:any)=>{
      return {
        orderId:ele?.attributes?.order_number,
        date:moment(ele?.attributes?.created_at).format("MMM D, YYYY"),
        productImage:ele?.attributes?.product_images?.[0],
        productName:ele.attributes?.product_names,
        customer:ele.attributes?.account_full_name,
        location:ele.attributes?.country_name,
        listedPrice:ele.attributes?.listed_price||0,
        id:ele.id
      }
    })
    this.setState({productCountData:{
      Pending:meta?.pending_orders_count,
      Accepted:meta?.accepted_orders_count,
      Rejected:meta?.rejected_orders_count,
    }})
  }else if(activeTab=="Offers"){

  data=response.map((ele:any)=>{
    return {
      offerId:ele?.attributes?.offer_id,
      date:ele?.attributes?.offer_date,
      productImage:ele?.attributes?.variant_info?.attributes?.images?.[0]?.url,
      productName:ele.attributes?.product_name,
      customer:ele.attributes?.customer_name,
      location:ele.attributes?.location,
      listedPrice:ele.attributes?.order_price,
      offerPrice:ele.attributes?.offer_price,
      id:ele.id
    }
  })
  this.setState({productCountData:{
    Pending:meta?.pending_offers_count,
    Accepted:meta?.accepted_offers_count,
    Rejected:meta?.rejected_offers_count,
  }})
  }
  else if(activeTab=="Wish Requests"){

    data=response.map((ele:any)=>{
      return {
        requestId:ele?.attributes?.request_id,
        productImage:ele?.attributes?.product_image[0]?.url,
        productName:ele?.attributes?.name,
        category:ele?.attributes?.category,
        requestDate:ele?.attributes?.request_date,
        assignDate:ele?.attributes?.assign_date,
        customer:ele?.attributes?.customer_name,
        location:ele?.attributes?.location,
        id:ele.id,
        status:{value:ele?.attributes?.status,label:ele?.attributes?.status}
      }
    })

this.setState({productCountData:{
  Pending:meta?.pending_requests_count,
  Accepted:meta?.accepted_requests_count,
  Rejected:meta?.rejected_requests_count,
}})
    }
  this.setState({tabData:data})
}
handleNormalAcceptRejectClick=async(activeTab:string,id:string|number,action:string)=>{
  const token=await getStorageData("loginToken");
  let body={};
  let endPoint=""
  if(activeTab=="Orders"){
    endPoint=`/bx_block_order_management/shopper_orders/${id}`;
    body={
      "shopper_order": {
          "shopper_action": action
      }
  }
  }else if(activeTab=="Offers"){
    endPoint=`/bx_block_request_management/offer_requests/update_offer_status`
   body= {
      "id": id,
      "new_status": action,
      "expired_date": moment().add(10, "days").format("YYYY-MM-DD"),
      
  }
  }else if(activeTab=="Wish Requests"){
    endPoint=`/bx_block_request_management/wish_requests/${id}`
    body={
        "status": action,
        "catalogue_id": id
    }
  }
  this.acceptRejectApi=this.apiCall(token,{
    method:"PUT",
    endPoint:endPoint,
    body:JSON.stringify(body)
  })
}

handleAccordionClick=async(id:any)=>{
const token=await getStorageData("loginToken")
  this.buyingRequestsListApi=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_request_management/buying_requests?catalogue_id=${id}`
  })
}
handleAcceptRejectClick=async(action:string,id:string|number,listType:string)=>{
const token=await getStorageData("loginToken");
let body={};
let endPoint=""
if(listType=="orders"){
  body={
    "shopper_order": {
        "shopper_action": action,
    },
  }
  endPoint=`/bx_block_order_management/shopper_orders/${id}`
}else if(listType=="offers"){
  body={
    "id": id,
    "new_status": action,
    "expired_date": moment().add(10, "days").format("YYYY-MM-DD"),
}
endPoint="/bx_block_request_management/offer_requests/update_offer_status"
}
  this.acceptRejectApi=this.apiCall(token,{
    method:"PUT",
    endPoint:endPoint,
    body:JSON.stringify(body)
  })
}
handleDeleteProductsModal=()=>{
  this.setState({deleteOrderModal:!this.state.deleteOrderModal})
}
getSingleProductId=(id:any,isHideModalOpen?:boolean,showModal?:boolean)=>{
  this.setState({singleProductId:id},()=>{
    if(isHideModalOpen){
      this.setState({openHideModal:true})
    }else if(showModal){
      this.setState({openConfirmationModal:true})
    }
    else{
      this.setState({deleteOrderModal:true})
    }
  })
}
getFilteredData = () => {
  const { activeTab, tabData } = this.state;
  return ["Buying Requests", "Products", "Orders", "Offers", "Wish Requests"].includes(activeTab) ? tabData : [];
};

handleCheckboxChange = (id: number) => {
  this.setState((prevState:any) => {
    const { selectedIds } = prevState;
    const isSelected = selectedIds.includes(id);
    const updatedIds = isSelected
      ? selectedIds.filter((selectedId:any) => selectedId !== id)
      : [...selectedIds, id];

    return { selectedIds: updatedIds };
  });
};
handleDaySelect=(data:any)=>{
  this.setState({selectedDayFilter:data},this.getOverviewdata)
}
handleSortOptionChange=(data:any)=>{
this.setState({selectedSortFilter:data},()=>{
  this.getSortdata()
})
}
getSortdata=async()=>{
  const token=await getStorageData("loginToken")
  let endPoint=this.returnSearchEndpoint()
  if(endPoint.includes("query=")){
    endPoint=endPoint.replace("query=","");
  }
  if(this.state.selectedSortFilter?.value!="recent"){
    endPoint=endPoint+`sort_by=${this.state.selectedSortFilter?.value}`
  }
    this.sortApiId=this.apiCall(token,{
      method:"GET",
      endPoint:endPoint
    })
}
handlesearchChange=(e:any)=>{
  this.setState({searchQuery:e.target.value},()=>{
    this.handleSearchDebounce()
  })
}
handleFilter=()=>{
  this.setState({openFilter:!this.state.openFilter,
  })
}
renderValueLabel = (value: number) => {
  return `$${value}`;
};
handleFromDate = (newValue: Date | null) => {
  this.setState({ fromDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleFromAssignDate = (newValue: Date | null) => {
  this.setState({ fromAssignDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleFromAssignCalendar=()=>{
  this.setState({openAssignFromCalendar:!this.state.openAssignFromCalendar})
}
handleFromCalendar=()=>{
  this.setState({openFromCalendar:!this.state.openFromCalendar})
}
handleToDate = (newValue: Date | null) => {
  this.setState({ toDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleToCalendar=()=>{
  this.setState({openToCalendar:!this.state.openFromCalendar})
}
handleToAssignCalendar=()=>{
  this.setState({openToAssignCalendar:!this.state.openFromCalendar})
}
handleToAssignDate = (newValue: Date | null) => {
  this.setState({ toAssignDateFilter: moment(newValue).format("YYYY-MM-DD") });
};
handleCategorySelect = (subCat: any) => {
  this.setState((prevState: any) => {
    const { selectedCategoryList } = prevState;
    if (selectedCategoryList.includes(subCat)) {
      return { selectedCategoryList: selectedCategoryList.filter((name: any) => name !== subCat) };
    }
    if (subCat === 0 || subCat === "all") {
      return { selectedCategoryList: [subCat] };
    }
    const filteredList = selectedCategoryList.filter((name: any) => name !== 0 && name !== "all");
    return { selectedCategoryList: [...filteredList, subCat] };
  });
};



handleBrandSelect = (subCat: any) => {
  this.setState((prevState: any) => {
    const { selectedBrandList } = prevState;

    if (selectedBrandList.includes(subCat)) {
      return { selectedBrandList: selectedBrandList.filter((name: any) => name !== subCat) };
    }
    if (subCat === 0 || subCat === "all") {
      return { selectedBrandList: [subCat] };
    }
    const filteredList = selectedBrandList.filter((name: any) => name !== 0 && name !== "all");
    return { selectedBrandList: [...filteredList, subCat] };
  });
};



handleUseFilterSelect = (subCat: any) => {
  const { selectedUseFilterList } = this.state;
  this.setState({
    selectedUseFilterList: selectedUseFilterList.includes(subCat)
      ? selectedUseFilterList.filter((name:any) => name !== subCat)
      : [...selectedUseFilterList, subCat],
  });
};
handleStatusFilterSelect = (subCat: any) => {
  this.setState((prevState: any) => {
    const { selectedStatusFilterList } = prevState;
    if (subCat === "All" || subCat === 0) {
      return { selectedStatusFilterList: ["All"] };
    }
    if (selectedStatusFilterList.includes("All")|| selectedStatusFilterList.includes(0)) {
      return { selectedStatusFilterList: [subCat] };
    }
    if (selectedStatusFilterList.includes(subCat)) {
      const updatedList = selectedStatusFilterList.filter((name: any) => name !== subCat);
      return { selectedStatusFilterList: updatedList.length > 0 ? updatedList : ["All"] };
    }
    return { selectedStatusFilterList: [...selectedStatusFilterList, subCat] };
  });
};

handlePriceChange = (event: any, newValue: number | number[]) => {
  if (Array.isArray(newValue) && newValue.length === 2) {
    this.setState({ priceRange: newValue });
  }
};
handleOfferPriceChange = (event: any, newValue: number | number[]) => {
  if (Array.isArray(newValue) && newValue.length === 2) {
    this.setState({ offerPriceRange: newValue });
  }
};
handleCancelFilter=()=>{
  this.setState({
    openFilter:false,
    selectedUseFilterList:[0],
    fromDateFilter:"",
    toDateFilter:"",
    fromAssignDateFilter:"",
    toAssignDateFilter:"",
    selectedBrandList:[0],
    selectedCategoryList:[0],
    selectedStatusFilterList:[0],
  },()=>{
    this.getTabData();
    this.getFilterList();
  });
}

getApplyFilterParams = () => {
  const filterParams=this.getFilterParams();
  const endpoint=this.getEndpoint()

  return `${endpoint}&&${filterParams}`;
};
 getFilterParams = () => {
  const { activeTab,offerPriceRange, priceRange, selectedCategoryList,fromAssignDateFilter,toAssignDateFilter, selectedBrandList, fromDateFilter, toDateFilter} = this.state;
  const updatedCategoryIds=selectedCategoryList?.filter((ele:any)=>ele!=="all"&&ele!==0);
  const updatedBrandsIds=selectedBrandList?.filter((ele:any)=>ele!=="all"&& ele!==0)
  const updatedPriceRange=[priceRange[0],priceRange[1]+20]
  const commonParams = {
    filter_by_price_ranges: priceRange ? updatedPriceRange.join("..") : null,
    filter_by_category_ids: updatedCategoryIds?.length ? updatedCategoryIds.join(",") : null,
  };

  if (activeTab === "Products"||activeTab=="Buying Requests") {
    return this.buildParams({
      ...commonParams, 
      status:this.state.selectedStatusFilterList?.filter((ele:any)=>ele!=="All"&& ele!==0).join(","),
      filter_by_brand_ids: updatedBrandsIds?.length && updatedBrandsIds.join(","),
    });
  }else  if (activeTab === "Orders") {
    return this.buildParams({
      ...commonParams,
      start_date: fromDateFilter ,
      end_date: toDateFilter ,

    });
  }else if(activeTab==="Offers"){
    return this.buildParams({
      ...commonParams,
      filter_by_listed_price_range:priceRange&& priceRange.join(".."),
      filter_by_price_ranges:offerPriceRange&& offerPriceRange.join(".."),
      start_date: fromDateFilter,
      end_date: toDateFilter,
    });
  }
  else if(activeTab==="Wish Requests"){
    return this.buildParams({
    from_assign_date: fromAssignDateFilter,
    to_assign_date: toAssignDateFilter,
    from_request_date: fromDateFilter,
    to_request_date: toDateFilter,
    filter_by_category_ids: updatedCategoryIds?.length && updatedCategoryIds.join(","),
    filter_by_brand_ids: updatedBrandsIds?.length && updatedBrandsIds.join(","),
    });
  }
  return "";
};

 getEndpoint = () => {
  const { activeTab,page, perPage,subTab } = this.state;
  let scope="";
  const status=subTab.split(" ")[0]
    if(status=="Accepted"){
      scope="&&scope=accepted"
    }else if(status=="Rejected"){
      scope="&&scope=rejected"
    }
  if (activeTab === "Products") {
    scope=status==="Hidden"?"&&scope=hidden_products":""
    return `/bx_block_catalogue/catalogues?page=${page}&&per_page=${perPage}`+scope;
  }else  if (activeTab === "Orders") {
    return `/bx_block_order_management/shopper_orders?page=${page}&&per_page=${perPage}`+scope;
  }else if(activeTab=="Offers"){
    return `/bx_block_request_management/offer_requests/fetch_shopper_offer?page=${this.state.page}&&per_page=${this.state.perPage}`+scope
  }else if(activeTab=="Wish Requests"){
    return `/bx_block_request_management/wish_requests/fetch_wish_lists?per_page=${this.state.perPage}&page_no=${this.state.page}`+scope
  }else if(activeTab=="Buying Requests"){
    return `/bx_block_catalogue/catalogues?show_buying_requests=true&&page=${this.state.page}&&per_page=${this.state.perPage}&&query=${this.state.searchQuery}`
  }

  return "";
};
buildParams = (paramsObj:any) => {
  const params = new URLSearchParams();
  Object.entries(paramsObj).forEach(([key, value]:any) => {
    if (value) {
      params.append(key, value);
    }
  });
  return decodeURIComponent(params.toString());
};

handleApplyBtn=async()=>{
  const endPointWithFilters=this.getApplyFilterParams();
  const token=await getStorageData("loginToken")
  this.filterApplyApiId=this.apiCall(token,{
    method:"GET",
    endPoint:endPointWithFilters
  })
  this.setState({openFilter:false})
}
getOverviewdata=async()=>{
  const token=await getStorageData("loginToken")
  this.overviewApiId=this.apiCall(token,{
    method:"GET",
    endPoint:`/bx_block_analytics/analytics?filter_by=${this.state.selectedDayFilter.value}`
  })
}
apiCall = (token: any,payload:any) => {
  let {method,endPoint,body}=payload
  const header = {
    'Content-Type': 'application/json',
    token: token||"",
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint 
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  body&&requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 return requestMessage.messageId;

};
handleHideModal=()=>{
  this.setState({openHideModal:!this.state.openHideModal})
}
handlePeriodChange=(event:any)=>{
  this.setState({hidePeriod:event?.target.value})
}
handleConfirmHidePeriod=async()=>{
  const {hidePeriod,selectedIds,singleProductId}=this.state
  const token =await getStorageData("loginToken");
  const body={
    "catalogue_ids":singleProductId|| selectedIds,
    "hidden_days": hidePeriod
}
this.hideProductApiId=this.apiCall(token,{
  method:"PUT",
  endPoint:`/bx_block_catalogue/catalogues/hide_catalogues`,
  body:JSON.stringify(body)
})
}
handleConfirmDeleteProducts=async()=>{
  const {selectedIds,singleProductId}=this.state
  const token =await getStorageData("loginToken");
  const body={
    "catalogue_ids":singleProductId|| selectedIds
}
this.deleteProductsApi=this.apiCall(token,{
  method:"DELETE",
  endPoint:`/bx_block_catalogue/catalogues/delete_all`,
  body:JSON.stringify(body)
})
}
navigationToAnyPage = (pageName: string) => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};
  // Customizable Area End
}
