import React from "react";

// Customizable Area Start
import BrandController,{
    Props
} from "./BrandController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import { Footer } from "../../../components/src/Footer.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  styled,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
  Drawer,
  Divider,
  Slider,
} from "@mui/material";
export const configJSON = require("./config");
import { HeartIconOutlined,SearchIcon, rightIcon,noProductIcon ,favHeartIcon} from "./assets";
// Customizable Area End

// Customizable Area Start
interface Color {
  id: string; 
  name: string;
  color_code: string; 
}
// Customizable Area End

export default class BrandWeb extends BrandController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  brandSearch(){
    return!this.brandId && (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Brand</Typography>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            data-testid="brandTestId"
            value={this.state.filteredBrands?.find((brand: any) => brand.name === this.state.searchTerm) || null}
            onInputChange={(event, newInputValue) => this.handleSearchChange(newInputValue)}
            options={this.state.filteredBrands}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Brands"
                variant="outlined"
                sx={{
                  fontFamily: `montserrat, Arial, sans-serif`,
                  backgroundColor: '#F9F9F9',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} alt='searchIcon'/>
                    </InputAdornment>
                  ),
                  endAdornment: null,
                }}
              />
            )}
            onChange={(event:any, value:any) => this.handleSelectBrand(value)}
            noOptionsText="No brands found"
            PaperComponent={({ children }:any) => (
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid rgba(83, 80, 63, 1)',
                  borderRadius: '4px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
                  marginTop: '8px',
                }}
              >
                {children}
              </Box>
            )}
            renderOption={(props:any, option:any) => {
              const isSelected = this.state.filteredBrands?.find((brand: any) => brand.name === this.state.searchTerm) === option;
              
              const baseStyle = {
                padding: '8px 16px',
                fontSize: '14px',
              };
            
              const selectedStyle = isSelected ? {
                backgroundColor: 'rgba(83, 80, 63, 1)', 
                color: '#ffffff', 
                fontWeight: 600,
              } : {
                backgroundColor: '#FFF', 
                color: '#000000', 
                fontWeight: 400,
              };

              const finalStyle = { ...baseStyle, ...selectedStyle };

              return (
                <li
                  {...props}
                  key={option.id}
                  style={finalStyle}
                >
                  {option.name}
                </li>
              );
            }}

            fullWidth
          />
        </FormControl>
      </Box>
    )
  }

 
  allFilter(){
    return  <Drawer
    anchor="left"
    open={true}
    onClose={() => this.handleCloseAllFilter()}
    PaperProps={{ sx: { backgroundColor: 'rgba(239, 238, 233, 1)',width: '400px', padding: '10px 25px', borderRadius: '12px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' } }}
    data-testid="filterTestId"
  >

<Box sx={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"100%"}}>
<div>

    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography className="montserratFont" variant="h6" sx={{ color: 'rgba(83, 80, 63, 1)',fontWeight: 600, fontSize: '16px'}}>
        Filters
      </Typography>
      <Button
        color="error"
        className="montserratFont"
        data-testid="clearBtnTestId"
        onClick={this.handleClearAllBtn}
        sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 61, 61, 1)', textTransform: 'capitalize' }}
      >
        Clear All
      </Button>
    </Box>
    <Divider sx={{margin: '15px 0px !important'}}/>
  <Box>
    <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}>
      Price Range
    </Typography>
    <Slider
      value={this.state.priceRange}
      step={1}
      min={this.state.minPriceData}
      max={this.state.maxPriceData}
      onChange={this.handlePriceChange}
      valueLabelDisplay="on"
      valueLabelFormat={this.renderValueLabel}
      data-testid="priceSliderTestId"
      sx={{
        margin: '30px 20px',
        width: '85%',
        color: "#70624D",
        height: 4,
        fontFamily: `monserrat, Arial, sans-serif`,
        "& .MuiSlider-thumb": {
          backgroundColor: "#70624D",
          border: "2px solid #FFFFFF",
          width: 20, 
          height: 20,
          "&:hover": {
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", 
          },
        },
        "& .MuiSlider-rail": {
          backgroundColor: "#ffffff",
          opacity: 1,
        },
        "& .MuiSlider-track": {
          backgroundColor: "#70624D",
        },
        "& .MuiSlider-valueLabel": {
          fontFamily: `montserrat, Arial, sans-serif`,
          marginLeft: '10px !important', 
          borderRadius: '4px',
          backgroundColor: "#ffffff",
          border: '1px solid rgba(83, 80, 63, 1)',
          color: "#70624D",
          fontSize: "12px",
          fontWeight: 600,
          top: -6, 
          "&:before": {
            display: "none"
          },
        },
      }}
    />
  </Box>
  
  {this.brandSearch()}
  {this.categoriFilter()}
<FormControl fullWidth margin="normal" sx={{ marginBottom: '15px' }}>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Year of production</Typography>
  <Select
    id="demo-simple-select"
    labelId="demo-simple-select-label"
    displayEmpty
    sx={{
      fontFamily: `montserrat, Arial, sans-serif`,
      height: '56px',
      backgroundColor: '#F9F9F9',
      borderRadius: '4px',
      '& .MuiSelect-select': {
        padding: '10px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none', 
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
    }}
    data-testid="yearTestId"
    value={this.state.year}
    onChange={(e) => this.setState({ year: e.target.value })}
  >
    <MenuItem 
    value="" 
    sx={{
      fontFamily: `montserrat, Arial, sans-serif`,
      '&.Mui-selected:hover': {
        backgroundColor: '#5b5b5b',
      },
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#80806e',  
      },
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
      }}>
        <em style={webStyle.selectPlaceholder}>Select year</em>
      </MenuItem>
    {this.state.productionYearData?.map((year: any) => (
      <MenuItem 
      key={year.product_year_production} 
      value={year.product_year_production}
      sx={{
        '&.Mui-selected': {
          backgroundColor: '#53503F', 
          color: '#ffffff',
          fontFamily: `montserrat, Arial, sans-serif`
        }
      }}
      >
        {year.product_year_production}
      </MenuItem>
    ))}
  </Select>
</FormControl>

{this.brandId&&<Box sx={{ marginBottom: '15px' }}>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Date Posted</Typography>
  <Box >
    <Button
      data-testid="recentPostBtn"
      variant="outlined"
      onClick={() => this.setState({ datePosted: 'recent' })}
      className="montserratFont"
      sx={{
        marginRight: '10px',
        flex: 1,
        borderRadius: '4px',
        backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#FFFFFF',
        color: this.state.datePosted === 'recent' ? '#FFFFFF' : '#333',
        borderColor: '#333',
        fontWeight: 500,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
          borderColor: '#333',
          boxShadow: 'none', 
        },
      }}
    >
      Recent
    </Button>
    <Button
      data-testid="oldPostBtn"
      variant="outlined"
      onClick={() => this.setState({ datePosted: 'older' })}
      className="montserratFont"
      sx={{
        flex: 1,
        borderRadius: '4px',
        backgroundColor: this.state.datePosted === 'older' ? '#70624D' : '#FFFFFF',
        color: this.state.datePosted === 'older' ? '#FFFFFF' : '#333',
        borderColor: '#333',
        fontWeight: 500,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: this.state.datePosted === 'recent' ? '#70624D' : '#f1f1f1', 
          borderColor: '#333',
          boxShadow: 'none', 
        },
      }}
    >
      Old
    </Button>
  </Box>
</Box>}

<FormControl fullWidth sx={{ marginBottom: '15px' }}>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Select Color</Typography>
  <Select
    id="demo-simple-select"
    labelId="demo-simple-select-label"
    displayEmpty
    data-testid="colorChoiceTestId"
    value={this.state.color}
    onChange={(e) => this.setState({ color: e.target.value })}
    renderValue={(selected) => {      
      const selectedColor = this.state.selectColorData.find((c: Color) => c.id === selected);

      if (selectedColor) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
            sx={{
              backgroundColor: '#fff',
              width: '33px',
              height: '33px',
              borderRadius: '4px',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #000',
              margin: '0px 15px 0px 0px'
            }}
          >
            <Box
              sx={{
                backgroundColor: selectedColor.color_code,
                width: '31px',
                height: '31px',
                borderRadius: '4px',
              }}
            />
          </Box>
            {selectedColor.name}
          </Box>
        );
      } else {
        return <em style={webStyle.selectPlaceholder}>Select color</em>;
      }
    }}
    sx={{
      height: '56px',
      borderRadius: '8px',
      backgroundColor: '#F9F9F9',
      '& .MuiSelect-select': {
        alignItems: 'center',
        display: 'flex',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
        '&.Mui-focused fieldset': {
          border: 'none', 
        },
      },
      
    }}
  >
    <MenuItem 
    value="" 
    sx={{
      fontFamily: 'Montserrat, sans-serif',
      '&.Mui-selected:hover': {
        backgroundColor: '#5b5b5b',
      },
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#80806e',  
      },
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
      }}>
        <em style={webStyle.selectPlaceholder}>Select color</em>
      </MenuItem>
    {this.state.selectColorData?.map((color: any) => (
      <MenuItem 
      key={color.id} 
      value={color.id}  
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        color: 'rgba(60, 62, 73, 1)'
      }}>
        <Box
          sx={{
            backgroundColor: color.color_code,
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            marginRight: '8px',
            fontFamily: `montserrat, Arial, sans-serif`,
            '&.Mui-selected': {
              fontFamily: `montserrat, Arial, sans-serif`,
            }
          }}
        />
        {color.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl fullWidth>
  <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Size</Typography>
  <Select
    id="demo-simple-select"
    labelId="demo-simple-select-label"
    displayEmpty
    data-testid="sizeTestId"
    value={this.state.size}
    onChange={(e) => this.setState({ size: e.target.value })}
    sx={{
      borderRadius: '8px',
      backgroundColor: '#F9F9F9',
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none', 
      },
    },
    }}
  >
    <MenuItem 
    value="" 
    sx={{
      fontFamily: `montserrat, Arial, sans-serif`,
      '&.Mui-selected:hover': {
        backgroundColor: '#5b5b5b',
      },
      '&.Mui-selected': {
        backgroundColor: '#80806e',  
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: '#e0e0e0',
      },
      }}>
        <em style={webStyle.selectPlaceholder}>Select size</em>
      </MenuItem>
    {this.state.sizeData?.map((data: any) => 
      <MenuItem 
      value={data.id} 
      sx={{
        fontFamily: `montserrat, Arial, sans-serif`,
        color: 'rgba(60, 62, 73, 1)'
      }}>
       
        <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          marginRight: '8px',
          fontFamily: `montserrat, Arial, sans-serif`,
          '&.Mui-selected': {
            fontFamily: `montserrat, Arial, sans-serif`,
          }
        }}
      >
       {data.name}
      </Box>
      </MenuItem>)}
  </Select>
</FormControl>
  
</div>
<Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginBottom: '34px' }}>
  <Button
    variant="outlined"
    data-testid="cancelBtnTestId"
    onClick={() => this.handleCloseAllFilter()}
    sx={{
      flex: 1,
      height: '48px',
      borderRadius: '8px',
      color: '#333',
      borderColor: '#333',
      fontWeight: 500,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#70624D',
        color: '#FFFFFF',
        border: 'none'
      },
    }}
    className="montserratFont"
  >
    Cancel
  </Button>
  <Button
    variant="contained"
    data-testid="applyTestId"
    onClick={this.handleApplyButton}
    sx={{
      flex: 1,
      height: '48px',
      borderRadius: '8px',
      backgroundColor: '#70624D',
      color: '#FFFFFF',
      fontWeight: 500,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#70624D',
        color: '#FFFFFF', 
        border: 'none'
      },
    }}
    className="montserratFont"
  >
    Apply
  </Button>
</Box>
</Box>


  </Drawer>
  }
  categoriFilter(){
    return <Box sx={{ marginBottom: '15px' }}>
    <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Category</Typography>
    <Box display="flex" flexWrap="wrap" gap={1}>
      {this.state.categoriesFilterOptions?.map((subCat: any) => (
        <Chip
          className="montserratFont"
          data-testid="subcategoryTestId"
          key={subCat.id}
          label={subCat.name}
          onClick={() => this.handleCategorSelect(subCat.id)}
          sx={{
            backgroundColor: this.state.selectedCategoryList.includes(subCat.id) ? '#70624D' : '#F5F5F5',
            color: this.state.selectedCategoryList.includes(subCat.id) ? '#FFFFFF' : '#333',
            borderRadius: '4px',
            fontWeight: this.state.selectedCategoryList.includes(subCat.id) ? 600 : 400,
            padding: '10px 16px',
            height: '40px',
            border: '1px solid rgba(83, 80, 63, 1)',
            fontSize: '12px'
          }}
          clickable
        />
      ))}
    </Box>
  </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <div
      data-test-id="productContainer"
      style={{
        backgroundColor: "#EDE6DC",
        textAlign: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
           <AppHeader 
            data-test-id="headerTest"
            closeLoginModal={this.closeLoginModal}
            openLoginModal={this.state.openLoginModal}
            count={this.state.cartLength}
            allFilter={this.state.allFilter} 
            handleCloseAllFilter={this.handleCloseAllFilter}
            categoryPageVisibility={this.handleCategoryVisibility}
            handleApplyButton={this.handleApplyButton}
            categoryId={this.state.categoryId}
            genderForFilter={this.state.gender}
        />
        <FullWidthTabComponent
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
            handleCategories={this.handleCategoryData}
            handleMoveToHome={this.handleNewArrivalNavigation}
            data-test-id='fullWidthTabComponent'
        />
           <div className="searchBar" style={{ textAlign: "center"}}>
          <AdvancedSearch data-test-id="search" navigation={this.props.navigation} id="" handleShowCategory={this.handleShowCategory} getCategoryData={this.handleCategoryDataSearch} />
          </div>
          {this.state.allFilter ?
           this.allFilter(): null }

      {!this.state.showCategoryPage &&    <div className="mainContent" style={{width:"100%",boxSizing:"border-box",padding:"0px 40px"}}>
          {<Box sx={webStyle.categoryMain} data-testid="categoryTestId">
        <Box sx={webStyle.categoryBtn} p={2}>
          <Box sx={webStyle.filterData}>
          <Button 
          className="montserratFont"
          sx={webStyle.darkBtn} 
          onClick={this.handleOpenAllFilter}
          data-test-id='allFilterTestId'
          >
            All Filters
          </Button>
          
          <Button 
          className="montserratFont"
          data-test-id="womenTestId"
          sx={this.state.sortingByWomen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={()=>{
            this.setState({sortingByWomen:!this.state.sortingByWomen},this.handleApplyButton)
          }}>
            Women
          </Button>

          <Button 
          className="montserratFont"
          data-test-id="menTestId"
          sx={this.state.sortingByMen ? webStyle.darkBtn : webStyle.transparentBtn} 
          onClick={()=>{
            this.setState({sortingByMen:!this.state.sortingByMen},this.handleApplyButton)
          }}>
            Men
          </Button>
          </Box>
          <Box>
          <FormControl style={webStyle.formControl}>
            {this.state.sortOption ? '' : 
            <InputLabel
              className="montserratFont"
              htmlFor="sortOption"
              style={{
                position: 'absolute',
                top: '50%',
                left: '10%',
                transform: 'translateY(-50%)',
                fontSize: '14px', 
                color: 'rgba(83, 80, 63, 1)', 
                pointerEvents: 'none', 
              }}
            >
              Sort by
            </InputLabel>
            }
            <Select
              className="montserratFont"
              name="sortOption"
              value={this.state.sortOption}
              onChange={this.handleSortOption}
              data-test-id="sortOptionTestId"
              renderValue={(selected) => `Sort by: ${configJSON.sortOptions[this.state.sortOption]}`}  
              sx={{
                ...webStyle.select, 
                fontWeight: 400,
                border: '1px solid rgba(83, 80, 63, 1)',
                backgroundColor: 'transparent',
             
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none', 
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
              }}
              inputProps={{ className: "montserratFont" }}
              MenuProps={{
                PaperProps: {
                  style: webStyle.menuProps,
                },
              }}
            >
              <MenuItem
              sx={menuItemCss}
              className="montserratFont"
              data-testid="productYearTestId"
              value="production_year" 
              style={webStyle.menuItem} 
              >
                Production Year
              </MenuItem>
              <MenuItem 
              sx={menuItemCss}
              className="montserratFont" value="date_of_listing" style={webStyle.menuItem}>
                Date of Listing
              </MenuItem>
              <MenuItem 
              sx={menuItemCss}
              className="montserratFont"
              value="price_high_to_low" 
              style={webStyle.menuItem} 
              data-testid="priceHightToLowTestId"
              >
                Price: High to Low
              </MenuItem>
              <MenuItem 
              className="montserratFont"
              sx={menuItemCss}
              value="price_low_to_high" 
              data-testid="priceLowToHighTestId"
              style={webStyle.menuItem} 
              >
                Price: Low to High
              </MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
      </Box>}
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography className="montserratFont" style={{cursor:"pointer"}} sx={webStyle.homeNavigate} >
              <span 
              data-test-id="homeBreadcrumb"
              onClick={()=>{
              this.props.navigation.navigate("Home")
            }}>
              Home
              </span>
            <img src={rightIcon} alt='rightIcon'/><span style={{fontWeight: 500}}>{this.brandId?this.state.product?.[0]?.attributes?.brand:"New Arrivals"}</span></Typography>
            <Typography className="montserratFont"> {this.state.product?.length}  Results</Typography>
          </Box>

        <Grid container spacing={5} p={2} sx={{backgroundColor: "#EDE6DC"}}>
{this.state.product?.length > 0 
? this.state.product?.map((showProduct: any, index: any) => (
        <>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}> 
          <Card sx={webStyle.cardMain} data-test-id={`productContainer${index}`} onClick={()=>this.navigateToDetailedProductView(showProduct?.attributes?.catalogue_id||showProduct.catalogue_id)}> 
            <Box sx={webStyle.cardMediaMain}>
              <CardMedia
                component="img"
                image={showProduct?.attributes?.image_url} 
                alt='productName'
                sx={webStyle.cardImageMain}
              />
              <IconButton
                sx={webStyle.wishlistIcon}
                aria-label="add to wishlist"
                data-test-id='addToFav'
                onClick={(event:any) => {
                  event?.stopPropagation()
                  this.handleAddToFavorite(showProduct?.id,showProduct?.attributes?.category_id)
              }}
              >
                <img src={showProduct?.attributes?.is_favorited ? favHeartIcon : HeartIconOutlined} alt="heart"/>
              </IconButton>
            </Box>
            <div style={webStyle.cardTypography as React.CSSProperties }> 
              <Typography variant="h6" className="montserratFont" sx={{ textAlign: "left", fontSize: '22px' }}>{showProduct?.attributes?.name}</Typography> 
              <Typography className="montserratFont" sx={{ margin: 0, fontSize: '16px',lineHeight: '30px' }} color="text.secondary"> 
                {showProduct?.attributes?.identity_name}
              </Typography> 
              <Typography variant="body1" sx={{ margin: 0, fontSize: '20px', fontWeight: 400 }}>{showProduct?.price||showProduct?.attributes?.price}</Typography> 
            </div> 
          </Card> 
        </Grid> 
        </>
      )) : 
      <Box style={{width: '100%', textAlign: 'center'}}>
        <p>{
              <MainBox>
              <Box className='emptyImageText'>
                <img className="bagIcon" src={noProductIcon} alt="" />
                <Box className='emptyTextBox'>
                  <Typography className='emptyText'>No Results Found</Typography>
                  <Typography className='emptyText2'>No results found. Please try again.</Typography>
                  <Button data-test-id='addProductTestId'
                  style={{height:"56px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                  }}
                  className="startShopping" 
                  onClick={this.handleShowCategory} variant="contained">
                  Back to Home
                  </Button>
                </Box>
              </Box>
            </MainBox>
          }</p>
      </Box>
      }
      </Grid> 
          </div>}
        <Footer data-test-id="footer" openLoginModal={this.openLoginModal}  handleCategoryData={this.handleCategoryData} categoriesList={this.state.categoriesArray} />

    </div>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  margin:"7rem 0px",
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  "& .emptyImageText": {
    justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      alignItems: 'center',
  },
  "& .emptyTextBox": {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '15px',
      justifyContent: 'center',
  },
  "& .emptyText": {
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    fontSize: '25px',
    textAlign: 'center',
  },
  "& .emptyText2": {
    fontWeight: 400,
    fontSize: '20px',
    color: '#53503F',
    textAlign: 'center',
    maxWidth: '338px',
    fontFamily: "'Montserrat', sans-serif",
  },
  "& .startShopping": {
    borderRadius: '4px',
    backgroundColor: '#53503F',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    width: '283px',
    color: '#EFEEE9',
    fontWeight: 600,
    textTransform: 'none',
      cursor: 'pointer',
  },
  "& .startShopping:hover": {
    backgroundColor:'#53503F',
  },
  "@media  (max-width:500px)": {
      '& .startShopping': {
        fontWeight: 500,
        width: 'unset',
        fontSize: '14px',
      },
      "& .emptyText2": {
          fontSize: '14px',
      },
      "& .bagIcon":{
        width:'100px',
        height:'100px',
      },
      "& .emptyText": {
        fontSize: '16px',
    },
  }
})
const menuItemCss={
  fontFamily: 'Montserrat, sans-serif',
  '&.Mui-selected:hover': {
    color: '#fff',
    backgroundColor: '#5b5b5b',
  },
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#80806e',  
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: '#e0e0e0',
  },
}
const webStyle = {

  darkBtn: {
    textTransform: "capitalize",
    fontWeight: 400,
    backgroundColor: '#53503F',
    color: '#ffffff',
    fontSize: "16px",
    textAlign: "center",
    border: '1px solid #53503F',
    borderRadius: "8px"
  },
  selectPlaceholder: {
    color: '#B0B0B0',
    fontSize: 'inherit',
    display: 'block',
    fontStyle: 'normal',
  },
  cardMain: {
    border: 'none',
    boxShadow: 'none',
    maxWidth: '100%', 
    cursor:"pointer"
  },
  cardMediaMain: {
    position: 'relative'
  },
  cardImageMain: {
    objectFit: 'contain',
    width: 200,
    margin: '16px auto',
    height: 300,
    borderRadius: 4,
  },
  wishlistIcon: {
    right: 8,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    position: 'absolute',
  },
  cardTypography: {
    display:"flex",
    margin: 0, 
    padding: '10px 0px',
    backgroundColor: '#EDE6DC', 
    flexDirection:"column",
    alignItems:"flex-start"
  },
  formControl: {
    color: '#8c8c8c',
    minWidth: 160,
    fontSize: '14px',
    borderRadius: 8,
  },
  inputLabel: {
    color: '#8c8c8c',
  },
  homeNavigate: {
    fontSize: '18px', 
    display: 'flex',
    width: '30%', 
  },
  filterData: {
    justifyContent: 'space-between',
    gap: '20px',
    display: 'flex',
  },
  categoryMain: {
    backgroundColor: "#EDE6DC",
  },
  categoryBtn: {
    gap: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: {
    backgroundColor: '#f2f2e6',
    fontWeight: 500,
    color: '#333',
    fontSize: '14px',
  },
  menuItem: {
    padding: '10px 20px',
    color: '#333',
    fontSize: '14px',
  },
  menuProps: {
    backgroundColor: '#ffff',
    borderRadius: 8,
  },
  transparentBtn: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    border: '1px solid #53503F',
    textTransform: "capitalize",
    color: '#53503F',
    borderRadius: "8px"
  },
};
// Customizable Area End