import React,{useEffect} from 'react';
const Logo=require("./Lgo.svg").default;
const profileIcon=require("./profileIcon.svg").default;
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import { getStorageData, removeStorageData } from '../../framework/src/Utilities';


import { Container, Box, styled,Button, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogOutConfirmationPopUp from './popups/LogOutConfirmationPopUp.web';
import {useNavigate} from 'react-router-dom';
interface ShopperHeaderProps {
}


const ShopperHeader: React.FC<ShopperHeaderProps> = ({ }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmationModal,setConfirmationModal]=React.useState(false)
  const [loginDetails,setLoginDetails]=React.useState<any>({})
  const navigate =useNavigate()
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
      };
      const checkRole=async()=>{
        const loginDetails=await getStorageData("loginDetails",true);
        const isShopper=loginDetails&&loginDetails.attributes.role_name==="personal_shopper";
        const isApproved=loginDetails&&loginDetails.attributes.is_admin_approved;
        if(isShopper && !isApproved){
          navigate("/");
        }else{ 
          setLoginDetails(loginDetails);
        }

      }
      useEffect(()=>{
        checkRole()
      },[])
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleConfirmationLogOut=()=>{
        setConfirmationModal(false)
      }
      const handleLogout = () => {
        setConfirmationModal(false)
        setAnchorEl(null)
        removeStorageData('loginToken')
        removeStorageData('loginDetails');
        window.location.href="/"
      }
      const handleLogOutModal=()=>{
        setAnchorEl(null)
        setConfirmationModal(true)
      }
  return (
    <header className='montserratFont' style={styles.header}>
      <div>
        <img
          style={{
            backgroundColor: "transparent",
            display: "block",
            cursor: "pointer",
            width: "105px",
            height: "70.35px",
            top: "19.82px",
            left: "40px",
            padding: "0.53px 11.67px 12.06px 11.67px",
          }}
          onClick={()=>{
            navigate("/Analytics")
          }}
          src={Logo}
          alt="logo"
        />
      </div>
      <div style={styles.rightSection}>
        <ResponsiveButton
          style={{
            ...styles.sellwithus,
            backgroundColor: "#EDE6DC",
            fontWeight: 500,
            color: "#53503F",
          }}
          onClick={()=>{
            navigate("/")
          }}
        >
          View Website
        </ResponsiveButton>
        <ResponsiveButton
          style={{
            ...styles.sellwithus,
            backgroundColor: "rgba(83, 80, 63, 1)",
            fontWeight: 500,
            color: "rgba(237, 230, 220, 1)",
          }}
          onClick={() => navigate('/Catalogue')}
        >
          SELL AN ITEM
        </ResponsiveButton>
        <div
          className="profileSection"
          onClick={(e: any) => handleClick(e)}
          style={{
            marginLeft: "17px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
        >
             {loginDetails?.attributes?.avatar_url ? (
                <img
                  src={loginDetails.attributes.avatar_url}
                  alt="Profile"
                  style={{
                    borderRadius: "50%",
                    height: "40px",
                    width: "40px",
                  }}
                />
              ) : (
                <span style={{height:"40px",fontWeight:600,width:"40px",border:"1px solid #53503F",borderRadius:"50%",display:"flex",fontSize:"23px",justifyContent:"center",alignItems:"center"}}>
                {`${loginDetails?.attributes?.first_name?.charAt(0).toUpperCase() || ""}${loginDetails?.attributes?.last_name?.charAt(0).toUpperCase() || ""}`}
                </span>
              )}
            
          <div
            className="info"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "21.94px",
                letterSpacing: "0.01em",
                textAlign: "left",
              }}
            >
              {loginDetails?.attributes?.first_name}{" "}{loginDetails?.attributes?.last_name}
            </span>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14.63px",
                letterSpacing: "0.01em",
                textAlign: "left",
              }}
            >
              Shopper(#01234)
            </span>
          </div>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-root": {
            boxSizing: "border-box",
            width: "325px",
            height: "190px",
            overflowY: "hidden",
          },
          ".MuiPopover-paper": {
            borderRadius: "8px",
            boxSizing: "border-box",
            width: "325px",
            height: "190px",
            padding: "20px",
            overflowY: "hidden",
            marginTop: "17px",
            transform: "translateX(-20px) !important",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            borderRadius: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "13px",
           
            }}
          >
            <div
              className="profileIcon"
              style={{ display: "flex", gap: "13px", cursor:'pointer', }}
              onClick={() => navigate('/userProfileShopper')}
              
            >
                {loginDetails?.attributes?.avatar_url ? (
                <img
                  src={loginDetails.attributes.avatar_url}
                  alt="Profile"
                  style={{
                    borderRadius: "50%",
                    height: "44px",
                    width: "44px",
                  }}
                />
              ) : (
                <span style={{height:"44px",fontWeight:600,width:"44px",border:"1px solid #53503F",borderRadius:"50%",display:"flex",fontSize:"23px",justifyContent:"center",alignItems:"center"}}>
                {`${loginDetails?.attributes?.first_name?.charAt(0).toUpperCase() || ""}${loginDetails?.attributes?.last_name?.charAt(0).toUpperCase() || ""}`}
                </span>
              )}
            
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "14px",
                    fontWeight: 800,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#53503F",
                  }}
                >
                 {loginDetails?.attributes?.first_name}{" "}{loginDetails?.attributes?.last_name}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#53503F",
                  }}
                >
                  Profile
                </Typography>
              </div>
            </div>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: "pointer", color: "#53503F" }}
            />
          </div>
          <hr style={{ color: "#53503F", marginTop: "27px" }} />
          <div
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "17.07px",
              textAlign: "left",
              padding:"7px 10px",
              cursor:'pointer',
              color:"#53503F"
            }}
            onClick={()=>{
              navigate("/Settings")
            }}
            className='montserratFont' 
          >
            Settings
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "17.07px",
              textAlign: "left",
              padding:"7px 10px",
              cursor:"pointer",
              color:"#53503F"
            }}
            className='montserratFont' 
            onClick={handleLogOutModal}
          >
            Log out
          </div>
        </div>
      </Popover>
   {confirmationModal&&  <LogOutConfirmationPopUp
              open={confirmationModal}
              handleClose={handleConfirmationLogOut} 
              handleLogOut={handleLogout}
              />}
    </header>
  );
};

const ResponsiveButton = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontFamily:"'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    padding: "10px 20px",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    border:"1px solid #53503F",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      padding: "8px 16px",
    },
    ".MuiButtonBase-root":{
    fontFamily:"'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    }
  }));
const styles: { [key: string]: React.CSSProperties } = {
  header: {
    width:"100%",
    height:"6rem",
    color:"rgba(83, 80, 63, 1)",
    boxSizing:"border-box",
    backgroundColor: "#EDE6DC",
    boxShadow: "0px 4px 8px 0px rgba(1, 1, 2, 0.19)",
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
    display:"flex",
    alignItems:"center",
    padding:"0px 40px",
    justifyContent:"space-between"
  },
  rightSection: {
   display:"flex",
   gap:"1.125rem"
  },
    sellwithus: {
    fontSize: "16px",
    fontFamily: "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    height: "44px",
    padding: "10px 16px",
    gap: "4px",
    borderRadius: "4px",
    border: "1px solid #53503F",
    cursor:"pointer"
  }
};

export default ShopperHeader;
