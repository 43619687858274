import React from "react";

// Customizable Area Start
import { Box, styled, Typography ,Accordion, AccordionSummary,AccordionDetails} from "@mui/material";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import {rightArrow,infoIcon, downArrowIcon} from "./assets";
import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
// Customizable Area End


export default class HelpFaq extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleReturnHeader = () => {
        return (
            this.state.userRole == 'personal_shopper' ?
                <ShopperHeader />
                : <AppHeader
                    closeLoginModal={this.closeLoginModal}
                    openLoginModal={this.state.openLoginModal}
                    count={this.state.cartData?.length}
                    data-test-id='appHeader'
                />
        )
    }
    handleReturnFooter = () => {
        return (
            this.state.userRole === 'personal_shopper'?<ShopperFooter/>:
               <Footer
                data-test-id="footerTest"
                openLoginModal={this.openLoginModal}
                handleCategoryData={this.handleCategoryData}
                categoriesList={this.state.landingPagesData}
            />
            
        )
    }
  // Customizable Area End

  render() {
      // Customizable Area Start
    return (
        <ContainerBoxFoundRequest >
            {this.handleReturnHeader()}
            <OuterMainBoxFoundRequest >
                <ArrowTopBox>
                    <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.home}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography
                        className="arrowLastText"> {configJSON.help_FAQ} </Typography>
                </ArrowTopBox>
                <MainOuterBox>
                    <Box className='leftBox'>
                        <Typography className="mainTitle">{configJSON.frequently_asked_questions}</Typography>
                        {this.state.faqData?.length > 0 && this.state.faqData?.map((element, index) => (
                            <ModiFiedAccordion data-test-id='accordion' key={index} disableGutters={true} square={true} style={{ borderBottom: this.state.faqData?.length - 1 == index ? '' : '1px solid rgba(83, 80, 63, 0.5)', }} >
                                <AccordionSummary
                                    expandIcon={<img src={downArrowIcon} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className="accordionSummary"
                                >
                                    <Typography component="span" className="questionTitle" dangerouslySetInnerHTML={{
                                        __html: element?.attributes?.question?.replace(/<p>&nbsp;<\/p>|<pre>\s*<\/pre>|\r\n/g, '')
                                            .trim()
                                    }} />
                                </AccordionSummary>
                                <AccordionDetails className="accordionDetails">
                                    <Typography className="accordionDetailsText" dangerouslySetInnerHTML={{
                                        __html: element?.attributes?.answer
                                    }} />
                                </AccordionDetails>
                            </ModiFiedAccordion>
                        ))}
                    </Box>
                    <Box className='rightBox'>
                        <Box className='rightBoxMainTitleBox'>
                            <Typography className="importantlinksTitle">{configJSON.important_links}</Typography>
                            <img src={infoIcon} alt="" />
                        </Box>
                        <Box component={'ul'} className="ulList">
                            {this.state.articlesData?.length > 0 && this.state.articlesData?.map((element, index) => (
                                <li onClick={()=>this.props?.navigation?.navigate('Article',{Id:element?.id})} data-test-id='underLineText' className="underLineText" dangerouslySetInnerHTML={{
                                    __html: element?.attributes?.question?.replace(/<p>&nbsp;<\/p>|<pre>\s*<\/pre>|\r\n/g, '')
                                        .trim()
                                }}
                                    key={index} style={{ listStyleType: 'disc', }} />
                            ))}
                        </Box>
                    </Box>
                </MainOuterBox>
            </OuterMainBoxFoundRequest>
            {this.handleReturnFooter()}
        </ContainerBoxFoundRequest>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBoxFoundRequest = styled(Box)({ backgroundColor: '#EDE6DC', })
const OuterMainBoxFoundRequest = styled(Box)({
    display: 'flex',
    padding: '40px 40px 94.6px 40px',
    gap: '20px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'center',
    '@media (max-width: 400px) ': { padding: '10px', }
})
const ArrowTopBox = styled(Box)({
    gap: '11px',
    display: 'flex',
    paddingLeft: '6px',
    alignItems: 'center',
    "& .arrowHomeText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        cursor: 'pointer',
        fontWeight: 400,
    },

    "& .arrowLastText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
        fontSize: '18px',
    }

})
const MainOuterBox = styled(Box)({
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(83, 80, 63, 1)',
    borderRadius: '4px',
    width: '1084px',
    minHeight: 'max-content',
    alignSelf: 'center',
    padding: '40px 30px 181px 30px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    gap: '39px',
    alignItems:'flex-start',
   
    "& .leftBox": {
        width: '650px'
    },
    "& .rightBox": {
        border: '1px solid rgba(83, 80, 63, 1)',
        width: '328px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '4px',
        boxShadow: ' 0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        paddingBottom:'74px'
    },
    "& .ulList":{
        display: 'flex',
        justifyContent: 'center',
        flexDirection:'column',
        gap: '18px',
    },
    "& .underLineText":{
        fontSize: '15px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight: 'normal',
        textDecoration:'underline',
        cursor:'pointer',
        width:'fit-content',
        "& p": {
            margin: '0',
            padding: '0'
        },
        "& pre": {
            display: 'none'
        }
    },
    "& .mainTitle": {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 600,
        lineHeight: 'normal',
        paddingBottom: '20px',
        boxSizing: 'border-box',
        borderBottom: '1px dashed rgba(83, 80, 63, 1)'

    },
    "& .importantlinksTitle": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: 'rgba(83, 80, 63, 1)',
        lineHeight: 'normal',
    },
    "& .rightBoxMainTitleBox": {
        display: 'flex',
        alignItems: "center",
        backgroundColor: "rgba(239, 238, 233, 1)",
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        padding: '15.96px 15px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
    },
    '@media (max-width: 1110px)':{
        width:'100%'
    },
    '@media (max-width: 850px)':{
        flexDirection:'column',
        alignItems:'center'
    },
    '@media (max-width: 765px)':{
        "& .leftBox":{
            width:"100%"
        }
    },
    '@media (max-width: 435px)':{
        "& .rightBox":{
            width:"100%"
        }
    }

})
const ModiFiedAccordion = styled(Accordion)({
    
    boxShadow: 'none',
    padding:'0px !important',
    "& .questionTitle": {
        fontSize: '18px',
        color: 'rgba(83, 80, 63, 1)',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        lineHeight: 'normal',
        "& p":{
           margin:'0'
        },
        "& pre":{
           display:'none'
        }
    },
    "& .accordionSummary": {
        padding: '25px 0px 25px 0px',
        "& .css-1betqn-MuiAccordionSummary-content":{
            margin:'0'
        }
    },
    "& .accordionDetails":{
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform:'none',
        padding:'0px 0px 25px 0px',
        "& .accordionDetailsText":{
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '16px',
            fontWeight: 400,
            color: 'rgba(83, 80, 63, 1)',
            textTransform:'none',
            lineHeight: 'normal',
            
        }
    },
   
   
})
// Customizable Area End