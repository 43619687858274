Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.exampleApiContentType = "application/json";
exports.sortOptions={
  "recent":"Recent",
  "price_low_to_high":"Price: Low to High",
  "price_high_to_low":"Price: High to Low",
  "production_year":"Production Year",
  "date_of_listing":"Date Of Listing"
}
// Customizable Area End
