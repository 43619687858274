export const ProductsIcon=require("../assets/ProductsIcon.svg").default
export const upArrowIcon=require("../assets/uparrow.svg").default
export const noProductIcon=require("../assets/productIcon.svg").default
export const purcahedOrderIcon=require("../assets/purchasedordersIcon.svg").default
export const OffersIcon=require("../assets/offersIcon.svg").default
export const requestsIcon=require("../assets/requestsIcon.svg").default
export const downArrowIcon=require("../assets/downArrow.svg").default
export const calenderIcon=require("../assets/button_calendar.png").default
export const rightArrow = require("../assets/rightIcon.png").default;
export const rightArrowIcon = require("../assets/rightArrowIcon.svg").default;
export const editIcon = require("../assets/editIcon.svg").default;
export const downLoadIcon=require("../assets/certificateIcon.svg").default;
export const editIconVariants=require("../assets/variantsEditIcon.svg").default;
