import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  getCategoryData:any;
  handleShowCategory?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  searchQuery:string;
  categoryData:any;
  categoryId:any;
  searchHistoryList:any;
  showSearchList:boolean,
  isLoggedIn: boolean;
  allFilter:boolean;
  sortingByWomen:boolean;
  selectedCategoryList:any[];
  year:string,
  color:string;
  size:any;
  datePosted:any;
  subCategory:any[];
  priceRange:any[];
  minPriceData: number,
  maxPriceData: number,
  productionYearData: any[],
  selectColorData:any;
  sizeData:any;
  categoriesFilterOptions:any[];
  brandData:any,
  subCategoryData: any,
  sortingByMen:boolean;
  sortOption:any;
  brand:any;
  filteredBrands:any[],
  selectedSubCategories:any[];
  selectedYear:"";
  searchTerm:any;
  isSearching:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  searchDebounceTimer:any;
  searchAPIId:any;
  searchHistoryApiId:any;
  deleteSearchAPIId:any;
  getFilterOptionsAPi:string="";
  filterApplyApiId:string="";
  addToFavoriteApi:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      searchQuery:"",
      categoryData:[],
      categoryId:"",
      searchHistoryList:["watch","bags"],
      showSearchList:false,
      isLoggedIn: false,
      allFilter:false,
      sortingByWomen:false,
      selectedCategoryList:[],
      year:"",
      color:"",
      size:"",
      datePosted:"",
      subCategory:[],
      priceRange:[],
      minPriceData: 45,
      maxPriceData: 450,
      productionYearData: [45,450],
      selectColorData:"",
      sizeData:"",
      categoriesFilterOptions:[],
      brandData:[],
      subCategoryData: [],
      sortingByMen:false,
      sortOption:"recent",
      brand:"",
      filteredBrands:[],
      selectedSubCategories:[],
      selectedYear:"",
      searchTerm:"",
      isSearching:true
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
      const token = await getStorageData("loginToken");
      this.setState({ token, isLoggedIn: !!token }, () => {
        if (this.state.isLoggedIn) {
          this.getSearchHistory(); 
        }
      });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.searchAPIId: {
         this.handlesearchApiResponse(responseJson)
            break;
          }
      
          case this.filterApplyApiId: {
           this.handleFilterApi(responseJson)
            break;
          }
      
          case this.searchHistoryApiId:
            this.setState({ searchHistoryList: responseJson?.data });
            break;
      
          case this.getFilterOptionsAPi: {
       this.handlefilterResponse(responseJson)
            break;
          }
      
          case this.deleteSearchAPIId:
            if (responseJson.message) {
              this.getSearchHistory();
            }
            break;
            case this.addToFavoriteApi:
              if(responseJson?.message){
                this.handleApplyButton()
              }
            break;
        }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    },
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    },
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };
  handleGetSearchHistory=(responseJson:any)=>{
    if (responseJson?.data?.length > 0) {
      this.getSearchHistory();
    }
  }
  deleteSearch=async(searchId:any)=>{
    const token=await getStorageData("loginToken")
      this.deleteSearchAPIId=this.apiCall(token||"",{
        method:"DELETE",
        endPoint:`/bx_block_advanced_search/search/destroy_search?id=${searchId}`
      })
  }
  handlefilterResponse=(responseJson:any)=>{
    const minPrice = Math.floor(responseJson.min_price);
    const maxPrice = Math.floor(responseJson.max_price);
    this.setState({
      priceRange: [minPrice, maxPrice],
      brandData: responseJson.brands,
      subCategoryData: responseJson.sub_categories,
      productionYearData: responseJson.production_year,
      selectColorData: responseJson.color_codes,
      sizeData: responseJson.size_names,
      minPriceData: minPrice,
      maxPriceData: maxPrice,
      filteredBrands: responseJson.brands,
      categoriesFilterOptions: responseJson.categories,
    });
  }
  handleSearchClick=(searchTerm:string) => this.setState({ searchQuery: searchTerm,showSearchList:false },this.searchApiCall)
  getSearchHistory=async()=>{
    const token=await getStorageData("loginToken")
      this.searchHistoryApiId=this.apiCall(token,{
        method:"GET",
        endPoint:`/bx_block_advanced_search/search/history`
      })
  }
  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(async() => {
        this.searchApiCall()      
     }, 500);
    }
    handleAddToFavorite=async(cartId:number)=>{
      const bodyAddFav = {
        "wishlist": {
          "catalogue_variant_id": cartId
          }
      }
      const tokenIs= await getStorageData('loginToken')
      const headerIs = {
        "Content-Type": configJSON.exampleApiContentType,
         ...(tokenIs&&{token: tokenIs})
      };
      const requestMessageAddToFavorite = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addToFavoriteApi = requestMessageAddToFavorite.messageId;
      requestMessageAddToFavorite.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      requestMessageAddToFavorite.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_wishlist/wishlists/add_favorite_or_remove`
      );
      requestMessageAddToFavorite.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerIs)
      );
      requestMessageAddToFavorite.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyAddFav)
      );
      runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);
    
    
    }
    searchApiCall=async()=>{
      const token=await getStorageData("loginToken");
        this.searchAPIId=this.apiCall(token,{
          method:"GET",
          endPoint:`/bx_block_advanced_search/search/catalogues?query=${this.state.searchQuery}`
        })
    }
    apiCall = (token: any,payload:any) => {
      let {method,endPoint}=payload
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token||"",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       method
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     return requestMessage.messageId;
  
    };
    handlesearchApiResponse=(responseJson:any)=>{
      if (responseJson.data.length > 0) {
        this.props.getCategoryData(true);
        this.setState(
          {
            categoryData: responseJson.data,
            categoryId: responseJson?.data?.[0]?.attributes?.category_id,
            allFilter: false,
          },
          () => this.handleGetSearchHistory(responseJson)
        );
      } else {
        this.setState({ categoryData: [],isSearching:false, allFilter: false },()=>{
        this.props.getCategoryData(true);
        });
      }
    }
    handleFilterApi=(responseJson:any)=>{
      const hasData = responseJson?.data?.length > 0;
      this.setState({
        categoryData: hasData ? responseJson.data : [],
        categoryId: hasData ? responseJson?.data?.[0]?.attributes?.category_id : null,
        allFilter: false,
        isSearching: hasData,
      });
    }
    handleFocus = () => {
      this.setState({ showSearchList: true,isSearching:true });
      setStorageData("isSearchOpened",true)
    };
  
    handleBlur = () => {
      this.setState({ showSearchList: false });
    };
  
  handleSearchQuery =(e:any)=>{
    if (this.searchDebounceTimer) { 
    clearTimeout(this.searchDebounceTimer);
     }
     if(e.target.value==""){
        this.props.handleShowCategory()
        this.setState({isSearching:true,categoryData:[]})
     }
     this.setState({searchQuery:e.target.value},()=>{
    if(this.state.searchQuery){
      this.handleSearchDebounce()
    }else{
      this.props?.handleShowCategory()
    }
     }
    )
    }

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  getAdvancedSearchList = (token: string) => {
    const header={
      token
    }
    return header
  };
  handleOpenAllFilter=()=>{
    this.setState({allFilter: true},()=>{
      this.handleFilterData()
    });
  }
  handleFilterData = async () => {
        const tokenIs= await getStorageData('loginToken')
        this.getFilterOptionsAPi= this.apiCall(tokenIs,{
          method:"GET",
          endPoint:`//bx_block_catalogue/catalogues/load_new_arrivals_filter`,
      })
      }
      renderValueLabel = (value: number) => {
        return `$${value}`;
      };
      handleSearchChange = (newInputValue: string) => {
        this.setState({ searchTerm: newInputValue });
      };
      handleSelectBrand = (selectedBrand: any) => {
        this.setState({ 
          searchTerm: selectedBrand ? selectedBrand.name : '',
          brand: selectedBrand ? selectedBrand.id : '' 
        });
      };
      handlesizechange=(size:any)=>{
       this.setState({size:size})
      }
      handleDatePosted=(selectedDate:string)=>{
        this.setState({datePosted:selectedDate})
      }
      handleColorChange=(color:any)=>{  
       this.setState({color})
      }
      handleWomenFilter = () => {
        this.setState({sortingByWomen:!this.state.sortingByWomen},this.handleApplyButton)
      };
      handleApplyButton =async () => {
        const endPointWithFilters=this.getApplyFilterParams();
        const token=await getStorageData("loginToken")
        this.filterApplyApiId= this.apiCall(token,{
          method:"GET",
          endPoint:endPointWithFilters
        })
      }
      handleyearChange=(year:any)=>{
        this.setState({year})
      }
      handlePriceChange = (event: any, newValue: number | number[]) => {
        if (Array.isArray(newValue) && newValue.length === 2) {
          this.setState({ priceRange: newValue });
        }
      };
      handleSubCategorySelect = (subCat: any) => {
        const { subCategory } = this.state;
        this.setState({
          subCategory: subCategory.includes(subCat)
            ? subCategory.filter((name:string) => name !== subCat)
            : [...subCategory, subCat],
        });
      };
      handleClearAllBtn = () => {
        this.setState({allFilter:false,selectedCategoryList:[],year:"",color:"",
          size:"",
          datePosted:"",
          subCategory:[],
          filteredBrands:[],
          searchTerm:"",
          brand:0,
          sortingByMen:false,
          sortingByWomen:false
        },()=>this.handleApplyButton())
        
            this.handleCloseAllFilter()
        
          }
      getApplyFilterParams = () => {
        const filterParams=this.getFilterParams();
        const endpoint=this.getEndpoint()
      
        return `${endpoint}&&${filterParams}`;
      };
      getEndpoint = () => {
       const endpoint=`/bx_block_advanced_search/search/catalogues?query=${this.state.searchQuery}`
          return endpoint
      };
      getFilterParams = () => {
      
        const { selectedCategoryList, sortOption, sortingByWomen, sortingByMen, brand, subCategory, year, color, size } = this.state;
        const params:any = {};
      
        if (sortOption) params.sort_by = sortOption;
        if (sortingByWomen) params.sorted_by_women = sortingByWomen;
        if (sortingByMen) params.sorted_by_men = sortingByMen;
        if (brand) params.filter_by_brand_ids = brand;
        if (subCategory) params.filter_by_subcategory_ids = subCategory;
        if (year) params.filter_by_product_year_production = year;
        if (color) params.filter_by_color_ids = color;
        if (size) params.filter_by_size_ids = size;
        if (this.state.selectedCategoryList) params.filter_by_category_ids=selectedCategoryList?.length ? selectedCategoryList.join(",") : null
        return this.buildParams(params);
      };
      buildParams = (paramsObj:any) => {
        const params = new URLSearchParams();
        Object.entries(paramsObj).forEach(([key, value]:any) => {
          if (value) {
            params.append(key, value);
          }
        });
        return decodeURIComponent(params.toString());
      };
      handleMenFilter = () => {
        this.setState({sortingByMen:!this.state.sortingByMen},this.handleApplyButton)
      }
      handleSortOption = (event: SelectChangeEvent) => {  
        this.setState({ sortOption:event.target.value }, this.handleApplyButton);
    };
    handleCloseAllFilter = () => {
      this.setState({allFilter: false})
    }
    navigateToDetailedProductView=(productId:string)=>{
      this.props.navigation.navigate("ProductDescription",{productId:productId,path:{productId:productId}})
    }
  // Customizable Area End
}
