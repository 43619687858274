export const rightArrow = require("../assets/rightIcon.png").default;
export const rightArrowIcon = require("../assets/rightArrow.svg").default;
export const deliveryIcon = require("../assets/deliveryIcon.svg").default;
export const orderStatusDot = require("../assets/orderStatusDot.svg").default;
export const stepperStatusIcon = require("../assets/stepperCheckedIcon.svg").default;
export const paymentVisa = require("../assets/paymentVisa.png").default;
export const locationIcon = require("../assets/location.svg").default;
export const invoiceIcon = require("../assets/invoice.svg").default;
export const locationTracker = require("../assets/locationTracker.svg").default;
export const needHelpIcon = require("../assets/needHelpIcon.svg").default;
export const orderIssue = require("../assets/issueIcon.svg").default;

