Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.profileEndPointApi='bx_block_profile/profiles'
exports.contactUsApiEndPoint='bx_block_contact_us/contacts'
exports.endPointCart=(cartId)=>`bx_block_shopping_cart/orders/${cartId}`
exports.filteredCatalogues=(categoryId)=>`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
exports.endPointgetcategoriesArrayData='bx_block_categories/categories'
exports.homeText='Home'
exports.ContactUs='Contact Us'
exports.Fill_in_the_form_below_and_we_will_get_back_to_you_as_soon_as_possible=`Fill in the form below, and we will get back to you as soon as possible!`

exports.first_Name='First Name*'
exports.last_Name='Last Name*'
exports.email='Email*'

exports.Your_last_name='Your last name'
exports.Your_first_name='Your first name'
exports.your_Email='Your email'
exports.phone_Number='Phone Number*'
exports.phoneErr='Invalid phone number'
exports.emailErr='Invalid email'
exports.message='Message*'
exports.writeHere='write here..'
exports.queryArray=[
  { value: 'Complaints',label:'Complaints'},
  { value: 'Feedback',label:'Feedback'},
  { value: 'Help request',label:'Help request'}
]
exports.type_of_query='Type of query'
exports.Select='Select'
exports.sendMessage='Send Message'
exports.successMessageHeading='Success!'
exports.go_to_Home='Go to Home'
// Customizable Area End