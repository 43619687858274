//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface ProductDesc {
  offer_id: string;
  status: string;
  offer_date: string;
  order_price: string;
  offer_price: string;
  wearname: string;
  category_name: string;
  brand_name: string;
  condition: string;
  material: string | null;
  product_use: string | null;
  product_water_resistance: string | null;
  product_year_production: number | null;
  packages: string;
  dust_bag: boolean;
  description: string;
}

interface ApiResponse {
    variant_info: any;
    productDesc: ProductDesc; 
    offer_id: string;
    status: string;
    offer_date: string;
    order_price: string;
    offer_price: string;
    wearname: string;
    category_name: string;
    brand_name: string;
    condition: string;
    material: string | null;
    product_use: string | null;
    product_water_resistance: string | null;
    product_year_production: number | null;
    packages: string;
    dust_bag: boolean;
    description: string;
}

interface Product {
  id: number;
  offer_id: string;
  status: string;
  product_name: string;
  brand_name: string;
  model_number: string;
  category_name: string;
  wearname: string;
  expired_date: string;
  created_at: string;
  updated_at: string;
  offer_date: string;
  order_price: string;
  offer_price: string;
  condition: string;
  material: string | null;
  product_use: string | null;
  product_water_resistance: string | null;
  product_year_production: number;
  packages: string;
  dust_bag: boolean;
  description: string;
  variant_info: VariantInfo;
}

interface VariantInfo {
  id: number;
  earning: number;
  catalogue_id: number;
  catalogue_variant_color: CatalogueVariantColor;
  catalogue_variant_size: CatalogueVariantSize;
  one_size: boolean;
  price: string;
  images: Image[];
}

interface CatalogueVariantColor {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  color_code: string;
}

interface CatalogueVariantSize {
  id: number;
  name: string;
  size: string | null;
  width: string | null;
  weight: string | null;
  height: string | null;
  category_id: number | null;
  scale: string | null;
}

interface Image {
  id: number;
  blob_id: number;
  url: string;
}

interface Meta {
  message: string;
}


interface Payload {
  method: string;
  endPoint: string;
}

interface Header {
  'Content-Type': string;
  token: string;
}

interface CartItemAttributes {
  id: number;
  catalogue_id: number;
  catalogue_variant_id: number;
  quantity: number;
  name: string;
  description: string;
  model_number: string;
  variant_color: string;
  variant_size: string;
  variant_qty: number;
  is_available: boolean;
  price: string;
  is_offer_available: boolean;
  image_url: string;
  is_favorited: boolean;
}

interface OrderItem {
  id: string;
  type: string;
  attributes: CartItemAttributes;
}

interface CartResponse {
  id: number;
  uuid: string;
  item: OrderItem;
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productDesc: ApiResponse;
  wishlistData: string;
  deletePopup: boolean;
  productId: number;
  showBuyNow: boolean;
  deleteOffer: boolean;
  bigImage: string;
  productExist: boolean;
  cartCount: number;
  imageId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OfferProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productDescriptionApiCallId:string="";
  wishlistApiCallId:string="";
  deleteApiCallId:string="";
  buyNowApiCallId: string="";
  cartApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      productDesc: [],
      wishlistData: '',
      deletePopup: false,
      productId: 0,
      showBuyNow: false,
      deleteOffer: false,
      bigImage: '',
      productExist: false,
      cartCount: 0,
      imageId: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
      if (apiRequestCallId && responseJson) {
        const apiHandlers = {
          [this.productDescriptionApiCallId]: this.handleProductDescResponse,
          [this.deleteDataApiCallId]: this.handleDeleteApiData,
          [this.buyNowApiCallId]: this.handleResponseApiData,
          [this.cartApiCallId]: this.handleCartApiData,
        };
    
        const handler = apiHandlers[apiRequestCallId];
        if (handler) {
          handler.call(this, responseJson);
        }
      }
    }
    
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleProductDescription()
    this.handleCart()
  }

  handleDeleteApiData = (responseJson: Delete) => {
    if(responseJson){
      this.setState({deleteOffer: false})
      this.props.navigation.navigate("RequestManagement")
    }
  }

  handleResponseApiData = (responseJson: OrderItem) => {
    if(responseJson.message){
      this.props.navigation.navigate('ShoppingCart')
    }
  }

  handleCartApiData = (responseJson) => {
    let productCatalogueId = this.state.productDesc?.variant_info?.attributes?.catalogue_id;

  const productExists = productCatalogueId 
    ? responseJson.data?.attributes?.order_items?.some(
        (item) => item.attributes?.catalogue_id === productCatalogueId
      ) 
    : false;

    this.setState({ 
      productExist: productExists,
      cartCount: responseJson.data.attributes.order_items.length
    });
};


  handleProductDescResponse = (responseJson: ApiResponse) => {
    if(responseJson){
      this.setState({
        productDesc: responseJson.data.attributes
  }, () => this.setState({
        bigImage: this.state.productDesc.variant_info?.attributes.images[0].url,
        imageId: this.state.productDesc.variant_info?.attributes.images[0].id
      }))}
  }

  handleWishRequest = (responseJson: string) => {
    this.setState({wishlistData: responseJson})
  }

  apiCall = async (data: ApiData) => {
    const token = await getStorageData("loginToken")

    const { contentType, method, endPoint, body } = data;
    
    const header = {
      'Content-Type': contentType,
      'token': token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleProductDescription = async () => {
    const productId=new URLSearchParams(window.location.search).get('productId')
    
    this.productDescriptionApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.productDescriptionApiEndPoint}+${productId}`,
    });
  }

  handleWishlist = async () => {
    let data = {
      "wishlist": {
          "catalogue_variant_id": 50
      }
    }
    this.wishlistApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.wishlist,
      body: data
    });
  }

  handleDeletePopupOpen = () => {
    this.setState({ deleteOffer: true })
  }

  handleDeletePopupClose = () => {
    this.setState({ deleteOffer: false })
  }

  handleDeleteOfferRequest = async () => {
    const { id } = this.state.productDesc;
    const loginToken = await getStorageData("loginToken")
  
    this.deleteDataApiCallId = await this.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.deleteOfferApiEndPoint}+${id}`,
      token: loginToken
    })

  }
  
  handleHomeNavigate = () => {
    this.props.navigation.navigate("Home")
  }

  handleOfferNavigate = () => {
    this.props.navigation.navigate("RequestManagement")
  }

  handleBigImage = (url: string, id: number) => {
    this.setState({bigImage: url, imageId: id})
  }

  handleBuyNow = async () => {
    const body = {
      data: {
        "catalogue_variant_id": Number(this.state.productDesc.variant_info.id),
        "catalogue_id": Number(this.state.productDesc.variant_info.attributes.catalogue_id),
      },
    };

    this.buyNowApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.addToCartApiEndPoint}`,
      body: body
    });

  }

  handleCart = async () => {
    const loginToken = await getStorageData("loginToken")

    this.cartApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getCartDataApiEndPoint}`,
      token: loginToken
    })
  }
  // Customizable Area End
}
