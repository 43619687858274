Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.categoriesEndPoint = "/bx_block_catalogue/catalogues/filtered_catalogues"
exports.filterEndPoint = "/bx_block_categories/categories/filter_view"
exports.moveToFavorite='bx_block_wishlist/wishlists/add_favorite_or_remove';
exports.sortOptions={
  "recent":"Recent",
  "price_low_to_high":"Price: Low to High",
  "price_high_to_low":"Price: High to Low",
  "production_year":"Production Year",
  "date_of_listing":"Date Of Listing"
}
// Customizable Area End