import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  page:number,
  perPageData:number,
  tabelData:any[],
  openConfirmationModal:boolean,
  deleteProductId:string|number|null,
  searchQuery:string,
  metaData:any,
  sortOption:{value:string,label:string}
  congratsMessageShow:boolean;
  openFilter:boolean;
  categoryList:any[];
  selectedCategoryList:any[];
  brandsList:any[];
  selectedBrandList:any[];
  statusList:any[];
  selectedStatusFilterList:any[];
  fromDateFilter:any;
  openFromCalendar:boolean;
  openToCalendar:boolean;
  toDateFilter:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WishRequestsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllWishRequestsAPI:string="";
  searchDebounceTimer:any=null;
  deleteProductsApi:string="";
  searchAPIId:string="";
  filterListApi:string="";
  filterApplyApiId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page:1,
      perPageData:5,
      tabelData:[],
      openConfirmationModal:false,
      deleteProductId:null,
      searchQuery:"",
      metaData:{},
      sortOption:{value:"",label:"Sort By : Recent"},
      congratsMessageShow:false,
      openFilter:false,
      categoryList:[],
      selectedCategoryList:[],
      brandsList:[],
      selectedBrandList:[],
      statusList:[],
      selectedStatusFilterList:[],
      fromDateFilter:"",
      openFromCalendar:false,
      openToCalendar:false,
      toDateFilter:"",
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if(apiRequestCallId==this.getAllWishRequestsAPI || apiRequestCallId===this.searchAPIId || apiRequestCallId==this.filterApplyApiId){
    this.setState({tabelData:responseJson.data,page:responseJson.meta.current_page,metaData:responseJson.meta,perPageData:5})
    }else if(apiRequestCallId==this.deleteProductsApi){
      if(responseJson.message){
    this.getWishRequestsList()
      }
    }else if(apiRequestCallId===this.filterListApi){
      this.setState({
        categoryList:responseJson?.categories,
        brandsList:responseJson?.brands,
        statusList:responseJson?.status,
      })
    }
    
    // Customizable Area End
  }

  componentDidMount = async () => {
    // Customizable Area Start
    this.getWishRequestsList();
    this.getFilterList();
    this.setState({selectedCategoryList:[0],
      selectedBrandList:[0],
      selectedStatusFilterList:[0]
    })

    // Customizable Area End
  };
  // Customizable Area Start
  handleChangePage = (_event: unknown, newPage: number) => {
    this.setState({page:newPage+1},this.getWishRequestsList)
  };
  getApplyFilterParams = () => {
    let filterParams = "";  
    const params = new URLSearchParams();
  
    const removeAll = (arr: any[]) => arr.filter(item => item !== 0);
  
    const selectedCategories = this.state.selectedCategoryList 
      ? removeAll(this.state.selectedCategoryList) 
      : [];
  
    const selectedBrands = this.state.selectedBrandList 
      ? removeAll(this.state.selectedBrandList) 
      : [];
  
    if (selectedCategories.length > 0) {
      params.append("filter_by_category_ids", selectedCategories.join(","));
    }
    
    if (selectedBrands.length > 0) {
      params.append("filter_by_brand_ids", selectedBrands.join(","));
    }
  
    if (this.state.fromDateFilter && this.state.toDateFilter) {
      params.append("offer_date_from", this.state.fromDateFilter);
      params.append("offer_date_to", this.state.toDateFilter);
    }      
  
    filterParams = decodeURIComponent(params.toString());
    return `?${filterParams}`;
  };
  
  handleSortOptionChange=(data:any)=>{
    this.setState({sortOption:data},()=>{
        this.getWishRequestsList(true)
    })
  }
  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      perPageData:parseInt(event.target.value),
      page:1,
    },this.getWishRequestsList)
  };
  handleOpenConfirmationModal=(productId:string|number)=>{
    this.setState({openConfirmationModal:!this.state.openConfirmationModal,deleteProductId:productId})
  }
  deleteProduct=async()=>{
    const token =await getStorageData("loginToken") 
    this.deleteProductsApi=this.wishApiCall(token,{
      method:"DELETE",
      endPoint:`/bx_block_request_management/wish_requests/${this.state.deleteProductId}`,
    })
  }
  handleConfirmationModal=()=>{
    this.setState({openConfirmationModal:false},this.deleteProduct)
  }
  handleCloseConfirmationModal=()=>{
    this.setState({openConfirmationModal:false})
  }
  handlesearchChange=(e:any)=>{
    this.setState({searchQuery:e.target.value},()=>{
      this.handleSearchDebounce()
    })
  }
  handleSearchDebounce = () =>{
    this.searchDebounceTimer = setTimeout(async() => {
      this.searchApiCall()
      
     }, 500);
    }
    searchApiCall=async()=>{
      const token=await getStorageData("loginToken");
      const endPoint=this.state.searchQuery?`/bx_block_request_management/wish_requests?query=${this.state.searchQuery}&&per_page=${this.state.perPageData}&page=${this.state.page>0 && this.state.page}`:
      `/bx_block_request_management/wish_requests?per_page=${this.state.perPageData}&page=${1}`
        this.searchAPIId=this.wishApiCall(token,{
          method:"GET",
          endPoint:endPoint
        })
    }
    getWishRequestsList = async (sorting?: boolean) => {
        const searchQuery = this.state.searchQuery ? `&&query=${this.state.searchQuery}` : "";
        const token = await getStorageData("loginToken");
      
        const sortingQuery = sorting ? `&&sort_by=${this.state.sortOption.value}` : "";
      
        this.getAllWishRequestsAPI = this.wishApiCall(token, {
          method: "GET",
          endPoint: `/bx_block_request_management/wish_requests?per_page=${this.state.perPageData}&&page=${this.state.page > 0 ? this.state.page : 1}${searchQuery}${sortingQuery}`,
        });
      };
      getFilterList=async()=>{
        const token=await getStorageData("loginToken");
        this.filterListApi=this.wishApiCall(token,{
          method:"GET",
          endPoint:`/bx_block_request_management/wish_requests/wish_filter`
        })
      }
      handleFromCalendar=()=>{
        this.setState({openFromCalendar:!this.state.openFromCalendar})
      }
      handleFilter=()=>{
        this.setState({openFilter:!this.state.openFilter,fromDateFilter:"",toDateFilter:""})
      }
      handleCancelFilter=()=>{
        this.setState({
        
        },this.handleFilter)
      }
      handleApplyBtn=async()=>{
        const filters=this.getApplyFilterParams();
        const token=await getStorageData("loginToken")
        this.filterApplyApiId=this.wishApiCall(token,{
          method:"GET",
          endPoint:`/bx_block_request_management/wish_requests${filters}&&page=${this.state.page}&&per_page=${this.state.perPageData}`
        })
        this.handleFilter();
      }
      handleToCalendar=()=>{
        this.setState({openToCalendar:!this.state.openFromCalendar})
      }
      handleToDate = (newValue: Date | null) => {
        this.setState({ toDateFilter: moment(newValue).format("DD-MM-YYYY") });
      };
      handleCategorySelect = (subCat: any) => {
        const { selectedCategoryList } = this.state;
        this.setState({
          selectedCategoryList: selectedCategoryList.includes(subCat)
            ? selectedCategoryList.filter((name:any) => name !== subCat)
            : [...selectedCategoryList, subCat],
        });
      };
      handleBrandSelect = (subCat: any) => {
        const { selectedBrandList } = this.state;
        this.setState({
          selectedBrandList: selectedBrandList.includes(subCat)
            ? selectedBrandList.filter((name:any) => name !== subCat)
            : [...selectedBrandList, subCat],
        });
      };
      handleStatusFilterSelect = (subCat: any) => {
        const { selectedStatusFilterList } = this.state;
        this.setState({
          selectedStatusFilterList: selectedStatusFilterList.includes(subCat)
            ? selectedStatusFilterList.filter((name:any) => name !== subCat)
            : [...selectedStatusFilterList, subCat],
        });
      };
      handleFromDate = (newValue: Date | null) => {
        this.setState({ fromDateFilter: moment(newValue).format("DD-MM-YYYY") });
      };
      
  wishApiCall = (token: any,payload:any) => {
    let {method,endPoint,body,contentType,type}=payload
    const header = {
      'Content-Type':contentType|| 'application/json',
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    body && type !== 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleNavigate = (data:{attributes:{id:number,status:string}}) => {
    if(data?.attributes?.status=='found'){
      this.props?.navigation?.navigate('FoundWishDetails',{Id:data?.attributes?.id})
    }else{
      this.props?.navigation?.navigate('MyWishDetails',{Id:data?.attributes?.id})
    }
  }

  // Customizable Area End
}