import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import { OptionTypeBase } from "react-select";
type ExtraType = number | string | null
export interface WishDetalsinterFace {
  id: number | string,
  type: string,
  attributes: {
    id: number | string,
    request_id: string,
    production_year: number | string,
    name: string,
    description: string,
    product_model_number: number | string,
    created_at: string,
    updated_at: string,
    status: string,
    category: string,
    brand: string,
    request_date: string,
    assign_date: number | string,
    rejected_date: number | string,
    customer: string,
    customer_name:string,
    location:string,
    catalogue_list: [
      {
          id: number|string,
          shopper_id: number|string,
          catalogue_id: number|string,
          catalogue_variant_id: number|string,
          catalogue_wear: string,
          catalogue_product_category: string,
          catalogue_product_brand: string,
          catalogue_product_condition: string,
          catalogue_product_material: string,
          catalogue_product_use: string,
          catalogue_product_water_resistance: string,
          catalogue_product_year_production: number|string,
          catalogue_packages: string,
          catalogue_dust_bag: boolean,
          catalogue_product_description: string
      }
  ],
    product_image: [{
      id: number | string,
      blob_id: number | string,
      url: string
    }],
  }
}
export interface ShoppingCartItem {
  id?: number,
  type?: string,
  attributes: {
    id: number,
    catalogue_id?: number,
    catalogue_variant_id?: number,
    quantity: number,
    name?: string,
    description?: string,
    model_number?: string,
    variant_color?: string,
    variant_size?: string,
    variant_qty?: number,
    approved_price?: string,
    price?: string,
    is_offer_available?: boolean,
    image_url?: string


  };
}
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;






  cartData: ShoppingCartItem[];
  openLoginModal: boolean;
  categoriesArrayData: [];
  showCategoryPage: boolean;
  categoryId: number | string;
  categoryName: string;
  categoryData: string[];
  wishData: WishDetalsinterFace;
  isActiveImage: string | number;
  isBigImage: {
    id: number | string,
    blob_id: number | string,
    url: string
  };
  isExpand: boolean;
  isRequestDeleteModal: boolean;
  deleteId: string | number;
  isPresentInCart:boolean;
  statusValue:{ label: string, value:string }|null|undefined|OptionTypeBase
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyWishDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  cartDataApiCallId?: string;
  categoriesArrayDataApi?: string;
  categoryFilterApi?: string;
  wishDetailApiCallId?: string;
  wishDetailShopperApiCallId?: string;
  wishDeleteApiCallId?: string;
  addToCartApi?:string;
  statusUpdateApi?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",

      cartData: [],
      openLoginModal: false,
      categoriesArrayData: [],
      showCategoryPage: false,
      categoryId: '',
      categoryName: '',
      categoryData: [],
      wishData: {
        id: '',
        type: '',
        attributes: {
          id: '',
          request_id: '',
          production_year: '',
          name: '',
          description: '',
          product_model_number: '',
          created_at: '',
          updated_at: '',
          status: '',
          category: '',
          brand: '',
          request_date: '',
          assign_date: '',
          rejected_date: '',
          customer: '',
          customer_name:'',
          location:'',
          catalogue_list: [
            {
                id: '',
                shopper_id: '',
                catalogue_id: '',
                catalogue_variant_id: '',
                catalogue_wear: '',
                catalogue_product_category: '',
                catalogue_product_brand: '',
                catalogue_product_condition: '',
                catalogue_product_material: '',
                catalogue_product_use: '',
                catalogue_product_water_resistance: '',
                catalogue_product_year_production: '',
                catalogue_packages: '',
                catalogue_dust_bag: false,
                catalogue_product_description: ''
            }
        ],
          product_image: [{
            id: '',
            blob_id: '',
            url: ''
          }]
        }
      },
      isActiveImage: '',
      isBigImage: {
        id: '',
        blob_id: '',
        url: ''
      },
      isExpand: false,
      isRequestDeleteModal: false,
      deleteId: '',
      isPresentInCart:false,
      statusValue:{ label: "Assign", value: "assign" }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson?.errors) {
      switch (apiRequestCallId) {
        case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items },()=>this.handleMatch())
          break;
        case this.categoriesArrayDataApi: this.setState({ categoriesArrayData: responseJson?.data })
          break;
        case this.categoryFilterApi: this.setState({ categoryData: responseJson?.data })
          break;
        case this.wishDetailApiCallId: this.setState({ wishData: responseJson?.data, isActiveImage: responseJson?.data?.attributes?.product_image[0]?.id, isBigImage: responseJson?.data?.attributes?.product_image[0] },()=>this.handleMatch())
          break;
        case this.wishDetailShopperApiCallId: this.setState({ wishData: responseJson?.data, isActiveImage: responseJson?.data?.attributes?.product_image[0]?.id, isBigImage: responseJson?.data?.attributes?.product_image[0] })
          break;
        case this.addToCartApi: this.handleFetchCartData()
          break;
        case this.statusUpdateApi: const productId = new URLSearchParams(window?.location?.search).get('Id')
          this.handleFetchWishDetailsShopper(productId)
          break;
           
      }
    }
    if (responseJson && responseJson?.errors) {
      if (responseJson?.errors[0]?.token) {
        removeStorageData('loginToken')
        removeStorageData('loginDetails')
        this.props?.navigation?.navigate('Home')
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {

    const productId=new URLSearchParams(window?.location?.search).get('Id')
    this.handleFetchWishDetails(productId)
    this.handleFetchWishDetailsShopper(productId)
    this.handleFetchCartData()
    this.getcategoriesArrayData()
    window?.scrollTo({top:0,behavior:'smooth'})
  };

  closeLoginModal = () => {
    this.setState({ openLoginModal: false })
  }
  openLoginModal = () => {
    this.setState({ openLoginModal: true })
  }
  handleFetchCartData = async () => {
    const cartId = await getStorageData('cartId')
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartDataApiCallId = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCart(cartId)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  getcategoriesArrayData = () => {
    const requestMessageDataCateg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesArrayDataApi = requestMessageDataCateg.messageId;
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointgetcategoriesArrayData
    );
    runEngine.sendMessage(requestMessageDataCateg.id, requestMessageDataCateg);

  }

  handleCategoryData = async (categoryName: string, categoryId: number) => {
    this.props?.navigation?.navigate("CategoriesWeb",{categoryId,categoryName})
  }

  handleNavigate = (path: string) => {
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  handleFetchWishDetails = async (productId: ExtraType) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishDetailApiCallId = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointWishDetailsApi(productId)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  handleImageChange = (imageData: { id: number | string, blob_id: number | string, url: string }) => {
    this.setState({ isActiveImage: imageData.id, isBigImage: imageData })
  }
  handleCheckPresent = (text: ExtraType | undefined) => {
    if (text == null || text == undefined || text == 'undefined'||text== '') {
      return false
    } {
      return true
    }
  }
  handleDeleteRequest = async (deleteId: number | string) => {
    this.setState({ isRequestDeleteModal: false })
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const requestMessagewishDelete = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishDeleteApiCallId = requestMessagewishDelete.messageId;
    requestMessagewishDelete.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    requestMessagewishDelete.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointWishDetailsApi(deleteId)
    );
    requestMessagewishDelete.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessagewishDelete.id, requestMessagewishDelete);
  }
  buyNowAddToCart = async (catalogue_id:number|string,catalogue_variant_id:number|string) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const body={
      data: {
        catalogue_variant_id: catalogue_variant_id,
        catalogue_id: catalogue_id
    }
    }
    const requestMessageBuyNowAddToCart = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCartApi = requestMessageBuyNowAddToCart.messageId;
    requestMessageBuyNowAddToCart.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageBuyNowAddToCart.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointAddToCart
    );
    requestMessageBuyNowAddToCart.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageBuyNowAddToCart.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageBuyNowAddToCart.id, requestMessageBuyNowAddToCart);
  }
  handleMatch = () => {
    const orderItem = this.state.cartData
    const catalogueList = this.state.wishData?.attributes?.catalogue_list && this.state.wishData?.attributes?.catalogue_list[0]
    const isIPresent = orderItem?.some((element) => element?.attributes?.catalogue_variant_id == catalogueList?.catalogue_variant_id && element?.attributes?.catalogue_id == catalogueList?.catalogue_id)
    this.setState({ isPresentInCart: isIPresent })
  }
  handleChangeStatus = async (productId: ExtraType,status:string) => {
    const token = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(token && { token: token })
    }
    const body = {
      "status": status,
    }
    const requestMessageChangeStatus = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.statusUpdateApi = requestMessageChangeStatus.messageId;
    requestMessageChangeStatus.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );
    requestMessageChangeStatus.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointWishDetailsApi(productId)
    );
    requestMessageChangeStatus.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageChangeStatus.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageChangeStatus.id, requestMessageChangeStatus);
  }
  handleChangeSelect =(event:{ label: string, value:string }|undefined|null|OptionTypeBase)=>{
   this.setState({statusValue:event})
  }
  handleCheckStatusBeforeNavigation = () => {
    const value = this.state.statusValue
    if (value?.value == 'found') {
      return false
    }else{
      return true
    }
  }
  handleNavigation = (path: string, itemId: number | string) => {
    this.props?.navigation?.navigate(path, { Id: itemId })
  }
  handleFetchWishDetailsShopper = async (productId: ExtraType) => {
    const tokenIs = await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      ...(tokenIs && { token: tokenIs })
    }
    const requestMessageShopper = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishDetailShopperApiCallId = requestMessageShopper.messageId;
    requestMessageShopper.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageShopper.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointWishDetailsShopperApi(productId)
    );
    requestMessageShopper.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageShopper.id, requestMessageShopper);
  }
  // Customizable Area End
}