import React from "react";

// Customizable Area Start
import OTPInputAuthController, {
    Props,
  } from "./OTPInputAuthController";
import OTPModal from "../../../components/src/popups/ForgotPasswordOtpPopUp.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class OTPInputAuthWeb extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <OTPModal
      otpErr={this.state.otpErr}
      handleCloseOtpModal={this.handleClose}
      openOtpModal={this.props.openOtpModal}
      handleSubmitOtp={this.handleSubmit}
      handleResendOtp={this.handleResendOtp}
      data-test-id='otpModal'
      />
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End