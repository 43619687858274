import React from "react";

// Customizable Area Start
import {
  styled,
} from "@mui/material/styles";
import ShopperHeader from "../../../components/src/ShopperHeader.web";
import ShopperFooter from "../../../components/src/ShopperFooter.web";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AddCartConfirmationPopUp from "../../../components/src/popups/addToCartConfirmation.web"


import {rightArrow,rightArrowIcon} from "./assets"
export const handleReturnElement = (title: string, value: string|number) => {
    return (
        <Box className='detailElementBox'>
            <Typography style={{ ...webStyle.titleHeading }}>{title}</Typography>
            <Typography style={{ ...webStyle.valueText,...webStyle.overflowText }}>{value||"-"}</Typography>
        </Box>
    )
}
// Customizable Area End

import RequstDetailsScreenController, {
  Props,
} from "./RequstDetailsScreenController.web";
import { Box, Button, Typography } from "@mui/material";

export default class RequstDetailsScreen extends RequstDetailsScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  priceDiv=()=>{
    return  this.state.navigationData.tabName=="Offers" &&  <div className="rprice" style={{display:"flex",gap:"12px"}}>
    <div className="priceDiv" style={{display:"flex",flexDirection:"column",
        justifyContent:"space-between",
        border:"1px solid #53503F",
        borderRadius:"6px",
        background:"white",
        width:"144px",
        height:"54px",
        alignItems:"center",
        boxSizing:"border-box",
        padding:"3px 0px",
        color:"#53503F"
    }}>
        <span style={{fontWeight:400,fontSize:"12px",fontStyle:"montserrat"}}>Listed Price</span>
        <span style={{fontWeight:600,fontSize:"18px",fontStyle:"montserrat"}}>{this.state.wishData?.listedPrice}</span>
    </div>
    <img src={rightArrowIcon} alt="arrow" />
    <div className="priceDiv" style={{display:"flex",flexDirection:"column",
        justifyContent:"space-between",
        border:"1px solid #53503F",
        borderRadius:"6px",
        background:"white",
        width:"144px",
        height:"54px",
        alignItems:"center",
        boxSizing:"border-box",
        padding:"3px 0px",
        color:"#53503F"
    }}>
        <span style={{fontWeight:400,fontSize:"12px",fontStyle:"montserrat"}}>Offer Price</span>
        <span style={{fontWeight:600,fontSize:"18px",fontStyle:"montserrat"}}>{this.state.wishData?.offerPrice}</span>
    </div>
 </div>
  }
  description=()=>{
    return  <Box className='productDetailsMainBoxDescBox'>
    <Typography style={{ ...webStyle.titleHeading }}>
        Description
    </Typography>
                                            <Typography style={{ ...webStyle.valueText }}>
  {this.state.isExpand 
    ? this.state.catalogueInfo?.description 
    : this.state.catalogueInfo?.description?.slice(0, 45)
  } 
  <span 
    data-test-id='readMore' 
    style={{ ...webStyle.titleHeading, cursor: 'pointer' }} 
    onClick={() => this.setState({ isExpand: !this.state.isExpand })}
  >
    {this.state.isExpand ? 'read less' : 'read more..'}
  </span>
</Typography>

</Box>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div data-test-id="detailsScreenId" style={{background:"rgb(237, 230, 220)"}}>
        <ShopperHeader/>
        <OuterMainBoxFoundRequest>


        <ArrowTopBox>
                    <Typography data-test-id='home' onClick={()=>{
                        this.props.navigation.navigate("Analytics")
                    }} className="arrowHomeText">{"Home"}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography className="arrowHomeText" data-test-id='OffersTest' onClick={()=>{
                        this.props.navigation.navigate("Analytics",{tab:this.state.navigationData.tabName,subTab:this.state.navigationData?.subTab})
                    }}> {this.state.navigationData?.tabName} </Typography>
                    <img 
                    src={rightArrow} 
                    alt="arrowIcon"
                     />
                    <Typography
                     className="arrowLastText"> {"Details"} </Typography>
        </ArrowTopBox>

              <MainParentBoxFoundRequest>
                    <ImageParentBoxFoundRequest>
                        <Box 
                         sx={{overflowY:(this.state.imagesData.length>6
                         )?'scroll':'unset'}}
                         className='smallImagesColumn'
                         >

                            {this.state.imagesData.length > 0&&this.state.imagesData?.map((element:any) => (
                                <Box style={{
                                    borderRadius: '4.77px',
                                    border: this.state.isActiveImage == element.id ? '1px solid rgba(83, 80, 63, 1)' : '1px solid transparent',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer',
                                    padding: '2.98px',
                                }} 
                                data-test-id='changeImage'
                                onClick={() => this.handleImageChange(element)}
                                >
                                 <Box  className='smallImageDiv'> <img src={element.url} alt="" /> </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box  className='bigImagesBox'
                        sx={{
                            height:" 479.36px",
                            width: "411.9px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderRadius: "4px",
                        }}
                        ><img style={{maxWidth: "100%",
                            maxHeight: "100%",
                            width:"241px",
                            height:"241px",
                            objectFit: "contain"}} src={this.state.isBigImage?.url||this.state.isBigImage} alt="" /></Box>
                    </ImageParentBoxFoundRequest>
                    <RightParentBoxFoundRequest>
                        <RightFirstTopBoxFoundRequest>
                             <Box
                              className='requestIdAndStatusParentBox'
                              ><Typography 
                              
                              className="requestedIdText"
                              >{this.state.navigationData.tabName==="Offers"?"Offer ID:":"Order ID:"} <span>{`#${this.state.wishData?.offerOrderNumber}`}</span></Typography>
                                <Typography 
                                // className="statusText"
                                className="dateParentBox"
                                style={{...webStyle.titleHeading}}
                                >{this.state.wishData?.offerOrderDate}
                                </Typography>
                             </Box>
                             <Box 
                             className='nameDateParentBox'
                             > <Typography 
                                className="nameText"> {this.state.wishData?.productName}</Typography>
                             </Box>
                             <Box 
                             className='requestIdAndStatusParentBox'
                             > <Typography 
                             className="dateParentBox"
                             style={{...webStyle.titleHeading,fontSize:"28px"}}
                                > {this.state.wishData?.price}</Typography>
                             </Box>
                        {this.priceDiv()}
                        </RightFirstTopBoxFoundRequest>
                        <RightMiddleBoxFoundRequest>
                            <Typography
                                className="productDetailsHeading"
                            >{"Product Details"}
                            </Typography>
                            <Box
                                className='productDetailsMainBox'
                            >
                                <Box className='productDetailsMainBoxTopBox'>
                                    {handleReturnElement("Wear",this.state.catalogueInfo?.wearName)}
                                    {handleReturnElement("Category",this.state.catalogueInfo?.categoryName)}
                                    {handleReturnElement("Brand",this.state.catalogueInfo?.brandName)}
                                    {handleReturnElement("Condition",this.state.catalogueInfo?.condition)}
                                    {handleReturnElement("Material",this.state.catalogueInfo?.material)}
                                 </Box>
                                <Box className='productDetailsMainBoxLowerBox'>
                                {handleReturnElement("Use",this.state.catalogueInfo?.use)}
                                {handleReturnElement("Water resistance",this.state.catalogueInfo?.waterResistance)}
                                {handleReturnElement("Production year",this.state.catalogueInfo?.productionYear)}
                                {handleReturnElement("Packages",this.state.catalogueInfo?.packages)}
                                {handleReturnElement("Dust Bags",this.state.catalogueInfo?.dustBags)}
                                  </Box>
                                  {this.description()}
                               
                                <Box className='productDetailsMainBoxDescBox'>
                                    <Typography style={{ ...webStyle.titleHeading }}>Product variants (01)
                                                                            </Typography>
                                    <Box sx={{height:"70px",background:"white",
                                    border:"1px solid #53503F",
                                        borderRadius:"4px",
                                        color:"#53503F",
                                        display:"flex",
                                        alignItems:"center",
                                        gap:"30px",
                                        maxWidth:"515px",
                                        boxSizing:"border-box",
                                        padding:"10px 20px"
                                    }}>
                                        <span style={{fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>1.</span>
                                        <img src={this.state.wishData?.variantInfo?.img}style={{borderRadius:"4px",border:"1px solid"}} width={50} height={50} alt="variant image" />
                                        <span style={{marginLeft:"-14px",fontWeight:600,fontSize:"18px",fontStyle:"montserrat"}}>{this.state.wishData?.variantInfo?.price}</span>
                                        <div style={{height:"22px",width:"22px",borderRadius:"4px",background:this.state.wishData?.variantInfo?.colorCode}}></div>
                                        <span style={{marginLeft:"-14px",fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>{this.state.wishData?.variantInfo?.colorName}</span>
                                        <span style={{fontWeight:600,fontSize:"16px",fontStyle:"montserrat"}}>{this.state.wishData?.variantInfo?.size}</span>
                                    </Box>
                                </Box>
                            </Box>
                        </RightMiddleBoxFoundRequest>
               
                        <RightMiddleBoxFoundRequest style={{borderBottom:"none",gap:"20px"}}>

                          <Typography
                                className="productDetailsHeading"
                                >{"Customer Details"}
                            </Typography>
                            <Box
                                className='productDetailsMainBox'
                            >
                                <Box 
                                // className='productDetailsMainBoxTopBox'
                                sx={{gap:"30px",display:"flex"}}
                                >
                                    {handleReturnElement("Customer Name",this.state.wishData?.customerName)}
                                    {handleReturnElement("Location",this.state.wishData?.customerLocation)}                                
                                        
                                 </Box>
                                 </Box>

                            { (this.state.navigationData.subTab=="Pending Offers"||this.state.navigationData.subTab=="Pending Orders")&&    <div style={{ display: "flex", gap: "15px",marginTop:"40px" }}>
                      <ResponsiveButton
                        style={{
                          ...webStyle.sellwithus,
                          backgroundColor: "rgba(83, 80, 63, 1)",
                          fontWeight: 600,
                          color: "rgba(237, 230, 220, 1)",
                          fontSize: "18px",
                          textTransform:"none",
                        }}
                        data-test-id="acceptbtn"
                        onClick={()=>{
                            this.handleOpenAcceptModal(this.state.wishData?.attributes?.id)
                            this.setState({requestType:this.state.navigationData.tabName==="Orders"?"order":"offer"})

                        }}
                      >
                      <CheckOutlinedIcon style={{fontSize:"18px",color:"rgba(239, 238, 233, 1)"}}/>  Accept
                      </ResponsiveButton>
                      <ResponsiveButton
                        style={{
                          ...webStyle.sellwithus,
                          fontSize: "18px",
                          backgroundColor: "#EDE6DC",
                          fontWeight: 600,
                          color: "#53503F",
                          textTransform:"none"
                        }}
                        data-test-id="rejectBtn"
                        onClick={()=>{
                            this.handleOpenRejectModal(this.state.wishData?.attributes?.id)
                            this.setState({requestType:this.state.navigationData.tabName==="Orders"?"order":"offer"})
                        }}
                      >
                        <CloseOutlinedIcon style={{fontSize:"18px",color:"rgba(83, 80, 63, 1)"}}/>
                        Reject
                      </ResponsiveButton>
                    </div>}
                        </RightMiddleBoxFoundRequest>
                    </RightParentBoxFoundRequest>
                  
                    </MainParentBoxFoundRequest>
                    </OuterMainBoxFoundRequest>

        <ShopperFooter/>
       {this.state.openAcceptModal && <AddCartConfirmationPopUp question={`Are you sure you want to accept the ${this.state.navigationData.tabName?.slice(0,-1)}?`
      } 
      data-test-id="confirmationModal"
      handleYes={this.handleAcceptYesClick} 
      handleClose={()=>{
        this.setState({openAcceptModal:false})
      }} 
      open={this.state.openAcceptModal} />
      }


{this.state.openRejectModal && <AddCartConfirmationPopUp question={`Are you sure you want to reject the ${this.state.navigationData.tabName?.slice(0,-1)}?`
      } 
      data-test-id="confirmationModal"

      handleYes={this.handleRejectYesClick} 
      handleClose={()=>{
        this.setState({openRejectModal:false})
      }} 
      open={this.state.openRejectModal} />
      }
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ArrowTopBox = styled(Box)({
    gap: '11px',
    display: 'flex',
    paddingLeft: '6px',
    alignItems: 'center',
    marginTop:"30px",
    "& .arrowHomeText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        cursor: 'pointer',
        fontWeight: 400,
    },

    "& .arrowLastText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
        fontSize: '18px',
    }

})
export const MainParentBoxFoundRequest = styled(Box)({
    display: 'flex',
    gap: '29.81px',
    width: '100%',
    '@media (max-width: 990px) ': {flexDirection:'column',alignItems:'center'}
})
export const OuterMainBoxFoundRequest = styled(Box)({
    display: 'flex',
    padding: '40px 60px 180px 40px',
    gap: '20px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'center',
    '@media (max-width: 400px) ': { padding: '10px',}
})
export const ImageParentBoxFoundRequest = styled(Box)({
    display: 'flex',
    gap: '7.72px',
    width: '485.19px',
    "& .smallImagesColumn": {height:'479.36px',
        width: "65.57px",
        display: 'flex',
        gap: '7.15px',
        flexDirection: 'column',
    },
    "& .smallImagesColumn>div> .smallImageDiv": {
        height: '62.59px',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '4.77px',
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .smallImagesColumn img": {
        height: '40.41px',
        width: '40.41px',
    },
    "& .bigImagesBox": {
        height: "479.36px",
        width: "411.9px",
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        objectFit: "contain"
    },
    "& .bigImagesBox img": {objectFit: 'none'},
     '@media (max-width: 600px) ': {
        flexDirection:'column-reverse',
        width:'100%',
        alignItems:'center',
        "& .smallImagesColumn": {
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            overflowX: 'scroll',
            height:'unset'
        },
        "& .smallImagesColumn>div> .smallImageDiv": { width: '100px', },
        "& .bigImagesBox": {width:'100%'  }
    }

})
export const RightParentBoxFoundRequest = styled(Box)({width: '100%',})

export const RightFirstTopBoxFoundRequest = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '17.93px',
    flexDirection: 'column',
    paddingBottom: '8px',
    boxSizing: 'border-box',
    borderBottom: "1px solid rgba(83, 80, 63, 0.5)",
    "& .requestIdAndStatusParentBox": {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    "& .requestedIdText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    "& .requestedIdText span": {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontSize: '20px',
    },

    "& .statusText": {
        backgroundColor: 'rgba(239, 238, 233, 1)',
        width: '124px',
        borderRadius: '16.45px',
        fontFamily: "'Montserrat', sans-serif",
        border: '0.5px solid rgba(83, 80, 63, 1)',
        fontWeight: 500,
        fontSize: '16px',
        display: 'flex',
        color: 'rgba(83, 80, 63, 1)',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 'normal',
        paddingBottom:'4px',
        paddingTop:'4px',
    },
    "& .dateParentBox": {
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
    },
    "& .nameText": {
        fontSize: '24px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        lineHeight: 'normal',
        overflow: 'hidden',
        color: 'rgba(83, 80, 63, 1)',
        textOverflow: 'ellipsis',
        maxWidth:'250px',
        whiteSpace: 'nowrap' as 'nowrap' ,
    },
    "& .nameDateParentBox": { display: 'flex', justifyContent: 'space-between', },
    "& .date": { textAlign: 'right' },
    "@media (max-width:450px)": {
        "& .nameText": { maxWidth: '150px' }
    }
})
export const RightMiddleBoxFoundRequest = styled(Box)({
    
    width: '100%',
    paddingTop:'20px',
    boxSizing:'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    borderBottom:"1px dashed rgba(83, 80, 63, 0.5)",
    paddingBottom:'20px',
    "& .productDetailsHeading":{fontFamily: "'Montserrat', sans-serif",fontSize: '20px',
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight:'normal'
    },
    "& .productDetailsMainBox":{display:'flex', flexDirection:'column',gap:'20px',},
    
    "& .productDetailsMainBoxTopBox, .productDetailsMainBoxLowerBox" :{ display:'grid',gap:'5px',gridTemplateColumns:'repeat(auto-fit, minmax(170px, 1fr))',},
     
    "& .detailElementBox":{
        display:'flex',
        gap:'12px',
        flexDirection:'column',
        width:'170px'
    },
    "& .productDetailsMainBoxDescBox":{display:'flex', flexDirection:'column', gap:'12px',},
})
const RightBottomBoxFoundRequest = styled(Box)({
    
    width: '100%',
    paddingTop: '45px',
    boxSizing: 'border-box',
    display:'flex',
    gap:'10px',
 
 
    '@media (max-width: 600px) ': {
        display: 'flex',
        justifyContent: 'center',
    },
    '@media (max-width: 895px) ': {
        flexDirection:'column',
        alignItems:'center'
    },

    
})
export const ResponsiveButton = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontFamily:
      "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    // padding: "10px 20px",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "8px 16px",
    },
    ".MuiButtonBase-root": {
      fontFamily:
        "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    },
  }));
const webStyle = {
    titleHeading: {
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 600,
    },
    valueText: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
        fontFamily: "'Montserrat', sans-serif",
    },
    overflowText:{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: 'hidden',
    },
    confirmationText: {fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 600,
        fontSize: '28px',
    },
    sellwithus: {
        fontSize: "18px",
        fontFamily:
          "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
        height: "56px",
        padding: "10px 16px",
        gap: "4px",
        borderRadius: "4px",
        border: "1px solid black",
        cursor: "pointer",
        width:"157px"
      },
}
// Customizable Area End
