import React from "react";
import { styled } from "@mui/system";
import { Modal, Box, Typography, IconButton, DialogActions, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FooterButton = styled("button")({
    width: "360px",
    height: "56px",
    padding: "16px 0px",
    borderRadius: "8px",
    color: "white",
    border: "none",
    backgroundColor: "#53503F",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "18px",
    fontWeight: 800,
    cursor: "pointer",
});

const RegistrationSuccess = ({showSuccess,handleClose}:any) => {
  return (
    <>
      <Modal
        open={showSuccess}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{              
          maxWidth: '100%',
          backdropFilter: "blur(8px)"
      }}
      >
        <Box sx={webStyle.main}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              onClick={handleClose}
              style={{ height: "24px", width: "24px", float: "right" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={webStyle.success}
              component="h2"
            >
              Success!
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={webStyle.passwordSuccess}
            >
              You have successfully registered 
              yourself on TRÉSOR
            </Typography>
            <FooterButton style={webStyle.continueShopping} onClick={handleClose}>Continue</FooterButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RegistrationSuccess;

const webStyle = {
    main: {
        boxSizing: "border-box",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#EFEEE9",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        maxWidth: "600px",
        width: "600px",
        outline: "none",
      },
    passwordSuccess: {
        marginTop:"25px",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "34px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "center",
        maxWidth: "355px",
        wordWrap: "break-word",
        whiteSpace: "normal",
        color: "#53503F",
    },
    continueShopping: {
        marginTop:"30px",
        marginBottom:"15px",
        fontSize: '18px',
        fontWeight: 600
    },
    success: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "24px",
        fontWeight: 800,
        lineHeight: "32px",
        letterSpacing: "-0.11999999731779099px",
        textAlign: "center",
        color: "#53503F",
      }
}