export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const eyeOpenIcon = require("../assets/image_.svg").default;
export const eyeCloseIcon = require("../assets/hidePassword.svg").default;
export const uploadIcon = require("../assets/view_.png").default;
export const rightArrowIcon = require("../assets/group_.png").default;
export const closeIcon = require("../assets/button_icon.png").default;
export const calenderIcon = require("../assets/button_calendar.svg").default;
export const checkBox = require("../assets/checkbox_.svg").default;
export const checkedBox = require("../assets/group_(1).svg").default;
export const downArrow = require("../assets/downArrow.png").default;