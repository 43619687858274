export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const rightArrow = require("../assets/rightIcon.png").default;
export const infoIcon = require("../assets/infoIcon.png").default;
export const downArrowIcon = require("../assets/downArrowIcon.png").default;
export const cardIcon = require("../assets/cardIcon2.png").default;
export const addIcon = require("../assets/addIcon.png").default;
export const lockIcon = require("../assets/lockIcon.png").default;
export const locationIcon = require("../assets/locationIcon.png").default;
export const deleteIconRed = require("../assets/deleteIcon2.png").default;
export const deActiveAccountIcon = require("../assets/deActiveIcon.png").default;
export const crossIcon=require('../assets/crossIcon.svg').default
export const visibilityIcon=require('../assets/visibilityEyeIcon.png').default
export const visibilityOffIcon=require('../assets/visibilityOffEyeIcon.png').default
export const checkBoxIcon=require('../assets/uncheckIcon.png').default
export const checkedIcon=require('../assets/chekedIcon.png').default






