import React from "react";

// Customizable Area Start
import { Box, Button, Modal, styled, Typography } from "@mui/material";

import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import { rightArrow,deleteIcon,crossIcon,buyNowIcon} from "./assets"
import MyWishDetailsController, {
  Props,
  configJSON
} from "./MyWishDetailsController";
// Customizable Area End


export default class FoundWishDetails extends MyWishDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

    handleDeleteRequestModal = (deleteId:number|string) => {
        return (
            <Box >
                <Modal
                    open={this.state.isRequestDeleteModal}
                    aria-labelledby="modal-modal-title"
                    data-test-id='requestDeleteModal'
                    onClose={() => this.setState({ isRequestDeleteModal: false })}
                    disableAutoFocus
                    aria-describedby="modal-modal-description"
                    disableEnforceFocus
                    sx={{ backdropFilter: "blur(5px)", outline: 'none' }}
                    
                >
                    <DeleteModalBox >
                        <Box 
                        data-test-id='crossIconBox'
                        onClick={() => this.setState({ isRequestDeleteModal: false })}
                         className="crossIconBox" 
                         >
                            <img src={crossIcon}
                             alt="cross" />
                        </Box>
                        <Typography style={styles.confirmationText}>
                            {configJSON.confirmation}</Typography>
                        <Typography   
                        className="areYouSureText"
                        >{configJSON.are_you_sure_you_want_to_delete_the_wish_request}
                        </Typography>
                        <CancelAndYesButtonBox>
                            <CancelButton
                             onClick={() => this.setState({ isRequestDeleteModal: false })}
                             data-test-id='cancelButton'
                              variant="contained" >{configJSON.not_now}
                              </CancelButton>
                            <RemoveDeleteButton data-test-id='removeButton'
                             variant="contained" 
                             onClick={()=>this.handleDeleteRequest(deleteId)} 
                             >{configJSON.yes}</RemoveDeleteButton>
                        </CancelAndYesButtonBox>
                    </DeleteModalBox>
                </Modal>
            </Box>
        )
    }
    handleReturnElement = (title: string, value: string|number) => {
        return (
            <Box className='detailElementBox'>
                <Typography style={{ ...styles.titleHeading }}>{title}</Typography>
                <Typography style={{ ...styles.valueText,...styles.overflowText }}>{this.handleCheckPresent(value) ? value : '-'}</Typography>
            </Box>
        )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
     const wishData =  this.state.wishData?.attributes
     const catalogueList = wishData?.catalogue_list&&wishData?.catalogue_list[0]
    return (
        
        <ContainerBoxFoundRequest >
            <AppHeader
                closeLoginModal={this.closeLoginModal}
                openLoginModal={this.state.openLoginModal}
                count={this.state.cartData?.length}
                data-test-id='appHeader'
            />

            <OuterMainBoxFoundRequest >
                 <ArrowTopBox>
                    <Typography data-test-id='home' onClick={() => this.handleNavigate('Home')} className="arrowHomeText">{configJSON.homeText}</Typography>
                    <img src={rightArrow} alt="rightIcon" />
                    <Typography className="arrowHomeText" data-test-id='WishRequests' onClick={() => this.handleNavigate('WishRequests')}> {configJSON.my_Wishes} </Typography>
                    <img 
                    src={rightArrow} 
                    alt="arrowIcon"
                     />
                    <Typography
                     className="arrowLastText"> {configJSON.details} </Typography>
                </ArrowTopBox>
                    <MainParentBoxFoundRequest>
                    <ImageParentBoxFoundRequest>
                        <Box 
                         sx={{overflowY:this.state.wishData?.attributes?.product_image?.length>6?'scroll':'unset'}}
                         className='smallImagesColumn'
                         >

                            {this.state.wishData?.attributes?.product_image?.length > 0&&this.state.wishData.attributes.product_image.map((element) => (
                                <Box style={{
                                    borderRadius: '4.77px',
                                    border: this.state.isActiveImage == element.id ? '1px solid rgba(83, 80, 63, 1)' : '1px solid transparent',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer',
                                    padding: '2.98px',
                                }} 
                                data-test-id='changeImage'
                                onClick={() => this.handleImageChange(element)}
                                >
                                 <Box  className='smallImageDiv'> <img src={element.url} alt="" /> </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box  className='bigImagesBox' ><img src={this.state.isBigImage?.url} alt="" /></Box>
                    </ImageParentBoxFoundRequest>
                    <RightParentBoxFoundRequest>
                        <RightFirstTopBoxFoundRequest>
                             <Box
                              className='requestIdAndStatusParentBox'
                              ><Typography 
                              
                              className="requestedIdText"
                              >{configJSON.request_Id} <span>{wishData?.request_id}</span></Typography>
                                <Typography 
                                className="statusText"
                                >{wishData?.status}
                                </Typography>
                             </Box>
                             <Box 
                             className='nameDateParentBox'
                             > <Typography 
                                className="nameText"> {wishData?.name}</Typography>
                                <Box  
                                className='dateParentBox'><Typography style={{...styles.titleHeading}}>{configJSON.requested_Date}</Typography>
                                    <Typography className="date"
                                        style={{ ...styles.valueText }}
                                    >{wishData?.request_date}
                                    </Typography>
                                </Box>
                             </Box>
                        </RightFirstTopBoxFoundRequest>
                        <RightMiddleBoxFoundRequest>
                            <Typography
                                className="productDetailsHeading"
                            >{configJSON.productDetails}
                            </Typography>
                            <Box
                                className='productDetailsMainBox'
                            >
                                <Box className='productDetailsMainBoxTopBox'>
                                    {this.handleReturnElement(configJSON.wear, catalogueList?.catalogue_wear)}
                                    {this.handleReturnElement(configJSON.category, catalogueList?.catalogue_product_category)}
                                    {this.handleReturnElement(configJSON.brand, catalogueList?.catalogue_product_brand)}
                                    {this.handleReturnElement(configJSON.condition, catalogueList?.catalogue_product_condition)}
                                    {this.handleReturnElement(configJSON.material, catalogueList?.catalogue_product_material)}
                                </Box>
                                <Box className='productDetailsMainBoxLowerBox'>
                                    {this.handleReturnElement(configJSON.use, catalogueList?.catalogue_product_use)}
                                    {this.handleReturnElement(configJSON.water_Resistance, catalogueList?.catalogue_product_water_resistance)}
                                    {this.handleReturnElement(configJSON.year_of_Production, catalogueList?.catalogue_product_year_production)}
                                    {this.handleReturnElement(configJSON.packages, catalogueList?.catalogue_packages)}
                                    {this.handleReturnElement(configJSON.dustBag, catalogueList?.catalogue_dust_bag ? "No" : "Yes")}

                                </Box>
                                <Box className='productDetailsMainBoxDescBox'>
                                    <Typography style={{ ...styles.titleHeading }}>{configJSON.description}</Typography>
                                    <Typography style={{ ...styles.valueText }}>{this.state.isExpand ? catalogueList?.catalogue_product_description : catalogueList?.catalogue_product_description?.slice(0, 145)} <span data-test-id='readMore' style={{ ...styles.titleHeading, cursor: 'pointer' }} onClick={() => this.setState({ isExpand: !this.state.isExpand })}>{this.state.isExpand ? 'read less' : 'read more..'}</span></Typography>
                                </Box>
                            </Box>
                        </RightMiddleBoxFoundRequest>
                        <RightBottomBoxFoundRequest>
                            <BuyButton data-test-id='buyNow' disabled={this.state.isPresentInCart} onClick={()=>this.buyNowAddToCart(catalogueList?.catalogue_id,catalogueList?.catalogue_variant_id)} className="buyNow" variant="outlined" startIcon={<img src={buyNowIcon} />}>
                                {configJSON.buyNow}
                            </BuyButton>
                            <DeleteButton data-test-id='deleteRequest' className="delete_Request" onClick={()=>this.setState({isRequestDeleteModal:true,deleteId:this.state.wishData?.id})} variant="outlined" startIcon={<img src={deleteIcon} />}>
                                {configJSON.delete_Request}
                            </DeleteButton>
                            <RequestButton data-test-id='request_Details' onClick={()=>this.props?.navigation?.navigate('MyWishDetails',{Id:wishData?.id})} className="request_Details"  variant="outlined" >
                                {configJSON.request_Details}
                            </RequestButton>
                        </RightBottomBoxFoundRequest>
                    </RightParentBoxFoundRequest>
                    </MainParentBoxFoundRequest>
            </OuterMainBoxFoundRequest>

            {this.handleDeleteRequestModal(this.state.deleteId)}

            <Footer
                data-test-id="footerTest"
                openLoginModal={this.openLoginModal}
                handleCategoryData={this.handleCategoryData}
                categoriesList={this.state.categoriesArrayData}
            />

        </ContainerBoxFoundRequest>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBoxFoundRequest = styled(Box)({ backgroundColor: '#EDE6DC',})
const OuterMainBoxFoundRequest = styled(Box)({
    display: 'flex',
    padding: '40px 40px 180px 40px',
    gap: '20px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'center',
    '@media (max-width: 400px) ': { padding: '10px',}
})
const ArrowTopBox = styled(Box)({
    gap: '11px',
    display: 'flex',
    paddingLeft: '6px',
    alignItems: 'center',
    "& .arrowHomeText": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        cursor: 'pointer',
        fontWeight: 400,
    },

    "& .arrowLastText": {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
        fontSize: '18px',
    }

})

const MainParentBoxFoundRequest = styled(Box)({
    display: 'flex',
    gap: '29.81px',
    width: '100%',
    '@media (max-width: 990px) ': {flexDirection:'column',alignItems:'center'}
})
const ImageParentBoxFoundRequest = styled(Box)({
    display: 'flex',
    gap: '7.72px',
    width: '485.19px',
    "& .smallImagesColumn": {height:'479.36px',
        width: "65.57px",
        display: 'flex',
        gap: '7.15px',
        flexDirection: 'column',
    },
    "& .smallImagesColumn>div> .smallImageDiv": {
        height: '62.59px',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '4.77px',
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .smallImagesColumn img": {
        height: '40.41px',
        width: '40.41px',
    },
    "& .bigImagesBox": {
        height: "479.36px",
        width: "411.9px",
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    "& .bigImagesBox img": {
        width: '241.94px',
        objectFit: 'contain',
        height: '241.94px',},
     '@media (max-width: 600px) ': {
        flexDirection:'column-reverse',
        width:'100%',
        alignItems:'center',
        "& .smallImagesColumn": {
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            overflowX: 'scroll',
            height:'unset'
        },
        "& .smallImagesColumn>div> .smallImageDiv": { width: '100px', },
        "& .bigImagesBox": {width:'100%'  }
    }

})
const RightParentBoxFoundRequest = styled(Box)({width: '100%',})

const RightFirstTopBoxFoundRequest = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: '17.93px',
    flexDirection: 'column',
    paddingBottom: '8px',
    boxSizing: 'border-box',
    borderBottom: "1px solid rgba(83, 80, 63, 0.5)",
    "& .requestIdAndStatusParentBox": {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    "& .requestedIdText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    "& .requestedIdText span": {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontSize: '20px',
    },

    "& .statusText": {
        backgroundColor: 'rgba(239, 238, 233, 1)',
        width: '124px',
        borderRadius: '16.45px',
        fontFamily: "'Montserrat', sans-serif",
        border: '0.5px solid rgba(83, 80, 63, 1)',
        fontWeight: 500,
        fontSize: '16px',
        display: 'flex',
        color: 'rgba(83, 80, 63, 1)',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 'normal',
        paddingBottom:'4px',
        paddingTop:'4px',
    },
    "& .dateParentBox": {
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
    },
    "& .nameText": {
        fontSize: '24px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        lineHeight: 'normal',
        overflow: 'hidden',
        color: 'rgba(83, 80, 63, 1)',
        textOverflow: 'ellipsis',
        maxWidth:'250px',
        whiteSpace: 'nowrap' as 'nowrap' ,
    },
    "& .nameDateParentBox": { display: 'flex', justifyContent: 'space-between', },
    "& .date": { textAlign: 'right' },
    "@media (max-width:450px)": {
        "& .nameText": { maxWidth: '150px' }
    }
})
const RightMiddleBoxFoundRequest = styled(Box)({
    
    width: '100%',
    paddingTop:'20px',
    boxSizing:'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    borderBottom:"1px dashed rgba(83, 80, 63, 0.5)",
    paddingBottom:'20px',
    "& .productDetailsHeading":{fontFamily: "'Montserrat', sans-serif",fontSize: '20px',
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 400,
        lineHeight:'normal'
    },
    "& .productDetailsMainBox":{display:'flex', flexDirection:'column',gap:'20px',},
    
    "& .productDetailsMainBoxTopBox, .productDetailsMainBoxLowerBox" :{ display:'grid',gap:'5px',gridTemplateColumns:'repeat(auto-fit, minmax(170px, 1fr))',},
     
    "& .detailElementBox":{
        display:'flex',
        gap:'12px',
        flexDirection:'column',
        width:'170px'
    },
    "& .productDetailsMainBoxDescBox":{display:'flex', flexDirection:'column', gap:'12px',},
})
const RightBottomBoxFoundRequest = styled(Box)({
    
    width: '100%',
    paddingTop: '45px',
    boxSizing: 'border-box',
    display:'flex',
    gap:'10px',
 
 
    '@media (max-width: 600px) ': {
        display: 'flex',
        justifyContent: 'center',
    },
    '@media (max-width: 895px) ': {
        flexDirection:'column',
        alignItems:'center'
    },

    
})
const BuyButton = styled(Button)({
    width: '432px',
    backgroundColor: 'rgba(83, 80, 63, 1)',
    height: '56px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: '8px',
    fontSize: '18px',
    fontWeight: 600,
    color: 'rgba(237, 230, 220, 1)',
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex',
    alignIems: 'center',
    border: '1px solid rgba(83, 80, 63, 1)',
    textDecoration: 'none',
    '&.Mui-disabled': {
        backgroundColor: 'rgba(83, 80, 63, 1)',
        color: 'rgba(237, 230, 220, 1)',
        opacity: 0.5,
        cursor: 'not-allowed'
    },

    "&:hover": {
        backgroundColor: 'rgba(83, 80, 63, 1) !important',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": {
        textDecoration: 'none',
        backgroundColor: 'rgba(83, 80, 63, 1) !important ',
        border: '1px solid rgba(83, 80, 63, 1) !important',
    },
    '@media (max-width: 525px) ': {

        width: '230px'

    },

})
const DeleteButton = styled(Button)({
    width: '192px',
    backgroundColor: 'rgba(239, 238, 233, 1)',
    height: '56px',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    cursor: 'pointer',
    color: 'rgba(83, 80, 63, 1)',
    display: 'flex',
    border: '1px solid rgba(83, 80, 63, 1)',
    alignIems: 'center',
    justifyContent: 'center',

    "&:hover": {
        border: '1px solid rgba(83, 80, 63, 1)',
        backgroundColor: 'rgba(239, 238, 233, 1)',
    },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root :hover": { textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1)',
        backgroundColor: 'rgba(239, 238, 233, 1)',
    },
})
const RequestButton = styled(Button)({
    height: '56px',
    width: '201px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: '8px',
    color: 'rgba(83, 80, 63, 1)',
    fontWeight: 600,
    border: '1px solid rgba(83, 80, 63, 1)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignIems: 'center',
    "&:hover": { backgroundColor: 'transparent ',  border: '1px solid rgba(83, 80, 63, 1)', },
    "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover": {
        textDecoration: 'none',
        border: '1px solid rgba(83, 80, 63, 1) !important',
        backgroundColor: 'transparent !important',
    },
})
const DeleteModalBox=styled(Box)({
    transform: 'translate(-50%, -50%) scale(0.89)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    borderRadius: '4px',
    width: '600px',
    boxSizing: 'border-box',
    padding: '68px 49px',
    display: 'flex',
    backgroundColor: '#EFEEE9',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',
    height: '340px',
    "& .areYouSureText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        color: '#53503F',
        marginTop:'8px',
        fontWeight: 400,
        textAlign:"center",
        maxWidth:'386px',
    },
    "& .paymentSuccessMessage": {
        fontSize: '20px',
        textAlign: "center",
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        marginTop: '16px',
        fontWeight: 400,
        maxWidth: '502px',
    },
    "& .crossIconBox": {
        cursor:'pointer',
        position: 'absolute', right: '28px', top: '28px',
     },
    "@media  (max-width:550px)": { width: 'unset', },
    '@media (max-width:400px )': { padding: '68px 25px', },
    '@media (max-width:362px )': { maxHeight: 'unset', height: 'unset', },
    '@media (max-width:359px )': { width: '100%', },
})

const CancelAndYesButtonBox=styled(Box)({
    display:'flex',
    gap:'50px',
    marginTop:'40px',
    alignItems:'center',
    '@media (max-width:420px )': {   gap:'28.45px',   },
    '@media (max-width:359px )': {   flexDirection:'column'  },
})
const CancelButton=styled(Button)({
    width: '155px',
    backgroundColor: 'transparent',
    border:'1px solid rgba(83, 80, 63, 1)',
    height: '56px',
    textTransform: 'none',
    borderRadius: '4px',
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",
    cursor: 'pointer',
    fontSize: '18px',
    color: '#53503F',
    "&:hover": {  backgroundColor: 'transparent',  },
})

const RemoveDeleteButton=styled(Button)({
    height: '56px',
    width: '155px',
    backgroundColor: '#53503F',
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#EDE6DC',
    "&:hover": {backgroundColor: '#53503F', },
})
const styles = {
    titleHeading: {
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        color: 'rgba(83, 80, 63, 1)',
        fontWeight: 600,
    },
    valueText: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(83, 80, 63, 1)',
        fontFamily: "'Montserrat', sans-serif",
    },
    overflowText:{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: 'hidden',
    },
    confirmationText: {fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 600,
        fontSize: '28px',
    },
}
// Customizable Area End