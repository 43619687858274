import React from "react";

// Customizable Area Start
import OfferFilterController, {
    Props,
} from "./OfferFilterController.web";
import { Box, Button, Typography, Drawer, Slider, Chip, InputAdornment, IconButton, TextField } from "@mui/material";
import { MONTSERRAT_THIN } from '../../../components/src/fontConstants.web';
import { calenderIcon } from './assets';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class OfferFilter extends OfferFilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSubCategory(){
    return (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>Subcategory</Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {this.state.subCategoryData.map((subCat: any) => (
            <Chip
              data-testid="categoryTestId"
              className="montserratFont"
              key={subCat.id}
              label={subCat.name}
              onClick={() => this.handleSubCategorySelect(subCat.id)}
              sx={{
                backgroundColor: this.state.subCategory.includes(subCat.id) ? '#70624D' : '#F5F5F5',
                color: this.state.subCategory.includes(subCat.id) ? '#FFFFFF' : '#333',
                borderRadius: '4px',
                fontWeight: this.state.subCategory.includes(subCat.id) ? 600 : 400,
                padding: '10px 16px',
                height: '40px',
                fontSize: '12px',
                border: '1px solid rgba(83, 80, 63, 1)'
              }}
              clickable
            />
          ))}
        </Box>
      </Box>
    )
  }
  
  handleStatus() {
    return (
      <Box sx={{ marginBottom: '15px' }}>
        <Typography className="montserratFont" sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '8px', color: '#333' }}>
          Status
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
          <Chip
            data-testid="acceptedTestId"
            className="montserratFont"
            label="Accepted"
            onClick={() => this.handleStatusSelect('accepted')}
            sx={{
              backgroundColor: this.state.status === 'accepted' ? '#70624D' : '#F5F5F5', // Highlight selected status
              color: this.state.status === 'accepted' ? '#FFFFFF' : '#333',
              borderRadius: '4px',
              fontWeight: this.state.status === 'accepted' ? 600 : 400,
              padding: '10px 16px',
              height: '40px',
              fontSize: '12px',
              border: '1px solid rgba(83, 80, 63, 1)',
            }}
            clickable
          />
          <Chip
            data-testid="rejectedTestId"
            className="montserratFont"
            label="Rejected"
            onClick={() => this.handleStatusSelect('rejected')}
            sx={{
              backgroundColor: this.state.status === 'rejected' ? '#70624D' : '#F5F5F5', 
              color: this.state.status === 'rejected' ? '#FFFFFF' : '#333',
              borderRadius: '4px',
              fontWeight: this.state.status === 'rejected' ? 600 : 400,
              padding: '10px 16px',
              border: '1px solid rgba(83, 80, 63, 1)',
              height: '40px',
              fontSize: '12px'
            }}
            clickable
          />
        </Box>
      </Box>
    );
  }

  DateDiv(){
    return <div className="date" style={{marginTop:"15px"}}>
      <div style={{display:"flex",gap:"10px",marginTop:"7px"}}>  
      <div>
      <span style={{color:"rgba(83, 80, 63, 1)"}} className="montserratMediumGoogle">From</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ width: '100%', marginTop: '5px',marginBottom: '10px' }}>
          <DatePicker
            data-testid="fromTestId"
            value={this.state.fromDateFilter}
            name='dateOfBirth'
            data-test-id="fromDate"
            onChange={(newValue) => this.handleFromDate(newValue ? new Date(newValue) : null)}
            onClose={() => this.setState({ openFromCalendar: false })}
            open={this.state.openFromCalendar}
            slots={{
              textField: TextField 
            }}
            slotProps={{
              textField: {
                placeholder: "dd/mm/yyyy",
                InputProps: {
                  sx: {
                    '&::placeholder': {
                      textDecoration: 'none',
                      color: '#BABABA'
                    },
                  },
                  endAdornment: ( 
                    <InputAdornment position="end" > 
                      <IconButton  edge="end"  onClick={this.handleFromCalendar}> 
                        <img data-test-id="fromdateIcon" src={calenderIcon} alt='data'/> 
                      </IconButton> 
                    </InputAdornment> 

                  ), 
                },
                sx: {
                  backgroundColor: '#fff',
                  ".MuiPickersCalendarHeader-labelContainer": {
                    transform: 'translateX(-50%)',
                    color: '#53503F', 
                    left: '49%',
                    position: 'absolute', 
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border:'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '15px 14px',
                  },
                  borderRadius: '4px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border:'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border:'none' 
                  },
                },
                fullWidth: true,
              },
              popper: {
                sx: {
                  ".MuiPickersDay-root.Mui-selected": {
                    backgroundColor: '#53503F', 
                    color: '#fff', 
                  },
                  ".MuiPickersArrowSwitcher-root": {
                    justifyContent: 'space-between', 
                    width: '100%', 
                    display: 'flex',
                    color: 'pink', 
                  },              
                  ".MuiPickersDay-root:hover": {
                    color: 'black', 
                    backgroundColor: '#53503F',
                  },                  
                  ".Mui-selected:hover": {
                    backgroundColor: '#53503F',
                  },              
                  ".MuiPickersCalendarHeader-root": {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 106x',
                    position: 'relative', 
                  },              
                  ".MuiPickersArrowSwitcher-root .MuiButtonBase-root": {
                    position: 'absolute', 
                    transform: 'translateY(-50%)', 
                    top: '50%',
                  },
                  ".MuiPickersArrowSwitcher-root .MuiButtonBase-root:first-of-type": {
                    left: '0',
                  },
                  ".MuiPickersArrowSwitcher-root .MuiButtonBase-root:last-of-type": {
                    right: '0', 
                  },
                  ".MuiPickersDay-root": {
                    color: '#53503F',
                  },
                  ".MuiPaper-root": { 
                    backgroundColor: '#fff', 
                    borderRadius: "10px",
                    color: '#53503F', 
                  }, 
                  ".MuiPickersDay-root.Mui-disabled": {
                    color: 'rgba(255, 255, 255, 0.3)', 
                  },
                  ".Mui-selected": {
                    color: 'pink', 
                    backgroundColor: 'red', 
                  },
                }
              }
            }}
          />
        </div>
      </LocalizationProvider>
      </ div>

        <div>
          <span style={{color:"rgba(83, 80, 63, 1)"}} className="montserratMediumGoogle">To</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: '100%',
              marginTop:'5px',
              marginBottom: '10px'}}>
              <DatePicker
                onClose={() => this.setState({ openToCalendar: false })}
                data-testid="toDate"
                name='dateOfBirth'
                open={this.state.openToCalendar}
                value={this.state.toDateFilter}
                slots={{
                  textField: TextField 
                }}
                onChange={(newValue) => this.handleToDate(newValue ? new Date(newValue) : null)}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: ( 
                        <InputAdornment position="end"> 
                          <IconButton data-testid='toCalenderTestId' edge="end"  onClick={this.handleToCalendar}> 
                            <img data-test-id="todateIcon" src={calenderIcon} alt='data'/> 
                          </IconButton> 
                        </InputAdornment>),  
                      sx: {
                        '&::placeholder': {
                          color: '#BABABA',
                          textDecoration: 'none',
                          display:"flex",
                        },
                      },
                    },
                    placeholder: "dd/mm/yyyy",
                    sx: {
                      backgroundColor: '#fff',
                      '& .MuiInputBase-input': {
                        padding: '15px 14px',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border:'none'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border:'none'
                      },
                      '& .Mui-error': {
                        border:'none'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border:'none'
                      },
                      borderRadius: '4px',
                    },
                    fullWidth: true,
                  },
                  popper: {
                    sx: {
                      ".MuiPickersCalendarHeader-root": {
                        justifyContent: 'space-between',
                        color: 'blue', 
                        alignItems: 'center',
                        padding: '0 16px',
                        display: 'flex',
                      },
                      ".MuiPaper-root": { 
                        color: '#53503F', 
                        backgroundColor: '#fff', 
                        borderRadius: "10px",
                      },                            
                      ".MuiPickersCalendarHeader-labelContainer": {
                        left: '49%',
                        transform: 'translateX(-50%)',
                        position: 'absolute', 
                        color: '#53503F', 
                      },
                      ".MuiPickersArrowSwitcher-root": {
                        color: 'pink', 
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%', 
                      },
                      ".Mui-selected:hover": {
                        backgroundColor: '#53503F',
                      },
                      ".Mui-selected": {
                        backgroundColor: 'red', 
                        color: 'pink', 
                      },
                      ".MuiPickersDay-root.Mui-selected": {
                        color: '#fff', 
                        backgroundColor: '#53503F', 
                      },
                      ".MuiPickersDay-root": {
                        color: '#53503F',
                      },
                      ".MuiPickersDay-root:hover": {
                        backgroundColor: '#53503F',
                        color: 'black', 
                      },
                      ".MuiPickersDay-root.Mui-disabled": {
                        color: 'rgba(255, 255, 255, 0.3)', 
                      },
                    },
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>
      </div>
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <Drawer
        open={true}
        anchor="left"
        onClose={() => this.props.handleOfferFilterClose()}
        PaperProps={{ sx: { backgroundColor: 'rgba(239, 238, 233, 1)',width: '400px', padding: '10px 25px', borderRadius: '12px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' } }}
        data-testid="filterTestId"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className="montserratFont" variant="h6" sx={{ color: 'rgba(83, 80, 63, 1)',fontWeight: 600, fontSize: '16px'}}>
            Filters
          </Typography>
          <Button
            className="montserratFont"
            color="error"
            data-testid="clearBtnTestId"
            onClick={this.handleClearAllBtn}
            sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255, 61, 61, 1)', textTransform: 'capitalize' }}
          >
            Clear All
          </Button>
        </Box>

        <Box>
          <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}>
            Listed Price
          </Typography>
          <Slider
            value={this.state.listedPriceRange}
            step={1}
            name="listedPriceTest"
            max={this.state.maxlistedPriceRange}
            min={this.state.minlistedPriceRange}
            onChange={this.handleListedPriceChange}
            valueLabelDisplay="on"
            valueLabelFormat={this.renderValueLabel}
            data-testid="priceSliderTestId"
            sx={{
              margin: '30px 20px',
              color: "#70624D",
              width: '85%',
              height: 4,
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              "& .MuiSlider-thumb": {
                backgroundColor: "#70624D",
                border: "2px solid #FFFFFF",
                height: 20,
                width: 20, 
                "&:hover": {
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", 
                },
              },
              "& .MuiSlider-rail": {
                opacity: 1,
                backgroundColor: "#ffffff",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#70624D",
              },
              "& .MuiSlider-valueLabel": {
                marginLeft: '10px !important', 
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                borderRadius: '4px',
                backgroundColor: "#ffffff",
                border: '1px solid rgba(83, 80, 63, 1)',
                color: "#70624D",
                fontSize: "12px",
                top: -6, 
                fontWeight: 600,
                "&:before": {
                  display: "none"
                },
              },
            }}
          />
        </Box>

        <Box>
          <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}>
            Offer Price
          </Typography>
          <Slider
            step={1}
            value={this.state.offerPriceRange}
            min={this.state.minofferPriceRange}
            max={this.state.maxofferPriceRange}
            onChange={this.handleOfferPriceChange}
            valueLabelDisplay="on"
            data-testid="offerSliderTestId"
            valueLabelFormat={this.renderValueLabel}
            sx={{
              margin: '30px 20px',
              width: '85%',
              height: 4,
              color: "#70624D",
              fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
              "& .MuiSlider-thumb": {
                backgroundColor: "#70624D",
                width: 20, 
                border: "2px solid #FFFFFF",
                height: 20,
                "&:hover": {
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)", 
                },
              },
              "& .MuiSlider-rail": {
                opacity: 1,
                backgroundColor: "#ffffff",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#70624D",
              },
              "& .MuiSlider-valueLabel": {
                fontFamily: `${MONTSERRAT_THIN.fontFamily}, Arial, sans-serif`,
                borderRadius: '4px',
                marginLeft: '10px !important', 
                backgroundColor: "#ffffff",
                border: '1px solid rgba(83, 80, 63, 1)',
                fontSize: "12px",
                color: "#70624D",
                fontWeight: 600,
                top: -6, 
                "&:before": {
                  display: "none"
                },
              },
            }}
          />
        </Box>
        {this.handleSubCategory()}
        <Box>
          <Typography className="montserratFont" sx={{ fontSize: "14px", fontWeight: 400, marginBottom: "8px", color: "#53503F" }}>
            Order Date
          </Typography>
          {this.DateDiv()}
        </Box>
        <Box>{this.handleStatus()}</Box>
        <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          gap: '16px', 
          marginTop: '24px',
          position: 'absolute',  
          bottom: 0,             
          left: 0,              
          right: 0,             
          padding: '16px',   
          backgroundColor: 'rgb(239, 238, 233);',
          marginBottom: '20px'
        }}>
          <Button
            variant="outlined"
            data-testid="offerCancelBtnTestId"
            onClick={() => this.props.handleOfferFilterClose()}
            sx={{
              flex: 1,
              borderRadius: '8px',
              height: '48px',
              color: '#333',
              borderColor: '#333',
              textTransform: 'capitalize',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#70624D',
                border: 'none',
                color: '#FFFFFF'
              },
            }}
            className="montserratFont"
          >
            Cancel
          </Button>
          <Button
            data-testid="applyTestId"
            variant="contained"
            onClick={this.handleApplyButton}
            sx={{
              flex: 1,
              borderRadius: '8px',
              height: '48px',
              backgroundColor: '#70624D',
              color: '#FFFFFF',
              textTransform: 'capitalize',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#70624D',
                border: 'none',
                color: '#FFFFFF', 
              },
            }}
            className="montserratFont"
          >
            Apply
          </Button>
        </Box>
      </Drawer>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    textColor: {
        color: 'red'
    }
};
// Customizable Area End